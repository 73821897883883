import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { AbsentRequest, Talent } from '@spec/Talent';
import React, { useState } from 'react';
import { Form, Strict, useForm, useFormDate } from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { useAbsentTalent } from '../../../../queries/talent';
import { ActionButton } from '../../../ActionButtons';
import { DateInput } from '../../../DateInput';
import { DoDisturbOnIcon } from '../../../Icons';
import { DialogActionButtons, DialogRow, StableDialog } from '../../../StableDialog';

interface Elements {
    leavedAt: Date | null;
}

const guard = (v: Elements): v is Strict<Elements> => v.leavedAt !== null;

const toAbsentRequest = (form: Form<Elements>): AbsentRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('Invalid form values');
    }
    return args;
};

export const AbsentTalent: React.FC<{ talent: Talent }> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useForm<Elements>({
        leavedAt: useFormDate(null, [required]),
    });
    const mutation = useAbsentTalent(props.talent.employment.employeeCode);
    return (
        <>
            <StableDialog noClose open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>従業員の休職</DialogTitle>
                <DialogContent>
                    <Typography>休職日を指定してください。</Typography>
                    <DialogRow label="">
                        <DateInput autoFocus formDate={form.leavedAt} />
                    </DialogRow>
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    startIcon={<DoDisturbOnIcon />}
                    submitLabel="休職設定する"
                    cancelLabel="キャンセル"
                    errorMessage="休職処理に失敗しました"
                    onSubmit={async () => {
                        await mutation.mutateAsync(toAbsentRequest(form));
                    }}
                    closeDialog={() => setDialogOpen(false)}
                />
            </StableDialog>
            <ActionButton startIcon={<DoDisturbOnIcon />} onClick={() => setDialogOpen(true)}>
                この従業員を休職させる
            </ActionButton>
        </>
    );
};
