import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import { Employment } from '@spec/Talent';
import React, { useContext } from 'react';
import { formatDate } from '../../../../lib/DateUtils';
import { DeleteIconButton, EditIconButton } from '../../../ActionButtons';
import { EmploymentContext, useDialogContext } from './Context';
import { EditTalentProps } from './Contract';

export const EmploymentHistories: React.FC<React.PropsWithChildren<EditTalentProps>> = (props) => {
    const theme = useTheme();
    const history = props.talent.employmentHistory ?? [];
    return (
        <Box>
            <Typography variant="subtitle1" color="primary">
                雇用履歴
            </Typography>
            <Paper
                variant="outlined"
                sx={{
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>email</TableCell>
                            <TableCell>社員番号</TableCell>
                            <TableCell>雇用形態</TableCell>
                            <TableCell>新卒</TableCell>
                            <TableCell>入社日</TableCell>
                            <TableCell>退職日</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((v) => (
                            <EmploymentRow key={v.id} employment={v} />
                        ))}
                    </TableBody>
                </Table>
                <AddHistoryButton />
            </Paper>
        </Box>
    );
};

const EmploymentRow: React.FC<{ employment: Employment }> = (props) => {
    const v = props.employment;
    const { setDialogMode } = useDialogContext();
    const context = useContext(EmploymentContext);
    return (
        <TableRow>
            <TableCell>{v.email}</TableCell>
            <TableCell>{v.employeeCode}</TableCell>
            <TableCell>{v.employmentStatus}</TableCell>
            <TableCell>{v.isNewGraduate && '○'}</TableCell>
            <TableCell sx={{ width: '10rem' }}>{formatDate(v.joinedAt)}</TableCell>
            <TableCell sx={{ width: '10rem' }}>{formatDate(v.leavedAt)}</TableCell>
            <TableCell>
                <EditIconButton
                    onClick={() => {
                        setDialogMode('EditEmployment');
                        context.employment.setValue(v);
                    }}
                />
                <DeleteIconButton
                    onClick={() => {
                        setDialogMode('DeleteEmployment');
                        context.employment.setValue(v);
                    }}
                />
            </TableCell>
        </TableRow>
    );
};

const AddHistoryButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <Box mt={2} textAlign="center">
            <Button
                size="medium"
                variant="outlined"
                color="primary"
                onClick={() => {
                    setDialogMode('AddEmployment');
                }}
            >
                新しい雇用履歴を追加する
            </Button>
        </Box>
    );
};
