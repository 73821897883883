import { DialogContent, DialogTitle } from '@mui/material';
import { useAddSurveyPeriod } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../survey/Context';
import { useCurrentGroupContext } from '../Context';
import { PeriodFormContent, toEditPeriodRequest, usePeriodForm } from '../PeriodForm';

export const AddPeriodDialog: React.FC = () => {
    const { surveyGroup } = useCurrentGroupContext();
    const { closeDialog } = useDialogContext();
    const form = usePeriodForm({
        name: '',
        openedAt: null,
        closedAt: null,
    });
    const mutation = useAddSurveyPeriod(surveyGroup.id);
    return (
        <>
            <DialogTitle>実施期間を追加する</DialogTitle>
            <DialogContent>
                <PeriodFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で実施期間を作成する"
                cancelLabel="作成せずに閉じる"
                errorMessage="作成に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditPeriodRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
