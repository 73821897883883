import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { TeamId, TeamIntroduction, TeamIntroductionsResponse } from '@spec/Organization';
import { useState } from 'react';
import { useForm, useFormText } from '../../../lib/Form';
import { useUpdateTeamIntroduction } from '../../../queries/organization';
import { ActionContainer } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { EditIcon } from '../../Icons';
import { ArticleMarkdown } from '../../Markdown';
import { SubTitle } from '../../PageTitle';
import { SubmitButton } from '../../SubmitButton';
import { TiptapEditor } from '../../notes/tiptap/TiptapEditor';

export const Introduction: React.FC<{
    teamId: TeamId;
    introductions: TeamIntroductionsResponse;
}> = (props) => {
    const theme = useTheme();
    const [edit, setEdit] = useState(false);
    const introduction = props.introductions.introductions.find((v) => v.teamId === props.teamId);
    if (introduction === undefined && edit === false) {
        return (
            <FlexBox>
                <Box flexGrow={1} textAlign="center">
                    <Typography color="text.disabled">組織の紹介はまだ書かれていません</Typography>
                </Box>
                <Button variant="outlined" startIcon={<EditIcon />} onClick={() => setEdit(true)}>
                    組織の紹介を書く
                </Button>
            </FlexBox>
        );
    }
    return (
        <Card
            sx={{
                backgroundColor: theme.palette.background.default,
            }}
            elevation={1}
            square={false}
        >
            <CardContent sx={{ py: 0, px: 2 }}>
                {edit === true ? (
                    <EditForm
                        teamId={props.teamId}
                        introduction={introduction}
                        onClose={() => setEdit(false)}
                    />
                ) : (
                    <FlexBox alignItems="flex-start">
                        <Box flexGrow={1}>
                            <ArticleMarkdown source={introduction?.summary ?? ''} fontSize={13} />
                            <ArticleMarkdown
                                source={introduction?.description ?? ''}
                                fontSize={13}
                            />
                        </Box>
                        <Box pt={2}>
                            <Tooltip arrow placement="top" title="組織の紹介は誰でも編集できます">
                                <Button
                                    variant="outlined"
                                    startIcon={<EditIcon />}
                                    onClick={() => setEdit(true)}
                                >
                                    編集する
                                </Button>
                            </Tooltip>
                        </Box>
                    </FlexBox>
                )}
            </CardContent>
        </Card>
    );
};

const EditForm: React.FC<{
    teamId: TeamId;
    introduction?: TeamIntroduction;
    onClose: () => void;
}> = (props) => {
    const form = useForm({
        summary: useFormText(props.introduction?.summary ?? ''),
        description: useFormText(props.introduction?.description ?? ''),
    });
    const mutation = useUpdateTeamIntroduction(props.teamId);
    return (
        <Box py={2}>
            <Alert severity="info">
                組織の一覧に表示されても見づらくならない程度に簡潔に書いてください。詳細はノートやGoogleDosに書いて、ここにはそのリンクを置くのもおすすめです。
            </Alert>
            <SubTitle
                mt={2}
                title="概要 : 組織の一覧などに表示します。1, 2行程度で簡潔に何の部署かを紹介してください。"
            />
            <Box mt={1}>
                <TiptapEditor formText={form.summary} />
            </Box>
            <SubTitle
                mt={2}
                title="詳細 : 組織の詳細画面などで表示します。部署の説明などの他にも、各種資料へのリンクなどを置くのがおすすめです。"
            />
            <Box mt={1}>
                <TiptapEditor formText={form.description} />
            </Box>
            <ActionContainer mt={2}>
                <FlexBox gap={4}>
                    <Box flexGrow={1}>
                        <Button fullWidth variant="outlined" onClick={() => props.onClose()}>
                            キャンセル
                        </Button>
                    </Box>
                    <Box flexGrow={1}>
                        <SubmitButton
                            fullWidth
                            variant="contained"
                            disabled={!form.canSubmit}
                            inProgress={mutation.isPending}
                            onClick={async () => {
                                await mutation.mutateAsync({
                                    summary: form.summary.value,
                                    description: form.description.value,
                                });
                                props.onClose();
                            }}
                        >
                            変更を保存する
                        </SubmitButton>
                    </Box>
                </FlexBox>
            </ActionContainer>
        </Box>
    );
};
