import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteSurveyGroup } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useTargetGroupContext } from '../Context';

export const DeleteGroupDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { group } = useTargetGroupContext();
    if (group === null) {
        throw Error('target group was not set');
    }
    const form = useEmptyForm();
    const mutation = useDeleteSurveyGroup(group.id);
    return (
        <>
            <DialogTitle>サーベイグループ「{group.name}」を削除する</DialogTitle>
            <DialogContent>
                <DialogRow label="名称">
                    <Typography>{group.name}</Typography>
                </DialogRow>
                <DialogRow label="概要">
                    <Typography>{group.description}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でサーベイグループを削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
