import { Box, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import dayjs from 'dayjs';
import { fullName } from '../../domains/Talent';
import { findById } from '../../lib/ArrayUtils';
import { useTalentsContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { IconText } from '../IconText';
import { AccessTimeIcon } from '../Icons';
import { RouterLink } from '../RouterLink';
import { TalentAvatar } from '../TalentAvatar';
import { HitonowaId } from '../talent/HitonowaId';
import { getAuthorUrl } from './urls';

export const ArticleAuthor: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const { talents } = useTalentsContext();
    const author = findById(props.article.talentId, talents);
    const url = getAuthorUrl(author.hitonowaId);
    return (
        <FlexBox mt={1} gap={1} flexWrap="nowrap" alignItems="flex-start">
            <RouterLink to={url}>
                <TalentAvatar size="medium" talent={author} />
            </RouterLink>
            <Box>
                <Typography variant="body2" sx={{ mt: '2px', lineHeight: 1 }}>
                    {fullName(author)}
                    {author.nicknames !== '' && `（${author.nicknames}）`}
                </Typography>
                <FlexBox gap={1}>
                    <RouterLink variant="body2" to={url}>
                        <HitonowaId hitonowaId={author.hitonowaId} />
                    </RouterLink>
                    <IconText icon={AccessTimeIcon}>
                        {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                    </IconText>
                </FlexBox>
            </Box>
        </FlexBox>
    );
};
