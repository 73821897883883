import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { ApplicationError } from '../../../../../Errors';
import { useUpdateTodoCategory } from '../../../../../queries/todoCategoryAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useEditCategoryContext } from '../Context';
import { TodoItemFormContent, useTodoCategoryForm } from './TodoCategoryForm';

export const EditCategoryDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { category } = useEditCategoryContext();
    if (category === null) {
        throw new ApplicationError('Target category is null');
    }
    const form = useTodoCategoryForm({
        label: category.label,
    });
    const mutation = useUpdateTodoCategory(category.id);
    return (
        <>
            <DialogTitle>カテゴリの更新</DialogTitle>
            <DialogContent>
                <TodoItemFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でカテゴリを更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
