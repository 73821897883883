import { Typography } from '@mui/material';
import React from 'react';
import { getIamUrl } from '../../Routes';
import { FlexBox } from '../FlexBox';
import { RouterLink } from '../RouterLink';

export const HitonowaId: React.FC<
    React.PropsWithChildren<{
        hitonowaId: string | null;
        enableLink?: boolean;
    }>
> = (props) => {
    if (props.hitonowaId === null) {
        return null;
    }
    return (
        <FlexBox flexWrap="nowrap" gap={0.5}>
            <img src="/favicon-32x32.png" width="20" height="20" />
            {props.enableLink ? (
                <RouterLink variant="body2" to={getIamUrl(props.hitonowaId)}>
                    {props.hitonowaId}
                </RouterLink>
            ) : (
                <Typography variant="body2">{props.hitonowaId}</Typography>
            )}
        </FlexBox>
    );
};
