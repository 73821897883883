import React from 'react';
import { Box, Container } from '@mui/material';
import { ActionContainer } from '../../../ActionButtons';
import { SubTitle } from '../../../PageTitle';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { AddCategoryDialog } from './dialogs/AddCategoryDialog';
import { DeleteCategoryDialog } from './dialogs/DeleteCategoryDialog';
import { EditCategoryDialog } from './dialogs/EditCategoryDialog';
import { AddCategoryButton } from './AddCategoryButton';
import { CategoriesTable } from './CategoriesTable';
import { ContextProvider, DialogMode } from './Context';

export const TodoCategories: React.FC = () => {
    return (
        <ContextProvider>
            <SubTitle title="カテゴリの一覧" />
            <Box mt={2}>
                <Container maxWidth="sm">
                    <CategoriesTable />
                </Container>
            </Box>
            <ActionContainer>
                <AddCategoryButton />
            </ActionContainer>
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddCategory: AddCategoryDialog,
    EditCategory: EditCategoryDialog,
    DeleteCategory: DeleteCategoryDialog,
};
