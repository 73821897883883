import { DialogContent, DialogTitle } from '@mui/material';
import { Team, UpdateTeamRequest } from '@spec/Organization';
import React from 'react';
import {
    Form,
    FormCheckBox,
    FormTextField,
    Strict,
    useForm,
    useFormBoolean,
    useFormDate,
    useFormText,
} from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { useCreateTeam } from '../../../../queries/organization';
import { DateInput } from '../../../DateInput';
import { DialogActionButtons, DialogRow } from '../../../StableDialog';
import { useDialogContext, useSelectedTeamContext } from '../Context';

interface Elements {
    code: string;
    name: string;
    establishedAt: Date | null;
    isCorporate: boolean;
    isBaseTeam: boolean;
}

const guard = (v: Elements): v is Strict<Elements> => v.establishedAt !== null;

const toUpdateTeamRequest = (form: Form<Elements>, parentTeam: Team): UpdateTeamRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('Invalid form values');
    }
    return {
        ...args,
        parentId: parentTeam.id,
        code: args.code === '' ? null : args.code,
    };
};

export const CreateDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { selectedTeam } = useSelectedTeamContext();
    if (selectedTeam.value === null) {
        throw Error('Team does not selected.');
    }
    const parentTeam = selectedTeam.value;
    const form = useForm<Elements>({
        code: useFormText(''),
        name: useFormText('', [required]),
        establishedAt: useFormDate(null, [required]),
        isCorporate: useFormBoolean(false),
        isBaseTeam: useFormBoolean(false),
    });
    const mutation = useCreateTeam();
    return (
        <>
            <DialogTitle>「{parentTeam.name}」の下に新しい組織を追加します</DialogTitle>
            <DialogContent>
                <DialogRow label="組織コード">
                    <FormTextField autoFocus fullWidth formText={form.code} />
                </DialogRow>
                <DialogRow label="部署名">
                    <FormTextField fullWidth formText={form.name} />
                </DialogRow>
                <DialogRow label="設立日">
                    <DateInput formDate={form.establishedAt} />
                </DialogRow>
                <DialogRow label="法人フラグ">
                    <FormCheckBox
                        formBoolean={form.isCorporate}
                        label="この組織を法人として扱う"
                        name="isCorporate"
                    />
                </DialogRow>
                <DialogRow label="ざっくり所属フラグ">
                    <FormCheckBox
                        formBoolean={form.isBaseTeam}
                        label="この組織をざっくり所属とみなす"
                        name="isBaseTeam"
                    />
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="組織を作成する"
                cancelLabel="キャンセル"
                errorMessage="作成に失敗しました"
                onSubmit={async () => {
                    // mutate function returns created team id, but we don't need it
                    await mutation.mutateAsync(toUpdateTeamRequest(form, parentTeam));
                }}
                closeDialog={closeDialog}
            />
        </>
    );
};
