import { useTheme } from '@mui/material';
import React from 'react';

const ORIGINAL_WIDTH = 183.784;
const ORIGINAL_HEIGHT = 79.054;

export const Logo: React.FC<React.PropsWithChildren<{ size?: number }>> = (props) => {
    const theme = useTheme();
    const color = theme.palette.text.primary;
    const width = props.size ?? ORIGINAL_WIDTH;
    const height = (width / ORIGINAL_WIDTH) * ORIGINAL_HEIGHT;
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 184.46 75.001"
            enableBackground="new 0 0 184.46 75.001"
            xmlSpace="preserve"
            fill={color}
        >
            <path
                d="M16.88,4.302v11.905c9.345-2.353,13.779-3.792,21.909-8.305l6.97,8.209c-7.339,3.072-18.901,7.345-28.879,9.313v7.44
	c0,1.968,0.105,2.4,2.903,2.4h13.41c6.389,0,11.404-1.05,13.094-1.435v9.883c-4.065,0.624-8.131,0.769-9.979,0.769H17.514
	c-6.863,0-11.352-1.873-11.352-8.545V4.302H16.88z"
            />
            <path
                d="M66.727,3.294v10.801c7.075,2.064,11.894,3.656,18.954,7.008v10.728c-4.987-2.704-13.252-5.59-18.954-7.799V45.49H55.429
	V3.294H66.727z"
            />
            <path d="M92.807,33.816c10.771-2.458,20.711-7.689,23.295-25.694h10c-1.709,19.13-12.773,30.11-33.295,35.36V33.816z" />
            <path
                d="M179.032,4.782v9.169c0,3.6-1.109,10.561-3.01,14.833c-4.171,9.313-19.338,16.463-34.671,17.233v-8.521
	c15.47-1.68,26.646-4.584,26.488-23.93h-21.541v11.809h-10.665V4.782H179.032z"
            />
            <path
                d="M8.162,55.664h2.429v7.249h0.056c0.386-0.601,0.993-1.152,1.738-1.488c0.718-0.385,1.573-0.6,2.484-0.6
	c1.794,0,4.665,0.959,4.665,4.968v6.913h-2.429v-6.673c0-1.896-0.801-3.456-3.092-3.456c-1.574,0-2.788,0.959-3.258,2.111
	c-0.138,0.289-0.165,0.601-0.165,1.008v7.01H8.162V55.664z"
            />
            <path
                d="M34.394,58.952c-0.91,0-1.518-0.624-1.518-1.368s0.635-1.344,1.572-1.344c0.939,0,1.547,0.6,1.547,1.344
	s-0.607,1.368-1.574,1.368H34.394z M33.234,72.706V61.089h2.429v11.617H33.234z"
            />
            <path
                d="M55.505,58.305v2.784h3.479v1.607h-3.479v6.266c0,1.439,0.47,2.256,1.822,2.256c0.662,0,1.049-0.049,1.408-0.145
	l0.109,1.608c-0.469,0.144-1.214,0.288-2.152,0.288c-1.132,0-2.043-0.336-2.623-0.889c-0.662-0.647-0.938-1.68-0.938-3.048v-6.337
	h-2.07v-1.607h2.07v-2.137L55.505,58.305z"
            />
            <path
                d="M78.091,72.97c-3.589,0-6.403-2.305-6.403-5.977c0-3.889,2.953-6.168,6.625-6.168c3.836,0,6.431,2.424,6.431,5.951
	c0,4.321-3.45,6.193-6.624,6.193H78.091z M78.174,71.386c2.318,0,4.058-1.896,4.058-4.537c0-1.968-1.132-4.439-4.003-4.439
	c-2.843,0-4.085,2.304-4.085,4.512c0,2.545,1.656,4.465,4.003,4.465H78.174z"
            />
            <path
                d="M95.707,64.233c0-1.225-0.028-2.185-0.111-3.145h2.153l0.138,1.896h0.056c0.663-1.08,2.208-2.16,4.417-2.16
	c1.849,0,4.72,0.959,4.72,4.943v6.938h-2.429v-6.697c0-1.872-0.801-3.456-3.092-3.456c-1.574,0-2.816,0.983-3.258,2.16
	c-0.109,0.265-0.165,0.647-0.165,0.983v7.01h-2.429V64.233z"
            />
            <path
                d="M125.335,72.97c-3.589,0-6.403-2.305-6.403-5.977c0-3.889,2.953-6.168,6.625-6.168c3.836,0,6.431,2.424,6.431,5.951
	c0,4.321-3.45,6.193-6.624,6.193H125.335z M125.418,71.386c2.318,0,4.058-1.896,4.058-4.537c0-1.968-1.132-4.439-4.003-4.439
	c-2.843,0-4.085,2.304-4.085,4.512c0,2.545,1.656,4.465,4.003,4.465H125.418z"
            />
            <path
                d="M143.364,61.089l1.794,5.881c0.358,1.295,0.718,2.52,0.966,3.72h0.083c0.304-1.177,0.745-2.425,1.187-3.696l2.181-5.904
	h2.043l2.07,5.809c0.497,1.367,0.884,2.615,1.187,3.792h0.083c0.221-1.177,0.58-2.4,1.021-3.769l1.904-5.832h2.402l-4.307,11.617
	h-2.208l-2.043-5.545c-0.469-1.32-0.855-2.473-1.187-3.84h-0.056c-0.331,1.392-0.745,2.615-1.214,3.863l-2.153,5.521h-2.208
	l-4.03-11.617H143.364z"
            />
            <path
                d="M176.839,69.921c0,1.008,0.056,1.992,0.193,2.785h-2.181l-0.221-1.465h-0.083c-0.718,0.912-2.181,1.729-4.085,1.729
	c-2.705,0-4.086-1.656-4.086-3.336c0-2.809,2.871-4.345,8.033-4.32v-0.24c0-0.961-0.304-2.713-3.037-2.688
	c-1.27,0-2.566,0.312-3.505,0.864l-0.553-1.416c1.104-0.601,2.733-1.008,4.417-1.008c4.112,0,5.106,2.424,5.106,4.752V69.921z
	 M174.465,66.776c-2.649-0.047-5.658,0.361-5.658,2.617c0,1.393,1.049,2.016,2.263,2.016c1.767,0,2.898-0.96,3.285-1.943
	c0.083-0.217,0.11-0.457,0.11-0.672V66.776z"
            />
            <polygon points="182.051,50.205 3.091,50.076 3.092,49.076 182.051,49.205 182.051,50.205 " />
        </svg>
    );
};
