import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTalents } from '../../queries/talent';
import { FeedIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { Loading } from '../WaitLoading';
import { BackToIndex } from './BackToIndex';
import { ContextProvider } from './Context';

export const Layout: React.FC = () => {
    const maybeTalents = useTalents();
    return (
        <Box>
            <PageTitle icon={FeedIcon} title="ノート" />
            <BackToIndex />
            <ContextProvider>{maybeTalents.isFetching ? <Loading /> : <Outlet />}</ContextProvider>
        </Box>
    );
};
