import dayjs from 'dayjs';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NewcomerList } from './NewcomerList';

export const Newcomers: React.FC = () => {
    const { year, month } = useParams();
    const date = dayjs(`${year}-${month}-01`);
    if (date.isValid() === false) {
        throw Error(`invalid year/month: ${year}/${month}`);
    }
    return <NewcomerList year={date.year()} month={date.month() + 1} defaultOpen />;
};
