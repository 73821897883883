import { TimelineDot } from '@mui/lab';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { TodoItem } from '@spec/Todo';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { getStatus } from '../../../../domains/Todo';
import { findById, sortByKey } from '../../../../lib/ArrayUtils';
import { FlexBox } from '../../../FlexBox';
import { Pager, sliceItems } from '../../../Pager';
import { RouterLink } from '../../../RouterLink';
import { getTodoItemAdminUrl } from '../../urls';
import { useTodoItemsContext } from '../Context';
import { getStatusLabel } from '../Contract';
import { useFilterFormContext } from './Context';

export const TodoItemTable: React.FC = () => {
    const { items } = useTodoItemsContext();
    const { condition } = useFilterFormContext();
    const [page, setPage] = useState(1);
    const itemsPerPage = 30;
    if (items.length === 0) {
        return <Typography color="error">やることが登録されていません</Typography>;
    }
    const filteredItems = sortByKey(items, 'id', 'desc')
        .filter((v) => {
            if (condition.categoryId === null) {
                return true;
            }
            return v.todoCategoryId === condition.categoryId;
        })
        .filter((v) => (condition.showFinishedItem ? true : getStatus(v) !== 'finished'));
    const slicedItems = sliceItems(filteredItems, page, itemsPerPage);
    return (
        <Box>
            <Pager
                current={page}
                setPage={setPage}
                amount={filteredItems.length}
                perItems={itemsPerPage}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>カテゴリ</TableCell>
                        <TableCell>タイトル</TableCell>
                        <TableCell>状態</TableCell>
                        <TableCell>締切</TableCell>
                        <TableCell>リマインド</TableCell>
                        <TableCell>完了者 / 対象者</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {slicedItems.map((v) => (
                        <QuestRow key={v.id} item={v} />
                    ))}
                </TableBody>
            </Table>
            <Pager
                current={page}
                setPage={setPage}
                amount={filteredItems.length}
                perItems={itemsPerPage}
            />
        </Box>
    );
};

const ItemStatus: React.FC<{ item: TodoItem }> = (props) => {
    const { color, label } = getStatusLabel(getStatus(props.item));
    return (
        <FlexBox flexWrap="nowrap" gap={0.5}>
            <TimelineDot color={color} sx={{ margin: 0, alignSelf: 'center' }} />
            {label}
        </FlexBox>
    );
};

const QuestRow: React.FC<{ item: TodoItem }> = (props) => {
    const { categories } = useTodoItemsContext();
    const { stats } = useTodoItemsContext();
    const v = props.item;
    const stat = stats.find((s) => s.todoItemId === v.id);
    return (
        <TableRow>
            <TableCell>{findById(v.todoCategoryId, categories).label}</TableCell>
            <TableCell sx={{ whiteSpace: 'normal' }}>
                <RouterLink to={getTodoItemAdminUrl(v.id)} variant="body2">
                    {v.title}
                </RouterLink>
            </TableCell>
            <TableCell>
                <ItemStatus item={v} />
            </TableCell>
            <TableCell>
                {v.deadline ? dayjs(v.deadline).format('YYYY-MM-DD (ddd) HH:mm') : '締切なし'}
            </TableCell>
            <TableCell>
                {v.reminders.length > 0 ? (
                    <>
                        {v.reminders.map((v, i) => (
                            <Typography key={i} variant="body2">
                                {dayjs(v).format('YYYY-MM-DD (ddd) HH:mm')}
                            </Typography>
                        ))}
                    </>
                ) : (
                    'なし'
                )}
            </TableCell>
            <TableCell>
                {stat?.completed ?? 0} / {stat?.assignees ?? 0}
            </TableCell>
        </TableRow>
    );
};
