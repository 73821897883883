import React from 'react';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { Typography } from '@mui/material';
import { getStatus } from '../../../../domains/Todo';
import { ActionButton, ActionContainer } from '../../../ActionButtons';
import { PersonAddIcon } from '../../../Icons';
import { useDialogContext, useTodoItemContext } from './Context';

export const AssignButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    const { item } = useTodoItemContext();
    const disabled = getStatus(item) === 'finished';
    return (
        <ActionContainer>
            {disabled === true && (
                <Typography color="error">公開終了したやることの対象者は変更できません</Typography>
            )}
            <ActionButton
                disabled={disabled}
                startIcon={<PersonAddIcon />}
                onClick={() => setDialogMode('Assign')}
            >
                対象者を検索して追加する
            </ActionButton>
            <ActionButton
                disabled={disabled}
                startIcon={<ContentPasteGoIcon />}
                onClick={() => setDialogMode('Paste')}
            >
                対象者を貼り付けて追加する
            </ActionButton>
        </ActionContainer>
    );
};
