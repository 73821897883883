import { Survey, SurveyGroup, SurveysResponse } from '@spec/Survey';
import { UseQueryResult } from '@tanstack/react-query';
import React, { ReactNode, createContext, useContext } from 'react';
import { TalentsContextProvider } from '../Context';
import { WaitQuery } from '../WaitLoading';

const SurveysContext = createContext(
    {} as {
        surveyGroups: SurveyGroup[];
        surveys: Survey[];
    }
);
export const SurveysContextProvider: React.FC<{
    maybeSurveys: UseQueryResult<SurveysResponse>;
    children: ReactNode;
}> = (props) => {
    return (
        <TalentsContextProvider>
            <WaitQuery query={props.maybeSurveys}>
                {({ data }) => (
                    <SurveysContext.Provider
                        value={{ surveyGroups: data.surveyGroups, surveys: data.surveys }}
                    >
                        {props.children}
                    </SurveysContext.Provider>
                )}
            </WaitQuery>
        </TalentsContextProvider>
    );
};
export const useSurveysContext = () => useContext(SurveysContext);
