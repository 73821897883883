import { Box, Typography } from '@mui/material';
import { SurveyGroup, SurveyPeriod, SurveyQuestion, SurveyResponse } from '@spec/Survey';
import { Talent } from '@spec/Talent';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isFulfilledResponse, isLikertResponse } from '../../../../domains/Survey';
import { findById, sortByKey } from '../../../../lib/ArrayUtils';
import { queryToArray } from '../../../../queries';
import { useMeContext } from '../../../../queries/me';
import { useSurveyTalentReport } from '../../../../queries/survey';
import { useTalents } from '../../../../queries/talent';
import { SubTitle } from '../../../PageTitle';
import { WaitLoading } from '../../../WaitLoading';
import { TalentSearch } from '../../../search/TalentSearch';
import { useSurveysContext } from '../../Context';
import { Memos } from './Memos';
import { ResponseCard } from './ResponseCard';
import { ScoreList } from './ScoreList';
import { TalentCard } from './TalentCard';
import { TalentChart } from './TalentChart';

export const TalentReport: React.FC<{ surveyGroup: SurveyGroup }> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const employeeCode = searchParams.get('code');
    const maybeTalents = useTalents();
    const [talent, setTalent] = useState<Talent | null>(null);
    useEffect(() => {
        const t = queryToArray(maybeTalents).find(
            (v) => v.employment.employeeCode === employeeCode
        );
        if (t !== undefined) {
            setTalent(t);
        }
    }, [employeeCode, maybeTalents]);
    return (
        <Box>
            <Typography>人を見る</Typography>
            <TalentSearch
                value={talent}
                onChange={(t) => {
                    setSearchParams({ code: t?.employment.employeeCode ?? '' });
                    setTalent(t);
                }}
            />
            {talent !== null && <X group={props.surveyGroup} talent={talent} />}
        </Box>
    );
};

const X: React.FC<{ group: SurveyGroup; talent: Talent }> = (props) => {
    const maybeReport = useSurveyTalentReport(props.group.id, props.talent.employment.employeeCode);
    const responses = (maybeReport.data ?? []).filter(isFulfilledResponse);
    return (
        <WaitLoading waitFor={[maybeReport]}>
            <Y group={props.group} responses={responses} talent={props.talent} />
        </WaitLoading>
    );
};

const Y: React.FC<{ group: SurveyGroup; responses: SurveyResponse[]; talent: Talent }> = (
    props
) => {
    const periodIds = new Set(props.responses.map((v) => v.surveyPeriodId));
    const periods = sortByKey(
        props.group.periods.filter((v) => periodIds.has(v.id)),
        'openedAt',
        'desc'
    );
    const { surveys } = useSurveysContext();
    const questionIds = new Set(props.responses.map((v) => v.questionId));
    const questions = surveys.map((v) => v.questions.filter((q) => questionIds.has(q.id))).flat();
    return (
        <Box mt={2}>
            <TalentCard talent={props.talent} />
            <TalentChart group={props.group} responses={props.responses.filter(isLikertResponse)} />
            {periods.map((period, index) => {
                const responses = props.responses.filter((v) => v.surveyPeriodId === period.id);
                return (
                    <PeriodItem
                        key={period.id}
                        talent={props.talent}
                        period={period}
                        questions={questions}
                        responses={responses}
                        index={index}
                    />
                );
            })}
        </Box>
    );
};

const PeriodItem: React.FC<{
    talent: Talent;
    period: SurveyPeriod;
    questions: SurveyQuestion[];
    responses: SurveyResponse[];
    index: number;
}> = (props) => {
    const { talent, period, questions, responses, index } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    useEffect(() => {
        const key = decodeURIComponent(location.hash).replace('#', '');
        if (key === period.name && ref?.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [location.hash, period.name]);
    const { grants } = useMeContext();
    const isOperable = grants.survey.operableSurveyGroups.includes(props.period.surveyGroupId);
    return (
        <div ref={ref}>
            <Box mt={3}>
                <SubTitle title={period.name} />
                <Box mt={1} mx={1}>
                    <ScoreList
                        questions={questions}
                        responses={responses.filter(isLikertResponse)}
                    />
                </Box>
                <Box mt={0.5} mx={1}>
                    {responses
                        .filter((v) => !isLikertResponse(v))
                        .map((v) => (
                            <ResponseCard
                                key={v.id}
                                question={findById(v.questionId, questions)}
                                response={v}
                            />
                        ))}
                </Box>
                {isOperable === true && <Memos period={period} talent={talent} index={index} />}
            </Box>
        </div>
    );
};
