import { Alert, Box, List, ListItemButton, Typography } from '@mui/material';
import React from 'react';
import { RouterLink } from '../RouterLink';
import { useApiReferenceContext } from './Context';
import { HowTo } from './HowTo';
import { getApiReferenceUrl } from './urls';

export const ApiReferences: React.FC = () => {
    const { references } = useApiReferenceContext();
    return (
        <Box>
            <Alert severity="warning">
                このページには開発者向けの情報が掲載されています。フィードバックはSlack
                #hitonowaへどうぞ。
            </Alert>
            <Box my={4}>
                <HowTo />
            </Box>
            <Typography variant="h5">API categories</Typography>
            <List>
                {[...references.keys()].map((category) => (
                    <ListItemButton
                        key={category}
                        component={RouterLink}
                        to={getApiReferenceUrl(category)}
                    >
                        {category}
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};
