import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { queryToArray } from '../../queries';
import { useTodoCategories } from '../../queries/todoCategory';
import { FactCheckIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { WaitLoading } from '../WaitLoading';
import { BackToIndex } from './BackToIndex';
import { ContextProvider } from './Context';

export const Layout: React.FC = () => {
    const maybeTodoCategories = useTodoCategories();
    return (
        <Box>
            <PageTitle icon={FactCheckIcon} title="やることリスト" />
            <BackToIndex />
            <WaitLoading waitFor={[maybeTodoCategories]}>
                <ContextProvider categories={queryToArray(maybeTodoCategories)}>
                    <Outlet />
                </ContextProvider>
            </WaitLoading>
        </Box>
    );
};
