import { Alert, Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { Grade, UpdateTalentRequest } from '@spec/Talent';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationError } from '../../../../Errors';
import { getTalentUrl } from '../../../../Routes';
import { FormTheme } from '../../../../Theme';
import { createHitonowaIdValidator, gradeToString, hiraToKana } from '../../../../domains/Talent';
import {
    Discipline,
    Form,
    FormCheckBox,
    FormText,
    FormTextField,
    useForm,
    useFormBoolean,
    useFormDate,
    useFormNumber,
    useFormText,
} from '../../../../lib/Form';
import { toLowerCase, zenToHan } from '../../../../lib/Form/Filters';
import { required } from '../../../../lib/Form/Validators';
import { queryToArray } from '../../../../queries';
import { useGrades, useTalents, useUpdateTalent } from '../../../../queries/talent';
import { ActionContainer } from '../../../ActionButtons';
import { DateInput } from '../../../DateInput';
import { FlexBox } from '../../../FlexBox';
import { FootNote } from '../../../FootNote';
import { GradeSelector } from '../../../GradeSelector';
import { SubmitButton } from '../../../SubmitButton';
import { TeamSelector } from '../../../TeamSelector';
import { WaitQuery } from '../../../WaitLoading';
import { NOT_SELECTED_WORKPLACE, WorkplaceSelector } from '../../../WorkplaceSelector';
import { HitonowaId } from '../../HitonowaId';
import { EditTalentProps } from './Contract';

interface Elements {
    hitonowaId: string;
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    romanName: string;
    emailAlias: string;
    slackId: string;
    teamId: number | null;
    position: string;
    gradeText: string;
    workplace: string;
    isNewGraduate: boolean;
    canSignIn: boolean;
    joinedAt: Date | null;
}

const guard = (v: Elements): v is Discipline<Elements, 'teamId' | 'joinedAt'> =>
    v.teamId !== null && v.joinedAt !== null;

const generateRequest = (form: Form<Elements>, grades: Map<string, Grade>): UpdateTalentRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw new ApplicationError('invalid parameter');
    }
    const { gradeText, ...rest } = args;
    return {
        ...rest,
        slackId: form.slackId.value === '' ? null : form.slackId.value,
        grade: grades.get(form.gradeText.value) ?? null,
        workplace: form.workplace.value === NOT_SELECTED_WORKPLACE ? null : form.workplace.value,
    };
};

export const EditPersonality: React.FC<React.PropsWithChildren<EditTalentProps>> = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const talents = useTalents();
    const otherTalents = queryToArray(talents).filter((v) => v.id !== props.talent.id);

    const form = useForm<Elements>({
        hitonowaId: useFormText(
            props.talent.hitonowaId ?? '',
            [required, createHitonowaIdValidator(otherTalents)],
            {
                onChange: [toLowerCase],
            }
        ),
        lastName: useFormText(props.talent.lastName, [required]),
        firstName: useFormText(props.talent.firstName, [required]),
        lastNameKana: useFormText(props.talent.lastNameKana, [required], {
            onCompositionEnd: [hiraToKana],
        }),
        firstNameKana: useFormText(props.talent.firstNameKana, [required], {
            onCompositionEnd: [hiraToKana],
        }),
        romanName: useFormText(props.talent.romanName, [required], {
            onCompositionEnd: [zenToHan],
        }),
        emailAlias: useFormText(props.talent.emailAlias, [], {
            onCompositionEnd: [zenToHan],
        }),
        slackId: useFormText(props.talent.slackId ?? '', [], {
            onCompositionEnd: [zenToHan],
        }),
        teamId: useFormNumber(props.talent.teamId, [required]),
        position: useFormText(props.talent.position),
        gradeText: useFormText(gradeToString(props.talent.grade)),
        workplace: useFormText(props.talent.workplace ?? NOT_SELECTED_WORKPLACE),
        isNewGraduate: useFormBoolean(props.talent.isNewGraduate),
        canSignIn: useFormBoolean(props.talent.canSignIn),
        joinedAt: useFormDate(props.talent.joinedAt, [required]),
    });

    const maybeGrades = useGrades();

    const mutation = useUpdateTalent(props.talent.employment.employeeCode);

    return (
        <FormTheme>
            <Typography variant="subtitle1" color="primary">
                基本プロフィール
            </Typography>
            <Card square={false}>
                <CardContent>
                    <Box m={1}>
                        <Box>
                            <Typography>ヒトノワID（必須）</Typography>
                            <FlexBox gap={theme.spacing(0, 2)}>
                                <Box flexBasis="50%">
                                    <FormTextField fullWidth formText={form.hitonowaId} />
                                </Box>
                                <Box flexBasis="50%">
                                    <HitonowaIdError hitonowaId={form.hitonowaId} />
                                </Box>
                            </FlexBox>
                            <FootNote>
                                ヒトノワでの個人URLに反映されます。半角英数字（小文字）とハイフンおよびアンダースコアで32文字まで利用できます。他の人が既に使っているIDは使えません。
                            </FootNote>
                        </Box>
                        <FlexBox mt={2}>
                            <Typography sx={{ width: '5rem' }}>氏名</Typography>
                            <Box flexGrow={1}>
                                <FlexBox>
                                    <Typography sx={{ width: '2.5rem' }}>姓</Typography>
                                    <Box flexGrow={1}>
                                        <FormTextField fullWidth formText={form.lastName} />
                                    </Box>
                                    <Typography ml={2} sx={{ width: '2.5rem' }}>
                                        名
                                    </Typography>
                                    <Box flexGrow={1}>
                                        <FormTextField fullWidth formText={form.firstName} />
                                    </Box>
                                </FlexBox>
                            </Box>
                        </FlexBox>
                        <FlexBox mt={2}>
                            <Typography sx={{ width: '5rem' }}>ヨミガナ</Typography>
                            <Box flexGrow={1}>
                                <FlexBox>
                                    <Typography sx={{ width: '2.5rem' }}>セイ</Typography>
                                    <Box flexGrow={1}>
                                        <FormTextField fullWidth formText={form.lastNameKana} />
                                    </Box>
                                    <Typography ml={2} sx={{ width: '2.5rem' }}>
                                        メイ
                                    </Typography>
                                    <Box flexGrow={1}>
                                        <FormTextField fullWidth formText={form.firstNameKana} />
                                    </Box>
                                </FlexBox>
                            </Box>
                        </FlexBox>
                        <Box mt={2}>
                            <Typography>英語名</Typography>
                            <FormTextField fullWidth formText={form.romanName} />
                        </Box>
                        <Box mt={2}>
                            <Typography>メールエイリアス</Typography>
                            <FormTextField fullWidth formText={form.emailAlias} />
                        </Box>
                        <Box mt={2}>
                            <Typography>SlackID</Typography>
                            <FormTextField fullWidth formText={form.slackId} />
                        </Box>
                        <Box mt={2}>
                            <Typography>所属</Typography>
                            <TeamSelector teamId={form.teamId} required />
                        </Box>
                        <Box mt={2}>
                            <Typography>役職</Typography>
                            <FormTextField fullWidth formText={form.position} />
                        </Box>
                        <Box mt={2}>
                            <Typography>グレード</Typography>
                            <GradeSelector formText={form.gradeText} />
                        </Box>
                        <Box mt={2}>
                            <Typography>勤務地</Typography>
                            <WorkplaceSelector formText={form.workplace} />
                        </Box>
                        <Box mt={2}>
                            <Typography>新卒フラグ</Typography>
                            <FormCheckBox
                                formBoolean={form.isNewGraduate}
                                label="新卒入社"
                                name="isNewGraduate"
                            />
                        </Box>
                        <Box mt={2}>
                            <Typography>ヒトノワへのログイン</Typography>
                            <FormCheckBox
                                formBoolean={form.canSignIn}
                                label="ログイン可"
                                name="canSignIn"
                            />
                        </Box>
                        <Box mt={2}>
                            <Typography>入社日</Typography>
                            <DateInput formDate={form.joinedAt} />
                        </Box>
                        <ActionContainer mt={2}>
                            <WaitQuery size="medium" query={maybeGrades}>
                                {({ data }) => (
                                    <SubmitButton
                                        fullWidth
                                        disabled={!form.canSubmit}
                                        inProgress={form.inProgress}
                                        onClick={() => {
                                            const args = generateRequest(form, data);
                                            form.send(
                                                mutation.mutateAsync(args).then(() => {
                                                    navigate(
                                                        getTalentUrl(
                                                            props.talent.employment.employeeCode
                                                        )
                                                    );
                                                })
                                            );
                                        }}
                                    >
                                        基本プロフィールを更新する
                                    </SubmitButton>
                                )}
                            </WaitQuery>
                        </ActionContainer>
                        <Box mt={2}>
                            {form.error && (
                                <Alert severity="error">プロフィールの更新に失敗しました</Alert>
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </FormTheme>
    );
};

const HitonowaIdError: React.FC<React.PropsWithChildren<{ hitonowaId: FormText }>> = ({
    hitonowaId,
}) => {
    const theme = useTheme();
    switch (hitonowaId.error) {
        case null:
            return null;
        case 'required':
            return (
                <Typography variant="body2" color="error">
                    省略できません
                </Typography>
            );
        case 'exists':
            return (
                <FlexBox gap={theme.spacing(1)}>
                    <HitonowaId hitonowaId={hitonowaId.value} enableLink />
                    <Typography variant="body2" color="error">
                        は既に利用されています
                    </Typography>
                </FlexBox>
            );
        default:
            return (
                <Typography variant="body2" color="error">
                    規定のフォーマットに従って入力してください
                </Typography>
            );
    }
};
