import React from 'react';
import { SubTitle } from '../PageTitle';
import { RequirePrivilege } from '../RequirePrivilege';
import { ContextualDialog, DialogComponents } from '../StableDialog';
import { ConfirmDialog } from './ConfirmDialog';
import { ContextProvider, DialogMode } from './Context';
import { RegisterFormContent } from './RegisterForm';

export const RegisterTalent: React.FC = () => {
    return (
        <ContextProvider>
            <RequirePrivilege fn={(grants) => grants.editTalent}>
                <SubTitle title="新規従業員登録" />
                <RegisterFormContent />
                <ContextualDialog components={dialogComponents} />
            </RequirePrivilege>
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    Confirm: ConfirmDialog,
};
