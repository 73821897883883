import { DialogContent, DialogTitle } from '@mui/material';
import React, { useContext } from 'react';
import { useUpdateEmployment } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { EmploymentContext, useDialogContext } from '../Context';
import {
    EmploymentFormContent,
    toEditEmploymentRequest,
    useEmploymentForm,
} from './EmploymentForm';

export const EditEmploymentDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const context = useContext(EmploymentContext);
    const v = context.employment.value;
    if (v === null) {
        throw Error(`null employment`);
    }
    const { id, ...args } = v;
    const form = useEmploymentForm(args);
    const mutation = useUpdateEmployment(id);
    return (
        <>
            <DialogTitle>雇用履歴の編集</DialogTitle>
            <DialogContent>
                <EmploymentFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で履歴を更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="履歴の更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditEmploymentRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
