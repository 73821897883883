import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
} from '@mui/material';
import { Workplace } from '@spec/Workplace';
import React, { useContext } from 'react';
import { queryToArray } from '../../../queries';
import { useTalents } from '../../../queries/talent';
import { DeleteIconButton, EditIconButton } from '../../ActionButtons';
import { WorkplaceContext, useDialogContext } from './Context';

export const WorkplaceTable: React.FC<{ workplaces: Workplace[] }> = (props) => {
    const theme = useTheme();
    return (
        <Paper
            variant="outlined"
            sx={{
                padding: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>名称</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.workplaces.map((v) => (
                        <WorkplaceRow key={v.id} workplace={v} />
                    ))}
                </TableBody>
            </Table>
            <AddWorkplaceButton />
        </Paper>
    );
};

const WorkplaceRow: React.FC<{ workplace: Workplace }> = (props) => {
    const { setDialogMode } = useDialogContext();
    const context = useContext(WorkplaceContext);
    const v = props.workplace;
    const relatedTalents = queryToArray(useTalents()).filter((x) => x.workplace === v.name).length;
    return (
        <TableRow>
            <TableCell>{v.name}</TableCell>
            <TableCell align="right">
                <EditIconButton
                    onClick={() => {
                        setDialogMode('Edit');
                        context.workplace.setValue(v);
                    }}
                />
                <Tooltip
                    title={`${relatedTalents}名が勤務先しています`}
                    placement="top"
                    disableHoverListener={relatedTalents === 0}
                >
                    <span>
                        <DeleteIconButton
                            disabled={relatedTalents > 0}
                            onClick={() => {
                                setDialogMode('Delete');
                                context.workplace.setValue(v);
                            }}
                        />
                    </span>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

const AddWorkplaceButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <Box mt={2} textAlign="center">
            <Button
                size="medium"
                variant="outlined"
                color="primary"
                onClick={() => {
                    setDialogMode('Add');
                }}
            >
                新しい勤務地を追加する
            </Button>
        </Box>
    );
};
