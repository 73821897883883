import { NotesArticleSummary } from '@spec/Notes';
import { Talent } from '@spec/Talent';
import { useState } from 'react';
import { findById } from '../../lib/ArrayUtils';
import { deleteStorage, loadStorage, storeStorage } from '../../lib/Storage';
import { useTalentsContext } from '../Context';
import { ArticleCacheSetter, ArticleInput } from './Contract';

export const useAuthor = (article: NotesArticleSummary): Talent => {
    const { talents } = useTalentsContext();
    return findById(article.talentId, talents);
};

const CACHE_KEY = 'notes:article:cache';
const getCacheKey = (tenant: string) => {
    if (tenant === 'carta') {
        return CACHE_KEY;
    }
    return `${CACHE_KEY}:${tenant}`;
};

export const deleteCache = (tenant: string) => deleteStorage(getCacheKey(tenant));

const load = (
    tenant: string,
    articleId: number | null,
    defaultValue: ArticleInput
): ArticleInput => {
    try {
        const cache = loadStorage(getCacheKey(tenant));
        if (cache === '') {
            return defaultValue;
        }
        const data = JSON.parse(cache);
        if (data.articleId !== articleId) {
            return defaultValue;
        }
        return {
            isPinned: data.isPinned ?? false,
            tags: data.tags ?? '',
            title: data.title ?? '',
            content: data.content ?? '',
        };
    } catch {
        return defaultValue;
    }
};

export const useArticleCache = (
    tenant: string,
    articleId: number | null,
    defaultValue: ArticleInput
): [ArticleInput, ArticleCacheSetter] => {
    const [value, _setValue] = useState(() => load(tenant, articleId, defaultValue));
    const setValue: ArticleCacheSetter = (k, v) => {
        _setValue((prev) => {
            if (prev[k] === v) {
                return prev;
            }
            const vv = { ...prev, articleId, [k]: v };
            storeStorage(getCacheKey(tenant), JSON.stringify(vv));
            return vv;
        });
    };
    return [value, setValue];
};
