import { Checkbox, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import { FilterCondition, FilterForm, FilterRow } from '../../../FilterForm';
import { RouterLink } from '../../../RouterLink';
import { categoriesAdminUrl } from '../../urls';
import { useTodoItemsContext } from '../Context';
import { useFilterFormContext } from './Context';

export const TodoItemFilterForm: React.FC = () => {
    const { categories } = useTodoItemsContext();
    const { condition, updateCondition } = useFilterFormContext();
    return (
        <FilterForm dense>
            <FilterRow>
                <FilterCondition label="カテゴリ">
                    <TextField
                        select
                        value={condition.categoryId ?? 'all'}
                        onChange={(e) => {
                            const v = Number(e.target.value);
                            updateCondition({ categoryId: Number.isNaN(v) ? null : v });
                        }}
                    >
                        <MenuItem value="all">すべて</MenuItem>
                        {categories.map((v) => (
                            <MenuItem key={v.id} value={v.id}>
                                {v.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <RouterLink to={categoriesAdminUrl} variant="body2">
                        カテゴリ管理
                    </RouterLink>
                </FilterCondition>
                <FilterCondition ml={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={condition.showFinishedItem}
                                onChange={(e) => {
                                    updateCondition({ showFinishedItem: e.target.checked });
                                }}
                                name="showFinishedItem"
                                size="small"
                            />
                        }
                        label={
                            <Typography variant="body2">公開終了したやることも表示する</Typography>
                        }
                    />
                </FilterCondition>
            </FilterRow>
        </FilterForm>
    );
};
