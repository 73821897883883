import { DialogTitle } from '@mui/material';
import React from 'react';
import { useAddPersonalEvent } from '../../../../queries/me';
import { DialogActionButtons } from '../../../StableDialog';
import { useDialogContext } from '../Context';
import {
    PersonalEventForm,
    toEditPersonalEventRequest,
    usePersonalEventHistoryForm,
} from '../PersonalEventForm';

export const AddPersonalEventDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const form = usePersonalEventHistoryForm({
        label: '',
        startYear: '',
        endYear: '',
    });
    const mutation = useAddPersonalEvent();
    return (
        <>
            <DialogTitle>経歴の追加</DialogTitle>
            <PersonalEventForm form={form} />
            <DialogActionButtons
                form={form}
                submitLabel="この内容で経歴を追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="経歴の追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditPersonalEventRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
