import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { ApplicationError } from '../../../../../Errors';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteTodoCategory } from '../../../../../queries/todoCategoryAdmin';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useEditCategoryContext } from '../Context';

export const DeleteCategoryDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { category } = useEditCategoryContext();
    if (category === null) {
        throw new ApplicationError('Target category is null');
    }
    const form = useEmptyForm();
    const mutation = useDeleteTodoCategory(category.id);
    return (
        <>
            <DialogTitle>カテゴリの削除</DialogTitle>
            <DialogContent>
                <DialogRow label="カテゴリ名">
                    <Typography>{category.label}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="このカテゴリを削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="削除に失敗しました"
                onSubmit={mutation.mutateAsync}
                closeDialog={closeDialog}
            />
        </>
    );
};
