import { DialogContent, DialogTitle } from '@mui/material';
import React, { useContext } from 'react';
import { useUpdateTransfer } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { TransferHistoryContext, useDialogContext } from '../Context';
import { TransferFormContent, toEditTransferRequest, useTransferForm } from './TransferForm';

export const EditTransferDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const context = useContext(TransferHistoryContext);
    const transfer = context.transfer.value;
    if (transfer === null) {
        throw Error(`null transfer`);
    }
    const { id, ...args } = transfer;
    const form = useTransferForm(args);
    const mutation = useUpdateTransfer(id);
    return (
        <>
            <DialogTitle>異動履歴の編集</DialogTitle>
            <DialogContent>
                <TransferFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で履歴を更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="履歴の更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditTransferRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
