import { TodoAssignment } from '@spec/Todo';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { getStatus } from '../../../../domains/Todo';
import { StrictMap } from '../../../../lib/MapUtils';
import { useTalentsContext, useTeamsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { PersonAddDisabledIcon } from '../../../Icons';
import { Pager, sliceItems } from '../../../Pager';
import { comparer, useTableSorter } from '../../../SortableTable';
import { FilterCondition, filterTalents } from './AssigneeFilterForm';
import { useDialogContext, useEmployeeCodesContext, useTodoItemContext } from './Context';
import { Assignee, toAssignee } from './Contract';

export const TargetTalentTable: React.FC<{
    assignments: TodoAssignment[];
    condition: FilterCondition;
}> = (props) => {
    const { talents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const { employeeCodes, setEmployeeCodes, resetEmployeeCodes } = useEmployeeCodesContext();
    const { setDialogMode } = useDialogContext();
    const { item } = useTodoItemContext();
    const disabled = getStatus(item) === 'finished';
    const assignees = new StrictMap(props.assignments.map((v) => [v.talentId, v]));

    const { sortKey, direction, SortHeader } = useTableSorter<Assignee>('employeeCode');

    const members = filterTalents(
        props.condition,
        talents.filter((v) => assignees.has(v.id)),
        teams,
        props.assignments
    )
        .map((t) => toAssignee(t, teams, assignees.get(t.id)))
        .sort((a, b) => comparer(a, b, sortKey, 'employeeCode', direction));

    const [page, setPage] = useState(1);
    const itemsPerPage = 30;
    if (members.length === 0) {
        return (
            <Box mt={4} textAlign="center">
                <Typography color="error">条件に合致する対象者はいません</Typography>
            </Box>
        );
    }
    const slicedItems = sliceItems(members, page, itemsPerPage);
    return (
        <Box>
            <Pager
                current={page}
                setPage={setPage}
                amount={members.length}
                perItems={itemsPerPage}
            />
            <FlexBox mt={1} mb={0.5} mx={2} gap={2}>
                <Typography variant="body2" color="primary">
                    {disabled ? '対象者は選択できません' : `${employeeCodes.size}件選択中です`}
                </Typography>
                <Button
                    variant="outlined"
                    disabled={employeeCodes.size === 0}
                    startIcon={<PersonAddDisabledIcon />}
                    onClick={() => setDialogMode('RemoveTargets')}
                >
                    チェックした人を対象から外す
                </Button>
            </FlexBox>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                disabled={disabled}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setEmployeeCodes(
                                            new Set(members.map((v) => v.employeeCode))
                                        );
                                    } else {
                                        resetEmployeeCodes();
                                    }
                                }}
                            />
                        </TableCell>
                        <SortHeader sortKey="employeeCode">社員番号</SortHeader>
                        <SortHeader sortKey="name">氏名</SortHeader>
                        <SortHeader sortKey="joinedAt">入社日</SortHeader>
                        <SortHeader sortKey="isNewGraduate">入社区分</SortHeader>
                        <SortHeader sortKey="employmentStatus">雇用形態</SortHeader>
                        <SortHeader sortKey="teamName">所属</SortHeader>
                        <SortHeader sortKey="completedAt">完了日時</SortHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {slicedItems.map((t) => (
                        <TalentRow key={t.id} assignee={t} />
                    ))}
                </TableBody>
            </Table>
            <Pager
                current={page}
                setPage={setPage}
                amount={members.length}
                perItems={itemsPerPage}
            />
        </Box>
    );
};

const format = (v: Dayjs | null): string => (v === null ? '' : v.format('YYYY-MM-DD HH:mm:ss'));

const TalentRow: React.FC<{ assignee: Assignee }> = (props) => {
    const t = props.assignee;
    const { employeeCodes, checkTalent } = useEmployeeCodesContext();
    const { item } = useTodoItemContext();
    const disabled = getStatus(item) === 'finished';
    return (
        <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                    disabled={disabled}
                    checked={employeeCodes.has(t.employeeCode)}
                    onClick={() => checkTalent(t.employeeCode)}
                />
            </TableCell>
            <TableCell>{t.employeeCode}</TableCell>
            <TableCell>{t.name}</TableCell>
            <TableCell>{t.joinedAt.format('YYYY-MM-DD')}</TableCell>
            <TableCell>{t.isNewGraduate ? '新卒' : '中途'}</TableCell>
            <TableCell>{t.employmentStatus}</TableCell>
            <TableCell>{t.teamName}</TableCell>
            <TableCell>{format(t.completedAt)}</TableCell>
        </TableRow>
    );
};
