import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { findById } from '../../../../lib/ArrayUtils';
import { queryToArray } from '../../../../queries';
import { useTodoAssignments } from '../../../../queries/todoAdmin';
import { SubTitle } from '../../../PageTitle';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { WaitLoading } from '../../../WaitLoading';
import { useTodoItemsContext } from '../Context';
import { AssignButton } from './AssignButton';
import { AssigneeFilterForm, useFilterCondition } from './AssigneeFilterForm';
import { ContextProvider, DialogMode, useDialogContext } from './Context';
import { HelpAlert } from './HelpAlert';
import { ItemStatusFlow } from './ItemStatusFlow';
import { TargetTalentTable } from './TargetTalentTable';
import { TodoItemCard } from './TodoItemCard';
import { AssignDialog } from './dialogs/AssignDialog';
import { CompleteTodoItemDialog } from './dialogs/CompleteTodoItemDialog';
import { DeleteTodoItemDialog } from './dialogs/DeleteTodoItemDialog';
import { EditTodoItemDialog } from './dialogs/EditTodoItemDialog';
import { PasteDialog } from './dialogs/PasteDialog';
import { PublishTodoItemDialog } from './dialogs/PublishTodoItemDialog';
import { RemoveTargetsDialog } from './dialogs/RemoveTargetsDialog';
import { UnpublishTodoItemDialog } from './dialogs/UnpublishTodoItemDialog';

export const TodoItemDetail: React.FC = () => {
    const { itemId } = useParams();
    const { items } = useTodoItemsContext();
    const item = findById(Number(itemId), items);
    const maybeAssignments = useTodoAssignments(item.id);
    const [condition, updateCondition] = useFilterCondition();
    return (
        <WaitLoading waitFor={[maybeAssignments]}>
            <ContextProvider item={item} assignments={queryToArray(maybeAssignments)}>
                <SubTitle title="やることの概要" />
                <Box display="flex" gap={2}>
                    <Box flexGrow={1} pt={3}>
                        <TodoItemCard />
                        <HelpAlert />
                    </Box>
                    <ItemStatusFlow />
                </Box>
                <SubTitle mt={2} title="対象者の一覧" />
                <Box mt={2}>
                    <AssigneeFilterForm condition={condition} updateCondition={updateCondition} />
                    <TargetTalentTable
                        assignments={queryToArray(maybeAssignments)}
                        condition={condition}
                    />
                    <AssignButton />
                </Box>
                <DialogWrapper />
            </ContextProvider>
        </WaitLoading>
    );
};

const DialogWrapper: React.FC = () => {
    const { dialogMode } = useDialogContext();
    const maxWidth = fullWidthDialogs.includes(dialogMode) ? 'lg' : 'sm';
    return <ContextualDialog components={dialogComponents} maxWidth={maxWidth} />;
};

const fullWidthDialogs: (DialogMode | null)[] = ['Assign', 'Paste', 'EditTodoItem'];

const dialogComponents: DialogComponents<DialogMode> = {
    EditTodoItem: EditTodoItemDialog,
    PublishTodoItem: PublishTodoItemDialog,
    UnpublishTodoItem: UnpublishTodoItemDialog,
    CompleteTodoItem: CompleteTodoItemDialog,
    DeleteTodoItem: DeleteTodoItemDialog,
    Assign: AssignDialog,
    Paste: PasteDialog,
    RemoveTargets: RemoveTargetsDialog,
};
