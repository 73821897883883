import { AppBar, Box, Toolbar, Typography, useTheme } from '@mui/material';
import React from 'react';

export const Footer: React.FC = () => {
    const theme = useTheme();
    return (
        <Box sx={{ width: '100%' }}>
            <AppBar
                elevation={0}
                position="relative"
                sx={{
                    backgroundColor: theme.palette.footer.background,
                    color: theme.palette.footer.text,
                }}
            >
                <Toolbar variant="dense">
                    <Typography variant="body2">&copy; CARTA HOLDINGS, INC.</Typography>
                    <Box flexGrow={1} />
                    <Box textAlign="right">
                        <Typography variant="body2" color="GrayText">
                            rev:{process.env.VITE_UI_REVISION}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};
