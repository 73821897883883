import { Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import { useMeContext } from '../../../../queries/me';
import { useLeaveOfAbsences } from '../../../../queries/talent';
import { ActionContainer } from '../../../ActionButtons';
import { WaitQuery } from '../../../WaitLoading';
import { AbsentTalent } from './AbsentTalent';
import { ReturnTalent } from './ReturnTalent';

export const AbsentMenu: React.FC<{ talent: Talent }> = (props) => {
    const { grants } = useMeContext();
    if (grants.showFullPersonality === false) {
        return null;
    }
    return <Loader talent={props.talent} />;
};

const Loader: React.FC<{ talent: Talent }> = (props) => {
    const maybeLeaveOfAbsences = useLeaveOfAbsences();
    return (
        <ActionContainer>
            <Typography variant="body2" color="primary">
                この従業員の休職情報を更新できます
            </Typography>
            <WaitQuery query={maybeLeaveOfAbsences}>
                {({ data }) => {
                    const absentTalentIds = new Set(
                        data.filter((v) => v.returnedAt === null).map((v) => v.talentId)
                    );
                    const isAbsent = absentTalentIds.has(props.talent.id);
                    return isAbsent ? (
                        <ReturnTalent talent={props.talent} />
                    ) : (
                        <AbsentTalent talent={props.talent} />
                    );
                }}
            </WaitQuery>
        </ActionContainer>
    );
};
