import { useCallback, useState } from 'react';
import { ZodType } from 'zod';

export const loadStorage = (key: string, defaultValue = ''): string => {
    try {
        return window.localStorage.getItem(key) ?? defaultValue;
    } catch {
        return defaultValue;
    }
};

export const hasStorageKey = (key: string) => {
    try {
        return window.localStorage.getItem(key) !== null;
    } catch {
        return false;
    }
};

export const storeStorage = (key: string, value: string): void => {
    try {
        window.localStorage.setItem(key, value);
    } catch {
        // noop
    }
};

export const deleteStorage = (key: string): void => {
    try {
        window.localStorage.removeItem(key);
    } catch {
        // noop
    }
};

export const useStorageValue = (
    key: string,
    defaultValue?: string
): [value: string, setValue: (v: string) => void] => {
    const [value, _setValue] = useState(() => loadStorage(key, defaultValue));
    const setValue = useCallback(
        (input: string) => {
            storeStorage(key, input);
            _setValue(input);
        },
        [key]
    );
    return [value, setValue];
};

export const useStorageToggle = (
    key: string,
    defaultValue = false
): [value: boolean, toggleValue: () => void] => {
    const boolToString = (v: boolean) => (v ? 'true' : '');
    const [value, _setValue] = useState(() => !!loadStorage(key, boolToString(defaultValue)));
    const toggleValue = () => {
        _setValue((prev) => {
            storeStorage(key, boolToString(!prev));
            return !prev;
        });
    };
    return [value, toggleValue];
};

type Setter<T> = <K extends keyof T>(k: K, v: T[K] | ((prevValue: T[K]) => T[K])) => void;

const isFunction = <Value>(input: any): input is (prevValue: Value) => Value =>
    typeof input === 'function';

export const useStorageJson = <T>(
    key: string,
    defaultValue: T,
    schema: ZodType<T>
): [T, Setter<T>] => {
    const [value, _setValue] = useState(() => loadJson(key, defaultValue, schema));
    const setValue: Setter<T> = (k, v) => {
        _setValue((prev) => {
            const newValue = isFunction(v) ? v(prev[k]) : v;
            if (prev[k] === newValue) {
                return prev;
            }
            const updatedValue = { ...prev, [k]: newValue };
            storeStorage(key, JSON.stringify(updatedValue));
            return updatedValue;
        });
    };

    return [value, setValue];
};

const loadJson = <T>(key: string, defaultValue: T, schema: ZodType<T>) => {
    try {
        const cache = loadStorage(key);
        if (cache === '') {
            return defaultValue;
        }
        const data = schema.parse(JSON.parse(cache));
        return data;
    } catch {
        return defaultValue;
    }
};
