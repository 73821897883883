import {
    TeamIntroductionsResponse,
    UpdateTeamIntroductionRequest,
    type AbolishTeamRequest,
    type CreateTeamResponse,
    type IsDeletableTeamResponse,
    type TeamId,
    type UpdateTeamRequest,
} from '@spec/Organization';
import {
    useMutation,
    useQuery,
    useQueryClient,
    type MutationFunction,
} from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useTeams = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.teams],
        queryFn: () => gateway.fetchTeams(),
    });
};

export const useTeamIntroductions = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.teams, 'introductions'],
        queryFn: () => gateway.get<TeamIntroductionsResponse>('/organization/teams/introductions'),
    });
};

export const useUpdateTeamIntroduction = (teamId: number) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (args: UpdateTeamIntroductionRequest) => {
            await gateway.post(`/organization/teams/introductions/${teamId}`, args);
        },
        onSuccess: () =>
            queryClient.invalidateQueries({ queryKey: [queryKey.teams, 'introductions'] }),
    });
};

const useTeamMutation = <Res = void, Req = void>(mutationFn: MutationFunction<Res, Req>) => {
    const queryClient = useQueryClient();
    return useMutation<Res, Error, Req>({
        mutationFn,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey.teams] }),
    });
};

export const useCreateTeam = () => {
    const gateway = useGateway();
    return useTeamMutation<TeamId, UpdateTeamRequest>((args) =>
        gateway.post<CreateTeamResponse>('/organization/teams', args).then((res) => res.teamId)
    );
};

export const useUpdateTeam = (teamId: TeamId) => {
    const gateway = useGateway();
    return useTeamMutation<void, UpdateTeamRequest>((args) =>
        gateway.put(`/organization/teams/${teamId}`, args)
    );
};

export const useDeleteTeam = (teamId: TeamId) => {
    const gateway = useGateway();
    return useTeamMutation<void>(() => gateway.delete(`/organization/teams/${teamId}`));
};

export const useAbolishTeam = (teamId: TeamId) => {
    const gateway = useGateway();
    return useTeamMutation<void, AbolishTeamRequest>((args) =>
        gateway.post(`/organization/teams/${teamId}/abolish`, args)
    );
};

export const useRestoreTeam = (teamId: TeamId) => {
    const gateway = useGateway();
    return useTeamMutation<void>(() => gateway.post(`/organization/teams/${teamId}/restore`));
};

export const useIsDeletableTeam = (teamId: TeamId) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.teams, 'isDeletable', teamId],
        queryFn: () =>
            gateway
                .get<IsDeletableTeamResponse>(`/organization/teams/${teamId}/deletable`)
                .then((res) => res.isDeletable),
    });
};
