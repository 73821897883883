import { Box, Card, CardContent, Divider, Button as MuiButton, Typography } from '@mui/material';
import { Survey, SurveyGroup, SurveyPeriod } from '@spec/Survey';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { FormTheme } from '../../../../Theme';
import { sortByKey } from '../../../../lib/ArrayUtils';
import { ActionButton, ActionContainer, LinkButton } from '../../../ActionButtons';
import { FlexBox } from '../../../FlexBox';
import { DeleteIcon, EditIcon, PeopleIcon, ScheduleIcon, SettingsIcon } from '../../../Icons';
import { Markdown } from '../../../Markdown';
import { NoItems } from '../../../NoItems';
import { SubTitle } from '../../../PageTitle';
import { RequirePrivilege } from '../../../RequirePrivilege';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { useSurveysContext } from '../../Context';
import {
    getGroupPermissionsUrl,
    getPeriodsUrl,
    getSurveyAdminUrl,
    getSurveyPermissionsUrl,
} from '../../urls';
import { ContextProvider, DialogMode, useDialogContext, useTargetGroupContext } from './Context';
import { AddGroupDialog } from './dialogs/AddGroupDialog';
import { AddSurveyDialog } from './dialogs/AddSurveyDialog';
import { DeleteGroupDialog } from './dialogs/DeleteGroupDialog';
import { EditGroupDialog } from './dialogs/EditGroupDialog';

export const SurveyAdmin: React.FC = () => {
    const { surveyGroups } = useSurveysContext();
    return (
        <RequirePrivilege fn={(grants) => grants.manageSurvey}>
            <ContextProvider>
                <Box mt={2}>
                    <SubTitle title="サーベイグループの一覧" />
                    <Box m={2}>
                        {surveyGroups.length === 0 && (
                            <NoItems mt={4}>サーベイグループがありません</NoItems>
                        )}
                        {surveyGroups.map((group) => (
                            <SurveyGroupCard key={group.id} group={group} />
                        ))}
                    </Box>
                </Box>
                <AddGroupButton />
                <ContextualDialog components={dialogComponents} />
            </ContextProvider>
        </RequirePrivilege>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddGroup: AddGroupDialog,
    EditGroup: EditGroupDialog,
    DeleteGroup: DeleteGroupDialog,
    AddSurvey: AddSurveyDialog,
};

const AddGroupButton = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <ActionContainer>
            <ActionButton startIcon={<EditIcon />} onClick={() => setDialogMode('AddGroup')}>
                サーベイグループを追加する
            </ActionButton>
        </ActionContainer>
    );
};

const SurveyGroupCard: React.FC<{ group: SurveyGroup }> = (props) => {
    const { setDialogMode } = useDialogContext();
    const { setGroup } = useTargetGroupContext();
    const { surveys: allSurveys } = useSurveysContext();
    const surveys = allSurveys.filter((v) => v.surveyGroupId === props.group.id);
    const period = sortByKey(props.group.periods, 'openedAt', 'desc')[0] ?? null;
    return (
        <Box mt={2}>
            <Card>
                <CardContent>
                    <FormTheme>
                        <FlexBox mb={0.5} gap={1}>
                            <Typography flexGrow={1} variant="h6" color="primary">
                                {props.group.name}
                            </Typography>
                            <MuiButton
                                variant="outlined"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => {
                                    setGroup(props.group);
                                    setDialogMode('EditGroup');
                                }}
                            >
                                グループを編集する
                            </MuiButton>
                            <MuiButton
                                variant="outlined"
                                size="small"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                    setGroup(props.group);
                                    setDialogMode('DeleteGroup');
                                }}
                            >
                                グループを削除する
                            </MuiButton>
                        </FlexBox>
                        <Divider />
                        <Markdown source={props.group.description} variant="body2" />
                        <Typography mt={2} color="primary">
                            最新の実施期間
                        </Typography>
                        <SurveyPeriodCard period={period} />
                        <Box mt={0.5}>
                            <Button startIcon={<ScheduleIcon />} to={getPeriodsUrl(props.group.id)}>
                                実施期間の一覧を見る
                            </Button>
                        </Box>
                        <Box mt={2}>
                            <Button
                                startIcon={<PeopleIcon />}
                                to={getGroupPermissionsUrl(props.group.id)}
                            >
                                メモ権限の管理
                            </Button>
                        </Box>
                        <Box mt={2}>
                            <SubTitle title="グループに含まれるサーベイ" />
                        </Box>
                        {surveys.length === 0 && <NoItems>サーベイがありません</NoItems>}
                        {surveys.map((v) => (
                            <SurveyRow key={v.id} survey={v} />
                        ))}
                        <Box textAlign="center">
                            <MuiButton
                                variant="outlined"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => {
                                    setGroup(props.group);
                                    setDialogMode('AddSurvey');
                                }}
                            >
                                サーベイを追加する
                            </MuiButton>
                        </Box>
                    </FormTheme>
                </CardContent>
            </Card>
        </Box>
    );
};

const SurveyPeriodCard: React.FC<{ period: SurveyPeriod | null }> = (props) => {
    if (props.period === null) {
        return <NoItems>実施期間がありません</NoItems>;
    }
    return (
        <Box>
            <Typography>{props.period.name}</Typography>
            <Typography variant="body2">
                {dayjs(props.period.openedAt).format('YYYY-MM-DD HH:mm')}
                {' 〜 '}
                {dayjs(props.period.closedAt).format('YYYY-MM-DD HH:mm')}
            </Typography>
            <Typography variant="body2">
                {props.period.questions.map((q) => q.shortTitle).join(', ')}
            </Typography>
        </Box>
    );
};

const SurveyRow: React.FC<{ survey: Survey }> = (props) => {
    return (
        <Box mt={1} mb={2}>
            <Typography mb={-1}>{props.survey.name}</Typography>
            <Markdown source={props.survey.description} variant="body2" />
            <FlexBox mt={1} gap={1}>
                <Button startIcon={<SettingsIcon />} to={getSurveyAdminUrl(props.survey.id)}>
                    サーベイ管理
                </Button>
                <Button startIcon={<PeopleIcon />} to={getSurveyPermissionsUrl(props.survey.id)}>
                    閲覧権限の管理
                </Button>
            </FlexBox>
        </Box>
    );
};

const Button: React.FC<{ startIcon: ReactNode; to: string; children: ReactNode }> = (props) => {
    return <LinkButton variant="outlined" size="small" {...props} />;
};
