import { Box, Card, CardContent, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import { Talent } from '@spec/Talent';
import React from 'react';
import { getTalentUrl } from '../../../Routes';
import { getTeamNameTree } from '../../../domains/Organization';
import { fullName } from '../../../domains/Talent';
import { findById } from '../../../lib/ArrayUtils';
import { LinkButton } from '../../ActionButtons';
import { useTeamsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { KeyboardDoubleArrowRightIcon } from '../../Icons';
import { ExternalLink, RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { getArticleUrl } from '../../notes/urls';

export const NewcomerCard: React.FC<{
    talent: Talent;
    pinnedArticles: NotesArticleSummary[];
}> = (props) => {
    const { teams } = useTeamsContext();
    const team = findById(props.talent.teamId, teams);
    const teamName = getTeamNameTree(team.id, teams).join(' ');
    const pinned =
        props.talent.selfIntroductionUrl !== '' &&
        props.pinnedArticles.some((v) =>
            props.talent.selfIntroductionUrl.endsWith(getArticleUrl(v.id, props.talent.hitonowaId))
        );
    return (
        <Card>
            <CardContent>
                <FlexBox flexWrap="nowrap">
                    <Box mr={1.5}>
                        <RouterLink to={getTalentUrl(props.talent.employment.employeeCode)}>
                            <TalentAvatar size="mediumLarge" talent={props.talent} />
                        </RouterLink>
                    </Box>
                    <Box>
                        <FlexBox>
                            <Typography>{fullName(props.talent)}</Typography>
                            <Typography variant="body2">さん</Typography>
                            <Typography ml={1} variant="body2">
                                {props.talent.workplace && `${props.talent.workplace}勤務`}
                            </Typography>
                        </FlexBox>
                        <Typography variant="body2">{teamName}</Typography>
                        {props.talent.selfIntroductionUrl !== '' && pinned === false && (
                            <FlexBox>
                                <Typography variant="body2">自己紹介</Typography>
                                <Box ml={1}>
                                    <ExternalLink
                                        variant="body2"
                                        href={props.talent.selfIntroductionUrl}
                                    >
                                        {props.talent.selfIntroductionUrl}
                                    </ExternalLink>
                                </Box>
                            </FlexBox>
                        )}
                        {props.pinnedArticles.map((v) => (
                            <Box key={v.id}>
                                <RouterLink
                                    variant="body2"
                                    to={getArticleUrl(v.id, props.talent.hitonowaId)}
                                >
                                    {v.title}
                                </RouterLink>
                            </Box>
                        ))}
                        <LinkButton
                            startIcon={<KeyboardDoubleArrowRightIcon />}
                            to={getTalentUrl(props.talent.employment.employeeCode)}
                        >
                            プロフィールを見る
                        </LinkButton>
                    </Box>
                </FlexBox>
            </CardContent>
        </Card>
    );
};
