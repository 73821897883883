import { DialogContent, DialogTitle } from '@mui/material';
import { FormTextField, useForm, useFormText } from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { useUpdateSurvey } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useSurveyContext } from '../Context';

export const EditSurveyDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { survey } = useSurveyContext();
    const form = useForm({
        name: useFormText(survey.name, [required]),
        description: useFormText(survey.description, [required]),
    });
    const mutation = useUpdateSurvey(survey.id);
    return (
        <>
            <DialogTitle>サーベイを編集する</DialogTitle>
            <DialogContent>
                <DialogRow label="名称">
                    <FormTextField autoFocus fullWidth formText={form.name} />
                </DialogRow>
                <DialogRow label="詳細">
                    <FormTextField fullWidth multiline minRows={4} formText={form.description} />
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でサーベイを更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
