import {
    Alert,
    Checkbox,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import type { EditSurveyQuestionRequest } from '@spec/Survey';
import { useState } from 'react';
import {
    FormTextField,
    useForm,
    useFormText,
    type Form,
    type FormText,
} from '../../../../../lib/Form';
import { maxLength, numberRange, numeric, required } from '../../../../../lib/Form/Validators';
import { useAddSurveyQuestion } from '../../../../../queries/surveyAdmin';
import { FlexBox } from '../../../../FlexBox';
import { CheckBoxIcon, LinearScaleIcon, SubjectIcon } from '../../../../Icons';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useSurveyContext } from '../Context';

type TabMode = 'Likert' | 'Boolean' | 'Text';

export const AddQuestionDialog = () => {
    const { survey } = useSurveyContext();
    const [activeTab, setActiveTab] = useState<TabMode>('Likert');
    return (
        <>
            <DialogTitle>「{survey.name}」に質問を追加する</DialogTitle>
            <DialogContent>
                <Alert severity="warning" sx={{ mb: 2 }}>
                    種別は後から変更できません
                </Alert>
                <ToggleButtonGroup
                    fullWidth
                    exclusive
                    color="primary"
                    value={activeTab}
                    onChange={(_, value) => {
                        if (value !== null) {
                            setActiveTab(value);
                        }
                    }}
                >
                    <ToggleButton value="Likert">
                        <LinearScaleIcon />
                        スコア
                    </ToggleButton>
                    <ToggleButton value="Boolean">
                        <CheckBoxIcon />
                        チェック
                    </ToggleButton>
                    <ToggleButton value="Text">
                        <SubjectIcon />
                        テキスト
                    </ToggleButton>
                </ToggleButtonGroup>
            </DialogContent>
            {activeTab === 'Likert' && <LikertForm />}
            {activeTab === 'Boolean' && <BooleanForm />}
            {activeTab === 'Text' && <TextForm />}
        </>
    );
};

const Actions = (props: { form: Form<any>; serialize: () => EditSurveyQuestionRequest }) => {
    const { closeDialog } = useDialogContext();
    const { survey } = useSurveyContext();
    const mutation = useAddSurveyQuestion(survey.id);
    return (
        <DialogActionButtons
            form={props.form}
            submitLabel="この質問を追加する"
            cancelLabel="追加せずに閉じる"
            errorMessage="追加に失敗しました"
            onSubmit={() => mutation.mutateAsync(props.serialize())}
            closeDialog={closeDialog}
        />
    );
};

const LikertForm = () => {
    const form = useForm({
        shortTitle: useFormText('', [required, maxLength(191)]),
        title: useFormText('', [required, maxLength(191)]),
        minLabel: useFormText('', [required, maxLength(191)]),
        maxLabel: useFormText('', [required, maxLength(191)]),
        minScore: useFormText('1', [required, numeric, numberRange(1, 10)]),
        maxScore: useFormText('7', [required, numeric, numberRange(1, 10)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
                <DialogRow label="スコアとラベル">
                    <FlexBox gap={1}>
                        <ScoreRange formText={form.minScore} />
                        <FormTextField formText={form.minLabel} />
                        <Typography flexGrow={1} textAlign="center">
                            〜
                        </Typography>
                        <FormTextField formText={form.maxLabel} />
                        <ScoreRange formText={form.maxScore} />
                    </FlexBox>
                </DialogRow>
            </DialogContent>
            <Actions
                form={form}
                serialize={() => {
                    const args = form.serialize();
                    return {
                        ...args,
                        minScore: Number(args.minScore),
                        maxScore: Number(args.maxScore),
                    };
                }}
            />
        </>
    );
};

const ScoreRange = (props: { formText: FormText }) => {
    const { formText } = props;
    const scoreRange = Array.from({ length: 10 }, (_, i) => i + 1);
    return (
        <TextField
            select
            value={formText.value}
            onChange={(e) => formText.setValue(e.target.value)}
        >
            {scoreRange.map((v) => (
                <MenuItem key={v} value={`${v}`}>
                    {v}
                </MenuItem>
            ))}
        </TextField>
    );
};

const BooleanForm = () => {
    const form = useForm({
        shortTitle: useFormText('', [required, maxLength(191)]),
        title: useFormText('', [required, maxLength(191)]),
        label: useFormText('', [required, maxLength(191)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
                <DialogRow label="チェックボックスのラベル">
                    <Checkbox disabled checked />
                    <FormTextField formText={form.label} />
                </DialogRow>
            </DialogContent>
            <Actions form={form} serialize={form.serialize} />
        </>
    );
};

const TextForm = () => {
    const form = useForm({
        shortTitle: useFormText('', [required, maxLength(191)]),
        title: useFormText('', [required, maxLength(191)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
            </DialogContent>
            <Actions form={form} serialize={form.serialize} />
        </>
    );
};
