import { Box, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { RoutingPattern } from '../Routes';
import { FormText } from '../lib/Form';
import { queryToArray } from '../queries';
import { useMeContext } from '../queries/me';
import { useWorkplaces } from '../queries/workplace';
import { FlexBox } from './FlexBox';
import { RouterLink } from './RouterLink';
import { WaitLoading } from './WaitLoading';

export const NOT_SELECTED_WORKPLACE = '-';

export const WorkplaceSelector: React.FC<{ formText: FormText }> = (props) => {
    const { grants } = useMeContext();
    const maybeWorkplaces = useWorkplaces();
    const workplaces = queryToArray(maybeWorkplaces);
    return (
        <WaitLoading size="small" waitFor={[maybeWorkplaces]}>
            <FlexBox>
                <TextField
                    select
                    value={props.formText.value}
                    onChange={(e) => {
                        props.formText.setValue(e.target.value);
                    }}
                >
                    <MenuItem value={NOT_SELECTED_WORKPLACE}>未選択</MenuItem>
                    {workplaces.map((v) => (
                        <MenuItem key={v.id} value={v.name}>
                            {v.name}
                        </MenuItem>
                    ))}
                </TextField>
                {grants.editWorkplaces && (
                    <Box ml={3}>
                        <RouterLink to={RoutingPattern.workplaces}>勤務地を編集する</RouterLink>
                    </Box>
                )}
            </FlexBox>
        </WaitLoading>
    );
};
