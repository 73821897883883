import React, { ReactNode } from 'react';
import {
    Alert,
    Box,
    Breakpoint,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    SxProps,
    Typography,
} from '@mui/material';
import { Form } from '../lib/Form';
import { ApplicationError } from '../Errors';
import { useGenericDialogContext } from './Context';
import { SubmitButton } from './SubmitButton';

export const StableDialog: React.FC<React.PropsWithChildren<{ noClose: boolean } & DialogProps>> = (
    props
) => {
    const { noClose, onClose, ...rest } = props;
    return (
        <Dialog
            {...rest}
            onClose={(event, reason) => {
                if (onClose !== undefined && canClose(noClose, reason)) {
                    onClose(event, reason);
                }
            }}
        />
    );
};

const canClose = (noClose: boolean, reason: 'backdropClick' | 'escapeKeyDown'): boolean => {
    if (noClose === false) {
        return true;
    }
    return reason !== 'backdropClick' && reason !== 'escapeKeyDown';
};

export const ContextualDialog: React.FC<{
    components: DialogComponents<string>;
    maxWidth?: Breakpoint;
}> = (props) => {
    const { dialogMode, closeDialog } = useGenericDialogContext<string>();
    const Component = dialogMode === null ? null : props.components[dialogMode];
    if (Component === undefined) {
        throw new ApplicationError(`Dialog component has not been defined for ${dialogMode}`);
    }
    const sx: SxProps = props.maxWidth === 'lg' ? { height: '100vh' } : {};
    return (
        <StableDialog
            open={dialogMode !== null}
            noClose
            onClose={closeDialog}
            maxWidth={props.maxWidth ?? 'sm'}
            fullWidth
            PaperProps={{ sx }}
        >
            {Component !== null && <Component />}
        </StableDialog>
    );
};

export type DialogComponents<T extends string> = { [K in T]: React.FC };

export const DialogActionButtons: React.FC<{
    form: Form<any>;
    startIcon?: ReactNode;
    submitLabel: string;
    cancelLabel: string;
    errorMessage: string;
    onSubmit: () => Promise<void>;
    closeDialog: () => void;
    additionalActions?: ReactNode;
}> = (props) => {
    return (
        <>
            <DialogContent sx={{ pt: 0 }}>
                {props.form.failed && <Alert severity="error">{props.errorMessage}</Alert>}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Box flexGrow={1}>
                    <Button
                        size="medium"
                        variant="outlined"
                        onClick={props.closeDialog}
                        tabIndex={2}
                    >
                        {props.cancelLabel}
                    </Button>
                </Box>
                {props.additionalActions !== undefined && props.additionalActions}
                <SubmitButton
                    startIcon={props.startIcon}
                    size="medium"
                    inProgress={props.form.inProgress}
                    disabled={!props.form.canSubmit}
                    onClick={() => {
                        props.form.send(props.onSubmit().then(props.closeDialog));
                    }}
                    tabIndex={1}
                >
                    {props.submitLabel}
                </SubmitButton>
            </DialogActions>
        </>
    );
};

export const DialogRow: React.FC<{ label: string; children: ReactNode }> = (props) => {
    return (
        <Box
            sx={{
                mt: 2,
                '&:first-of-type': { mt: 0 },
            }}
        >
            <Typography>{props.label}</Typography>
            <Box mt={0.5}>{props.children}</Box>
        </Box>
    );
};
