import { Box } from '@mui/material';
import { SurveyGroup, SurveyLikertResponse } from '@spec/Survey';
import { line } from 'billboard.js';
import dayjs from 'dayjs';
import { Chart } from '../../../Chart';
import { useSurveysContext } from '../../Context';

export const TalentChart: React.FC<{
    group: SurveyGroup;
    responses: SurveyLikertResponse[];
}> = (props) => {
    const periodIds = new Set(props.responses.map((v) => v.surveyPeriodId));
    const periods = props.group.periods.filter((v) => periodIds.has(v.id));
    const questionIds = new Set(props.responses.map((v) => v.questionId));
    const { surveys } = useSurveysContext();
    const questions = surveys.map((v) => v.questions.filter((q) => questionIds.has(q.id))).flat();
    const findResponse = (periodId: number, questionId: number): number | null =>
        props.responses.find((v) => v.surveyPeriodId === periodId && v.questionId === questionId)
            ?.score ?? null;
    const columns = [
        ['x', ...periods.map((v) => dayjs(v.openedAt).format('YYYY-MM'))],
        ...questions.map((q) => [q.shortTitle, ...periods.map((v) => findResponse(v.id, q.id))]),
    ];
    return (
        <Box>
            <Chart
                options={{
                    data: {
                        type: line(),
                        x: 'x',
                        columns,
                    },
                    line: { connectNull: true },
                    axis: {
                        x: {
                            type: 'timeseries',
                            tick: {
                                culling: false,
                                format: '%Y/%m',
                            },
                        },
                    },
                }}
            />
        </Box>
    );
};
