import { Alert, Box, Typography } from '@mui/material';
import { HRBP_LIST_URL } from '../../../Routes';
import { ExternalLink } from '../../RouterLink';

export const Partners: React.FC = () => {
    return (
        <Box>
            <Alert severity="info">
                <Typography variant="body1">
                    CARTAでは各事業部にそれぞれ人事担当（HRBP）がつき、人事面から事業成長を支えています。
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                    各種人事関連の相談（評価、採用、育成、制度など）はHRBPまでお寄せください。
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                    各事業部の担当は社内ポータル（CARUUPE）の
                    <ExternalLink icon href={HRBP_LIST_URL} sx={{ mx: 0.5 }}>
                        HRBP担当
                    </ExternalLink>
                    から確認できます。
                </Typography>
            </Alert>
        </Box>
    );
};
