import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import { ReactNode } from 'react';
import { getTalentUrl } from '../Routes';
import { findTeam } from '../domains/Organization';
import { fullName } from '../domains/Talent';
import { LinkButton } from './ActionButtons';
import { useTeamsContext } from './Context';
import { FlexBox } from './FlexBox';
import { KeyboardDoubleArrowRightIcon } from './Icons';
import { TalentAvatar } from './TalentAvatar';

export const PopoverTalent: React.FC<{ talent: Talent; children: ReactNode }> = (props) => {
    const { talent } = props;
    const { teams } = useTeamsContext();
    const team = findTeam(talent.teamId, teams);
    const baseTeam = findTeam(team.baseTeamId, teams);
    return (
        <Tooltip
            components={{ Tooltip: Box }}
            placement="top"
            title={
                <Card square={false}>
                    <CardContent>
                        <Typography variant="body2">{baseTeam.name}</Typography>
                        {team.name !== baseTeam.name && (
                            <Typography variant="body2">{team.name}</Typography>
                        )}
                        <FlexBox mt={1} gap={1}>
                            <TalentAvatar size="medium" talent={talent} />
                            <Box>
                                <Typography>{talent.employment.employeeCode}</Typography>
                                <Typography>{fullName(talent)}</Typography>
                            </Box>
                        </FlexBox>
                        <Box mt={1}>
                            <LinkButton
                                startIcon={<KeyboardDoubleArrowRightIcon />}
                                to={getTalentUrl(talent.employment.employeeCode)}
                            >
                                プロフィールを見る
                            </LinkButton>
                        </Box>
                    </CardContent>
                </Card>
            }
        >
            <Box>{props.children}</Box>
        </Tooltip>
    );
};
