import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useEmptyForm } from '../../../../lib/Form';
import { useDeletePersonalEvent } from '../../../../queries/me';
import { DialogActionButtons, DialogRow } from '../../../StableDialog';
import { useDialogContext, usePersonalEventHistoryContext } from '../Context';

export const DeletePersonalEventDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { personalEvent } = usePersonalEventHistoryContext();
    if (personalEvent === null) {
        throw Error('null personalEvent');
    }
    const form = useEmptyForm();
    const mutation = useDeletePersonalEvent(personalEvent.id);
    return (
        <>
            <DialogTitle>経歴の削除</DialogTitle>
            <DialogContent>
                <DialogRow label="所属など">
                    <Typography>{personalEvent.label}</Typography>
                </DialogRow>
                <DialogRow label="開始年">
                    <Typography>{personalEvent.startYear}年</Typography>
                </DialogRow>
                <DialogRow label="終了年">
                    <Typography>
                        {personalEvent.endYear !== null && `${personalEvent.endYear}年`}
                    </Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で経歴を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="経歴の削除に失敗しました"
                onSubmit={mutation.mutateAsync}
                closeDialog={closeDialog}
            />
        </>
    );
};
