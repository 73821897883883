import dayjs from 'dayjs';
import React from 'react';
import { UnitText } from './UnitText';

export const MonthDuration: React.FC<React.PropsWithChildren<{ now: Date; date: Date | null }>> = (
    props
) => {
    if (props.date === null) {
        return <>-</>;
    }
    const diffMonth = dayjs(props.now).diff(props.date, 'month');
    if (diffMonth < 12) {
        return (
            <>
                {diffMonth}
                <UnitText text="ヶ月" />
            </>
        );
    }
    return (
        <>
            {Math.floor(diffMonth / 12)}
            <UnitText text="年 " />
            {diffMonth % 12}
            <UnitText text="ヶ月" />
        </>
    );
};
