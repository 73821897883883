import React from 'react';
import { Box, Typography } from '@mui/material';

export const FootNote: React.FC<React.PropsWithChildren<unknown>> = (props) => (
    <Box mt={0.5} ml={1}>
        <Typography variant="caption" color="primary">
            {props.children}
        </Typography>
    </Box>
);
