import { DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { ApplicationError } from '../../../../../Errors';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteSurveyQuestion } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useEditQuestionContext } from '../Context';

export const DeleteQuestionDialog = () => {
    const { closeDialog } = useDialogContext();
    const { question } = useEditQuestionContext();
    if (question.value === null) {
        throw new ApplicationError('question is not stored in the context');
    }
    const q = question.value;
    const form = useEmptyForm();
    const mutation = useDeleteSurveyQuestion(q.id);
    return (
        <>
            <DialogTitle>「{q.title}」を削除する</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography>
                        削除できるのは実施期間と紐付けられていない質問だけです。
                    </Typography>
                    <Typography>一度削除した質問は復元できません。</Typography>
                </Stack>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この質問を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
