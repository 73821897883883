import React from 'react';
import { ActionButton, ActionContainer } from '../../../ActionButtons';
import { EditIcon } from '../../../Icons';
import { useDialogContext } from './Context';

export const AddTodoItemButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <ActionContainer>
            <ActionButton startIcon={<EditIcon />} onClick={() => setDialogMode('AddTodoItem')}>
                新しい「やること」を作る
            </ActionButton>
        </ActionContainer>
    );
};
