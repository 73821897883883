import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import dayjs from 'dayjs';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { fullName } from '../../../domains/Talent';
import { useNotesArticles } from '../../../queries/notes';
import { ActionContainer } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { IconText } from '../../IconText';
import { AccessTimeIcon, FeedIcon, PushPinIcon } from '../../Icons';
import { RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { WaitInfiniteQuery } from '../../WaitLoading';
import { HitonowaId } from '../../talent/HitonowaId';
import { ArticleTags } from '../ArticleTags';
import { useAuthor } from '../Hooks';
import { LikesCounter } from '../LikesCounter';
import { getArticleUrl, getAuthorUrl } from '../urls';
import { NotesHeader } from './Header';

export const Articles: React.FC = () => {
    const maybeArticles = useNotesArticles();
    return (
        <Box>
            <Box mb={2}>
                <NotesHeader />
            </Box>
            <WaitInfiniteQuery query={maybeArticles}>
                {({ data, isFetching, fetchNextPage, hasNextPage }) => (
                    <Container maxWidth="md">
                        <ArticleList articles={data.pages.flatMap((page) => page.articles)} />
                        <ActionContainer>
                            <LoadingButton
                                fullWidth
                                variant="outlined"
                                size="medium"
                                loading={isFetching}
                                loadingPosition="start"
                                startIcon={<FeedIcon />}
                                onClick={() => fetchNextPage()}
                                disabled={!hasNextPage}
                            >
                                もっと見る
                            </LoadingButton>
                        </ActionContainer>
                    </Container>
                )}
            </WaitInfiniteQuery>
            <Outlet />
        </Box>
    );
};

const ArticleList: React.FC<{ articles: NotesArticleSummary[] }> = (props) => {
    if (props.articles.length === 0) {
        return (
            <Box mt={10} textAlign="center">
                <Typography color="error">まだ記事が書かれていません。</Typography>
            </Box>
        );
    }
    return (
        <Box>
            {props.articles.map((v) => (
                <ArticleCard key={v.id} article={v} />
            ))}
        </Box>
    );
};

export const ArticleCard: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const v = props.article;
    const author = useAuthor(v);
    const articleUrl = getArticleUrl(v.id, author.hitonowaId);
    const authorUrl = getAuthorUrl(author.hitonowaId);
    return (
        <Card square={false}>
            <CardContent sx={{ pb: 0 }}>
                <FlexBox flexWrap="nowrap" gap={1}>
                    <RouterLink to={articleUrl}>
                        <TalentAvatar size="medium" talent={author} />
                    </RouterLink>
                    <Box flexGrow={1}>
                        <FlexBox gap={0.5}>
                            <RouterLink variant="h6" to={articleUrl} modal>
                                {v.title}
                            </RouterLink>
                            {v.isPinned === true && (
                                <PushPinIcon fontSize="small" color="secondary" />
                            )}
                        </FlexBox>
                        <FlexBox gap={1} my={-0.5}>
                            <Typography variant="body2">
                                {fullName(author)}
                                {author.nicknames !== '' && `（${author.nicknames}）`}
                            </Typography>
                            <RouterLink variant="body2" to={authorUrl}>
                                <HitonowaId hitonowaId={author.hitonowaId} />
                            </RouterLink>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                            <ArticleTags tags={v.tags} />
                        </FlexBox>
                    </Box>
                    <LikesCounter articleId={props.article.id} />
                </FlexBox>
            </CardContent>
        </Card>
    );
};
