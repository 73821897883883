export class StrictMap<K, V> extends Map<K, V> {
    get(k: K): V {
        const v = super.get(k);
        if (v === undefined) {
            throw new Error(`The map does not have ${k}`);
        }
        return v;
    }

    getOr(k: K, x: V): V {
        const v = super.get(k);
        if (v === undefined) {
            return x;
        }
        return v;
    }
}

export const groupBy = <T, K>(array: T[], keySelector: (item: T) => K): Map<K, T[]> => {
    const map = new Map<K, T[]>();
    array.forEach((item) => {
        const keyValue = keySelector(item);
        const group = map.get(keyValue);
        if (group === undefined) {
            map.set(keyValue, [item]);
        } else {
            group.push(item);
        }
    });
    return map;
};
