import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import React, { useState } from 'react';
import { fullNameKana } from '../../domains/Talent';
import { queryToArray } from '../../queries';
import { useTalents } from '../../queries/talent';
import { useTeamsContext } from '../Context';
import { getOptionLabel, isTalent, searchItems } from './Domains';
import { ItemCard } from './ItemCard';

const COMPLETION_MIN_LENGTH = 2;

type Item = Talent | string;

export const TalentSearch: React.FC<{
    value: Talent | null;
    onChange: (v: Talent | null) => void;
    filterTalent?: (v: Talent) => boolean;
}> = (props) => {
    const { teams } = useTeamsContext();
    const maybeTalents = useTalents();
    const talents =
        props.filterTalent !== undefined
            ? queryToArray(maybeTalents).filter(props.filterTalent)
            : queryToArray(maybeTalents);
    const items: Item[] = [...talents].sort((a, b) => {
        const labelA = fullNameKana(a);
        const labelB = fullNameKana(b);
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);
    const inProgress = maybeTalents.isPending;
    return (
        <Autocomplete
            fullWidth
            forcePopupIcon={false}
            options={items}
            getOptionLabel={(v) => getOptionLabel(v)}
            loading={inProgress}
            loadingText={
                <Box textAlign="center">
                    <CircularProgress color="primary" size={20} />
                </Box>
            }
            noOptionsText={
                <Typography variant="body2" color="error">
                    候補が見つかりません
                </Typography>
            }
            renderOption={(p, v) => (
                <li {...p} key={typeof v === 'string' ? v : v.id}>
                    <ItemCard item={v} teams={teams} />
                </li>
            )}
            getOptionDisabled={(v) => !isTalent(v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="人を名前や社員番号などで検索"
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {maybeTalents.isPending ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <SearchIcon />
                                    )}
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
            value={props.value}
            inputValue={inputValue}
            onInputChange={(_event, value) => setInputValue(value)}
            open={open && !selected && inputValue.length >= COMPLETION_MIN_LENGTH}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            filterOptions={(options, state) =>
                inProgress ? [] : searchItems(state.inputValue, options)
            }
            onChange={(_event, value) => {
                if (isTalent(value)) {
                    setSelected(true);
                    props.onChange(talents.find((v) => v.id === value.id) ?? null);
                } else {
                    setSelected(false);
                    props.onChange(null);
                }
            }}
        />
    );
};
