import React from 'react';
import { Route } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { Index } from './';
import { Layout } from './Layout';
import { Layout as AdminLayout } from './admin/Layout';
import { TodoCategories } from './admin/categories';
import { TodoItemDetail } from './admin/itemDetail';
import { TodoItemList } from './admin/itemList';

export const TodoRoutes = (): React.ReactNode => (
    <Route path={RoutingPattern.todo} element={<Layout />}>
        <Route index element={<Index />} />
        <Route path="admin" element={<AdminLayout />}>
            <Route index element={<TodoItemList />} />
            <Route path="categories" element={<TodoCategories />} />
            <Route path="items/:itemId" element={<TodoItemDetail />} />
        </Route>
    </Route>
);
