import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useAddTransfer } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useEditTalentContext } from '../Context';
import { TransferFormContent, toEditTransferRequest, useTransferForm } from './TransferForm';

export const AddTransferDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { employeeCode } = useEditTalentContext();
    const form = useTransferForm({ teamId: null, joinedAt: null, leavedAt: null });
    const mutation = useAddTransfer(employeeCode);
    return (
        <>
            <DialogTitle>異動履歴の追加</DialogTitle>
            <DialogContent>
                <TransferFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で履歴を追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="履歴の追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditTransferRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
