import { FlexBox } from '../FlexBox';
import { RouterLink } from '../RouterLink';
import { getTagUrl } from './urls';

export const ArticleTags: React.FC<{ tags: string[] }> = (props) => {
    return (
        <FlexBox gap={1}>
            {props.tags.map((tag) => (
                <RouterLink key={tag} color="primary" variant="body2" to={getTagUrl(tag)}>
                    #{tag}
                </RouterLink>
            ))}
        </FlexBox>
    );
};
