import { Box, Button, Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import { TalentId } from '@spec/Talent';
import { useParams } from 'react-router-dom';
import { fullName } from '../../../../domains/Talent';
import { findById } from '../../../../lib/ArrayUtils';
import { useMeContext } from '../../../../queries/me';
import { useSurveyGroupOperatePermissions } from '../../../../queries/survey';
import { useTalentsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { PersonAddDisabledIcon, PersonAddIcon } from '../../../Icons';
import { SubTitle } from '../../../PageTitle';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { TalentAvatar } from '../../../TalentAvatar';
import { WaitQuery } from '../../../WaitLoading';
import { useSurveysContext } from '../../Context';
import { ContextProvider, DialogMode, useDialogContext, useTalentIdContext } from './Context';
import { AddPermissionDialog } from './dialogs/AddPermissionDialog';
import { RevokePermissionDialog } from './dialogs/RevokePermissionDialog';
import { NoItems } from '../../../NoItems';

export const Operators: React.FC = () => {
    const { groupId } = useParams();
    const { surveyGroups } = useSurveysContext();
    const group = findById(Number(groupId), surveyGroups);
    const maybeOperators = useSurveyGroupOperatePermissions(group.id);
    return (
        <ContextProvider>
            <SubTitle title={`${group.name}のメモ権限`} />
            <Typography my={1} variant="body2">
                以下の人たちはこのサーベイグループの全ての回答の閲覧とメモの読み書きができます。
            </Typography>
            <Card>
                <CardContent>
                    <WaitQuery query={maybeOperators} size="medium">
                        {({ data }) => <OperatorList talentIds={data} />}
                    </WaitQuery>
                    <GrantButton />
                </CardContent>
            </Card>
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddPermission: AddPermissionDialog,
    RevokePermission: RevokePermissionDialog,
};

const OperatorList: React.FC<{ talentIds: TalentId[] }> = (props) => {
    if (props.talentIds.length === 0) {
        return <NoItems>このサーベイグループのメモ権限は誰にも付与されていません。</NoItems>;
    }
    return (
        <FlexBox flexGrow={1} gap={2}>
            {props.talentIds.map((v) => (
                <Operator key={v} talentId={v} />
            ))}
        </FlexBox>
    );
};

const Operator: React.FC<{ talentId: TalentId }> = (props) => {
    const { talents } = useTalentsContext();
    const talent = findById(props.talentId, talents);
    const { setDialogMode } = useDialogContext();
    const { setTalentId } = useTalentIdContext();
    return (
        <FlexBox mt={0.5} flexWrap="nowrap" gap={0.5}>
            <TalentAvatar size="small" talent={talent} />
            <Typography variant="body2">{fullName(talent)}</Typography>
            <Tooltip title="メモ権限を削除する">
                <IconButton
                    size="small"
                    onClick={() => {
                        setTalentId(talent.id);
                        setDialogMode('RevokePermission');
                    }}
                >
                    <PersonAddDisabledIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </FlexBox>
    );
};

const GrantButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    const { grants } = useMeContext();
    const disabled = grants.manageSurvey === false;
    return (
        <Box mt={1}>
            <Button
                variant="outlined"
                startIcon={<PersonAddIcon />}
                disabled={disabled}
                onClick={() => {
                    setDialogMode('AddPermission');
                }}
            >
                追加する
            </Button>
            {disabled && (
                <Typography mt={1} variant="body2" color="primary">
                    メモ権限の追加には「サーベイ管理」の権限が必要です
                </Typography>
            )}
        </Box>
    );
};
