import { DialogContent, DialogTitle } from '@mui/material';
import { Talent } from '@spec/Talent';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationError } from '../../../../../Errors';
import { isAvailableTalent } from '../../../../../domains/Talent';
import { findById } from '../../../../../lib/ArrayUtils';
import { Form, useForm, useFormNumber } from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { useGrantGroupOperate } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { TalentSearch } from '../../../../search/TalentSearch';
import { useSurveysContext } from '../../../Context';
import { useDialogContext } from '../Context';

const getTalentId = (form: Form<{ talentId: number | null }>): number => {
    const args = form.serialize();
    if (args.talentId === null) {
        throw new ApplicationError('Unexpected null of talentId');
    }
    return args.talentId;
};

export const AddPermissionDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { groupId } = useParams();
    const { surveyGroups } = useSurveysContext();
    const group = findById(Number(groupId), surveyGroups);

    const [talent, setTalent] = useState<Talent | null>(null);
    const form = useForm({ talentId: useFormNumber(null, [required]) });
    const mutation = useGrantGroupOperate(group.id);
    return (
        <>
            <DialogTitle>{group.name}のメモ権限を追加する</DialogTitle>
            <DialogContent>
                <DialogRow label="対象者">
                    <TalentSearch
                        filterTalent={(v) => isAvailableTalent(v)}
                        value={talent}
                        onChange={(v) => {
                            form.talentId.setValue(v?.id ?? null);
                            setTalent(v);
                        }}
                    />
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この人にメモ権限を付与する"
                cancelLabel="付与せずに閉じる"
                errorMessage="メモ権限の付与に失敗しました"
                onSubmit={() => mutation.mutateAsync(getTalentId(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
