import { useState } from 'react';
import { useAsyncState } from '../AsyncResource';
import {
    Filters,
    Form,
    FormBoolean,
    FormDate,
    FormNumber,
    FormText,
    FormValues,
    Validator,
} from './Contract';
import { trim } from './Filters';
import { canFormSubmit, hasDirtyElement, hasErrorElement } from './Utils';

export const useFormText = (
    initialValue: string,
    validators?: Validator[],
    filters?: Partial<Filters>
): FormText => {
    const [value, _setValue] = useState(createFV(initialValue, validators, filters));
    const setValue = (input: string, dirty = true) =>
        _setValue(createFV(input, validators, filters, dirty));
    return { ...value, setValue };
};

const emptyFilters: Filters = {
    onChange: [],
    onCompositionEnd: [],
    onBlur: [],
};

export const useFormDate = (
    initialValue: Date | null,
    validators: Validator<Date | null>[] = []
): FormDate => {
    const [value, _setValue] = useState<Omit<FormDate, 'setValue'>>({
        value: initialValue,
        error: findError(initialValue, validators),
        dirty: false,
        validators,
        filters: emptyFilters,
    });
    const setValue = (input: Date | null, dirty = true) =>
        _setValue({
            value: input,
            error: findError(input, validators),
            dirty,
            validators,
            filters: emptyFilters,
        });
    return { ...value, setValue };
};

export const useFormNumber = (
    initialValue: number | null,
    validators: Validator<number | null>[] = []
): FormNumber => {
    const [value, _setValue] = useState<Omit<FormNumber, 'setValue'>>({
        value: initialValue,
        error: findError(initialValue, validators),
        dirty: false,
        validators,
        filters: emptyFilters,
    });
    const setValue = (input: number | null, dirty = true) =>
        _setValue({
            value: input,
            error: findError(input, validators),
            dirty,
            validators,
            filters: emptyFilters,
        });
    return { ...value, setValue };
};

export const useFormBoolean = (
    initialValue: boolean,
    validators: Validator<boolean>[] = []
): FormBoolean => {
    const [value, _setValue] = useState<Omit<FormBoolean, 'setValue'>>({
        value: initialValue,
        error: findError(initialValue, validators),
        dirty: false,
        validators,
        filters: emptyFilters,
    });
    const setValue = (input: boolean, dirty = true) =>
        _setValue({
            value: input,
            error: findError(input, validators),
            dirty,
            validators,
            filters: emptyFilters,
        });
    return { ...value, setValue };
};

export const createFV = (
    input: string,
    validators: Validator[] = [],
    filters?: Partial<Filters>,
    dirty?: boolean
): Omit<FormText, 'setValue'> => {
    const error = findError(input, validators);
    return {
        value: input,
        error,
        dirty: dirty ?? false,
        validators,
        filters: {
            onChange: filters?.onChange ?? [],
            onCompositionEnd: filters?.onCompositionEnd ?? [],
            onBlur: filters?.onBlur ?? [trim],
        },
    };
};

const findError = <T>(input: T, validators: Validator<T>[]): string | null => {
    for (const validator of validators) {
        const result = validator(input);
        if (result !== null) {
            return result;
        }
    }
    return null;
};

const mapObject = (x: FormValues<any>) =>
    Object.fromEntries(Object.entries(x).map(([k, v]) => [k, v.value]));

export const useForm = <T>(elements: FormValues<T>): Form<T> => {
    const [updated, setUpdated] = useAsyncState<true>({ inProgress: false, value: null });
    return {
        ...elements,
        canSubmit: canFormSubmit(Object.values(elements)),
        hasDirtyElement: hasDirtyElement(Object.values(elements)),
        hasErrorElement: hasErrorElement(Object.values(elements)),
        send: (f: Promise<void>) => {
            setUpdated({ inProgress: true, value: null });
            f.then(() => setUpdated({ inProgress: false, value: true })).catch((e) => {
                setUpdated({
                    inProgress: false,
                    value: e instanceof Error ? e : Error('unknown error'),
                });
            });
        },
        inProgress: updated.inProgress,
        succeeded: updated.value === true,
        failed: updated.value instanceof Error,
        error: updated.value instanceof Error ? updated.value : null,
        serialize: () => mapObject(elements) as T,
    };
};

export const useEmptyForm = (): Form<{ dummy: string }> => {
    const dummy: FormText = {
        ...createFV('', [], {}, true),
        setValue: () => {},
    };
    return useForm({ dummy });
};
