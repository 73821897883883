import { NOTES_IMAGE_MAX_WIDTH } from '@spec/Notes';
import ImageExtension, { type ImageOptions } from '@tiptap/extension-image';
import { EditorView, NodeView } from '@tiptap/pm/view';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { Node } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import { firebaseApp } from '../../../App';
import { ApplicationError, ParameterError } from '../../../Errors';
import { uploadImage } from '../../../queries/notes';
import { type Gateway } from '../../../stores/Gateway';

const getImages = (data: DataTransfer | null): File[] => {
    return Array.from(data?.files ?? []).filter((file) => /image/i.test(file.type));
};

const LAMBDA_REQUEST_LIMIT_BYTES = 6000 * 1000;

const upload = async (
    gateway: Gateway,
    image: File,
    view: EditorView,
    position: number,
    loadingNode: Node
) => {
    const { schema } = view.state;
    try {
        const resizedImage = await resizeImage(image);
        if (resizedImage.size > LAMBDA_REQUEST_LIMIT_BYTES) {
            throw new ParameterError('Image size is too large', 'parameter/image-too-large');
        }
        const path = await uploadImage(gateway, resizedImage);
        view.dispatch(
            view.state.tr.replaceWith(
                position,
                position + loadingNode.nodeSize,
                schema.nodes.image.create({ src: path })
            )
        );
    } catch (error) {
        view.dispatch(
            view.state.tr.replaceWith(
                position,
                position + loadingNode.nodeSize,
                schema.text(getErrorMessage(error))
            )
        );
    }
};

const getErrorMessage = (e: unknown): string => {
    if (e instanceof ParameterError) {
        switch (e.code) {
            case 'parameter/image-not-supported':
                return 'サポートされていない画像形式です';
            case 'parameter/image-too-large':
                return '画像サイズが大きすぎます';
        }
    }
    return 'アップロードに失敗しました';
};

interface ExtendedImageOptions extends ImageOptions {
    gateway: Gateway;
}

export default ImageExtension.extend<ExtendedImageOptions>({
    renderHTML() {
        // to avoid rendering img element
        return ['p'];
    },

    addNodeView() {
        return (props) => new MyFirebaseImageView(props.node);
    },

    addProseMirrorPlugins() {
        const gateway = this.options.gateway;
        if (!this.options.gateway) {
            throw new ApplicationError('ImageUploadExtension requires a gateway');
        }
        return [
            new Plugin({
                props: {
                    handleDOMEvents: {
                        drop(view, event) {
                            const images = getImages(event.dataTransfer);
                            if (images.length === 0) {
                                return;
                            }
                            event.preventDefault();

                            const { schema } = view.state;
                            const coordinates = view.posAtCoords({
                                left: event.clientX,
                                top: event.clientY,
                            });
                            if (coordinates === null) {
                                throw Error('coordinates is null');
                            }
                            const image = images[0];
                            const node = schema.nodes.paragraph.create(
                                {},
                                schema.text('画像アップロード中...')
                            );
                            const transaction = view.state.tr.insert(coordinates.pos, node);
                            view.dispatch(transaction);
                            void upload(gateway, image, view, coordinates.pos, node);
                        },
                        paste(view, event) {
                            const images = getImages(event.clipboardData);
                            if (images.length === 0) {
                                return;
                            }
                            event.preventDefault();

                            const { schema } = view.state;
                            const position = view.state.selection.from;
                            const image = images[0];
                            const node = schema.nodes.paragraph.create(
                                {},
                                schema.text('画像アップロード中...')
                            );
                            const transaction = view.state.tr.insert(position, node);
                            view.dispatch(transaction);
                            void upload(gateway, image, view, position, node);
                        },
                    },
                },
            }),
        ];
    },
});

class MyFirebaseImageView implements NodeView {
    dom: HTMLImageElement;

    constructor(node: Node) {
        this.dom = document.createElement('img');
        if (node.attrs.src.startsWith('/')) {
            const uploadRef = ref(getStorage(firebaseApp), node.attrs.src);
            void getDownloadURL(uploadRef).then((url) => {
                this.dom.src = url;
            });
        } else {
            this.dom.alt = '外部画像は利用できません';
        }
    }
}

// Keep original image quality
const JPEG_IMAGE_QUALITY = 1;

const resizeImage = async (file: File): Promise<File> => {
    // Do not break the animation GIF
    if (file.type === 'image/gif') {
        return file;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    await new Promise<HTMLImageElement>((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = () =>
            reject(new ParameterError('Failed to load image', 'parameter/image-not-supported'));
    });
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('Failed to get canvas context');
    }

    let { width, height } = img;
    if (width > NOTES_IMAGE_MAX_WIDTH) {
        const scaleFactor = NOTES_IMAGE_MAX_WIDTH / width;
        width = NOTES_IMAGE_MAX_WIDTH;
        height = height * scaleFactor;
    }

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(img, 0, 0, width, height);

    return new Promise<File>((resolve) => {
        canvas.toBlob(
            (newBlob) => {
                if (!newBlob) {
                    throw new Error('Failed to create Blob');
                }
                const newFile = new File([newBlob], file.name, {
                    type: file.type,
                    lastModified: new Date().getTime(),
                });
                resolve(newFile);
            },
            file.type,
            JPEG_IMAGE_QUALITY
        );
    });
};
