import { Box, CircularProgress, Typography } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import {
    InfiniteQueryObserverSuccessResult,
    QueryObserverSuccessResult,
    UseInfiniteQueryResult,
    UseQueryResult,
} from '@tanstack/react-query';
import React, { ReactNode, useState } from 'react';
import { reportError } from '../Errors';
import { AsyncResource } from '../lib/AsyncResource';
import { FlexBox } from './FlexBox';
import { IconCircle } from './IconCircle';
import { ClearIcon } from './Icons';
import { SubmitButton } from './SubmitButton';

const RenderQuery = <T,>(props: {
    query: UseQueryResult<T> | UseInfiniteQueryResult<T>;
    children: (result: any) => ReactNode;
    loading?: ReactNode;
    size?: LoadingSize;
    error?: ReactNode | ((e: Error) => ReactNode);
}) => {
    if (props.query.isError) {
        if (props.error === undefined) {
            return <ErrorContent />;
        }
        return typeof props.error === 'function' ? props.error(props.query.error) : props.error;
    }
    if (props.query.isPending) {
        return props.loading ?? <SizedLoading size={props.size} />;
    }
    return props.children(props.query);
};

export const WaitQuery = <T,>(props: {
    query: UseQueryResult<T>;
    children: (result: QueryObserverSuccessResult<T>) => ReactNode;
    loading?: ReactNode;
    size?: LoadingSize;
    error?: ReactNode | ((e: Error) => ReactNode);
}) => RenderQuery(props);

export const WaitInfiniteQuery = <T,>(props: {
    query: UseInfiniteQueryResult<T>;
    children: (result: InfiniteQueryObserverSuccessResult<T>) => ReactNode;
    loading?: ReactNode;
    size?: LoadingSize;
    error?: ReactNode | ((e: Error) => ReactNode);
}) => RenderQuery(props);

export const WaitLoading: React.FC<{
    waitFor: WaitFor[];
    children: React.ReactNode | React.ReactNode[];
    size?: LoadingSize;
}> = (props) => {
    const errors = props.waitFor.flatMap((v) => {
        const x = pickError(v);
        return x === null ? [] : x;
    });
    if (errors.length > 0) {
        errors.forEach((e) => reportError(e));
        return <ErrorContent />;
    }
    if (props.waitFor.some(isInProgress)) {
        return <SizedLoading size={props.size} />;
    }
    return <>{props.children}</>;
};

type WaitFor = AsyncResource<any> | UseQueryResult;

const isAsyncResource = (v: unknown): v is AsyncResource<any> => {
    if (typeof v !== 'object' || v === null) {
        return false;
    }
    const keys = Object.keys(v);
    return keys.includes('inProgress') && keys.includes('value');
};

const wrapError = (v: unknown): Error => (v instanceof Error ? v : Error(`${v}`));

const pickError = (v: WaitFor): Error | null => {
    if (isAsyncResource(v)) {
        return v.value instanceof Error ? v.value : null;
    }
    return v.isError ? wrapError(v.error) : null;
};

const isInProgress = (v: WaitFor): boolean => {
    if (isAsyncResource(v)) {
        return v.inProgress;
    }
    return v.isPending;
};

type LoadingSize = 'large' | 'medium' | 'small';

const SizedLoading: React.FC<{ size?: LoadingSize }> = (props) => {
    switch (props.size) {
        case 'small':
            return (
                <FlexBox mt={2}>
                    <CircularProgress color="secondary" size={20} />
                    <Box ml={1}>
                        <Typography variant="body2">読み込み中</Typography>
                    </Box>
                </FlexBox>
            );
        case 'medium':
            return (
                <Box my={2} textAlign="center">
                    <CircularProgress color="secondary" size={32} />
                    <Box mt={1}>
                        <Typography variant="body2">読み込み中</Typography>
                    </Box>
                </Box>
            );
        default:
            return (
                <Box mt={20}>
                    <Loading />
                </Box>
            );
    }
};

export const Loading: React.FC = () => {
    return (
        <Box textAlign="center">
            <CircularProgress color="secondary" />
            <Box mt={4}>
                <Typography variant="body2">読み込み中</Typography>
            </Box>
        </Box>
    );
};

export const ErrorContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [inProgress, setInProgress] = useState(false);
    return (
        <Box my={10} textAlign="center">
            <Box mb={4}>
                <IconCircle icon={ClearIcon} color={deepOrange[800]} />
            </Box>
            <Box mb={3}>
                <Typography variant="h6">データの読み込みに失敗しました</Typography>
            </Box>
            <Box mb={2}>
                <Typography>しばらくアクセスがないと準備に少し時間がかかります。</Typography>
            </Box>
            <Box mb={4}>
                <Typography>
                    通常30秒〜1分ぐらいで利用可能になるので、少し待ってください。
                </Typography>
            </Box>
            <Box mb={2}>
                <SubmitButton
                    size="large"
                    variant="outlined"
                    inProgress={inProgress}
                    onClick={() => {
                        setInProgress(true);
                        window.location.reload();
                    }}
                >
                    今すぐ再読み込み
                </SubmitButton>
            </Box>
        </Box>
    );
};
