import { DialogContent, DialogTitle } from '@mui/material';
import { useAddSurveyGroup } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../Context';
import { GroupFormContent, useGroupForm } from '../GroupForm';

export const AddGroupDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const form = useGroupForm({ name: '', description: '' });
    const mutation = useAddSurveyGroup();
    return (
        <>
            <DialogTitle>サーベイグループを追加する</DialogTitle>
            <DialogContent>
                <GroupFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でサーベイグループを追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
