import { Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import { useNotesLikes } from '../../queries/notes';
import { FlexBox } from '../FlexBox';
import { FavoriteBorderIcon, FavoriteIcon } from '../Icons';
import { WaitQuery } from '../WaitLoading';

export const LikesCounter: React.FC<{ articleId: number }> = (props) => {
    const maybeLikes = useNotesLikes();
    return (
        <FlexBox flexWrap="nowrap" gap={0.5} sx={{ minWidth: '32px' }}>
            <WaitQuery
                query={maybeLikes}
                loading={<FavoriteBorderIcon fontSize="small" color="disabled" />}
            >
                {({ data }) => {
                    const liked = data.likedArticleIds.includes(props.articleId);
                    const Icon = liked ? FavoriteIcon : FavoriteBorderIcon;
                    const color = liked ? pink['A200'] : 'inherit';
                    return (
                        <>
                            <Icon fontSize="small" sx={{ color }} />
                            <Typography sx={{ color }}>
                                {data.likes[props.articleId] ?? 0}
                            </Typography>
                        </>
                    );
                }}
            </WaitQuery>
        </FlexBox>
    );
};
