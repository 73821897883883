import { Route } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { ModalBase } from '../PageModal';
import { TeamDetail } from './detail';
import { TeamList } from './list';

export function OrganizationRoutes() {
    return (
        <Route
            path={RoutingPattern.organization}
            element={<ModalBase path={RoutingPattern.organization} element={<TeamList />} />}
        >
            <Route path="team/:teamId" element={<TeamDetail />} />
        </Route>
    );
}
