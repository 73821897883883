import { Box, CircularProgress, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import { useQueryClient } from '@tanstack/react-query';
import { useMeContext } from '../../../queries/me';
import { useTogglePinArticle } from '../../../queries/notes';
import { PushPinIcon, PushPinOutlinedIcon } from '../../Icons';

export const PinButton: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const { me } = useMeContext();
    if (me.id !== props.article.talentId) {
        return null;
    }
    return <PinToggleButton article={props.article} />;
};

const PinToggleButton: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const pinned = props.article.isPinned;
    const theme = useTheme();
    const queryClient = useQueryClient();
    const mutation = useTogglePinArticle(queryClient, props.article.id, pinned);
    const title = pinned ? (
        'ピン留めを解除する'
    ) : (
        <Typography variant="body2">
            ピン留めした記事はプロフィール画面やあなたの記事一覧で常に先頭に固定表示されます
        </Typography>
    );

    const color = pinned ? theme.palette.secondary.main : theme.palette.text.disabled;
    const Icon = pinned ? PushPinIcon : PushPinOutlinedIcon;
    return (
        <Box width="40px" height="40px" textAlign="center">
            {mutation.isPending ? (
                <CircularProgress size={16} color="secondary" sx={{ marginTop: '12px' }} />
            ) : (
                <Tooltip title={title} arrow placement="top">
                    <IconButton
                        sx={{
                            color,
                            '&:hover': {
                                color: theme.palette.secondary.main,
                                backgroundColor: `${theme.palette.secondary.main}18`,
                            },
                        }}
                        onClick={() => mutation.mutate()}
                    >
                        <Icon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};
