import { TodoCategory, TodoItem } from '@spec/Todo';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../Context';

export type DialogMode = 'ConfirmTodoItem';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const TodoCategoriesContext = createContext(
    {} as {
        categories: TodoCategory[];
    }
);
const TodoCategoriesContextProvider: React.FC<{
    categories: TodoCategory[];
    children: ReactNode;
}> = (props) => {
    return (
        <TodoCategoriesContext.Provider
            value={{
                categories: props.categories,
            }}
        >
            {props.children}
        </TodoCategoriesContext.Provider>
    );
};
export const useTodoCategoriesContext = () => useContext(TodoCategoriesContext);

const TodoItemContext = createContext(
    {} as {
        item: TodoItem | null;
        setItem: (v: TodoItem) => void;
    }
);
export const TodoItemContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    const [item, setItem] = useState<TodoItem | null>(null);
    return (
        <TodoItemContext.Provider value={{ item, setItem }}>
            {props.children}
        </TodoItemContext.Provider>
    );
};
export const useTodoItemContext = () => useContext(TodoItemContext);

export const ContextProvider: React.FC<{
    categories: TodoCategory[];
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <TodoCategoriesContextProvider categories={props.categories}>
                <TodoItemContextProvider>{props.children}</TodoItemContextProvider>
            </TodoCategoriesContextProvider>
        </DialogContextProvider>
    );
};
