import {
    Checkbox,
    DialogContent,
    DialogTitle,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import type { Privilege } from '@spec/Grants';
import { useState } from 'react';
import { privilegeLabelMap } from '../../../../../domains/Grants';
import { fullName } from '../../../../../domains/Talent';
import { useForm, useFormBoolean } from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { useGrantPrivileges } from '../../../../../queries/grant';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext } from '../../edit/Context';
import { usePrivilegeContext, useTargetTalentContext } from '../Context';

export const GrantDialog = () => {
    const { closeDialog } = useDialogContext();
    const { talent } = useTargetTalentContext();
    const { grantedPrivileges } = usePrivilegeContext();
    const [selected, setSelected] = useState<Set<Privilege>>(new Set());
    const form = useForm({
        selected: useFormBoolean(false, [required]),
    });
    const mutation = useGrantPrivileges(talent.id);
    return (
        <>
            <DialogTitle>権限の付与</DialogTitle>
            <DialogContent>
                <DialogRow label={`${fullName(talent)}さんに新しく付与する権限を選択してください`}>
                    {[...privilegeLabelMap].map(([k, v]) => (
                        <ListItemButton
                            key={k}
                            dense
                            disabled={grantedPrivileges.has(k)}
                            onClick={() => {
                                setSelected((prev) => {
                                    const next = new Set(prev);
                                    if (next.has(k)) {
                                        next.delete(k);
                                    } else {
                                        next.add(k);
                                    }
                                    form.selected.setValue(next.size > 0);
                                    return next;
                                });
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={grantedPrivileges.has(k) || selected.has(k)}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText>{v}</ListItemText>
                        </ListItemButton>
                    ))}
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="権限を付与する"
                cancelLabel="キャンセル"
                errorMessage="権限の付与に失敗しました"
                onSubmit={() => mutation.mutateAsync(selected)}
                closeDialog={closeDialog}
            />
        </>
    );
};
