import {
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
} from '@mui/material';
import React, { type ReactNode } from 'react';
import { HelpAccordion } from '../../HelpAccordion';

export const EmployeeCodeInfo: React.FC = () => {
    return (
        <HelpAccordion title="CARTAの社員番号区分（ヒトノワに登録されていない区分も含みます）">
            <Container maxWidth="md">
                <Box display="flex" justifyContent="center" mx={4} gap={5}>
                    <Box flexGrow={1}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell>1文字目 : 法人</HeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>ビズテーラー・パートナーズ</TableCell>
                                    <TableCell>b</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>CARTA HOLDINGS</TableCell>
                                    <TableCell>c</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lighthouse Studio</TableCell>
                                    <TableCell>l</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>CARTA KNOT</TableCell>
                                    <TableCell>n</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>リサーチパネル</TableCell>
                                    <TableCell>p</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>ヨミテ</TableCell>
                                    <TableCell>r</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Zucks China</TableCell>
                                    <TableCell>y</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>関係会社</TableCell>
                                    <TableCell>z</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                    <Box flexGrow={1}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell>2文字目 : 雇用区分</HeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>正社員, 契約社員, パート, 嘱託</TableCell>
                                    <TableCell>a</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>役員</TableCell>
                                    <TableCell>b</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>派遣社員</TableCell>
                                    <TableCell>c</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>受入出向</TableCell>
                                    <TableCell>d</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>業務委託</TableCell>
                                    <TableCell>e</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>その他</TableCell>
                                    <TableCell>x</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Container>
        </HelpAccordion>
    );
};

function HeaderCell(props: { children: ReactNode }) {
    const theme = useTheme();
    return (
        <TableCell
            {...props}
            colSpan={2}
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            }}
        />
    );
}
