import { Box } from '@mui/material';
import type { Grant } from '@spec/Grants';
import { NoItems } from '../NoItems';
import { useFilterFormContext } from './Context';
import { GrantsFilterForm } from './GrantsFilterForm';
import { GrantsTable } from './GrantsTable';
import { filterGrants } from './filter';

export const GrantsList = (props: { grants: Grant[] }) => {
    const { condition } = useFilterFormContext();
    const grants = filterGrants(props.grants, condition);
    return (
        <Box>
            <Box my={2}>
                <GrantsFilterForm />
            </Box>
            {grants.length === 0 ? (
                <NoItems mt={4}>条件に合致する権限は設定されていません。</NoItems>
            ) : (
                <GrantsTable grants={grants} />
            )}
        </Box>
    );
};
