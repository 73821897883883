import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { LinkButton } from '../ActionButtons';
import { BeforeIcon } from '../Icons';

export const BackToIndex: React.FC = () => {
    const location = useLocation();
    if (location.pathname !== RoutingPattern.apiReference) {
        return (
            <Box my={2}>
                <LinkButton to={RoutingPattern.apiReference} startIcon={<BeforeIcon />}>
                    APIリファレンスのトップに戻る
                </LinkButton>
            </Box>
        );
    }
    return null;
};
