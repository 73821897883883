import { TodoCategory, TodoItem, TodoItemStat } from '@spec/Todo';
import React, { createContext, ReactNode, useContext } from 'react';

const TodoItemsContext = createContext(
    {} as {
        categories: TodoCategory[];
        items: TodoItem[];
        stats: TodoItemStat[];
    }
);
const TodoItemsContextProvider: React.FC<{
    categories: TodoCategory[];
    items: TodoItem[];
    stats: TodoItemStat[];
    children: ReactNode;
}> = (props) => {
    return (
        <TodoItemsContext.Provider
            value={{
                categories: props.categories,
                items: props.items,
                stats: props.stats,
            }}
        >
            {props.children}
        </TodoItemsContext.Provider>
    );
};
export const useTodoItemsContext = () => useContext(TodoItemsContext);

export const ContextProvider: React.FC<{
    categories: TodoCategory[];
    items: TodoItem[];
    stats: TodoItemStat[];
    children: ReactNode;
}> = (props) => {
    return (
        <TodoItemsContextProvider
            categories={props.categories}
            items={props.items}
            stats={props.stats}
        >
            {props.children}
        </TodoItemsContextProvider>
    );
};
