import { Box } from '@mui/material';
import { ActionButton, ActionContainer } from '../ActionButtons';
import { AccessibilityNewIcon, DoDisturbOnIcon } from '../Icons';
import { SubTitle } from '../PageTitle';
import { HumanCapital } from './HumanCapital';
import { leaveOfAbsencesUrl, sabbaticalLeavesUrl } from './urls';

export function AdminDashBoard() {
    return (
        <Box>
            <SubTitle mb={2} title="人的資本データ" />
            <HumanCapital />
            <SubTitle mt={4} title="労務メニュー" />
            <ActionContainer>
                <ActionButton startIcon={<AccessibilityNewIcon />} to={sabbaticalLeavesUrl}>
                    ボーナス休暇の一覧を見る
                </ActionButton>
                <ActionButton startIcon={<DoDisturbOnIcon />} to={leaveOfAbsencesUrl}>
                    休職情報の一覧を見る
                </ActionButton>
            </ActionContainer>
        </Box>
    );
}
