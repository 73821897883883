import { Route } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { ApiReferences } from './';
import { Category } from './Category';
import { Layout } from './Layout';

export const ApiReferenceRoutes = (): React.ReactNode => (
    <Route path={RoutingPattern.apiReference} element={<Layout />}>
        <Route index element={<ApiReferences />} />
        <Route path=":category" element={<Category />} />
    </Route>
);
