import { Chip, Typography } from '@mui/material';
import { TodoAssignment, TodoItem } from '@spec/Todo';
import type { TodoNotification } from '@spec/notification';
import dayjs from 'dayjs';
import { type ReactNode } from 'react';
import { ApplicationError } from '../Errors';
import { FlexBox } from '../components/FlexBox';
import { getTodoItemUrl } from '../components/todo/urls';

export type TodoItemStatus = 'draft' | 'public' | 'finished';

export interface Announcement {
    id: number;
    key: string;
    title: string | ReactNode;
    publishedAt: Date;
    contents: string[];
    action: {
        label: string;
        url: string;
    } | null;
}

export const getStatus = (item: TodoItem): TodoItemStatus => {
    if (item.publishedAt === null) {
        if (item.finishedAt === null) {
            return 'draft';
        }
        throw new ApplicationError(
            `Detect unexpected status, not published but finished: ${item.title}`
        );
    }
    if (item.finishedAt === null) {
        return 'public';
    }
    return 'finished';
};

export const canPublish = (item: TodoItem, assignments: TodoAssignment[]): boolean => {
    if (item.description === '') {
        return false;
    }
    if (assignments.length === 0) {
        return false;
    }
    return true;
};

export const buildAnnouncement = (v: TodoNotification): Announcement[] => {
    const f = (v: TodoNotification, contents: string[]): Announcement => ({
        id: v.id,
        key: '',
        title: (
            <FlexBox gap={0.5}>
                <Chip color="primary" label={v.payload.category} />
                <Typography>{v.payload.title}</Typography>
            </FlexBox>
        ),
        publishedAt: v.occurredAt,
        contents,
        action:
            v.action === 'assign'
                ? {
                      label: 'やることの詳細を見る',
                      url: getTodoItemUrl(v.payload.id),
                  }
                : null,
    });
    switch (v.action) {
        case 'assign': {
            const messages = ['新しいやることが設定されました。'];
            if (v.payload.deadline !== null) {
                messages.push(
                    `${dayjs(v.payload.deadline).format('M月D日 HH:mm')} までの対応をお願いします。`
                );
            }
            return [f(v, messages)];
        }
        case 'finish':
            return [f(v, ['管理者によって公開終了されました。'])];
        case 'unpublish':
            return [f(v, ['管理者によって取り下げられました。'])];
        case 'withdraw':
            return [f(v, ['管理者の操作で対象から外れました。'])];
        default:
            return [];
    }
};
