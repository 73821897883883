import { Box, Divider, SvgIconProps, Typography, useTheme, type BoxProps } from '@mui/material';
import React from 'react';
import { FlexBox } from './FlexBox';

export const PageTitle: React.FC<{
    title: string;
    icon?: React.FC<SvgIconProps>;
}> = (props) => {
    const theme = useTheme();
    return (
        <Box mb={3}>
            <FlexBox
                sx={{
                    color: theme.palette.text.secondary,
                }}
            >
                {props.icon && <props.icon />}
                <Typography ml={0.5} variant="h6">
                    {props.title}
                </Typography>
            </FlexBox>
            <Divider sx={{ borderColor: theme.palette.primary.light }} />
        </Box>
    );
};

export const SubTitle: React.FC<
    {
        title: string;
        icon?: React.FC<SvgIconProps>;
    } & BoxProps
> = (props) => {
    const { title, ...rest } = props;
    return (
        <Box {...rest}>
            <FlexBox gap={0.5}>
                {props.icon && <props.icon fontSize="small" color="primary" />}
                <Typography variant="subtitle1" color="primary">
                    {title}
                </Typography>
            </FlexBox>
            <Divider />
        </Box>
    );
};
