import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormTextField, useForm, useFormText } from '../../../lib/Form';
import { useTenantContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { SearchIcon } from '../../Icons';
import { getSearchUrl } from '../urls';

export const SearchForm: React.FC<{ word: string }> = (props) => {
    const navigate = useNavigate();
    const form = useForm({
        word: useFormText(props.word),
    });
    const { tenant } = useTenantContext();
    const placeholder =
        tenant === 'carta'
            ? '記事を検索する（Kibelaの検索結果へのリンクも出ます）'
            : '記事を検索する';
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                navigate(getSearchUrl(form.word.value));
            }}
        >
            <FlexBox gap={1}>
                <Box flexGrow={1}>
                    <FormTextField
                        autoFocus
                        formText={form.word}
                        placeholder={placeholder}
                        fullWidth
                    />
                </Box>
                <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    color="primary"
                    disabled={form.word.value === ''}
                    startIcon={<SearchIcon />}
                >
                    検索する
                </Button>
            </FlexBox>
        </form>
    );
};
