import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutingPattern } from '../../../../../Routes';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteTodoItem } from '../../../../../queries/todoAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../itemList/Context';
import { useTodoItemContext } from '../Context';

export const DeleteTodoItemDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { item } = useTodoItemContext();
    const form = useEmptyForm();
    const mutation = useDeleteTodoItem(item.id);
    const navigate = useNavigate();
    return (
        <>
            <DialogTitle>「{item.title}」を削除する</DialogTitle>
            <DialogContent>
                <Typography>この操作は間違えて作ったやることを削除するためのものです。</Typography>
                <Typography mt={1}>削除すると管理者の一覧からも参照できなくなります。</Typography>
                <Typography mt={1}>
                    一度削除したやることは元に戻せません。それでも構いませんか？
                </Typography>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="削除に失敗しました"
                onSubmit={() =>
                    mutation.mutateAsync().then(() => navigate(RoutingPattern.todoAdmin))
                }
                closeDialog={closeDialog}
            />
        </>
    );
};
