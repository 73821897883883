import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, {
    DependencyList,
    EffectCallback,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { FormDate } from '../lib/Form';
import { zenToHan } from '../lib/Form/Filters';
import { DateTimeInput } from './DateTimeInput';
import { FlexBox } from './FlexBox';

const format = (v: Date | null): string | null => (v ? dayjs(v).format('YYYY-MM-DD') : null);

const useDidMountEffect = (func: EffectCallback, deps: DependencyList) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export const DateInput: React.FC<{
    formDate: FormDate;
    min?: Date;
    max?: Date;
    autoFocus?: boolean;
}> = (props) => {
    const [year, setYear] = useState<number | null>(props.formDate.value?.getFullYear() ?? null);
    const [month, setMonth] = useState<number | null>(
        props.formDate.value ? props.formDate.value.getMonth() + 1 : null
    );
    const [day, setDay] = useState<number | null>(props.formDate.value?.getDate() ?? null);
    const [date, setDate] = useState<Date | null>(props.formDate.value);

    useDidMountEffect(() => {
        if (year === null || month === null || day === null) {
            setDate(null);
            return;
        }
        const next = new Date(Date.UTC(year, month - 1, day));
        if (
            `${year}-${month}` !== dayjs(next).format('YYYY-M') ||
            (props.min && props.min.getTime() > next.getTime()) ||
            (props.max && props.max.getTime() < next.getTime())
        ) {
            setDate(null);
        } else {
            setDate(next);
        }
    }, [year, month, day, props.min, props.max]);

    useDidMountEffect(() => {
        if (format(date) !== format(props.formDate.value)) {
            props.formDate.setValue(date);
        }
    }, [format(date)]);

    return (
        <FlexBox>
            <TextField
                autoFocus={props.autoFocus}
                sx={{ width: '4rem' }}
                error={date === null}
                value={year ?? ''}
                onChange={(e) => setYear(toNullishNumber(e))}
                slotProps={{
                    htmlInput: { maxLength: 4 },
                }}
            ></TextField>
            <Box ml={0.5} mr={1}>
                <Typography>年</Typography>
            </Box>
            <TextField
                sx={{ width: '3rem', '& .MuiInputBase-input': { textAlign: 'right' } }}
                error={date === null}
                value={month ?? ''}
                onChange={(e) => setMonth(toNullishNumber(e))}
                slotProps={{
                    htmlInput: { maxLength: 2 },
                }}
            ></TextField>
            <Box ml={0.5} mr={1}>
                <Typography>月</Typography>
            </Box>
            <TextField
                sx={{ width: '3rem', '& .MuiInputBase-input': { textAlign: 'right' } }}
                error={date === null}
                value={day ?? ''}
                onChange={(e) => setDay(toNullishNumber(e))}
                slotProps={{
                    htmlInput: { maxLength: 2 },
                }}
            ></TextField>
            <Box ml={0.5}>
                <Typography>日</Typography>
            </Box>
            <Box mr={1} sx={{ width: '2rem' }}>
                <Typography>
                    （<span className="weekday">{date ? dayjs(date).format('ddd') : '-'}</span>）
                </Typography>
            </Box>
        </FlexBox>
    );
};

const toNullishNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
): number | null => {
    const x = parseInt(zenToHan(e.target.value), 10);
    return Number.isNaN(x) ? null : x;
};

const OptionalInput: React.FC<{
    formDate: FormDate;
    label: string;
    children: ReactNode;
}> = (props) => {
    const [checked, setChecked] = useState(props.formDate.value !== null);
    return (
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={(e) => {
                            const v = e.target.checked;
                            setChecked(v);
                            if (v === false) {
                                props.formDate.setValue(null);
                            }
                        }}
                        size="small"
                    />
                }
                label={<Typography variant="body2">{props.label}</Typography>}
            />
            {checked && props.children}
        </Box>
    );
};

interface OptionalDateInputProps {
    formDate: FormDate;
    label: string;
    min?: Date;
    max?: Date;
}

export const OptionalDateInput: React.FC<OptionalDateInputProps> = (props) => {
    return (
        <OptionalInput formDate={props.formDate} label={props.label}>
            <DateInput autoFocus formDate={props.formDate} min={props.min} max={props.max} />
        </OptionalInput>
    );
};

export const OptionalDateTimeInput: React.FC<OptionalDateInputProps> = (props) => {
    return (
        <OptionalInput formDate={props.formDate} label={props.label}>
            <DateTimeInput autoFocus formDate={props.formDate} min={props.min} max={props.max} />
        </OptionalInput>
    );
};
