import React from 'react';
import { Route } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { ModalBase } from '../PageModal';
import { Layout } from './Layout';
import { Article } from './article';
import { AuthorContextProvider } from './article/AuthorContext';
import { UpdateArticle } from './article/UpdateForm';
import { Articles } from './articles';
import { ListByAuthor } from './articles/ListByAuthor';
import { ListByTag } from './articles/ListByTag';
import { Post } from './post';
import { Search } from './search';

export const NotesRoutes = (): React.ReactNode => (
    <Route path={RoutingPattern.notes} element={<Layout />}>
        <Route path="" element={<ModalBase path={RoutingPattern.notes} element={<Articles />} />}>
            <Route path=":author/:articleId" element={<Article />} />
        </Route>
        <Route path="tag/:tag" element={<ListByTag />} />
        <Route path=":author" element={<AuthorContextProvider />}>
            <Route index element={<ListByAuthor />} />
            <Route path=":articleId">
                <Route path="edit" element={<UpdateArticle />} />
            </Route>
        </Route>
        <Route path="post" element={<Post />} />
        <Route path="search" element={<Search />} />
    </Route>
);
