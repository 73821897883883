import React from 'react';
import { Box, BoxProps } from '@mui/material';

export const FlexBox: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
    const { children, ...rest } = props;
    const boxProps: BoxProps = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    };
    Object.assign(boxProps, rest);
    return <Box {...boxProps}>{children}</Box>;
};
