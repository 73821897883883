import { Alert, Box, Container, Stack, type LinkProps } from '@mui/material';
import { useMeContext } from '../../../queries/me';
import { ActionButton, ActionContainer, LinkButton } from '../../ActionButtons';
import { EditIcon } from '../../Icons';
import { ExternalLink, SlackLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { TenantContent } from '../../TenantContent';
import { KIBELA_ARCHIVE_URL } from '../Contract';
import { SearchForm } from '../search/SearchForm';
import { getAuthorUrl, newPostUrl } from '../urls';

const Link = (props: LinkProps) => <ExternalLink mx={0.5} icon {...props} />;

export const NotesHeader: React.FC = () => {
    const { me } = useMeContext();
    return (
        <Box>
            <Container maxWidth="md">
                <Stack spacing={1}>
                    <TenantContent carta={<Carta />} treasure2024={<Treasure2024 />} />
                </Stack>
                <Box mt={2}>
                    <SearchForm word="" />
                </Box>
                <Box mt={4} textAlign="right">
                    <LinkButton
                        size="medium"
                        startIcon={<TalentAvatar size="small" talent={me} />}
                        to={getAuthorUrl(me.hitonowaId)}
                    >
                        あなたの記事一覧
                    </LinkButton>
                </Box>
            </Container>
            <ActionContainer mt={-4}>
                <ActionButton startIcon={<EditIcon />} to={newPostUrl}>
                    新しい記事を書く
                </ActionButton>
            </ActionContainer>
        </Box>
    );
};

const Carta = () => {
    return (
        <>
            <Alert severity="info">
                新しい記事の投稿やコメントはSlackの
                <SlackLink channel="C05NXBDMGS0">#hitonowa_notes</SlackLink>
                に、記事がいいね！されると
                <SlackLink channel="C06661479B4">#hitonowa_likes</SlackLink>
                に通知されます。
            </Alert>
            <Alert severity="info">
                Kibelaの記事は
                <Link href={KIBELA_ARCHIVE_URL}>Google Drive</Link>
                にアーカイブされています。
            </Alert>
        </>
    );
};

const Treasure2024 = () => {
    return (
        <>
            <Alert severity="info">
                新しい記事の投稿やコメントはSlackの
                <SlackLink channel="C07BEEB2YH0">#hitonowa_notes</SlackLink>
                に、記事がいいね！されると
                <SlackLink channel="C07BBH6BUTX">#hitonowa_likes</SlackLink>
                に通知されます。
            </Alert>
        </>
    );
};
