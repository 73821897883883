import { Box, Card, CardContent, DialogContent, DialogTitle, Typography } from '@mui/material';
import { NOTES_COMMENT_MAX_LENGTH, NotesArticle } from '@spec/Notes';
import { useState } from 'react';
import { FormTheme } from '../../../Theme';
import { FormTextField, useForm, useFormText } from '../../../lib/Form';
import { LengthCounter } from '../../../lib/Form/LengthCounter';
import { maxLength } from '../../../lib/Form/Validators';
import { usePostComment } from '../../../queries/notes';
import { ActionContainer } from '../../ActionButtons';
import { ChatIcon } from '../../Icons';
import { DialogActionButtons, StableDialog } from '../../StableDialog';
import { SubmitButton } from '../../SubmitButton';

export const CommentForm: React.FC<{ article: NotesArticle }> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useForm({ content: useFormText('', [maxLength(NOTES_COMMENT_MAX_LENGTH)]) });
    const mutation = usePostComment(props.article.id);
    return (
        <Card square={false}>
            <CardContent sx={{ px: 4 }}>
                <FormTheme>
                    <Box mt={1}>
                        <FormTextField formText={form.content} fullWidth multiline minRows={4} />
                    </Box>
                    <LengthCounter text={form.content.value} maxLength={NOTES_COMMENT_MAX_LENGTH} />
                </FormTheme>
                <ActionContainer mt={2}>
                    <SubmitButton
                        fullWidth
                        variant="outlined"
                        inProgress={form.inProgress}
                        disabled={
                            !form.canSubmit ||
                            // avoid to highlight the textfield when the comment is empty
                            form.content.value === ''
                        }
                        startIcon={<ChatIcon />}
                        onClick={() => setDialogOpen(true)}
                    >
                        コメントを投稿する
                    </SubmitButton>
                </ActionContainer>
                <StableDialog
                    fullWidth
                    maxWidth="sm"
                    noClose
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                >
                    <DialogTitle>コメントを投稿する</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">
                            以下の内容でコメントを投稿します。投稿したコメントの修正や削除はできません。
                        </Typography>
                        <Typography mt={2} mx={2} variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                            {form.content.value}
                        </Typography>
                    </DialogContent>
                    <DialogActionButtons
                        form={form}
                        startIcon={<ChatIcon />}
                        submitLabel="コメントを投稿する"
                        cancelLabel="キャンセル"
                        errorMessage="コメントの投稿に失敗しました"
                        onSubmit={async () => {
                            await mutation.mutateAsync(form.content.value);
                            form.content.setValue('');
                        }}
                        closeDialog={() => setDialogOpen(false)}
                    />
                </StableDialog>
            </CardContent>
        </Card>
    );
};
