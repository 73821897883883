import { Box } from '@mui/material';
import React from 'react';
import { queryToArray } from '../../queries';
import { useAssignedTodoItems } from '../../queries/todo';
import { useTodoCategories } from '../../queries/todoCategory';
import { SubTitle } from '../PageTitle';
import { WaitLoading } from '../WaitLoading';
import { TodoItemCard } from '../todo/TodoItemCard';

export const AssignedTodoItems: React.FC = () => {
    const maybeCategories = useTodoCategories();
    const categories = queryToArray(maybeCategories);
    const maybeItems = useAssignedTodoItems();
    const items = queryToArray(maybeItems);
    return (
        <WaitLoading size="medium" waitFor={[maybeCategories, maybeItems]}>
            {items.length > 0 && (
                <Box>
                    <SubTitle title="あなたのやること" />
                    <Box mt={2}>
                        {items.map((item) => (
                            <TodoItemCard key={item.id} item={item} categories={categories} />
                        ))}
                    </Box>
                </Box>
            )}
        </WaitLoading>
    );
};
