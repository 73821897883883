import { TodoCategory } from '@spec/Todo';
import { createContext, ReactNode, useContext, useState } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode = 'AddCategory' | 'EditCategory' | 'DeleteCategory';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const EditCategoryContext = createContext(
    {} as {
        category: TodoCategory | null;
        setCategory: React.Dispatch<React.SetStateAction<TodoCategory | null>>;
    }
);
const EditCategoryContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [category, setCategory] = useState<TodoCategory | null>(null);
    return (
        <EditCategoryContext.Provider value={{ category, setCategory }}>
            {props.children}
        </EditCategoryContext.Provider>
    );
};
export const useEditCategoryContext = () => useContext(EditCategoryContext);

export const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <EditCategoryContextProvider>{props.children}</EditCategoryContextProvider>
        </DialogContextProvider>
    );
};
