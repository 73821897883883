import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent, Transfer } from '@spec/Talent';
import dayjs from 'dayjs';
import { getTeamNameTree } from '../../../domains/Organization';
import { SubTitle } from '../../PageTitle';
import { HistoryTable } from './HistoryTable';

export const TransferHistory: React.FC<{ talent: Talent; teams: Team[] }> = (props) => {
    return (
        <Box>
            <SubTitle mt={1} title="異動歴" />
            <Box mt={1}>
                <TransfersTable
                    transfers={props.talent.transferHistory ?? []}
                    teams={props.teams}
                />
            </Box>
        </Box>
    );
};

const TransfersTable: React.FC<{ transfers: Transfer[]; teams: Team[] }> = (props) => {
    if (props.transfers.length === 0) {
        return <Typography variant="body2">履歴がありません</Typography>;
    }
    return (
        <HistoryTable>
            <TableBody>
                {props.transfers.map((v) => (
                    <TableRow key={v.id}>
                        <TableCell>{getTeamNameTree(v.teamId, props.teams).join(' ')}</TableCell>
                        <TableCell>
                            {dayjs(v.joinedAt).format('YYYY年M月D日〜')}
                            {v.leavedAt === null
                                ? '現在'
                                : dayjs(v.leavedAt).format('YYYY年M月D日')}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </HistoryTable>
    );
};
