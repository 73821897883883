import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import type { Announcement } from '../../domains/Todo';
import { LinkButton } from '../ActionButtons';
import { FlexBox } from '../FlexBox';

export const AnnouncementCard: React.FC<{
    announcement: Announcement;
    onClose: () => void;
}> = (props) => {
    const title = props.announcement.title;
    return (
        <Card>
            <CardContent>
                <FlexBox px={0.5} gap={1}>
                    <Box flexGrow={1}>
                        {typeof title === 'string' ? <Typography>{title}</Typography> : title}
                    </Box>
                    <Typography variant="body2">
                        {dayjs(props.announcement.publishedAt).format('YYYY-MM-DD HH:mm')}
                    </Typography>
                </FlexBox>
                <Box mt={0.5}>
                    <Divider />
                </Box>
                <Stack mt={1} mx={0.5} spacing={1}>
                    {props.announcement.contents.map((v, i) => (
                        <Typography key={i}>{v}</Typography>
                    ))}
                    {props.announcement.action !== null && (
                        <Box px={2} py={1}>
                            <LinkButton
                                fullWidth
                                size="medium"
                                variant="contained"
                                onClick={props.onClose}
                                to={props.announcement.action.url}
                            >
                                {props.announcement.action.label}
                            </LinkButton>
                        </Box>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};
