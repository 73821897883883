import { Typography, useTheme } from '@mui/material';
import { Talent } from '@spec/Talent';
import React from 'react';
import { FlexBox } from '../FlexBox';

export const SlackName: React.FC<{ talent: Talent }> = (props) => {
    const name = props.talent.slackName || '-';
    const theme = useTheme();
    const suffix =
        props.talent.slackId === null
            ? `_Monochrome_${theme.palette.mode === 'dark' ? 'White' : 'Black'}`
            : '';
    const imageFileName = `/images/Slack_Mark${suffix}.svg`;
    return (
        <FlexBox flexWrap="nowrap">
            <img src={imageFileName} width="20" height="20" style={{ marginLeft: '-4px' }} />
            <Typography variant="body2">{name}</Typography>
        </FlexBox>
    );
};
