import React, { ReactNode } from 'react';
import { Box, SvgIconProps, Typography } from '@mui/material';
import { FlexBox } from './FlexBox';

export const IconText: React.FC<
    React.PropsWithChildren<{
        icon: React.FC<SvgIconProps>;
        color?:
            | 'initial'
            | 'inherit'
            | 'primary'
            | 'secondary'
            | 'textPrimary'
            | 'textSecondary'
            | 'error';
        children: ReactNode | ReactNode[];
    }>
> = (props) => {
    return (
        <FlexBox flexWrap="nowrap">
            <Box mt={0.5} mr={0.5}>
                <props.icon color="disabled" fontSize="small" />
            </Box>
            <Box mr={0.5}>
                <Typography variant="body2" color={props.color}>
                    {props.children}
                </Typography>
            </Box>
        </FlexBox>
    );
};
