import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import type { Secondment } from '@spec/Talent';
import { DeleteIconButton, EditIconButton } from '../../../ActionButtons';
import { NoItems } from '../../../NoItems';
import { useDialogContext, useSecondmentContext } from './Context';
import type { EditTalentProps } from './Contract';

export const EditSecondment = (props: EditTalentProps) => {
    const theme = useTheme();
    const secondment = props.talent.secondment;
    return (
        <Box>
            <Typography variant="subtitle1" color="primary">
                出向関連情報
            </Typography>
            <Paper
                variant="outlined"
                sx={{
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {secondment === null ? (
                    <Box>
                        <NoItems>出向関連情報はありません</NoItems>
                        <AddSecondmentButton />
                    </Box>
                ) : (
                    <SecondmentCard secondment={secondment} />
                )}
            </Paper>
        </Box>
    );
};

const SecondmentCard = (props: { secondment: Secondment }) => {
    const v = props.secondment;
    const { setDialogMode } = useDialogContext();
    const { setSecondment } = useSecondmentContext();
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>駐在区分</TableCell>
                        <TableCell>企業名</TableCell>
                        <TableCell>メールアドレス</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{v.assignmentType}</TableCell>
                        <TableCell>{v.companyName}</TableCell>
                        <TableCell>{v.email}</TableCell>
                        <TableCell>
                            <EditIconButton
                                onClick={() => {
                                    setSecondment(v);
                                    setDialogMode('EditSecondment');
                                }}
                            />
                            <DeleteIconButton
                                onClick={() => {
                                    setSecondment(v);
                                    setDialogMode('DeleteSecondment');
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography mt={2} variant="body2" color="primary" textAlign="center">
                ※出向関連情報は一人につきひとつしか登録できません
            </Typography>
        </Box>
    );
};

const AddSecondmentButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <Box mt={2} textAlign="center">
            <Button
                size="medium"
                variant="outlined"
                color="primary"
                onClick={() => {
                    setDialogMode('AddSecondment');
                }}
            >
                新しい出向関連情報を追加する
            </Button>
        </Box>
    );
};
