import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { getTalentUrl } from '../../../../Routes';
import { getTeamNameTree } from '../../../../domains/Organization';
import { fullName, fullNameKana, gradeToString } from '../../../../domains/Talent';
import { useTeamsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { RouterLink } from '../../../RouterLink';
import { TalentAvatar } from '../../../TalentAvatar';

export const TalentCard: React.FC<{ talent: Talent }> = (props) => {
    const t = props.talent;
    const theme = useTheme();
    const { teams } = useTeamsContext();
    const teamName = getTeamNameTree(t.teamId, teams).join(' ');
    return (
        <Card>
            <CardContent>
                <FlexBox flexWrap="nowrap" gap={theme.spacing(1)}>
                    <Box mr={0.5}>
                        <TalentAvatar size="medium" talent={t} />
                    </Box>
                    <Typography variant="body2">{t.employment.employeeCode}</Typography>
                    <Typography variant="body2">
                        <RouterLink variant="body2" to={getTalentUrl(t.employment.employeeCode)}>
                            {fullName(t)}（{fullNameKana(t)}）
                        </RouterLink>
                    </Typography>
                    <Typography variant="body2">{teamName}</Typography>
                    <Typography variant="body2">{t.position}</Typography>
                    <Typography variant="body2">
                        {dayjs(t.joinedAt).format('YYYY年M月D日')}
                        {t.isNewGraduate ? '新卒入社' : '中途入社'}
                    </Typography>
                    <Typography variant="body2">{`グレード : ${gradeToString(
                        t.grade
                    )}`}</Typography>
                    <Typography variant="body2">勤務地 : {t.workplace ?? '-'}</Typography>
                </FlexBox>
            </CardContent>
        </Card>
    );
};
