import * as Sentry from '@sentry/react';
import { AuthErrorCode, NotFoundErrorCode, ParameterErrorCode } from '@spec/Errors';

export class ErrorWithCode<T extends string> extends Error {
    public code: T;

    constructor(message: string, code: T) {
        super(message);
        this.code = code;
    }
}

export class AuthError extends ErrorWithCode<AuthErrorCode> {
    constructor(message: string, code: AuthErrorCode) {
        super(message, code);
        this.name = 'AuthError';
    }
}

export class ForbiddenError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ForbiddenError';
    }
}

export class NotFoundError extends ErrorWithCode<NotFoundErrorCode> {
    constructor(message: string, code: NotFoundErrorCode) {
        super(message, code);
        this.name = 'NotFoundError';
    }
}

export class ParameterError extends ErrorWithCode<ParameterErrorCode> {
    constructor(message: string, code?: ParameterErrorCode) {
        super(message, code ?? 'parameter/invalid-request');
        this.name = 'ParameterError';
    }
}

export class ApplicationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ApplicationError';
    }
}

const noReportErrors: AuthErrorCode[] = ['auth/token-has-expired'];

export const shouldReportSentry = (err: Error): boolean => {
    if (err instanceof AuthError && noReportErrors.includes(err.code)) {
        return false;
    }
    return true;
};

export const reportError = (err: Error) => {
    if (shouldReportSentry(err)) {
        Sentry.captureException(err);
    }
};

export const reportInfo = (message: string) => {
    Sentry.captureMessage(message);
};
