import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { PersonalEvent } from '@spec/Talent';
import React from 'react';
import { ActionButton, ActionContainer } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { DeleteIcon, EditIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { ContextualDialog, DialogComponents } from '../../StableDialog';
import {
    ContextProvider,
    DialogMode,
    useDialogContext,
    usePersonalEventHistoryContext,
} from './Context';
import { AddPersonalEventDialog } from './dialogs/AddPersonalEventDialog';
import { DeletePersonalEventDialog } from './dialogs/DeletePersonalEventDialog';
import { EditPersonalEventDialog } from './dialogs/EditPersonalEventDialog';

export const PersonalEventHistory: React.FC<{ personalEvents: PersonalEvent[] }> = (props) => {
    return (
        <ContextProvider>
            <Box>
                <SubTitle title="過去の経歴" />
                <Box mt={1} ml={1}>
                    <Typography variant="body2" color="primary">
                        職歴や学歴、その他のイベントを自分で登録できます。経歴は開始年の順に並びます。
                    </Typography>
                </Box>
                <Box m={1}>
                    {props.personalEvents.map((v) => (
                        <PersonalEventRow key={v.id} personalEvent={v} />
                    ))}
                </Box>
            </Box>
            <AddPersonalEventButton />
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    Add: AddPersonalEventDialog,
    Edit: EditPersonalEventDialog,
    Delete: DeletePersonalEventDialog,
};

const PersonalEventRow: React.FC<{ personalEvent: PersonalEvent }> = (props) => {
    const theme = useTheme();
    const { setDialogMode } = useDialogContext();
    const { setPersonalEvent } = usePersonalEventHistoryContext();
    const v = props.personalEvent;
    return (
        <FlexBox
            sx={{
                '&:hover': {
                    backgroundColor: theme.palette.background.paper,
                },
            }}
        >
            <Box sx={{ width: '7rem' }}>
                <Typography variant="body2">
                    {v.startYear}年{v.endYear !== null && <> 〜 {v.endYear}年</>}
                </Typography>
            </Box>
            <Box flexGrow={1}>
                <Typography variant="body2">{v.label}</Typography>
            </Box>
            <IconButton
                size="small"
                onClick={() => {
                    setDialogMode('Edit');
                    setPersonalEvent(v);
                }}
            >
                <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
                size="small"
                onClick={() => {
                    setDialogMode('Delete');
                    setPersonalEvent(v);
                }}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </FlexBox>
    );
};

const AddPersonalEventButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <ActionContainer>
            <ActionButton
                onClick={() => {
                    setDialogMode('Add');
                }}
            >
                新しい経歴を追加する
            </ActionButton>
        </ActionContainer>
    );
};
