import React, { createContext, ReactNode, useContext, useState } from 'react';

export type ResponseMap = Map<number, ResponseValue>;
export type ResponseValue = number | boolean | string;

const ApplyFormContext = createContext(
    {} as {
        responses: ResponseMap;
        setResponses: (k: number, v: ResponseValue) => void;
    }
);
export const useApplyFormContext = () => useContext(ApplyFormContext);

export const ContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [responses, _setResponses] = useState<ResponseMap>(new Map());
    const setResponses = (k: number, v: ResponseValue) => {
        const x = new Map(responses);
        if (v === '' || v === false) {
            x.delete(k);
        } else {
            x.set(k, v);
        }
        _setResponses(x);
    };
    return (
        <ApplyFormContext.Provider value={{ responses, setResponses }}>
            {props.children}
        </ApplyFormContext.Provider>
    );
};
