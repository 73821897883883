import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { findTeam } from '../../../../../domains/Organization';
import { formatDate } from '../../../../../lib/DateUtils';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteTransfer } from '../../../../../queries/talent';
import { useTeamsContext } from '../../../../Context';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { TransferHistoryContext, useDialogContext } from '../Context';

export const DeleteTransferDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const context = useContext(TransferHistoryContext);
    const transfer = context.transfer.value;
    if (transfer === null) {
        throw Error(`null transfer`);
    }
    const { teams } = useTeamsContext();
    const currentTeam = findTeam(transfer.teamId, teams);
    const form = useEmptyForm();
    const mutation = useDeleteTransfer(transfer.id);
    return (
        <>
            <DialogTitle>異動履歴の削除</DialogTitle>
            <DialogContent>
                <DialogRow label="所属">
                    <Typography>{currentTeam?.name}</Typography>
                </DialogRow>
                <DialogRow label="着任日">
                    <Typography>{formatDate(transfer.joinedAt)}</Typography>
                </DialogRow>
                <DialogRow label="離任日">
                    <Typography>{formatDate(transfer.leavedAt)}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この異動履歴を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="履歴の削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
