import { EditSurveyPeriodRequest } from '@spec/Survey';
import React from 'react';
import { Box } from '@mui/material';
import {
    Form,
    FormTextField,
    Strict,
    useForm,
    useFormDate,
    useFormText,
} from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { DateTimeInput } from '../../../DateTimeInput';
import { DialogRow } from '../../../StableDialog';

interface Elements {
    name: string;
    openedAt: Date | null;
    closedAt: Date | null;
}
type PeriodForm = Form<Elements>;

export const usePeriodForm = (v: Elements) =>
    useForm<typeof v>({
        name: useFormText(v.name, [required]),
        openedAt: useFormDate(v.openedAt, [required]),
        closedAt: useFormDate(v.closedAt, [required]),
    });

const guard = (v: Elements): v is Strict<Elements> => v.openedAt !== null && v.closedAt !== null;

export const toEditPeriodRequest = (form: PeriodForm): EditSurveyPeriodRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('invalid parameters');
    }
    return args;
};

export const PeriodFormContent: React.FC<{ form: PeriodForm }> = ({ form }) => {
    return (
        <Box>
            <DialogRow label="名称">
                <FormTextField autoFocus fullWidth formText={form.name} />
            </DialogRow>
            <DialogRow label="開始日時">
                <DateTimeInput formDate={form.openedAt} />
            </DialogRow>
            <DialogRow label="終了日時">
                <DateTimeInput formDate={form.closedAt} />
            </DialogRow>
        </Box>
    );
};
