import SecurityIcon from '@mui/icons-material/Security';
import { Alert, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Grants } from '@spec/Grants';
import React, { ReactNode, useState } from 'react';
import { AuthError, reportError } from '../Errors';
import { useMeContext } from '../queries/me';
import { IconCircle } from './IconCircle';

export const RequirePrivilege: React.FC<{
    fn: (grants: Grants) => boolean;
    children: ReactNode;
    alertMessage?: string;
}> = (props) => {
    const [sent, setSent] = useState(false);
    const { me, grants } = useMeContext();
    if (props.fn(grants) === false) {
        if (sent === false) {
            setSent((prev) => {
                if (prev === false) {
                    reportError(
                        new AuthError(
                            `${me.hitonowaId} does not granted.`,
                            'auth/permission-denied'
                        )
                    );
                }
                return true;
            });
        }
        return (
            <Box mt={4}>
                {props.alertMessage !== undefined && (
                    <Alert severity="error">{props.alertMessage}</Alert>
                )}
                <Box my={8} textAlign="center">
                    <Box>
                        <IconCircle icon={SecurityIcon} color={grey[400]} />
                    </Box>
                    <Box mt={4}>
                        <Typography variant="h6">
                            このページの閲覧に必要な権限が不足しています
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <Typography>
                            権限の設定が間違っていると思われる場合は、Slack #hitonowa
                        </Typography>
                    </Box>
                    <Box mt={0.5}>
                        <Typography>もしくはお近くのHRにお問い合わせください。</Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
    return <>{props.children}</>;
};
