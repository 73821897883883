import { DialogContent, DialogTitle } from '@mui/material';
import React, { useContext } from 'react';
import { queryToArray } from '../../../../queries';
import { useUpdateWorkplace, useWorkplaces } from '../../../../queries/workplace';
import { DialogActionButtons } from '../../../StableDialog';
import { WorkplaceContext, useDialogContext } from '../Context';
import { WorkplaceFormContext, useWorkplaceForm } from './WorkplaceForm';

export const EditWorkplaceDialog: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { closeDialog } = useDialogContext();
    const context = useContext(WorkplaceContext);
    const v = context.workplace.value;
    if (v === null) {
        throw Error(`null workplace`);
    }
    const workplaceNames = queryToArray(useWorkplaces())
        .filter((x) => x.id !== v.id)
        .map((v) => v.name);
    const form = useWorkplaceForm({ name: v.name }, workplaceNames);
    const mutation = useUpdateWorkplace(v.id);
    return (
        <>
            <DialogTitle>勤務地の更新</DialogTitle>
            <DialogContent>
                <WorkplaceFormContext form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で勤務地を更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
