import React, { ReactNode, useState } from 'react';
import { Alert, AlertColor, Box } from '@mui/material';
import { useTodoItemContext } from './Context';

export const HelpAlert: React.FC = () => {
    const [warn, setWarn] = useState(false);
    const { item, assignments } = useTodoItemContext();
    const items: [AlertColor, ReactNode][] = [];
    if (item.description === '') {
        items.push(['warning', '↑の「編集する」から、やることの詳細を書きましょう']);
    }
    if (assignments.length === 0) {
        items.push(['warning', '↓から対象者を設定しましょう']);
    }
    if (items.length > 0 && warn === false) {
        setWarn(true);
    }
    if (items.length === 0) {
        if (warn === false) {
            return null;
        }
        items.push(['success', '公開準備ができました。→からいつでも公開できます']);
    }
    return (
        <Box mt={2}>
            {items.map(([color, text], i) => (
                <Box key={i} mt={1}>
                    <Alert severity={color}>{text}</Alert>
                </Box>
            ))}
        </Box>
    );
};
