import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { accessibleSurveyGroupIds } from '../../../domains/Survey';
import { findById } from '../../../lib/ArrayUtils';
import { SubTitle } from '../../PageTitle';
import { RequirePrivilege } from '../../RequirePrivilege';
import { useSurveysContext } from '../Context';
import { PeriodReport } from './periodReport/PeriodReport';
import { TalentReport } from './talentReport/TalentReport';

export const Report: React.FC = () => {
    const { groupId } = useParams();
    const { surveys, surveyGroups } = useSurveysContext();
    const group = findById(Number(groupId), surveyGroups);
    const [searchParams] = useSearchParams();
    const employeeCode = searchParams.get('code');
    return (
        <Box>
            <RequirePrivilege
                fn={(grants) => accessibleSurveyGroupIds(surveys, grants.survey).includes(group.id)}
            >
                <SubTitle title={group.name} />
                {group.periods.length === 0 ? (
                    <Box mt={8} textAlign="center">
                        <Typography color="error">
                            このサーベイはまだ一度も実施されていません
                        </Typography>
                    </Box>
                ) : (
                    <Box mt={2}>
                        {employeeCode ? (
                            <TalentReport surveyGroup={group} />
                        ) : (
                            <PeriodReport surveyGroup={group} />
                        )}
                    </Box>
                )}
            </RequirePrivilege>
        </Box>
    );
};
