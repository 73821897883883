import { ApplicationError } from '../Errors';

export const sortByKey = <T, K extends keyof T>(
    items: T[],
    key: K,
    order: 'asc' | 'desc' = 'asc'
): T[] =>
    [...items].sort((a, b) => {
        const f = (t: T) => {
            const v = t[key];
            return v instanceof Date ? v.getTime() : v;
        };
        const _a = f(a);
        const _b = f(b);
        if (_a === _b) {
            return 0;
        }
        const result = _a > _b ? 1 : -1;
        return order === 'asc' ? result : result * -1;
    });

export const uniqueByKey = <T, K extends keyof T>(
    items: T[],
    key: K,
    priority: 'first' | 'last' = 'first'
): T[] => {
    const uniqueMap = new Map<T[K], T>();
    for (const item of items) {
        if (priority === 'first' && uniqueMap.has(item[key])) {
            continue;
        }
        uniqueMap.set(item[key], item);
    }
    return [...uniqueMap.values()];
};

export const findById = <T extends { id: number }>(id: number, items: T[]): T =>
    findBy('id', id, items);

export const findBy = <T, K extends keyof T>(key: K, value: T[K], items: T[]): T => {
    const filtered = items.filter((v) => v[key] === value);
    if (filtered.length === 0) {
        throw new ApplicationError(`Item was not found ${String(key)}: ${value}`);
    }
    if (filtered.length > 1) {
        throw new ApplicationError(`Multiple items were found ${String(key)}: ${value}`);
    }
    return filtered[0];
};
