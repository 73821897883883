import type { Privacy, Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { isAvailableTalent, isEnrolledTalent } from './Talent';

export const calcEmployeeCount = (talents: Talent[]): number =>
    talents.filter(isEnrolledTalent).filter((v) => /^c[ab]/.test(v.employment.employeeCode)).length;

export const calcAverageTenure = (talents: Talent[], currentTime: Date): number => {
    const targets = filterPermanentEmployees(talents);
    let total = 0;
    for (const talent of targets) {
        const diffMonth = dayjs(currentTime).diff(talent.joinedAt, 'month');
        total += diffMonth;
    }
    return total / targets.length;
};

export const calcAverageAge = (
    talents: Talent[],
    privacies: Privacy[],
    currentTime: Date
): number => {
    const targets = filterPermanentEmployees(talents);
    const ages: number[] = [];
    for (const talent of targets) {
        const privacy = privacies.find((v) => v.talentId === talent.id);
        if (privacy?.birthday) {
            const age = dayjs(currentTime).diff(privacy?.birthday, 'year');
            ages.push(age);
        }
    }
    return ages.reduce((acc, val) => acc + val, 0) / ages.length;
};

export const calcFemaleRatio = (talents: Talent[], privacies: Privacy[]): number => {
    const targets = filterPermanentEmployees(talents);
    const sexes: number[] = [];
    for (const talent of targets) {
        const privacy = privacies.find((v) => v.talentId === talent.id);
        if (privacy?.sex) {
            sexes.push(privacy?.sex);
        }
    }
    return sexes.filter((v) => v === 2).length / sexes.length;
};

const filterPermanentEmployees = (talents: Talent[]) =>
    talents.filter((v) => v.employment.employmentStatus === '正社員').filter(isAvailableTalent);
