import { useCallback, useEffect, useRef, useState } from 'react';

export interface AsyncResource<T> {
    inProgress: boolean;
    value: null | T | Error;
}

export const toNullable = <T>(v: AsyncResource<T>): T | null => {
    if (v.inProgress || v.value instanceof Error) {
        return null;
    }
    return v.value;
};

export const toArray = <T>(v: AsyncResource<T[]>): T[] => toNullable(v) ?? [];

const useIsMounted = () => {
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    return useCallback(() => isMounted.current, []);
};

export const useAsyncState = <T>(
    defaultValue: AsyncResource<T>
): [AsyncResource<T>, (v: AsyncResource<T>) => void] => {
    const isMounted = useIsMounted();
    const [value, setRawValue] = useState(defaultValue);
    const setValue = useCallback(
        (newValue: AsyncResource<T>) => {
            if (isMounted() === true) {
                setRawValue(newValue);
            }
        },
        [isMounted]
    );
    return [value, setValue];
};

export const useRefState = <T>(defaultValue: T): [T, (v: T) => void] => {
    const isMounted = useIsMounted();
    const [value, setRawValue] = useState(defaultValue);
    const setValue = useCallback(
        (newValue: T) => {
            if (isMounted() === true) {
                setRawValue(newValue);
            }
        },
        [isMounted]
    );
    return [value, setValue];
};
