import type { TodoItemsResponse } from '@spec/Todo';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { sortByKey } from '../lib/ArrayUtils';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useAssignedTodoItems = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.assignedTodoItems],
        queryFn: async () => {
            const items = await gateway
                .get<TodoItemsResponse>('/todo/assigned')
                .then((res) => res.items);
            return sortByKey(items, 'id');
        },
    });
};

export const useCompleteTodoItem = (itemId: number) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation<void>({
        mutationFn: () => gateway.put(`/todo/assigned/${itemId}`),
        onSuccess: () =>
            Promise.all([
                queryClient.invalidateQueries({ queryKey: [queryKey.assignedTodoItems] }),
                queryClient.invalidateQueries({ queryKey: [queryKey.todoItems] }),
            ]),
    });
};
