import { Extension } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';

/**
 * Converts pasted HTML into a tight list.
 */
export const TightList = Extension.create({
    name: 'tightList',
    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    transformPastedHTML: (html) => {
                        const tempDiv = document.createElement('div');
                        tempDiv.innerHTML = html;
                        const pElements = tempDiv.querySelectorAll('li > p');
                        pElements.forEach((p) => {
                            const parent = p.parentNode;
                            if (parent) {
                                while (p.firstChild) {
                                    parent.insertBefore(p.firstChild, p);
                                }
                                parent.removeChild(p);
                            }
                        });
                        return tempDiv.innerHTML;
                    },
                },
            }),
        ];
    },
});
