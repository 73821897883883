import { DialogContent, DialogTitle, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteSurveyPeriod } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useEditPeriodContext } from '../Context';

export const DeletePeriodDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { period } = useEditPeriodContext();
    const v = period.value;
    if (v === null) {
        throw Error('target period was not set');
    }
    const form = useEmptyForm();
    const mutation = useDeleteSurveyPeriod(v.id);
    return (
        <>
            <DialogTitle>実施期間を削除する</DialogTitle>
            <DialogContent>
                <DialogRow label="名称">
                    <Typography>{v.name}</Typography>
                </DialogRow>
                <DialogRow label="開始日時">
                    <Typography>{dayjs(v.openedAt).format('YYYY-MM-DD HH:mm')}</Typography>
                </DialogRow>
                <DialogRow label="終了日時">
                    <Typography>{dayjs(v.closedAt).format('YYYY-MM-DD HH:mm')}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この実施期間を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
