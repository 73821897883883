import CheckBoxIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterList';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from '@mui/material';
import { SurveyGroup, SurveyMemo, SurveyQuestion, SurveyResponse } from '@spec/Survey';
import { TalentId } from '@spec/Talent';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    isBooleanResponse,
    isFulfilledResponse,
    isLikertQuestion,
    isLikertResponse,
    isTextResponse,
} from '../../../../domains/Survey';
import { findTalent, fullName } from '../../../../domains/Talent';
import { findById, sortByKey } from '../../../../lib/ArrayUtils';
import { StateValue, useStateValue } from '../../../../lib/Context';
import { useMeContext } from '../../../../queries/me';
import { useSurveyMemos, useSurveyPeriodReport } from '../../../../queries/survey';
import { useCurrentTimeContext, useTalentsContext, useTeamsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { ExpandMoreIcon } from '../../../Icons';
import { Markdown } from '../../../Markdown';
import { Pager, sliceItems } from '../../../Pager';
import { PopoverTalent } from '../../../PopoverTalent';
import { RouterLink } from '../../../RouterLink';
import { comparer, useTableSorter } from '../../../SortableTable';
import { TalentAvatar } from '../../../TalentAvatar';
import { WaitLoading, WaitQuery } from '../../../WaitLoading';
import { TalentSearch } from '../../../search/TalentSearch';
import { useSurveysContext } from '../../Context';
import { getReportUrl } from '../../urls';
import { ContextProvider, useFilterFormContext } from './Context';
import { filterTalents } from './Filter';
import { FilterForm } from './FilterForm';

export const PeriodReport: React.FC<{ surveyGroup: SurveyGroup }> = (props) => {
    const [, setSearchParams] = useSearchParams();
    const { currentTime } = useCurrentTimeContext();
    return (
        <ContextProvider surveyGroup={props.surveyGroup} currentTime={currentTime}>
            <Stack spacing={2}>
                <Box>
                    <Typography>人を見る</Typography>
                    <TalentSearch
                        value={null}
                        onChange={(t) => {
                            setSearchParams({ code: t?.employment.employeeCode ?? '' });
                        }}
                    />
                </Box>
                <FilterForm surveyGroup={props.surveyGroup} />
                <Report surveyGroup={props.surveyGroup} />
            </Stack>
        </ContextProvider>
    );
};

const Report: React.FC<{
    surveyGroup: SurveyGroup;
}> = (props) => {
    const { availableTeams } = useTeamsContext();
    const { talents } = useTalentsContext();
    const { condition } = useFilterFormContext();
    const targetTalentIds = new Set(
        filterTalents(condition, talents, availableTeams).map((v) => v.id)
    );
    const maybeReport = useSurveyPeriodReport(props.surveyGroup.id, condition.periodId);
    const responses = (maybeReport.data ?? [])
        .filter((v) => targetTalentIds.has(v.talentId))
        .filter(isFulfilledResponse);
    const period = findById(condition.periodId, props.surveyGroup.periods);
    const maybeMemos = useSurveyMemos(period);
    return (
        <WaitLoading waitFor={[maybeReport]}>
            <WaitQuery query={maybeMemos}>
                {({ data }) => (
                    <ResponsesTable group={props.surveyGroup} responses={responses} memos={data} />
                )}
            </WaitQuery>
        </WaitLoading>
    );
};

type Response = {
    respondedAt: Dayjs | null;
    lastMemoedAt: number;
    talentId: TalentId;
    employeeCode: string;
    name: string;
    [k: number]: number;
};

const ResponsesTable: React.FC<{
    group: SurveyGroup;
    responses: SurveyResponse[];
    memos: SurveyMemo[];
}> = (props) => {
    const { talents } = useTalentsContext();
    const { availableTeams } = useTeamsContext();
    const ITEMS_PER_PAGE = 100;
    const { page, setPage, condition } = useFilterFormContext();
    const requires = useStateValue<Set<number>>(new Set());

    const { sortKey, direction, SortHeader, onSort } = useTableSorter<Response>(
        'respondedAt',
        'desc'
    );

    const { surveys } = useSurveysContext();
    const questions = surveys
        .filter((v) => v.surveyGroupId === props.group.id)
        .map((v) => v.questions)
        .flat();
    const questionIds = [...new Set(props.responses.map((v) => v.questionId))].sort(
        (a, b) => a - b
    );
    const talentResponses: Map<TalentId, SurveyResponse[]> = new Map();

    if (condition.notApplied === true) {
        for (const t of filterTalents(condition, talents, availableTeams)) {
            talentResponses.set(t.id, []);
        }
    }

    for (const res of props.responses) {
        talentResponses.set(res.talentId, [...(talentResponses.get(res.talentId) ?? []), res]);
    }
    const getScore = (questionId: number, responses: SurveyResponse[]): number => {
        const x = responses.find((v) => v.questionId === questionId);
        if (x === undefined) {
            return 0;
        }
        return isLikertResponse(x) ? x.score : 0;
    };
    const rows = [...talentResponses.entries()]
        .map(([talentId, responses]) => {
            const talent = findTalent(talentId, talents);
            return {
                respondedAt:
                    responses.length === 0
                        ? null
                        : dayjs(Math.max(...responses.map((v) => v.respondedAt.getTime()))),
                lastMemoedAt:
                    props.memos
                        .find((m) => m.respondentTalentId === talentId)
                        ?.publishedAt.getTime() ?? 0,
                talentId: talent.id,
                employeeCode: talent.employment.employeeCode,
                name: fullName(talent),
                responses,
            };
        })
        .filter((row) =>
            [...requires.value].every(
                (questionId) => !!row.responses.find((res) => res.questionId === questionId)
            )
        )
        .sort((a, b) => {
            const order = direction === 'asc' ? 1 : -1;
            if (typeof sortKey === 'number') {
                return (getScore(sortKey, a.responses) - getScore(sortKey, b.responses)) * order;
            }
            return comparer(a, b, sortKey, 'employeeCode', direction);
        });
    const rowsSlice = sliceItems(rows, page, ITEMS_PER_PAGE);

    const { grants } = useMeContext();
    const isOperable = grants.survey.operableSurveyGroups.includes(props.group.id);

    return (
        <Box>
            <ResponseFilter
                questions={questions.filter((q) => questionIds.includes(q.id))}
                requires={requires}
            />
            {rows.length === 0 ? (
                <Typography my={4} textAlign="center" color="error">
                    条件に合致する回答はありません
                </Typography>
            ) : (
                <Box>
                    <Box my={2}>{rows.length}人が回答しました</Box>
                    <Pager
                        current={page}
                        setPage={setPage}
                        amount={rows.length}
                        perItems={ITEMS_PER_PAGE}
                    />
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <SortHeader sortKey="respondedAt">回答日時</SortHeader>
                                <SortHeader sortKey="employeeCode">社員番号</SortHeader>
                                <SortHeader sortKey="name">氏名</SortHeader>
                                {questionIds.map((questionId) => {
                                    const question = questions.find((q) => q.id === questionId);
                                    if (question === undefined) {
                                        throw Error(`question was not found`);
                                    }
                                    return isLikertQuestion(question) ? (
                                        <TableCell key={questionId} sx={{ px: 0 }}>
                                            <Tooltip arrow title={question.title} placement="top">
                                                <TableSortLabel
                                                    active={sortKey === questionId}
                                                    direction={direction}
                                                    onClick={() => onSort(questionId)}
                                                >
                                                    {question.shortTitle[0]}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    ) : (
                                        <TableCell key={questionId}>
                                            {question.shortTitle}
                                        </TableCell>
                                    );
                                })}
                                {isOperable === true && (
                                    <SortHeader sortKey="lastMemoedAt">最新メモ</SortHeader>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsSlice.map((v) => (
                                <TableRow key={v.employeeCode}>
                                    <TableCell>
                                        {v.respondedAt ? v.respondedAt.format('MM/DD HH:mm') : '-'}
                                    </TableCell>
                                    <TableCell>{v.employeeCode}</TableCell>
                                    <TableCell>
                                        <PopoverTalent talent={findTalent(v.talentId, talents)}>
                                            <RouterLink
                                                variant="body2"
                                                to={getReportUrl(props.group.id, v.employeeCode)}
                                            >
                                                {v.name}
                                            </RouterLink>
                                        </PopoverTalent>
                                    </TableCell>
                                    {questionIds.map((questionId) => (
                                        <ResponseCell
                                            key={questionId}
                                            response={v.responses.find(
                                                (v) => v.questionId === questionId
                                            )}
                                        />
                                    ))}
                                    {isOperable === true && (
                                        <TableCell>
                                            <Memo
                                                group={props.group}
                                                memos={props.memos.filter(
                                                    (m) => m.respondentTalentId === v.talentId
                                                )}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pager
                        current={page}
                        setPage={setPage}
                        amount={rows.length}
                        perItems={ITEMS_PER_PAGE}
                    />
                </Box>
            )}
        </Box>
    );
};

const Memo: React.FC<{ group: SurveyGroup; memos: SurveyMemo[] }> = (props) => {
    const { talents } = useTalentsContext();
    if (props.memos.length === 0) {
        return null;
    }
    const memo = sortByKey(props.memos, 'publishedAt', 'desc')[0];
    const author = findById(memo.authorTalentId, talents);
    const headline = memo.content.split('\n')[0];
    const talent = findById(memo.respondentTalentId, talents);
    const period = findById(memo.surveyPeriodId, props.group.periods);
    return (
        <Tooltip
            components={{ Tooltip: Box }}
            placement="top-start"
            title={
                <Card>
                    <CardContent>
                        <FlexBox gap={1}>
                            <TalentAvatar talent={author} size="small" />
                            <Typography variant="body2">{fullName(author)}</Typography>
                            <Typography variant="body2">
                                {dayjs(memo.publishedAt).format('YYYY-MM-DD HH:mm:ss')}
                            </Typography>
                        </FlexBox>
                        <Box my={1}>
                            <Divider />
                        </Box>
                        <Markdown source={memo.content} variant="body2" />
                        {props.memos.length > 1 && (
                            <Box textAlign="right">
                                <RouterLink
                                    variant="body2"
                                    to={getReportUrl(
                                        props.group.id,
                                        talent.employment.employeeCode,
                                        period.name
                                    )}
                                >
                                    他{props.memos.length - 1}件を見る
                                </RouterLink>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            }
        >
            <Typography variant="body2">{headline}</Typography>
        </Tooltip>
    );
};

const ResponseFilter: React.FC<{
    questions: SurveyQuestion[];
    requires: StateValue<Set<number>>;
}> = (props) => {
    const questions = props.questions.filter((q) => !isLikertQuestion(q));
    if (questions.length === 0) {
        return null;
    }
    return (
        <Box mt={1}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FlexBox>
                        <FilterAltIcon />
                        <Box ml={0.5}>記述項目への回答の有無で絞り込む</Box>
                    </FlexBox>
                </AccordionSummary>
                <AccordionDetails>
                    {questions.map((q) => (
                        <FlexBox key={q.id}>
                            <Box ml={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.requires.value.has(q.id)}
                                            onChange={(e) => {
                                                props.requires.setValue((prev) => {
                                                    const x = new Set(prev);
                                                    if (e.target.checked) {
                                                        x.add(q.id);
                                                    } else {
                                                        x.delete(q.id);
                                                    }
                                                    return x;
                                                });
                                            }}
                                            name={q.shortTitle}
                                            size="small"
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {q.shortTitle} : {q.title}
                                        </Typography>
                                    }
                                />
                            </Box>
                        </FlexBox>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

const ResponseCell: React.FC<{ response?: SurveyResponse }> = (props) => {
    if (props.response === undefined) {
        return <TableCell />;
    }
    if (isLikertResponse(props.response)) {
        return (
            <TableCell align="center" sx={{ pl: 0, pr: 2.5 }}>
                {props.response.score}
            </TableCell>
        );
    }
    if (isTextResponse(props.response)) {
        return <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{props.response.message}</TableCell>;
    }
    if (isBooleanResponse(props.response)) {
        return <TableCell>{props.response.checked && <CheckBoxIcon fontSize="small" />}</TableCell>;
    }
    return <TableCell></TableCell>;
};
