import { Team, TeamMember, type TeamId } from '@spec/Organization';
import { Grade, JobClass, LeftTalent, SexCode, Talent, TalentId } from '@spec/Talent';
import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import { getDownloadURL, getMetadata, getStorage, ref } from 'firebase/storage';
import { unparse } from 'papaparse';
import { firebaseApp } from '../App';
import { ApplicationError } from '../Errors';
import { findById } from '../lib/ArrayUtils';
import { generateCsv } from '../lib/Csv';
import { Validator } from '../lib/Form';
import { findBaseTeam, generateBreadcrumbs } from './Organization';

export const NO_GRADE_SHORT_TEXT = '-';
export const NO_GRADE_LONG_TEXT = 'グレードなし';

export const isJobClass = (v: string): v is JobClass => ['A', 'E'].includes(v);

export const gradeToString = (v: Grade | null, noGradeText = NO_GRADE_SHORT_TEXT): string => {
    if (v === null) {
        return noGradeText;
    }
    return `${v.jobClass}${v.level}`;
};

export type TalentSorter = (a: Talent, b: Talent) => -1 | 0 | 1;

export const sortTalentsByJoinedAt: TalentSorter = (_a, _b) => {
    const a = dayjs(_a.joinedAt);
    const b = dayjs(_b.joinedAt);
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return sortTalentsByEmployeeCode(_a, _b);
};

export const sortTalentsByGrade: TalentSorter = (_a, _b) => {
    const a = gradeToSortKey(_a.grade);
    const b = gradeToSortKey(_b.grade);
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return sortTalentsByEmployeeCode(_a, _b);
};

export const sortTalentsByEmployeeCode: TalentSorter = (a, b) => {
    if (a.employment.employeeCode < b.employment.employeeCode) {
        return -1;
    }
    if (a.employment.employeeCode > b.employment.employeeCode) {
        return 1;
    }
    return 0;
};

const gradeToSortKey = (v: Grade | null): string => {
    if (v === null) {
        return '';
    }
    const classes = {
        A: 1,
        E: 2,
    };
    return `${classes[v.jobClass]}${v.level}`;
};

export const fullName = (v: Talent | TeamMember): string => `${v.lastName} ${v.firstName}`;

export const fullNameKana = (v: Talent): string => `${v.lastNameKana} ${v.firstNameKana}`;

export const searchTalents = (text: string, talents: Talent[]): Talent[] =>
    talents.filter((v) => searchTalent(text, v));

export const searchTalent = (text: string, v: Talent): boolean =>
    text
        .split(/\s+/)
        .every((element) =>
            hiraToKana(
                [
                    v.hitonowaId ?? '',
                    v.lastName,
                    v.firstName,
                    `${v.lastName}${v.firstName}`,
                    v.lastNameKana,
                    v.firstNameKana,
                    `${v.lastNameKana}${v.firstNameKana}`,
                    v.romanName,
                    v.slackName,
                    v.nicknames,
                    v.employment.email,
                    v.emailAlias,
                    v.githubId,
                    v.secondment?.companyName ?? '',
                    v.position,
                ]
                    .join('\t')
                    .toLowerCase()
            ).includes(hiraToKana(element).toLowerCase())
        );

const CHAR_OFFSET = 0x60;

export const hiraToKana = (v: string): string =>
    v.replace(/[\u3041-\u3096]/g, (match) =>
        String.fromCharCode(match.charCodeAt(0) + CHAR_OFFSET)
    );

export const isAvailableTalent = (v: Talent): boolean =>
    isEnrolledTalent(v) && v.canSignIn === true;

export const isEnrolledTalent = (v: Talent): boolean => !isLeftTalent(v) && !isSuspendedTalent(v);

export const isLeftTalent = (v: Talent): v is LeftTalent => v.employment.leavedAt !== null;

export const isSuspendedTalent = (v: Talent): boolean => v.isSuspended !== false;

export const detectBirthdayText = (birthday: Date | null): string => {
    if (birthday === null) {
        return '未入力';
    }
    return dayjs(birthday).format('YYYY年M月D日');
};

export const detectSexText = (sex: SexCode): string => {
    switch (sex) {
        case 1:
            return '男性';
        case 2:
            return '女性';
        case 0:
            return '未入力';
        case 9:
            return 'その他';
        default:
            throw new Error(`got an unexpected sex code : ${sex}`);
    }
};

export const createHitonowaIdValidator =
    (talents: Talent[]): Validator =>
    (hitonowaId) => {
        if (/^[a-z0-9_-]{1,32}$/.exec(hitonowaId) === null) {
            return 'invalid';
        }
        if (talents.find((t) => t.hitonowaId === hitonowaId) !== undefined) {
            return 'exists';
        }
        return null;
    };

export const getProfileImageUrl = async (
    talent: Talent,
    suffix?: string
): Promise<string | null> => {
    if (talent.profileImagePath === null) {
        return null;
    }
    const f = (path: string) => getDownloadURL(ref(getStorage(firebaseApp), path));
    if (!suffix) {
        return f(talent.profileImagePath);
    }
    try {
        return await f(`${talent.profileImagePath}${suffix}`);
    } catch (e) {
        if (e instanceof FirebaseError && e.code === 'storage/object-not-found') {
            return f(talent.profileImagePath);
        }
        return null;
    }
};

export const hasOwnProfileImage = async (talent: Talent): Promise<boolean> => {
    if (talent.profileImagePath === null) {
        return false;
    }
    const metadata = await getMetadata(ref(getStorage(firebaseApp), talent.profileImagePath));
    return metadata.customMetadata?.upload !== 'hr';
};

export const findTalent = (talentId: TalentId, talents: Talent[]): Talent => {
    const talent = talents.find((v) => v.id === talentId);
    if (talent === undefined) {
        throw new ApplicationError(`Talent not found id: ${talentId}`);
    }
    return talent;
};

export const generateTalentsCsv = (talents: Talent[], teams: Team[], now: Date): string => {
    const fields = [
        '社員番号',
        'ヒトノワID',
        '姓',
        '名',
        'セイ',
        'メイ',
        '英語名',
        'メールアドレス',
        'メールエイリアス',
        'SlackID',
        'グレード',
        '勤務地',
        '雇用形態',
        '役職',
        '所属（最上位）',
        'ざっくり所属',
        '所属組織名',
        '所属組織コード',
        '所属（フル）',
        '入社日',
        '入社歴',
        '新卒/中途',
        '電話番号',
        '退職日',
    ];
    const format = (v: Date | null) => (v === null ? '' : dayjs(v).format('YYYY-MM-DD'));
    const data = talents.map((t) => {
        const bread = generateBreadcrumbs(t.teamId, teams);
        const team = findById(t.teamId, teams);
        const baseTeam = findBaseTeam(t, teams);
        return [
            t.employment.employeeCode,
            t.hitonowaId,
            t.lastName,
            t.firstName,
            t.lastNameKana,
            t.firstNameKana,
            t.romanName,
            t.employment.email,
            t.emailAlias,
            t.slackId,
            gradeToString(t.grade),
            t.workplace ?? '',
            t.employment.employmentStatus,
            t.position,
            bread[1]?.name ?? '',
            baseTeam?.name ?? '',
            team.name,
            team.code,
            bread.map((v) => v.name).join(' > '),
            format(t.joinedAt),
            `${dayjs(now).diff(t.joinedAt, 'years') + 1}年目`,
            t.isNewGraduate ? '新卒' : '中途',
            `="${t.tel}"`,
            format(t.employment.leavedAt),
        ];
    });
    return generateCsv(unparse({ fields, data }));
};

export const getTeamMembers = (
    talents: Talent[],
    teamId: TeamId,
    withAdditionalMember: boolean
): Talent[] => {
    const members = talents.filter((v) => v.teamId === teamId);
    if (!withAdditionalMember) {
        return members;
    }
    const additionalMembers = talents.filter((v) =>
        v.additionalPosts.some((post) => post.teamId === teamId)
    );
    return [...new Set([...members, ...additionalMembers])];
};
