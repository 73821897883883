import React, { createContext, ReactNode } from 'react';
import { StateValue, useStateValue } from '../../../lib/Context';

export const ShowCodeContext = createContext(
    {} as {
        showCode: StateValue<boolean>;
    }
);
const ShowCodeContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const showCode = useStateValue(false);
    return (
        <ShowCodeContext.Provider value={{ showCode }}>{props.children}</ShowCodeContext.Provider>
    );
};

export const ContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    return <ShowCodeContextProvider>{props.children}</ShowCodeContextProvider>;
};
