import type { NotificationsResponse } from '@spec/notification';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useNotifications = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notifications],
        queryFn: () =>
            gateway.get<NotificationsResponse>('/notifications').then((res) => res.notifications),
        refetchOnWindowFocus: true,
    });
};

export const useMarkNotificationAsRead = () => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (notificationEventId: number) =>
            gateway.post(`/notifications`, { notificationEventId }),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey.notifications] }),
    });
};
