import { MenuItem, TextField } from '@mui/material';
import { privilegeLabelMap } from '../../domains/Grants';
import { FilterCondition, FilterForm, FilterRow } from '../FilterForm';
import { useFilterFormContext } from './Context';
import { NOT_SELECTED, conditionAsStrings, handleChange } from './filter';

export const GrantsFilterForm = () => {
    const { condition, updateCondition } = useFilterFormContext();
    const values = conditionAsStrings(condition);
    return (
        <FilterForm dense>
            <FilterRow>
                <FilterCondition label="権限">
                    <TextField
                        select
                        value={values.privilege}
                        name="privilege"
                        onChange={(e) => updateCondition(handleChange(e))}
                    >
                        <MenuItem value={NOT_SELECTED}>指定なし</MenuItem>
                        {[...privilegeLabelMap].map(([k, v]) => (
                            <MenuItem key={k} value={k}>
                                {v}
                            </MenuItem>
                        ))}
                    </TextField>
                </FilterCondition>
            </FilterRow>
        </FilterForm>
    );
};
