import { MenuItem, TextField } from '@mui/material';
import { NO_GRADE_LONG_TEXT, NO_GRADE_SHORT_TEXT, gradeToString } from '../domains/Talent';
import { FormText } from '../lib/Form';
import { useGrades } from '../queries/talent';
import { WaitQuery } from './WaitLoading';

export const GradeSelector: React.FC<{ formText: FormText }> = (props) => {
    const maybeGrades = useGrades();
    return (
        <WaitQuery size="small" query={maybeGrades}>
            {({ data }) => (
                <TextField
                    select
                    value={props.formText.value}
                    onChange={(e) => {
                        props.formText.setValue(e.target.value);
                    }}
                >
                    <MenuItem value={NO_GRADE_SHORT_TEXT}>{NO_GRADE_LONG_TEXT}</MenuItem>
                    {[...data.values()].map((v) => {
                        const grade = gradeToString(v);
                        return (
                            <MenuItem key={grade} value={grade}>
                                {grade}
                            </MenuItem>
                        );
                    })}
                </TextField>
            )}
        </WaitQuery>
    );
};
