import { TodoAssignment, TodoItem } from '@spec/Todo';
import { createContext, ReactNode, useContext, useState } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode =
    | 'EditTodoItem'
    | 'PublishTodoItem'
    | 'UnpublishTodoItem'
    | 'CompleteTodoItem'
    | 'DeleteTodoItem'
    | 'Assign'
    | 'Paste'
    | 'RemoveTargets';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const TodoItemContext = createContext(
    {} as {
        item: TodoItem;
        assignments: TodoAssignment[];
    }
);
const TodoItemContextProvider: React.FC<{
    item: TodoItem;
    assignments: TodoAssignment[];
    children: ReactNode;
}> = (props) => {
    return (
        <TodoItemContext.Provider value={{ item: props.item, assignments: props.assignments }}>
            {props.children}
        </TodoItemContext.Provider>
    );
};
export const useTodoItemContext = () => useContext(TodoItemContext);

const EmployeeCodesContext = createContext(
    {} as {
        employeeCodes: Set<string>;
        checkTalent: (employeeCode: string) => void;
        setEmployeeCodes: (employeeCodes: Set<string>) => void;
        resetEmployeeCodes: () => void;
    }
);
const EmployeeCodesContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    const [employeeCodes, setEmployeeCodes] = useState<Set<string>>(new Set());
    const checkTalent = (employeeCode: string) => {
        setEmployeeCodes((before) => {
            if (before.has(employeeCode)) {
                before.delete(employeeCode);
            } else {
                before.add(employeeCode);
            }
            return new Set(before);
        });
    };
    const resetEmployeeCodes = () => setEmployeeCodes(new Set());
    return (
        <EmployeeCodesContext.Provider
            value={{
                employeeCodes,
                checkTalent,
                setEmployeeCodes,
                resetEmployeeCodes,
            }}
        >
            {props.children}
        </EmployeeCodesContext.Provider>
    );
};
export const useEmployeeCodesContext = () => useContext(EmployeeCodesContext);

export const ContextProvider: React.FC<{
    item: TodoItem;
    assignments: TodoAssignment[];
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <TodoItemContextProvider item={props.item} assignments={props.assignments}>
                <EmployeeCodesContextProvider>{props.children}</EmployeeCodesContextProvider>
            </TodoItemContextProvider>
        </DialogContextProvider>
    );
};
