import { Box, Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import React, { useEffect } from 'react';
import { getTalentSetDefaultImageUrl } from '../../../Routes';
import { hasOwnProfileImage, isAvailableTalent } from '../../../domains/Talent';
import { useAsyncState } from '../../../lib/AsyncResource';
import { useMeContext } from '../../../queries/me';
import { ActionButton, ActionContainer } from '../../ActionButtons';
import { PersonAddIcon } from '../../Icons';
import { WaitLoading } from '../../WaitLoading';

export const SetDefaultImageButton: React.FC<{ talent: Talent }> = (props) => {
    const { grants } = useMeContext();
    const [hasOwn, setHasOwn] = useAsyncState<boolean>({ inProgress: true, value: null });
    useEffect(() => {
        hasOwnProfileImage(props.talent)
            .then((value) => setHasOwn({ inProgress: false, value }))
            .catch((e) => setHasOwn({ inProgress: false, value: e }));
    }, [props.talent, setHasOwn]);
    if (grants.uploadProfileImage === false) {
        return null;
    }
    const disabled = isAvailableTalent(props.talent) === false || hasOwn.value === true;
    return (
        <WaitLoading waitFor={[hasOwn]}>
            <ActionContainer>
                <Typography variant="body2" color="primary">
                    あなたはプロフィール画像が未設定の人の画像を設定できます
                </Typography>
                <Box>
                    <ActionButton
                        disabled={disabled}
                        startIcon={<PersonAddIcon />}
                        to={getTalentSetDefaultImageUrl(props.talent.employment.employeeCode)}
                    >
                        プロフィール画像を設定する
                    </ActionButton>
                    {disabled && (
                        <Box mt={0.5}>
                            <Typography variant="body2" color="error">
                                この人のプロフィール画像は更新できません
                            </Typography>
                        </Box>
                    )}
                </Box>
            </ActionContainer>
        </WaitLoading>
    );
};
