import { EditEmploymentRequest } from '@spec/Talent';
import React from 'react';
import { Box } from '@mui/material';
import {
    Discipline,
    Form,
    FormCheckBox,
    FormTextField,
    useForm,
    useFormBoolean,
    useFormDate,
    useFormText,
} from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { DateInput, OptionalDateInput } from '../../../../DateInput';
import { DialogRow } from '../../../../StableDialog';

interface Elements {
    email: string;
    employeeCode: string;
    employmentStatus: string;
    isNewGraduate: boolean;
    joinedAt: Date | null;
    leavedAt: Date | null;
}
type EmploymentForm = Form<Elements>;

export const useEmploymentForm = (v: Elements) =>
    useForm<typeof v>({
        email: useFormText(v.email, [required]),
        employeeCode: useFormText(v.employeeCode, [required]),
        employmentStatus: useFormText(v.employmentStatus, [required]),
        isNewGraduate: useFormBoolean(v.isNewGraduate),
        joinedAt: useFormDate(v.joinedAt, [required]),
        leavedAt: useFormDate(v.leavedAt),
    });

const guard = (v: Elements): v is Discipline<Elements, 'joinedAt'> => v.joinedAt !== null;

export const toEditEmploymentRequest = (form: EmploymentForm): EditEmploymentRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('invalid parameters');
    }
    return args;
};

export const EmploymentFormContent: React.FC<{ form: EmploymentForm }> = ({ form }) => {
    return (
        <Box>
            <DialogRow label="email">
                <FormTextField autoFocus formText={form.email} fullWidth />
            </DialogRow>
            <DialogRow label="社員番号">
                <FormTextField formText={form.employeeCode} fullWidth />
            </DialogRow>
            <DialogRow label="雇用形態">
                <FormTextField formText={form.employmentStatus} fullWidth />
            </DialogRow>
            <DialogRow label="新卒フラグ">
                <FormCheckBox
                    formBoolean={form.isNewGraduate}
                    label="新卒入社"
                    name="isNewGraduate"
                />
            </DialogRow>
            <DialogRow label="入社日">
                <DateInput formDate={form.joinedAt} />
            </DialogRow>
            <DialogRow label="退職日">
                <OptionalDateInput formDate={form.leavedAt} label="退職済み" />
            </DialogRow>
        </Box>
    );
};
