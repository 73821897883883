import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useUpdateSecondment } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useEditTalentContext, useSecondmentContext } from '../Context';
import { SecondmentFormContent, useSecondmentForm } from './SecondmentForm';

export const EditSecondmentDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { employeeCode } = useEditTalentContext();
    const { secondment } = useSecondmentContext();
    if (secondment === null) {
        throw Error(`null Secondment`);
    }
    const form = useSecondmentForm(secondment);
    const mutation = useUpdateSecondment(employeeCode);
    return (
        <>
            <DialogTitle>出向関連情報の編集</DialogTitle>
            <DialogContent>
                <SecondmentFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で出向関連情報を更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="出向関連情報の更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
