import { Alert, Box, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { findAvailableLeave, getDeadline } from '../../../domains/SabbaticalLeave';
import { useMeContext } from '../../../queries/me';
import { useCurrentTimeContext } from '../../Context';
import { HelpAccordion } from '../../HelpAccordion';
import { ExternalLink } from '../../RouterLink';

export const SabbaticalLeaveAlert: React.FC = () => {
    const { sabbaticalLeaves, nextSabbaticalLeave } = useMeContext();
    const { currentTime } = useCurrentTimeContext();
    const current = findAvailableLeave(sabbaticalLeaves, currentTime);
    if (current !== null) {
        return (
            <Box>
                <Alert>
                    <Box mb={0.5}>
                        あなたには現在ボーナス休暇が付与されています。
                        {getDeadline(current).format('YYYY年M月D日')}
                        までに取得を完了してください。
                    </Box>
                    <Typography variant="body2">
                        （既に取得済みの場合もこのメッセージが表示されています）
                    </Typography>
                </Alert>
                <Help />
            </Box>
        );
    }
    if (nextSabbaticalLeave !== null) {
        return (
            <Box>
                <Alert>
                    あなたの次のボーナス休暇は
                    {dayjs(nextSabbaticalLeave).format(' YYYY年M月D日 ')}
                    に付与される予定です。
                </Alert>
                <Help />
            </Box>
        );
    }
    return null;
};

const Help: React.FC = () => {
    const theme = useTheme();
    return (
        <Box mt={1}>
            <HelpAccordion title="ボーナス休暇について">
                <Box sx={{ fontSize: theme.typography.body2.fontSize }}>
                    <Box>CARTAのボーナス休暇は、以下のいずれかを選択して取得できます。</Box>
                    <Box>
                        <ul>
                            <li>15営業日の休暇</li>
                            <li>10営業日の休暇 + 現金5万円</li>
                            <li>5営業日の休暇 + 現金10万円</li>
                        </ul>
                    </Box>
                    <Box mt={1}>
                        旧{'VOYAGE GROUP'}
                        在籍時に1ヶ月休暇を所持している人がいるかもしれませんが、ヒトノワ上では区別せず「ボーナス休暇」と表記しています。
                    </Box>
                    <Box mt={1}>
                        2021年末時点でサイバー・コミュニケーションズに雇用されていた人へのボーナス休暇のフル支給は2025年からとなります。それまでの移行措置については
                        <ExternalLink
                            href="https://docs.google.com/presentation/d/1wS6WMpo4HTzlq0b7cl0INST0wAm8y6ro-i8OlUh181E/edit#slide=id.ged66a2b8ad_1_257"
                            variant="body2"
                        >
                            Keelの資料
                        </ExternalLink>
                        を確認してください。
                    </Box>
                    <Box mt={1}>
                        なお、現時点ではシステムとして発生日しか把握できておらず、既に取得済みであってもボーナス休暇があるように表示されてしまいます。ご了承ください。
                    </Box>
                    <Box mt={1}>
                        本件についてのお問い合わせはSlack
                        #hitonowaか、各部署のHRBP（不明な場合はお近くの人事）までお願いします。
                    </Box>
                </Box>
            </HelpAccordion>
        </Box>
    );
};
