import React from 'react';
import { Box, SvgIconProps, useTheme } from '@mui/material';

const SIZE = 104;

export const IconCircle: React.FC<{
    icon: React.FC<SvgIconProps>;
    color?: string;
}> = (props) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'relative',
                width: SIZE,
                height: SIZE,
                margin: '0 auto',
                textAlign: 'left',
            }}
        >
            <svg height={SIZE} width={SIZE} style={{ position: 'absolute' }}>
                <circle
                    cx={SIZE / 2}
                    cy={SIZE / 2}
                    r="48"
                    fill={props.color ?? theme.palette.error.main}
                />
            </svg>
            <Box
                sx={{
                    position: 'absolute',
                    width: SIZE,
                    height: SIZE,
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <props.icon
                    sx={{
                        color: theme.palette.background.default,
                        fontSize: SIZE / 2,
                    }}
                />
            </Box>
        </Box>
    );
};
