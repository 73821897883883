import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ApplicationError } from '../../../../../Errors';
import { fullName } from '../../../../../domains/Talent';
import { findById } from '../../../../../lib/ArrayUtils';
import { useEmptyForm } from '../../../../../lib/Form';
import { useRevokeSurveyRead } from '../../../../../queries/surveyAdmin';
import { useTalentsContext, useTeamsContext } from '../../../../Context';
import { FlexBox } from '../../../../FlexBox';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { TalentAvatar } from '../../../../TalentAvatar';
import { useSurveysContext } from '../../../Context';
import { useDialogContext, useTalentIdContext, useTeamIdContext } from '../Context';

export const RevokePermissionDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { surveyId } = useParams();
    const { surveys } = useSurveysContext();
    const survey = findById(Number(surveyId), surveys);

    const { teamId } = useTeamIdContext();
    const { teams } = useTeamsContext();
    if (teamId === null) {
        throw new ApplicationError('unknown teamId');
    }
    const team = findById(teamId, teams);

    const { talents } = useTalentsContext();
    const { talentId } = useTalentIdContext();
    if (talentId === null) {
        throw new ApplicationError('unknown talentId');
    }
    const talent = findById(talentId, talents);
    const form = useEmptyForm();
    const mutation = useRevokeSurveyRead(survey.id, teamId);
    return (
        <>
            <DialogTitle>{survey.name}の閲覧権限を削除する</DialogTitle>
            <DialogContent>
                <DialogRow label="対象の組織">{team.name}</DialogRow>
                <DialogRow label="対象者">
                    <FlexBox gap={1}>
                        <TalentAvatar size="small" talent={talent} />
                        <Typography variant="body2">{talent.employment.employeeCode}</Typography>
                        <Typography variant="body2">{fullName(talent)}</Typography>
                    </FlexBox>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この閲覧権限を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="閲覧権限の削除に失敗しました"
                onSubmit={() => mutation.mutateAsync(talentId)}
                closeDialog={closeDialog}
            />
        </>
    );
};
