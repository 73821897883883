import { Box } from '@mui/material';
import { RoutingPattern } from '../../Routes';
import { useNotesArticles } from '../../queries/notes';
import { useTalents } from '../../queries/talent';
import { LinkButton } from '../ActionButtons';
import { TalentsContextProvider } from '../Context';
import { SubTitle } from '../PageTitle';
import { Loading, WaitQuery } from '../WaitLoading';
import { ArticleCard } from '../notes/articles';

const DISPLAY_ARTICLES = 5;

export const RecentNotes: React.FC = () => {
    const maybeTalents = useTalents();
    const maybeArticles = useNotesArticles();
    if (maybeTalents.isFetching) {
        return <Loading />;
    }
    return (
        <Box mb={4}>
            <TalentsContextProvider>
                <SubTitle title="ノートの新しい記事" />
                <WaitQuery query={maybeArticles}>
                    {({ data }) => (
                        <Box mt={2}>
                            {data.pages[0].articles.slice(0, DISPLAY_ARTICLES).map((v) => (
                                <ArticleCard key={v.id} article={v} />
                            ))}
                        </Box>
                    )}
                </WaitQuery>
                <LinkButton fullWidth variant="outlined" to={RoutingPattern.notes}>
                    ノートの記事一覧を見る
                </LinkButton>
            </TalentsContextProvider>
        </Box>
    );
};
