import { Table, tableCellClasses, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const HistoryTable: React.FC<{ children: ReactNode }> = (props) => {
    const theme = useTheme();
    return (
        <Table
            sx={{
                [`& .${tableCellClasses.root}`]: {
                    padding: theme.spacing(0, 2, 0.5),
                    border: 'none',
                },
            }}
        >
            {props.children}
        </Table>
    );
};
