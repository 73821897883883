import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { FlexBox } from './FlexBox';
import { ExpandMoreIcon, HelpOutlineIcon } from './Icons';

export const HelpAccordion: React.FC<React.PropsWithChildren<{ title: string }>> = (props) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FlexBox>
                    <HelpOutlineIcon color="primary" />
                    <Box ml={1}>
                        <Typography variant="body2">{props.title}</Typography>
                    </Box>
                </FlexBox>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 4 }}>{props.children}</AccordionDetails>
        </Accordion>
    );
};
