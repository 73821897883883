import { createContext, useContext, type ReactNode } from 'react';

const NavContext = createContext(
    {} as {
        collapse: boolean;
        firstPath: string;
    }
);

export const useNavContext = () => useContext(NavContext);

export const NavContextProvider = (props: {
    collapse: boolean;
    firstPath: string;
    children: ReactNode;
}) => (
    <NavContext.Provider value={{ collapse: props.collapse, firstPath: props.firstPath }}>
        {props.children}
    </NavContext.Provider>
);
