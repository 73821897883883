import { DialogContent, DialogTitle } from '@mui/material';
import { useUpdateSurveyPeriod } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { PeriodFormContent, toEditPeriodRequest, usePeriodForm } from '../../periods/PeriodForm';
import { useDialogContext, useEditPeriodContext } from '../Context';

export const EditPeriodDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { period } = useEditPeriodContext();
    const v = period.value;
    if (v === null) {
        throw Error('target period was not set');
    }
    const form = usePeriodForm({
        name: v.name,
        openedAt: v.openedAt,
        closedAt: v.closedAt,
    });
    const mutation = useUpdateSurveyPeriod(v.id);
    return (
        <>
            <DialogTitle>実施期間を編集する</DialogTitle>
            <DialogContent>
                <PeriodFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で実施期間を更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditPeriodRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
