import { Alert, Box, Stack, Typography } from '@mui/material';
import { ValueFeedback, ValueFeedbackParticipation } from '@spec/ValueFeedback';
import dayjs from 'dayjs';
import { getValueFeedbackUrl } from '../../Routes';
import { getStatus, sortValueFeedbacksPreferLatest } from '../../domains/ValueFeedback';
import { useMeContext } from '../../queries/me';
import { useValueFeedbacks } from '../../queries/valueFeedback';
import { ActionButton, ActionContainer } from '../ActionButtons';
import { useCurrentTimeContext } from '../Context';
import { ListAltIcon, PeopleIcon } from '../Icons';
import { SubTitle } from '../PageTitle';
import { ExternalLink } from '../RouterLink';
import { WaitQuery } from '../WaitLoading';
import { ValueFeedbackStatus } from '../valueFeedback/Contract';

export const ValueFeedbackAnnouncement: React.FC = () => {
    const maybeValueFeedbacks = useValueFeedbacks();
    const { currentTime } = useCurrentTimeContext();
    return (
        <WaitQuery query={maybeValueFeedbacks} size="medium">
            {({ data }) => {
                const latest = data.sort((a, b) =>
                    sortValueFeedbacksPreferLatest(a.valueFeedback, b.valueFeedback)
                )[0];
                if (latest === undefined) {
                    return null;
                }
                return (
                    <StatusCard
                        valueFeedback={latest.valueFeedback}
                        participation={latest.participation}
                        currentTime={currentTime}
                    />
                );
            }}
        </WaitQuery>
    );
};

const POST_NOTICE_DAYS = 10;

const shouldDisplay = (
    valueFeedback: ValueFeedback,
    participation: ValueFeedbackParticipation,
    currentTime: Date
) => {
    const status = getStatus(valueFeedback, currentTime);
    if (status === 'prepare') {
        return false;
    }
    if (status === 'published') {
        const daysAfterEnd = dayjs(currentTime).diff(dayjs(valueFeedback.publishedAt), 'day');
        if (daysAfterEnd > POST_NOTICE_DAYS) {
            return false;
        }
    }
    if (participation.isParticipant) {
        return true;
    }
    if (participation.hasRequest) {
        return ['feedback', 'confirmFeedback', 'published'].includes(status);
    }
    return false;
};

const StatusCard: React.FC<{
    valueFeedback: ValueFeedback;
    participation: ValueFeedbackParticipation;
    currentTime: Date;
}> = (props) => {
    if (!shouldDisplay(props.valueFeedback, props.participation, props.currentTime)) {
        return null;
    }
    const status = getStatus(props.valueFeedback, props.currentTime);
    return (
        <Box>
            <SubTitle title="バリューフィードバックのお知らせ" />
            <Box mt={2} textAlign="center">
                <Alert variant="filled" severity="success">
                    {props.valueFeedback.name}バリューフィードバック
                    {status === 'published' ? 'の結果が公開されました。' : '実施中です。'}
                </Alert>
                <Stack mt={4} spacing={1}>
                    {status === 'selection' && (
                        <>
                            <Typography>
                                フィードバック者の選定期間は
                                {dayjs(props.valueFeedback.finishSelectionAt).format('M/D(ddd)')}
                                までです。
                            </Typography>
                            <Typography>
                                一人が受けられるフィードバック依頼の上限は先着10名までとなっていますので、早めの選定をおすすめします。
                            </Typography>
                        </>
                    )}
                    {status === 'confirmSelection' && (
                        <Typography>
                            自己振り返りとフィードバックの記入期間は
                            {dayjs(props.valueFeedback.startFeedbackAt).format(
                                ' YYYY-MM-DD (ddd) HH:mm '
                            )}
                            からです。開始までしばらくお待ち下さい。
                        </Typography>
                    )}
                    {status === 'feedback' && (
                        <>
                            <Typography>
                                自己振り返り・フィードバックが書けるようになりました。
                            </Typography>
                            <Typography>
                                記入期間は
                                {dayjs(props.valueFeedback.finishFeedbackAt).format(
                                    ' YYYY-MM-DD (ddd) HH:mm '
                                )}
                                までです。
                            </Typography>
                        </>
                    )}
                    {status === 'confirmFeedback' && (
                        <Typography>公開待ちです。もうしばらくお待ち下さい。</Typography>
                    )}
                    {status === 'published' && (
                        <Typography>
                            自分で書いた自己振り返りと他者へのフィードバックに加えて、もらったフィードバックが見えるようになりました。
                        </Typography>
                    )}
                </Stack>
                <Box mt={2}>
                    <Typography>
                        制度の詳細については
                        <ExternalLink
                            href="https://docs.google.com/presentation/d/1Q7s8xqjp8JXAhM11Id4OrGHpwX3nPuv0rwl1Vc1xmCY"
                            icon={true}
                        >
                            説明会資料
                        </ExternalLink>
                        も参照してください。
                    </Typography>
                </Box>
                <Box my={1}>
                    <Typography>
                        お問い合わせは担当HRBP（あるいはお近くの人事）かSlack #hitonowa
                        までお願いします。
                    </Typography>
                </Box>
                <ActionButtons valueFeedback={props.valueFeedback} status={status} />
            </Box>
        </Box>
    );
};

const buttonLabels: Map<ValueFeedbackStatus, string> = new Map([
    ['selection', 'フィードバック者を選ぶ'],
    ['feedback', '自己振り返り・フィードバックを記入する'],
    ['published', '自己振り返り・フィードバックを見る'],
]);

const ActionButtons: React.FC<{ valueFeedback: ValueFeedback; status: ValueFeedbackStatus }> = (
    props
) => {
    const { grants } = useMeContext();
    const buttonLabel = buttonLabels.get(props.status);
    if (buttonLabel === undefined) {
        return null;
    }
    return (
        <ActionContainer>
            <ActionButton startIcon={<PeopleIcon />} to={getValueFeedbackUrl(props.valueFeedback)}>
                {buttonLabel}
            </ActionButton>
            {props.status === 'published' && grants.valueFeedback.readableTeamIds.length > 0 && (
                <ActionButton
                    startIcon={<ListAltIcon />}
                    to={getValueFeedbackUrl(props.valueFeedback, 'manager')}
                >
                    チームのフィードバックを見る
                </ActionButton>
            )}
        </ActionContainer>
    );
};
