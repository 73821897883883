import { Tooltip } from '@mui/material';
import { Privilege } from '@spec/Grants';
import React, { ReactNode } from 'react';
import { privilegeLabelMap } from '../domains/Grants';
import { HelpOutlineIcon } from './Icons';

export const HelpTip: React.FC<{ title: ReactNode }> = (props) => {
    return (
        <Tooltip arrow title={props.title} placement="top">
            <HelpOutlineIcon color="primary" fontSize="small" />
        </Tooltip>
    );
};

export const PrivilegeTip: React.FC<{ privilege: Privilege }> = (props) => {
    return (
        <HelpTip
            title={`あなたは「${privilegeLabelMap.get(
                props.privilege
            )}」権限を持っているため、この情報が見えています`}
        />
    );
};
