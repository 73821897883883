import { Typography } from '@mui/material';
import React from 'react';
import { RoutingPattern } from '../../../Routes';
import { useMeContext } from '../../../queries/me';
import { ActionButton, ActionContainer } from '../../ActionButtons';
import { EditIcon } from '../../Icons';

export const RegisterButton: React.FC = () => {
    const { grants } = useMeContext();
    if (grants.editTalent === false) {
        return null;
    }
    return (
        <ActionContainer>
            <Typography variant="body2" color="primary">
                あなたは従業員を新規登録する権限を持っています
            </Typography>
            <ActionButton startIcon={<EditIcon />} to={RoutingPattern.registerTalent}>
                新しい従業員を登録する
            </ActionButton>
        </ActionContainer>
    );
};
