import { Box, Typography } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { RoutingPattern } from '../../../Routes';
import { LinkButton } from '../../ActionButtons';
import { IconCircle } from '../../IconCircle';
import { FeedIcon } from '../../Icons';

export const ArticleNotFound: React.FC = () => {
    return (
        <Box my={10} textAlign="center">
            <Box>
                <IconCircle icon={FeedIcon} color={deepOrange[800]} />
            </Box>
            <Typography mt={4} variant="h6">
                指定のノートが見つかりません
            </Typography>
            <Typography mt={3}>記事が公開停止されたか、URLが変わった可能性があります。</Typography>
            <Box mt={4}>
                <LinkButton
                    size="large"
                    variant="outlined"
                    to={RoutingPattern.notes}
                    reloadDocument
                >
                    ノートのトップに戻る
                </LinkButton>
            </Box>
        </Box>
    );
};
