import { TeamId } from '@spec/Organization';
import { EditTransferRequest } from '@spec/Talent';
import React from 'react';
import { Box } from '@mui/material';
import { Discipline, Form, useForm, useFormDate, useFormNumber } from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { DateInput, OptionalDateInput } from '../../../../DateInput';
import { DialogRow } from '../../../../StableDialog';
import { TeamSelector } from '../../../../TeamSelector';

interface Elements {
    teamId: TeamId | null;
    joinedAt: Date | null;
    leavedAt: Date | null;
}
type TransferForm = Form<Elements>;

export const useTransferForm = (v: Elements) =>
    useForm<typeof v>({
        teamId: useFormNumber(v.teamId, [required]),
        joinedAt: useFormDate(v.joinedAt, [required]),
        leavedAt: useFormDate(v.leavedAt),
    });

const guard = (v: Elements): v is Discipline<Elements, 'teamId' | 'joinedAt'> =>
    v.teamId !== null && v.joinedAt !== null;

export const toEditTransferRequest = (form: TransferForm): EditTransferRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('invalid parameters');
    }
    return args;
};

export const TransferFormContent: React.FC<{ form: TransferForm }> = ({ form }) => {
    return (
        <Box>
            <DialogRow label="所属">
                <TeamSelector teamId={form.teamId} required />
            </DialogRow>
            <DialogRow label="着任日">
                <DateInput formDate={form.joinedAt} />
            </DialogRow>
            <DialogRow label="離任日">
                <OptionalDateInput formDate={form.leavedAt} label="離任済み" />
            </DialogRow>
        </Box>
    );
};
