import { DialogContent, DialogTitle } from '@mui/material';
import { useUpdateSurveyGroup } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useTargetGroupContext } from '../Context';
import { GroupFormContent, useGroupForm } from '../GroupForm';

export const EditGroupDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { group } = useTargetGroupContext();
    if (group === null) {
        throw Error('target group was not set');
    }
    const form = useGroupForm(group);
    const mutation = useUpdateSurveyGroup(group.id);
    return (
        <>
            <DialogTitle>サーベイグループ「{group.name}」を編集する</DialogTitle>
            <DialogContent>
                <GroupFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でサーベイグループを更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
