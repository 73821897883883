import {
    Alert,
    Box,
    CircularProgress,
    IconButton,
    IconButtonProps,
    Tooltip,
    Typography,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import { NotesArticleSummary } from '@spec/Notes';
import { TalentId } from '@spec/Talent';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { findTalent } from '../../../domains/Talent';
import { useMeContext } from '../../../queries/me';
import { useToggleLikeArticle } from '../../../queries/notes';
import { useTalentsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { FavoriteBorderIcon, FavoriteIcon } from '../../Icons';
import { PopoverTalent } from '../../PopoverTalent';
import { TalentAvatar } from '../../TalentAvatar';

export const Likes: React.FC<{
    article: NotesArticleSummary;
    talentIds: TalentId[];
}> = (props) => {
    const { me } = useMeContext();
    const liked = props.talentIds.includes(me.id);

    const queryClient = useQueryClient();
    const mutation = useToggleLikeArticle(queryClient, props.article.id, liked);

    return (
        <FlexBox>
            <LikeButton
                liked={liked}
                isLoading={mutation.isPending}
                onClick={() => mutation.mutate()}
            />
            <Typography>{props.talentIds.length}</Typography>
            <FlexBox ml={2} gap={0.5}>
                {props.talentIds.map((v) => (
                    <Like key={v} talentId={v} />
                ))}
            </FlexBox>
            {mutation.isError && (
                <Box mt={2}>
                    <Alert severity="error">いいね！に失敗しました</Alert>
                </Box>
            )}
        </FlexBox>
    );
};

const LikeButton: React.FC<IconButtonProps & { liked: boolean; isLoading: boolean }> = (props) => {
    const { liked, isLoading, ...rest } = props;
    const title = liked ? 'いいね！を取り消す' : 'いいね！';
    const Icon = liked ? FavoriteIcon : FavoriteBorderIcon;
    const color = liked ? pink['A200'] : 'inherit';
    return (
        <Box width="40px" height="40px" textAlign="center">
            <Tooltip title={title} arrow placement="bottom">
                {isLoading ? (
                    <CircularProgress size={14} sx={{ marginTop: '12px', color: pink['A200'] }} />
                ) : (
                    <IconButton
                        sx={{
                            color,
                            '&:hover': {
                                color: pink['A200'],
                                backgroundColor: `${pink['A200']}18`,
                            },
                        }}
                        {...rest}
                    >
                        <Icon />
                    </IconButton>
                )}
            </Tooltip>
        </Box>
    );
};

const Like: React.FC<{ talentId: TalentId }> = (props) => {
    const { talents } = useTalentsContext();
    const talent = findTalent(props.talentId, talents);
    return (
        <PopoverTalent talent={talent}>
            <TalentAvatar size="small" talent={talent} />
        </PopoverTalent>
    );
};
