import { Box, Paper, Typography, useTheme } from '@mui/material';
import {
    LeaveOfAbsence,
    NextSabbaticalLeave,
    SabbaticalLeave,
    SabbaticalLeaveBaseDate,
    Talent,
} from '@spec/Talent';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { queryToArray } from '../../../queries';
import {
    useLeaveOfAbsences,
    useNextSabbaticalLeaves,
    useSabbaticalLeaves,
} from '../../../queries/talent';
import { FlexBox } from '../../FlexBox';
import { HelpTip, PrivilegeTip } from '../../HelpTip';
import { UnitText } from '../../UnitText';
import { WaitLoading, WaitQuery } from '../../WaitLoading';

export const SabbaticalLeaves: React.FC<{ talent: Talent }> = (props) => {
    const maybeLeaves = useSabbaticalLeaves();
    const maybeNextLeaves = useNextSabbaticalLeaves();
    return (
        <Box mt={2}>
            <FlexBox>
                <Typography>ボーナス休暇</Typography>
                <PrivilegeTip privilege="SHOW_FULL_PERSONALITY" />
            </FlexBox>
            <WaitLoading size="medium" waitFor={[maybeLeaves, maybeNextLeaves]}>
                <X
                    talent={props.talent}
                    leaves={maybeLeaves.data?.leaves ?? []}
                    baseDates={maybeLeaves.data?.baseDates ?? []}
                    nextLeaves={queryToArray(maybeNextLeaves)}
                />
            </WaitLoading>
        </Box>
    );
};

const format = (v: Date) => dayjs(v).format('YYYY-MM-DD');

const X: React.FC<{
    talent: Talent;
    leaves: SabbaticalLeave[];
    baseDates: SabbaticalLeaveBaseDate[];
    nextLeaves: NextSabbaticalLeave[];
}> = (props) => {
    const theme = useTheme();
    const leaves = props.leaves.filter((v) => v.talentId === props.talent.id);
    const baseDate = props.baseDates.find((v) => v.talentId === props.talent.id) ?? null;
    const nextLeave = props.nextLeaves.find((v) => v.talentId === props.talent.id) ?? null;
    const maybeAbsences = useLeaveOfAbsences();
    return (
        <Box mt={1}>
            <Paper sx={{ px: 2, py: 1 }}>
                <FlexBox mt={0.5} gap={theme.spacing(1)}>
                    <Typography variant="body2">過去の発生日</Typography>
                    {leaves.length === 0 && <Typography variant="body2">実績なし</Typography>}
                    {leaves.map((v) => (
                        <Typography key={v.id} variant="body2">
                            {format(v.offeredAt)}
                        </Typography>
                    ))}
                </FlexBox>
                <FlexBox mt={0.5} gap={theme.spacing(1)}>
                    <Typography variant="body2">個別の基準日設定</Typography>
                    <Typography variant="body2">
                        {baseDate === null ? (
                            <>設定されていません</>
                        ) : (
                            <>{format(baseDate.basedOn)}</>
                        )}
                    </Typography>
                </FlexBox>
                <FlexBox mt={0.5} gap={theme.spacing(1)}>
                    <Typography variant="body2">次回の発生予定日</Typography>
                    <Typography variant="body2">
                        {nextLeave === null ? (
                            <>発生予定はありません</>
                        ) : (
                            <>{format(nextLeave.offeringAt)}</>
                        )}
                    </Typography>
                </FlexBox>
                <WaitQuery query={maybeAbsences}>
                    {({ data }) => (
                        <Dates
                            talent={props.talent}
                            leaves={data.filter((v) => v.talentId === props.talent.id)}
                        />
                    )}
                </WaitQuery>
            </Paper>
        </Box>
    );
};

const Dates: React.FC<{ talent: Talent; leaves: LeaveOfAbsence[] }> = (props) => {
    const anniversaries = listAnniversaries(props.talent.joinedAt, props.leaves);
    return (
        <Box mt={1}>
            <FlexBox gap={0.5}>
                <Typography variant="body2">仮想ボーナス休暇発生日の一覧</Typography>
                <HelpTip title="入社から休職期間を除いた3年ごとの日付とその間の休職日数を確認できます。個別基準日の調整などの参考にしてください。" />
            </FlexBox>
            {anniversaries.map((v) => (
                <Typography key={v.anniversary.unix()} variant="body2">
                    {v.anniversary.format('YYYY-MM-DD')}
                    {v.deductedDays > 0 && (
                        <span>
                            （{v.deductedDays}
                            <UnitText text="日控除" />）
                        </span>
                    )}
                </Typography>
            ))}
        </Box>
    );
};

const listAnniversaries = (
    joinedAt: Date,
    leaves: LeaveOfAbsence[]
): { anniversary: Dayjs; deductedDays: number }[] => {
    const anniversaries = [];
    let current = dayjs(joinedAt);
    const now = dayjs();
    let futureAdded = false;

    while (!futureAdded) {
        let next = current.add(3, 'years');
        let deductedDays = 0;
        for (const leave of leaves.filter(
            (v) => v.returnedAt !== null && dayjs(v.leavedAt).isAfter(current)
        )) {
            if (dayjs(leave.leavedAt).isAfter(next)) {
                break;
            }
            const duration = dayjs(leave.returnedAt).diff(leave.leavedAt, 'days');
            deductedDays += duration;
            next = next.add(duration, 'days');
        }
        anniversaries.push({ anniversary: next, deductedDays });
        if (next.isAfter(now)) {
            futureAdded = true;
        }
        current = next;
    }

    return anniversaries;
};
