import { Box, useTheme } from '@mui/material';
import { Talent } from '@spec/Talent';
import { Data, bar } from 'billboard.js';
import dayjs from 'dayjs';
import React from 'react';
import { Team } from '../../../../../api/base/src/@spec/Organization';
import { gradeToString } from '../../../domains/Talent';
import { Chart } from '../../Chart';
import { FlexBox } from '../../FlexBox';

const formatTick = (x: unknown) => (Number.isInteger(x) ? `${x}` : '');

export const Visualizer: React.FC<{ teams: Team[]; talents: Talent[] }> = (props) => {
    return (
        <FlexBox>
            <EmploymentStatusChart talents={props.talents} />
            <GradeChart talents={props.talents} />
            <JoinedYearChart talents={props.talents} />
        </FlexBox>
    );
};

const OrganizationChart: React.FC<{ title: string; data: Data }> = (props) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: '33%',
                '& .bb text': {
                    fill: theme.palette.text.primary,
                },
                '& .bb-tooltip': {
                    backgroundColor: theme.palette.background.paper,
                    '& td': {
                        backgroundColor: theme.palette.background.paper,
                    },
                },
            }}
        >
            <Chart
                options={{
                    title: { text: props.title },
                    data: {
                        ...props.data,
                        type: bar(),
                        labels: { colors: theme.palette.text.primary },
                    },
                    tooltip: {
                        format: {
                            title: () => props.title,
                        },
                    },
                    axis: {
                        x: {
                            tick: {
                                show: false,
                                text: {
                                    show: false,
                                },
                            },
                        },
                        y: {
                            tick: {
                                format: formatTick,
                            },
                        },
                    },
                }}
            />
        </Box>
    );
};

const EmploymentStatusChart: React.FC<{ talents: Talent[] }> = (props) => {
    const employment: Record<string, number> = {};
    props.talents.map((v) => {
        const key = v.employment.employmentStatus === '' ? '-' : v.employment.employmentStatus;
        if (employment[key] === undefined) {
            employment[key] = 0;
        }
        employment[key]++;
    });
    return (
        <OrganizationChart
            key={props.talents.length}
            title="雇用形態"
            data={{
                json: [employment],
                keys: { value: [...Object.keys(employment)] },
            }}
        />
    );
};

const GradeChart: React.FC<{ talents: Talent[] }> = (props) => {
    const grade: Record<string, number> = {};
    props.talents.map((v) => {
        const g = gradeToString(v.grade);
        if (grade[g] === undefined) {
            grade[g] = 0;
        }
        grade[g]++;
    });
    return (
        <OrganizationChart
            key={props.talents.length}
            title="グレード"
            data={{
                json: [grade],
                keys: { value: [...Object.keys(grade).sort()] },
                xSort: true,
            }}
        />
    );
};

const JoinedYearChart: React.FC<{ talents: Talent[] }> = (props) => {
    const joined: Record<string, number> = {};
    props.talents.map((v) => {
        const y = dayjs(v.joinedAt).format("'YY");
        if (joined[y] === undefined) {
            joined[y] = 0;
        }
        joined[y]++;
    });
    return (
        <OrganizationChart
            key={props.talents.length}
            title="入社年"
            data={{
                json: [joined],
                keys: { value: [...Object.keys(joined).sort()] },
            }}
        />
    );
};
