import { Divider } from '@mui/material';
import { useMeContext } from '../../../queries/me';
import { RoutingPattern } from '../../../Routes';
import {
    AccountTreeIcon,
    DashboardIcon,
    FactCheckIcon,
    FeedIcon,
    HistoryIcon,
    PersonIcon,
    PollIcon,
    SecurityIcon,
    TerminalIcon,
} from '../../Icons';
import { NavItem } from './NavItem';
import { NavList } from './NavList';

export const Treasure2024 = () => {
    const { grants } = useMeContext();
    return (
        <>
            <NavList>
                <NavItem
                    icon={DashboardIcon}
                    label="ダッシュボード"
                    href={RoutingPattern.index}
                    paths={['', 'sabbatical-leaves']}
                />
                <NavItem
                    icon={FactCheckIcon}
                    label="やることリスト"
                    href={RoutingPattern.todo}
                    paths={['todo']}
                />
                <NavItem
                    icon={PersonIcon}
                    label="人を探す"
                    href={RoutingPattern.talents}
                    paths={['talents', 'iam']}
                />
                <NavItem
                    icon={AccountTreeIcon}
                    label="組織を見る"
                    href={RoutingPattern.organization}
                    paths={['organization']}
                />
                <NavItem
                    icon={PollIcon}
                    label="サーベイ"
                    href={RoutingPattern.survey}
                    paths={['survey']}
                />
                <NavItem
                    icon={FeedIcon}
                    label="ノート"
                    href={RoutingPattern.notes}
                    paths={['notes']}
                />
            </NavList>
            <Divider />
            <NavList>
                <NavItem
                    icon={SecurityIcon}
                    label="権限管理"
                    href={RoutingPattern.grants}
                    paths={['grants']}
                />
                {grants.readLogs === true && (
                    <NavItem
                        icon={HistoryIcon}
                        label="操作履歴"
                        href={RoutingPattern.logs}
                        paths={['logs']}
                    />
                )}
                <NavItem
                    icon={TerminalIcon}
                    label="APIリファレンス"
                    href={RoutingPattern.apiReference}
                    paths={['api-reference']}
                />
            </NavList>
            <Divider />
        </>
    );
};
