import { SurveyPeriod } from '@spec/Survey';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { findById } from '../../../../lib/ArrayUtils';
import { ActionButton, ActionContainer } from '../../../ActionButtons';
import { ScheduleIcon } from '../../../Icons';
import { SubTitle } from '../../../PageTitle';
import { RequirePrivilege } from '../../../RequirePrivilege';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { useSurveysContext } from '../../Context';
import { AddPeriodDialog } from './dialogs/AddPeriodDialog';
import { DeletePeriodDialog } from './dialogs/DeletePeriodDialog';
import { EditPeriodDialog } from './dialogs/EditPeriodDialog';
import { LinkQuestionsDialog } from './dialogs/LinkQuestionsDialog';
import { ContextProvider, DialogMode, useDialogContext } from './Context';
import { PeriodsTable } from './PeriodsTable';

export const SurveyPeriods: React.FC = () => {
    const { groupId } = useParams();
    const { surveyGroups } = useSurveysContext();
    const group = findById(Number(groupId), surveyGroups);
    return (
        <ContextProvider surveyGroup={group}>
            <RequirePrivilege fn={(grants) => grants.manageSurvey}>
                <SubTitle title={`${group.name}の実施期間`} />
                <Periods periods={group.periods} />
                <ContextualDialog components={dialogComponents} />
            </RequirePrivilege>
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddPeriod: AddPeriodDialog,
    EditPeriod: EditPeriodDialog,
    LinkQuestions: LinkQuestionsDialog,
    DeletePeriod: DeletePeriodDialog,
};

const Periods: React.FC<{ periods: SurveyPeriod[] }> = (props) => {
    const { setDialogMode } = useDialogContext();
    return (
        <Box mt={2}>
            <PeriodsTable periods={props.periods} />
            <ActionContainer mt={2}>
                <ActionButton
                    startIcon={<ScheduleIcon />}
                    onClick={() => setDialogMode('AddPeriod')}
                >
                    新しい実施期間を作る
                </ActionButton>
            </ActionContainer>
        </Box>
    );
};
