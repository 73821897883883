import { Alert, Box, Typography } from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import { Workplace } from '@spec/Workplace';
import dayjs from 'dayjs';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { findChildTeams } from '../../../domains/Organization';
import {
    TalentSorter,
    gradeToString,
    isLeftTalent,
    searchTalent,
    sortTalentsByEmployeeCode,
    sortTalentsByGrade,
    sortTalentsByJoinedAt,
} from '../../../domains/Talent';
import { queryToArray } from '../../../queries';
import { useMeContext } from '../../../queries/me';
import { useTalents } from '../../../queries/talent';
import { useWorkplaces } from '../../../queries/workplace';
import { useTeamsContext } from '../../Context';
import { Pager, sliceItems } from '../../Pager';
import { TenantContent } from '../../TenantContent';
import { WaitLoading } from '../../WaitLoading';
import { ConditionForm, NO_GRADE_TEXT, NO_WORKPLACE_TEXT } from './ConditionForm';
import { ContextProvider, useFilterFormContext } from './Context';
import { CsvDownload } from './CsvDownload';
import { EmployeeCodeInfo } from './EmployeeCodeInfo';
import { RegisterButton } from './RegisterButton';
import { TalentListCard } from './TalentCard';
import { Enrollment, HiringCategory, ListFilterCondition, SortOrder } from './filter';

export const TalentList: React.FC = () => {
    const maybeTalents = useTalents();
    const maybeWorkplaces = useWorkplaces();
    return (
        <>
            <ContextProvider>
                <WaitLoading waitFor={[maybeTalents, maybeWorkplaces]}>
                    <Content
                        talents={queryToArray(maybeTalents)}
                        workplaces={queryToArray(maybeWorkplaces)}
                    />
                </WaitLoading>
            </ContextProvider>
            <Outlet />
        </>
    );
};

const Content: React.FC<{ talents: Talent[]; workplaces: Workplace[] }> = (props) => {
    const { grants } = useMeContext();
    const privileged = grants.showLeavedTalent;
    const { filterCondition, viewCondition, page, setPage } = useFilterFormContext();
    const { teams } = useTeamsContext();
    const talents = sortTalents(
        filterTalents(props.talents, teams, filterCondition),
        viewCondition.sortOrder,
        viewCondition.sortReverse
    );
    const slicedTalents = sliceItems(talents, page, viewCondition.itemsPerPage);
    const showLeavedTalent =
        filterCondition.enrollment === 'all' || filterCondition.enrollment === 'leaved';
    return (
        <Box>
            <ConditionForm talents={props.talents} teams={teams} workplaces={props.workplaces} />
            <CsvDownload talents={talents} />
            <TenantContent
                carta={
                    <Box mt={2}>
                        <EmployeeCodeInfo />
                    </Box>
                }
            />
            {showLeavedTalent && privileged && (
                <Box mt={2}>
                    <Alert severity="warning">
                        あなたには権限が付与されているため、退職者の氏名やメールアドレスが見えます。
                    </Alert>
                </Box>
            )}
            {talents.length === 0 ? (
                <Box mt={10} textAlign="center">
                    <Typography color="error">条件に合致する人はいません。</Typography>
                </Box>
            ) : (
                <Box mt={4}>
                    <Pager
                        current={page}
                        setPage={setPage}
                        amount={talents.length}
                        perItems={viewCondition.itemsPerPage}
                    />
                    <Box>
                        {slicedTalents.map((v) => (
                            <TalentListCard key={v.id} talent={v} teams={teams} />
                        ))}
                    </Box>
                    <Pager
                        current={page}
                        setPage={setPage}
                        amount={talents.length}
                        perItems={viewCondition.itemsPerPage}
                    />
                </Box>
            )}
            <RegisterButton />
        </Box>
    );
};

const filterTalents = (
    talents: Talent[],
    teams: Team[],
    condition: ListFilterCondition
): Talent[] => {
    const teamIds =
        condition.teamId === null ? [] : findChildTeams(condition.teamId, teams).map((v) => v.id);
    return talents
        .filter((v) => {
            if (condition.joinedYears.length === 0) {
                return true;
            }
            return condition.joinedYears.includes(dayjs(v.joinedAt).year());
        })
        .filter((v) => filterByHiringCategory(v, condition.hiringCategory))
        .filter((v) => {
            if (condition.grades.length === 0) {
                return true;
            }
            return condition.grades.includes(gradeToString(v.grade, NO_GRADE_TEXT));
        })
        .filter((v) => {
            if (condition.employmentStatus.length === 0) {
                return true;
            }
            return condition.employmentStatus.includes(v.employment.employmentStatus);
        })
        .filter((v) => {
            if (condition.secondmentType.length === 0) {
                return true;
            }
            return condition.secondmentType.includes(v.secondment?.assignmentType ?? '');
        })
        .filter((v) => {
            if (condition.employeeCode === '') {
                return true;
            }
            return v.employment.employeeCode
                .toLocaleLowerCase()
                .startsWith(condition.employeeCode.toLowerCase());
        })
        .filter((v) => {
            if (condition.workplace === null) {
                return true;
            }
            return (v.workplace === null ? NO_WORKPLACE_TEXT : v.workplace) === condition.workplace;
        })
        .filter((v) => {
            if (condition.teamId === null) {
                return true;
            }
            if (teamIds.includes(v.teamId)) {
                return true;
            }
            for (const post of v.additionalPosts) {
                if (teamIds.includes(post.teamId)) {
                    return true;
                }
            }
            return false;
        })
        .filter((v) => filterByEnrollment(v, condition.enrollment))
        .filter((v) => {
            if (condition.name === '') {
                return true;
            }
            return searchTalent(condition.name, v);
        })
        .filter((v) => (condition.showSuspended ? true : !v.isSuspended))
        .filter((v) => (condition.noProfileImage ? v.profileImagePath === null : true))
        .filter((v) => (condition.noSlackIntegration ? v.slackId === null : true));
};

const filterByHiringCategory = (v: Talent, category: HiringCategory): boolean => {
    switch (category) {
        case 'all':
            return true;
        case 'newGraduate':
            return v.isNewGraduate === true;
        case 'experienced':
            return v.isNewGraduate === false;
        default:
            throw Error(`got unexpected hiring category: ${category}`);
    }
};

const filterByEnrollment = (v: Talent, enrollment: Enrollment): boolean => {
    switch (enrollment) {
        case 'all':
            return true;
        case 'current':
            return !isLeftTalent(v);
        case 'leaved':
            return isLeftTalent(v);
        default:
            throw Error(`got unexpected enrollment: ${enrollment}`);
    }
};

const detectSortFunction = (order: SortOrder): TalentSorter => {
    switch (order) {
        case 'joinedAt':
            return sortTalentsByJoinedAt;
        case 'employeeCode':
            return sortTalentsByEmployeeCode;
        case 'grade':
            return sortTalentsByGrade;
        default:
            throw Error(`got unexpected sort order: ${order}`);
    }
};

const sortTalents = (talents: Talent[], sortOrder: SortOrder, shouldReverse: boolean): Talent[] => {
    const sorted = talents.sort(detectSortFunction(sortOrder));
    return shouldReverse ? sorted.reverse() : sorted;
};
