import { Box, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getTalentUrl } from '../../../Routes';
import { useAuthorsNote } from '../../../queries/notes';
import { CardLink, LinkButton } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { IconText } from '../../IconText';
import { AccessTimeIcon, KeyboardDoubleArrowRightIcon, PushPinIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { Pager, sliceItems } from '../../Pager';
import { TalentAvatar } from '../../TalentAvatar';
import { WaitQuery } from '../../WaitLoading';
import { ArticleTags } from '../ArticleTags';
import { useAuthor } from '../Hooks';
import { LikesCounter } from '../LikesCounter';
import { useAuthorContext } from '../article/AuthorContext';
import { getArticleUrl } from '../urls';

export const ListByAuthor: React.FC = () => {
    const { author } = useAuthorContext();
    const maybeArticles = useAuthorsNote(author.hitonowaId);
    return (
        <Container maxWidth="md">
            <WaitQuery query={maybeArticles}>
                {({ data }) => (
                    <>
                        <FlexBox gap={1}>
                            <TalentAvatar size="medium" talent={author} />
                            <Typography variant="h6">{author.hitonowaId}さんの記事一覧</Typography>
                        </FlexBox>
                        <Box mt={0.5}>
                            <Divider />
                        </Box>
                        <Box mt={1} mx={2}>
                            <LinkButton
                                to={getTalentUrl(author.employment.employeeCode)}
                                startIcon={<KeyboardDoubleArrowRightIcon />}
                            >
                                {author.hitonowaId}さんのプロフィールを見る
                            </LinkButton>
                            <ArticleList
                                articles={data.articles.filter((v) => v.talentId === author.id)}
                            />
                        </Box>
                    </>
                )}
            </WaitQuery>
        </Container>
    );
};

const ITEMS_PER_PAGE = 20;

const ArticleList: React.FC<{ articles: NotesArticleSummary[] }> = (props) => {
    const [page, setPage] = useState(1);
    if (props.articles.length === 0) {
        return (
            <Box mt={10} textAlign="center">
                <Typography color="error">まだ記事が書かれていません。</Typography>
            </Box>
        );
    }
    const pinnedArticles = props.articles.filter((v) => v.isPinned);
    const notPinnedArticles = props.articles.filter((v) => !v.isPinned);
    const articlesSlice = sliceItems(notPinnedArticles, page, ITEMS_PER_PAGE);
    return (
        <Box mt={2}>
            <SubTitle title="ピン留めされた記事" />
            <Box mt={2}>
                <PinnedArticles articles={pinnedArticles} />
            </Box>
            <SubTitle title="その他の記事" />
            <Box mt={2}>
                <Pager
                    current={page}
                    setPage={setPage}
                    amount={notPinnedArticles.length}
                    perItems={ITEMS_PER_PAGE}
                />
                {articlesSlice.map((v) => (
                    <ArticleCard key={v.id} article={v} />
                ))}
                <Pager
                    current={page}
                    setPage={setPage}
                    amount={notPinnedArticles.length}
                    perItems={ITEMS_PER_PAGE}
                />
            </Box>
        </Box>
    );
};

const PinnedArticles: React.FC<{ articles: NotesArticleSummary[] }> = (props) => {
    if (props.articles.length === 0) {
        return null;
    }
    return (
        <FlexBox mt={1} mb={3} gap={2}>
            {props.articles.map((v) => (
                <ArticleCard key={v.id} article={v} />
            ))}
        </FlexBox>
    );
};

const ArticleCard: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const v = props.article;
    const author = useAuthor(v);
    return (
        <Card
            square={false}
            sx={{
                flexGrow: 1,
                flexBasis: 0,
            }}
        >
            <CardLink to={getArticleUrl(v.id, author.hitonowaId)}>
                <CardContent sx={{ pb: 0.5, px: 1.5 }}>
                    <FlexBox flexWrap="nowrap" gap={1}>
                        <Box flexGrow={1}>
                            <FlexBox gap={0.5}>
                                <Typography variant="h6" color="link">
                                    {v.title}
                                </Typography>
                                {v.isPinned === true && (
                                    <PushPinIcon fontSize="small" color="secondary" />
                                )}
                            </FlexBox>
                            <FlexBox gap={1}>
                                <IconText icon={AccessTimeIcon}>
                                    {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                                </IconText>
                                <ArticleTags tags={v.tags} />
                            </FlexBox>
                        </Box>
                        <LikesCounter articleId={props.article.id} />
                    </FlexBox>
                </CardContent>
            </CardLink>
        </Card>
    );
};
