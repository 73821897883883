import { Box, Button, ButtonGroup, MenuItem, TextField, Typography } from '@mui/material';
import { EditTodoItemRequest } from '@spec/Todo';
import React, { useRef } from 'react';
import {
    Discipline,
    Form,
    FormTextField,
    useForm,
    useFormDate,
    useFormNumber,
    useFormText,
    Validator,
} from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { OptionalDateTimeInput } from '../../../../DateInput';
import { DialogRow } from '../../../../StableDialog';
import { useTodoItemsContext } from '../../Context';

const dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:[03]0$/;

const regex: Validator = (v) => {
    if (v === '' || v.split('\n').every((vv) => dateTimePattern.test(vv))) {
        return null;
    }
    return 'regex';
};

interface Elements {
    todoCategoryId: number | null;
    title: string;
    description: string;
    deadline: Date | null;
    reminders: string;
}
type TodoItemForm = Form<Elements>;

export const useTodoItemForm = (v: Elements) =>
    useForm<typeof v>({
        todoCategoryId: useFormNumber(v.todoCategoryId, [required]),
        title: useFormText(v.title, [required]),
        description: useFormText(v.description),
        deadline: useFormDate(v.deadline),
        reminders: useFormText(v.reminders, [regex]),
    });

const guard = (v: Elements): v is Discipline<Elements, 'todoCategoryId'> =>
    v.todoCategoryId !== null;

export const toEditTodoItemRequest = (form: TodoItemForm): EditTodoItemRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('invalid parameters');
    }
    return {
        ...args,
        reminders: args.reminders
            .split('\n')
            .filter((v) => dateTimePattern.test(v))
            .map((v) => new Date(v)),
    };
};

export const TodoItemBasicFormContent: React.FC<{ form: TodoItemForm }> = ({ form }) => {
    const { categories } = useTodoItemsContext();
    return (
        <>
            <DialogRow label="カテゴリ">
                <TextField
                    select
                    error={form.todoCategoryId.error !== null}
                    value={form.todoCategoryId.value ?? '-'}
                    onChange={(e) => {
                        const v = Number(e.target.value);
                        form.todoCategoryId.setValue(Number.isNaN(v) ? null : v);
                    }}
                >
                    <MenuItem value="-">未選択</MenuItem>
                    {categories.map((v) => (
                        <MenuItem key={v.id} value={v.id}>
                            {v.label}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogRow>
            <DialogRow label="タイトル">
                <FormTextField autoFocus fullWidth formText={form.title} />
            </DialogRow>
        </>
    );
};

export const TodoItemFormContent: React.FC<{ form: TodoItemForm }> = ({ form }) => {
    const ref = useRef<HTMLTextAreaElement | null>(null);
    const f = (a: string, b: string) => {
        const textarea = ref.current;
        if (textarea === null) {
            return;
        }
        const before = textarea.value.substring(0, textarea.selectionStart);
        const selection = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
        const after = textarea.value.substring(textarea.selectionEnd, textarea.value.length);
        form.description.setValue([before, a, selection, b, after].join(''));
    };
    return (
        <Box>
            <TodoItemBasicFormContent form={form} />
            <DialogRow label="詳細">
                <ButtonGroup variant="outlined" sx={{ my: 1 }}>
                    <Button onClick={() => f(' *', '* ')} sx={{ fontWeight: 'bold' }}>
                        太字
                    </Button>
                    <Button onClick={() => f(' _', '_ ')} sx={{ fontStyle: 'italic' }}>
                        斜体
                    </Button>
                    <Button onClick={() => f(' ~', '~ ')} sx={{ textDecoration: 'line-through' }}>
                        打ち消し
                    </Button>
                    <Button onClick={() => f('<|', '>')}>各種リンク</Button>
                </ButtonGroup>
                <FormTextField
                    InputProps={{ inputRef: ref }}
                    autoFocus
                    fullWidth
                    multiline
                    minRows={8}
                    formText={form.description}
                />
            </DialogRow>
            <DialogRow label="締切日時">
                <OptionalDateTimeInput formDate={form.deadline} label="締切日時を設定する" />
            </DialogRow>
            <DialogRow label="リマインド日時">
                <Typography mb={0.5} variant="body2" color="primary">
                    1行ごとに「2023-02-21 09:00」のように書いてください。設定できるのは毎時
                    {'00, 30'}分のみです。
                </Typography>
                <FormTextField fullWidth multiline minRows={4} formText={form.reminders} />
            </DialogRow>
        </Box>
    );
};
