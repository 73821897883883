import { Box } from '@mui/material';
import { type Secondment } from '@spec/Talent';
import React from 'react';
import { Form, FormTextField, useForm, useFormText } from '../../../../../lib/Form';
import { maxLength, required } from '../../../../../lib/Form/Validators';
import { DialogRow } from '../../../../StableDialog';

type SecondmentForm = Form<Secondment>;

export const useSecondmentForm = (v: Secondment) =>
    useForm<typeof v>({
        assignmentType: useFormText(v.assignmentType, [required, maxLength(191)]),
        companyName: useFormText(v.companyName, [required, maxLength(191)]),
        email: useFormText(v.email, [maxLength(191)]),
    });

export const SecondmentFormContent: React.FC<{ form: SecondmentForm }> = ({ form }) => {
    return (
        <Box>
            <DialogRow label="駐在区分">
                <FormTextField formText={form.assignmentType} fullWidth />
            </DialogRow>
            <DialogRow label="企業名">
                <FormTextField formText={form.companyName} fullWidth />
            </DialogRow>
            <DialogRow label="メールアドレス">
                <FormTextField formText={form.email} fullWidth />
            </DialogRow>
        </Box>
    );
};
