import { Form, FormTextField, useForm, useFormText } from '../../../../lib/Form';
import { required, unique } from '../../../../lib/Form/Validators';
import { DialogRow } from '../../../StableDialog';

interface Elements {
    name: string;
}

export const useWorkplaceForm = (v: Elements, workplaceNames: string[]) => {
    return useForm<typeof v>({
        name: useFormText(v.name, [required, unique(workplaceNames)]),
    });
};

export const WorkplaceFormContext: React.FC<{ form: Form<Elements> }> = ({ form }) => {
    return (
        <DialogRow label="名称">
            <FormTextField
                fullWidth
                formText={form.name}
                helperText={form.name.error === 'unique' ? '登録済みの勤務地です' : null}
            />
        </DialogRow>
    );
};
