import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { ApplicationError } from '../../../Errors';
import { useEmptyForm } from '../../../lib/Form';
import { useCompleteTodoItem } from '../../../queries/todo';
import { useGenericDialogContext } from '../../Context';
import { DialogActionButtons } from '../../StableDialog';
import { useTodoItemContext } from '../Context';

export const ConfirmTodoItem: React.FC = () => {
    const { closeDialog } = useGenericDialogContext<string>();
    const { item } = useTodoItemContext();
    const form = useEmptyForm();
    if (item === null) {
        throw new ApplicationError('Item was not stored');
    }
    const mutation = useCompleteTodoItem(item.id);
    return (
        <>
            <DialogTitle>「{item.title}」を一覧から外しますか？</DialogTitle>
            <DialogContent>
                <Typography>
                    やることを完了させた場合、あるいは対応が不要な場合は一覧から外してください。
                </Typography>
                <Typography mt={1}>
                    外すと一覧に表示されなくなり、Slackリマインドの対象からも外れます。
                </Typography>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="やることの一覧から外す"
                cancelLabel="外さずに閉じる"
                errorMessage="操作に失敗しました"
                onSubmit={mutation.mutateAsync}
                closeDialog={closeDialog}
            />
        </>
    );
};
