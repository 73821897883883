import { Team } from '@spec/Organization';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { getOptionLabel, isTeam, searchItems } from './Domains';
import { ItemCard } from './ItemCard';

type Item = Team | string;

const NEXT_OF_z = '{';

export const TeamSearch: React.FC<
    React.PropsWithChildren<{
        teams: Team[];
        value: Team | null;
        onChange: (v: Team | null) => void;
        required?: true;
    }>
> = (props) => {
    const items: Item[] = [...props.teams].sort((a, b) => {
        const codeA = a.code ?? NEXT_OF_z;
        const codeB = b.code ?? NEXT_OF_z;
        if (codeA < codeB) {
            return -1;
        }
        if (codeA > codeB) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    return (
        <Autocomplete
            fullWidth
            options={items}
            getOptionLabel={(v) => getOptionLabel(v)}
            loadingText={
                <Box textAlign="center">
                    <CircularProgress color="primary" size={20} />
                </Box>
            }
            noOptionsText={
                <Typography variant="body2" color="error">
                    候補が見つかりません
                </Typography>
            }
            renderOption={(p, v) => (
                <li {...p} key={typeof v === 'string' ? v : v.id}>
                    <ItemCard item={v} teams={props.teams} />
                </li>
            )}
            getOptionDisabled={(v) => !isTeam(v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={props.required && props.value === null}
                    placeholder="組織を名前で検索"
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <SearchIcon />
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
            filterOptions={(options, state) => searchItems(state.inputValue, options)}
            value={props.value}
            onChange={(_event, value) => {
                if (isTeam(value)) {
                    props.onChange(props.teams.find((v) => v.id === value.id) ?? null);
                } else {
                    props.onChange(null);
                }
            }}
        />
    );
};
