import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { queryToArray } from '../../../../queries';
import { useAddWorkplace, useWorkplaces } from '../../../../queries/workplace';
import { DialogActionButtons } from '../../../StableDialog';
import { useDialogContext } from '../Context';
import { WorkplaceFormContext, useWorkplaceForm } from './WorkplaceForm';

export const AddWorkplaceDialog: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { closeDialog } = useDialogContext();
    const workplaceNames = queryToArray(useWorkplaces()).map((v) => v.name);
    const form = useWorkplaceForm({ name: '' }, workplaceNames);
    const mutation = useAddWorkplace();
    return (
        <>
            <DialogTitle>勤務地の追加</DialogTitle>
            <DialogContent>
                <WorkplaceFormContext form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で勤務地を追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
