import { ReactNode } from 'react';
import { DialogContextProvider } from '../Context';
import { TodoItemContextProvider } from '../todo/Context';

export type DialogMode = 'ConfirmTodoItem';

export const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <TodoItemContextProvider>{props.children}</TodoItemContextProvider>
        </DialogContextProvider>
    );
};
