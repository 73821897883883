import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import { Team } from '@spec/Organization';
import { Transfer } from '@spec/Talent';
import React, { useContext } from 'react';
import { formatDate } from '../../../../lib/DateUtils';
import { DeleteIconButton, EditIconButton } from '../../../ActionButtons';
import { useTeamsContext } from '../../../Context';
import { TransferHistoryContext, useDialogContext } from './Context';
import { EditTalentProps } from './Contract';

export const TransferHistories: React.FC<React.PropsWithChildren<EditTalentProps>> = (props) => {
    const theme = useTheme();
    const history = props.talent.transferHistory ?? [];
    const { teams } = useTeamsContext();
    return (
        <Box>
            <Typography variant="subtitle1" color="primary">
                異動履歴
            </Typography>
            <Paper
                variant="outlined"
                sx={{
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>所属部署</TableCell>
                            <TableCell>組織コード</TableCell>
                            <TableCell>着任日</TableCell>
                            <TableCell>離任日</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((v) => (
                            <TransferRow key={v.id} transfer={v} teams={teams} />
                        ))}
                    </TableBody>
                </Table>
                <AddHistoryButton />
            </Paper>
        </Box>
    );
};

const isUnexpectedTransfer = (t: Transfer) => {
    if (t.leavedAt === null) {
        return false;
    }
    return t.joinedAt.getTime() > t.leavedAt.getTime();
};

const TransferRow: React.FC<{ transfer: Transfer; teams: Team[] }> = (props) => {
    const theme = useTheme();
    const team = props.teams.find((v) => v.id === props.transfer.teamId);
    if (team === undefined) {
        throw Error(`team ${props.transfer.teamId} was not found`);
    }
    const { setDialogMode } = useDialogContext();
    const context = useContext(TransferHistoryContext);
    return (
        <TableRow>
            <TableCell>{team.name}</TableCell>
            <TableCell>{team.code}</TableCell>
            <TableCell sx={{ width: '10rem' }}>{formatDate(props.transfer.joinedAt)}</TableCell>
            <TableCell
                sx={{
                    width: '10rem',
                    color: isUnexpectedTransfer(props.transfer)
                        ? theme.palette.error.main
                        : undefined,
                }}
            >
                {formatDate(props.transfer.leavedAt)}
            </TableCell>
            <TableCell>
                <EditIconButton
                    onClick={() => {
                        setDialogMode('EditTransfer');
                        context.transfer.setValue(props.transfer);
                    }}
                />
                <DeleteIconButton
                    onClick={() => {
                        setDialogMode('DeleteTransfer');
                        context.transfer.setValue(props.transfer);
                    }}
                />
            </TableCell>
        </TableRow>
    );
};

const AddHistoryButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <Box mt={2} textAlign="center">
            <Button
                size="medium"
                variant="outlined"
                color="primary"
                onClick={() => {
                    setDialogMode('AddTransfer');
                }}
            >
                新しい異動履歴を追加する
            </Button>
        </Box>
    );
};
