import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import React from 'react';
import { isLeftTalent } from '../../../domains/Talent';
import { useMeContext } from '../../../queries/me';
import { SubTitle } from '../../PageTitle';
import { HistoryTable } from './HistoryTable';

export const EmploymentHistory: React.FC<{ talent: Talent }> = (props) => {
    return (
        <Box>
            <SubTitle mt={1} title="入退社履歴" />
            <Box mt={1}>
                <EmploymentsTable talent={props.talent} />
            </Box>
        </Box>
    );
};

const EmploymentsTable: React.FC<{ talent: Talent }> = (props) => {
    const { grants } = useMeContext();
    const privileged = grants.showLeavedTalent;
    const employments = props.talent.employmentHistory ?? [];
    if (employments.length === 0) {
        return <Typography variant="body2">履歴がありません</Typography>;
    }
    return (
        <HistoryTable>
            <TableHead>
                <TableRow>
                    <TableCell>社員番号</TableCell>
                    <TableCell>メールアドレス</TableCell>
                    <TableCell>雇用形態</TableCell>
                    <TableCell>入社区分</TableCell>
                    <TableCell>入社日</TableCell>
                    <TableCell>退職日</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {employments.map((v, i) => (
                    <TableRow key={i}>
                        <TableCell>{v.employeeCode}</TableCell>
                        <TableCell>
                            {isLeftTalent(props.talent) && !privileged ? (
                                <Box color="text.disabled">
                                    退職者のメールアドレスは表示されません
                                </Box>
                            ) : (
                                v.email
                            )}
                        </TableCell>
                        <TableCell>{v.employmentStatus}</TableCell>
                        <TableCell>{v.isNewGraduate ? '新卒' : '中途'}</TableCell>
                        <TableCell>{dayjs(v.joinedAt).format('YYYY年M月D日')}</TableCell>
                        <TableCell>
                            {v.leavedAt ? dayjs(v.leavedAt).format('YYYY年M月D日') : '-'}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </HistoryTable>
    );
};
