import { Route } from 'react-router-dom';
import { AdminDashBoard } from '.';
import { RoutingPattern } from '../../Routes';
import { Layout } from './Layout';
import { LeaveOfAbsences } from './leaveOfAbsences';
import { SabbaticalLeaves } from './sabbaticalLeaves';

export function AdminRoutes() {
    return (
        <Route path={RoutingPattern.admin} element={<Layout />}>
            <Route index element={<AdminDashBoard />} />
            <Route path="sabbatical-leaves" element={<SabbaticalLeaves />} />
            <Route path="leave-of-absences" element={<LeaveOfAbsences />} />
        </Route>
    );
}
