import { TodoCategory } from '@spec/Todo';
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DeleteIconButton, EditIconButton } from '../../../ActionButtons';
import { useTodoItemsContext } from '../Context';
import { useDialogContext, useEditCategoryContext } from './Context';

export const CategoriesTable: React.FC = () => {
    const { categories } = useTodoItemsContext();
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>カテゴリ名</TableCell>
                    <TableCell padding="checkbox"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {categories.map((v) => (
                    <CategoryRow key={v.id} category={v} />
                ))}
            </TableBody>
        </Table>
    );
};

const CategoryRow: React.FC<{ category: TodoCategory }> = (props) => {
    const { setDialogMode } = useDialogContext();
    const { setCategory } = useEditCategoryContext();
    return (
        <TableRow>
            <TableCell>{props.category.label}</TableCell>
            <TableCell>
                <EditIconButton
                    onClick={() => {
                        setCategory(props.category);
                        setDialogMode('EditCategory');
                    }}
                />
                <DeleteIconButton
                    onClick={() => {
                        setCategory(props.category);
                        setDialogMode('DeleteCategory');
                    }}
                />
            </TableCell>
        </TableRow>
    );
};
