import { Alert, Box, Button, Typography } from '@mui/material';
import { SurveyPeriod } from '@spec/Survey';
import { Talent } from '@spec/Talent';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormTextField, useForm, useFormText } from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { postMemo } from '../../../../queries/survey';
import { useGateway } from '../../../../stores/Gateway';
import { FlexBox } from '../../../FlexBox';
import { EditIcon, FeedIcon } from '../../../Icons';
import { SubmitButton } from '../../../SubmitButton';

interface Elements {
    content: string;
}

export const PostMemoForm: React.FC<{ period: SurveyPeriod; talent: Talent }> = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <Box mt={2}>
            <FlexBox gap={2}>
                <Typography color="primary">人事メモ</Typography>
                <Button
                    variant="outlined"
                    startIcon={<FeedIcon />}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    メモを追加する
                </Button>
            </FlexBox>
            {open === true && (
                <FormContent
                    period={props.period}
                    talent={props.talent}
                    onClose={() => setOpen(false)}
                />
            )}
        </Box>
    );
};

const FormContent: React.FC<{
    period: SurveyPeriod;
    talent: Talent;
    onClose: () => void;
}> = (props) => {
    const form = useForm<Elements>({ content: useFormText('', [required]) });
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return (
        <Box mt={1}>
            <FormTextField fullWidth multiline minRows={3} formText={form.content} />
            <Box mt={1}>
                <SubmitButton
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    disabled={!form.canSubmit}
                    inProgress={form.inProgress}
                    onClick={() =>
                        form.send(
                            postMemo(
                                gateway,
                                queryClient,
                                props.period.id,
                                props.talent.id,
                                form.content.value
                            ).then(props.onClose)
                        )
                    }
                >
                    メモを残す
                </SubmitButton>
            </Box>
            {form.failed && (
                <Box mt={2}>
                    <Alert severity="error">メモの書き込みに失敗しました</Alert>
                </Box>
            )}
        </Box>
    );
};
