import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import type { NotesArticle } from '@spec/Notes';
import dayjs from 'dayjs';
import { fullName } from '../../domains/Talent';
import { useNotesArticle } from '../../queries/notes';
import { useTenantContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { AccessTimeIcon } from '../Icons';
import { IconText } from '../IconText';
import { RouterLink } from '../RouterLink';
import { TalentAvatar } from '../TalentAvatar';
import { WaitQuery } from '../WaitLoading';
import { useAuthor } from './Hooks';
import { getArticleUrl } from './urls';

export const ArticleOgp = (props: { url: string }) => {
    const { tenant } = useTenantContext();
    const regex = new RegExp(`^${window.location.origin}/${tenant}/notes/[^/]+/([0-9]+)$`);
    const match = props.url.match(regex);
    if (match === null) {
        return null;
    }
    const articleId = Number(match[1]);
    return <OgpCard articleId={articleId} />;
};

const OgpCard = (props: { articleId: number }) => {
    const maybeArticle = useNotesArticle(props.articleId);
    return (
        <Card
            sx={(theme) => ({
                maxWidth: { xs: '100%', sm: '600px' },
                my: 1,
                p: 0,
                backgroundColor: theme.palette.background.default,
            })}
        >
            <WaitQuery query={maybeArticle}>{({ data }) => <C article={data.article} />}</WaitQuery>
        </Card>
    );
};

const C = (props: { article: NotesArticle }) => {
    const author = useAuthor(props.article);
    const articleUrl = getArticleUrl(props.article.id, author.hitonowaId);
    return (
        <CardActionArea component={RouterLink} to={articleUrl}>
            <CardContent sx={{ p: 0 }}>
                <FlexBox flexWrap="nowrap" gap={1} pl={2}>
                    <Box flexGrow={1}>
                        <Typography variant="h4" mt={0.5} mb={0}>
                            {props.article.title}
                        </Typography>
                        <FlexBox gap={1}>
                            <Typography variant="body2">
                                {fullName(author)}
                                {author.nicknames !== '' && `（${author.nicknames}）`}
                            </Typography>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                        </FlexBox>
                    </Box>
                    <TalentAvatar size="mediumLarge" talent={author} />
                </FlexBox>
            </CardContent>
        </CardActionArea>
    );
};
