import {
    Box,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { ApplicationError } from '../../../Errors';
import { getGitHubUrl, getIamUrl } from '../../../Routes';
import { fullName, getTeamMembers, gradeToString } from '../../../domains/Talent';
import { useCurrentTimeContext, useTalentsContext } from '../../Context';
import { MonthDuration } from '../../MonthDuration';
import { ExternalLink, RouterLink } from '../../RouterLink';
import { comparer, useTableSorter } from '../../SortableTable';
import { UnitText } from '../../UnitText';
import { useShowAdditionalMembersContext } from './Context';

interface Member {
    hitonowaId: string;
    teamId: number;
    name: string;
    employeeCode: string;
    email: string;
    emailAlias: string;
    githubId: string;
    grade: string;
    isNewGraduate: boolean;
    employmentStatus: string;
    position: string;
    joinedAt: Dayjs;
    isAdditionalMember: boolean;
}

const toMember = (t: Talent, currentTeam: Team): Member => ({
    hitonowaId: t.hitonowaId,
    teamId: t.teamId,
    name: fullName(t),
    employeeCode: t.employment.employeeCode,
    email: t.employment.email,
    emailAlias: t.emailAlias,
    githubId: t.githubId ?? '',
    grade: gradeToString(t.grade),
    isNewGraduate: t.isNewGraduate,
    employmentStatus: t.employment.employmentStatus,
    position: findPosition(t, currentTeam),
    joinedAt: dayjs(t.joinedAt),
    isAdditionalMember: t.teamId !== currentTeam.id,
});

export const MemberTable: React.FC<{ team: Team }> = (props) => {
    const { enrolledTalents } = useTalentsContext();
    const { showAdditionalMembers } = useShowAdditionalMembersContext();
    const members = getTeamMembers(enrolledTalents, props.team.id, showAdditionalMembers.value).map(
        (v) => toMember(v, props.team)
    );

    const { sortKey, direction, SortHeader } = useTableSorter<Member>('employeeCode');
    const sortedMembers = members.sort((a, b) =>
        comparer(a, b, sortKey, 'employeeCode', direction)
    );

    if (members.length === 0) {
        return (
            <Box mx={2} color="text.disabled">
                <Typography variant="body2">現在この部署に在籍している人はいません</Typography>
            </Box>
        );
    }
    return (
        <Box mx={2}>
            <Typography>
                {members.length}
                <UnitText text="名" />
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortHeader
                            padding="none"
                            sx={{ width: '2rem' }}
                            sortKey="isAdditionalMember"
                        />
                        <SortHeader sortKey="name">氏名</SortHeader>
                        <SortHeader sortKey="employeeCode">社員番号</SortHeader>
                        <SortHeader sortKey="email">メールアドレス</SortHeader>
                        <SortHeader sortKey="emailAlias">メールエイリアス</SortHeader>
                        <SortHeader sortKey="githubId">GitHub ID</SortHeader>
                        <SortHeader sortKey="grade">グレード</SortHeader>
                        <SortHeader sortKey="employmentStatus">雇用形態</SortHeader>
                        <SortHeader sortKey="position">役職</SortHeader>
                        <SortHeader sortKey="joinedAt">入社歴</SortHeader>
                        <SortHeader sortKey="joinedAt">入社</SortHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedMembers.map((v) => (
                        <TalentRow key={v.hitonowaId} member={v} team={props.team} />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

const findPosition = (talent: Talent, team: Team): string => {
    if (talent.teamId === team.id) {
        return talent.position;
    }
    const post = talent.additionalPosts.find((v) => v.teamId === team.id);
    if (post === undefined) {
        throw new ApplicationError('Post was not found');
    }
    return post.position;
};

const TalentRow: React.FC<{ member: Member; team: Team }> = (props) => {
    const { currentTime } = useCurrentTimeContext();
    const member = props.member;
    return (
        <TableRow>
            <TableCell padding="none" align="center">
                {member.isAdditionalMember === true && (
                    <Chip label="兼" size="small" sx={{ fontWeight: 400 }} />
                )}
            </TableCell>
            <TableCell>
                <RouterLink to={getIamUrl(member.hitonowaId)} variant="body2">
                    {member.name}
                </RouterLink>
            </TableCell>
            <TableCell>{member.employeeCode}</TableCell>
            <TableCell>{member.email}</TableCell>
            <TableCell>{member.emailAlias}</TableCell>
            <TableCell>
                {member.githubId !== '' && (
                    <ExternalLink variant="body2" href={getGitHubUrl(member.githubId)}>
                        {member.githubId}
                    </ExternalLink>
                )}
            </TableCell>
            <TableCell>{member.grade}</TableCell>
            <TableCell>{member.employmentStatus}</TableCell>
            <TableCell>{member.position}</TableCell>
            <TableCell>
                <MonthDuration now={currentTime} date={member.joinedAt.toDate()} />
            </TableCell>
            <TableCell>
                <JoinedAt joinedAt={member.joinedAt} isNewGraduate={member.isNewGraduate} />
            </TableCell>
        </TableRow>
    );
};

const JoinedAt: React.FC<{ joinedAt: Dayjs; isNewGraduate: boolean }> = (props) => {
    const joinedAt = props.joinedAt;
    return props.isNewGraduate ? (
        <>
            {joinedAt.format('YY')}
            <UnitText text="新卒" />
        </>
    ) : (
        <>
            {joinedAt.format('YYYY')}
            <UnitText text="年" />
            {joinedAt.format('M')}
            <UnitText text="月" />
        </>
    );
};
