import { Box, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getTalentUrl } from '../../../../Routes';
import { fullName } from '../../../../domains/Talent';
import { useEmptyForm } from '../../../../lib/Form';
import { useSetDefaultImage } from '../../../../queries/talent';
import { ActionButton, ActionContainer, LinkButton } from '../../../ActionButtons';
import { BeforeIcon, DeleteIcon } from '../../../Icons';
import { DialogActionButtons } from '../../../StableDialog';
import { UploadProfileImage } from '../../UploadProfileImage';
import { EditTalentProps } from '../edit/Contract';

export const UploadDefaultImage: React.FC<EditTalentProps> = (props) => {
    const [updated, setUpdated] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const form = useEmptyForm();
    const onClose = useCallback(() => setDialogOpen(false), [setDialogOpen]);
    const mutation = useSetDefaultImage(props.talent.employment.employeeCode);
    return (
        <Box>
            <Box>
                <LinkButton
                    to={getTalentUrl(props.talent.employment.employeeCode)}
                    startIcon={<BeforeIcon />}
                >
                    プロフィールに戻る
                </LinkButton>
            </Box>
            <UploadProfileImage
                talent={props.talent}
                onUpload={(blob) =>
                    mutation.mutateAsync(blob).then(() => {
                        setUpdated(true);
                    })
                }
            />
            <Box mt={6}>
                <Divider />
            </Box>
            <ActionContainer>
                <ActionButton
                    startIcon={<DeleteIcon />}
                    disabled={props.talent.profileImagePath === null && updated === false}
                    onClick={() => setDialogOpen(true)}
                >
                    画像を削除する
                </ActionButton>
            </ActionContainer>
            {deleted && <Navigate to={getTalentUrl(props.talent.employment.employeeCode)} />}
            <Dialog open={dialogOpen} maxWidth="sm" fullWidth onClose={onClose}>
                <DialogTitle>プロフィール画像の削除</DialogTitle>
                <DialogContent>
                    <Typography>
                        {fullName(props.talent)}さんに設定したプロフィール画面を削除します
                    </Typography>
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    startIcon={<DeleteIcon />}
                    submitLabel="画像を削除する"
                    cancelLabel="キャンセル"
                    errorMessage="削除に失敗しました"
                    onSubmit={() =>
                        mutation.mutateAsync(null).then(() => {
                            setDeleted(true);
                        })
                    }
                    closeDialog={onClose}
                />
            </Dialog>
        </Box>
    );
};
