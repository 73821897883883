import { FormValue } from './Contract';

export const canFormSubmit = (elements: FormValue<any>[]): boolean =>
    hasDirtyElement(elements) && !hasErrorElement(elements);

export const hasDirtyElement = (elements: FormValue<any>[]): boolean =>
    elements.some((v) => v.dirty === true);

export const hasErrorElement = (elements: FormValue<any>[]): boolean =>
    elements.some((v) => v.error !== null);
