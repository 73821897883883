import { Team } from '@spec/Organization';
import React, { useEffect, useState } from 'react';
import { FormNumber } from '../lib/Form';
import { useTeamsContext } from './Context';
import { TeamSearch } from './search/TeamSearch';

export const TeamSelector: React.FC<{ teamId: FormNumber; required?: true }> = (props) => {
    const { teams } = useTeamsContext();
    const [currentTeam, setCurrentTeam] = useState<Team | null>(
        teams.find((v) => v.id === props.teamId.value) ?? null
    );
    useEffect(() => {
        const teamId = currentTeam !== null ? currentTeam.id : null;
        if (teamId !== props.teamId.value) {
            props.teamId.setValue(teamId);
        }
    }, [currentTeam, props.teamId]);
    return (
        <TeamSearch
            teams={teams}
            value={currentTeam}
            onChange={setCurrentTeam}
            required={props.required}
        />
    );
};
