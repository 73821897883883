import { Box, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddTodoItem } from '../../../../../queries/todoAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { getTodoItemAdminUrl } from '../../../urls';
import { useDialogContext } from '../Context';
import { TodoItemBasicFormContent, toEditTodoItemRequest, useTodoItemForm } from './TodoItemForm';

export const AddTodoItemDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const form = useTodoItemForm({
        todoCategoryId: null,
        title: '',
        description: '',
        deadline: null,
        reminders: '',
    });
    const mutation = useAddTodoItem();
    const navigate = useNavigate();
    return (
        <>
            <DialogTitle>やることの追加</DialogTitle>
            <DialogContent>
                <TodoItemBasicFormContent form={form} />
                <Box mt={2} textAlign="center">
                    <Typography>
                        カテゴリとタイトル以外の項目は作成後に設定してください。
                    </Typography>
                    <Typography mt={0.5}>ここで設定した内容も後から自由に変更できます。</Typography>
                </Box>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でやることを追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="追加に失敗しました"
                onSubmit={() =>
                    mutation
                        .mutateAsync(toEditTodoItemRequest(form))
                        .then((res) => navigate(getTodoItemAdminUrl(res.todoItemId)))
                }
                closeDialog={closeDialog}
            />
        </>
    );
};
