import { Workplace } from '@spec/Workplace';
import React, { createContext } from 'react';
import { StateValue, useStateValue } from '../../../lib/Context';
import { useGenericDialogContext, DialogContextProvider } from '../../Context';

export type DialogMode = 'Add' | 'Edit' | 'Delete';
export const useDialogContext = useGenericDialogContext<DialogMode>;

export const WorkplaceContext = createContext(
    {} as {
        workplace: StateValue<Workplace | null>;
    }
);
const WorkplaceContextProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const workplace = useStateValue<Workplace | null>(null);
    return (
        <WorkplaceContext.Provider value={{ workplace }}>
            {props.children}
        </WorkplaceContext.Provider>
    );
};

export const ContextProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    return (
        <DialogContextProvider>
            <WorkplaceContextProvider>{props.children}</WorkplaceContextProvider>
        </DialogContextProvider>
    );
};
