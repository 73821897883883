import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { SurveyQuestion, SurveyResponse } from '@spec/Survey';
import { isBooleanQuestion, isBooleanResponse, isTextResponse } from '../../../../domains/Survey';

export const ResponseCard: React.FC<{
    question: SurveyQuestion;
    response: SurveyResponse;
}> = (props) => {
    return (
        <Card>
            <CardContent>
                <Typography variant="body2">{props.question.shortTitle}</Typography>
                <Box my={0.5}>
                    <Divider />
                </Box>
                {isTextResponse(props.response) && (
                    <Typography variant="body2">{props.response.message}</Typography>
                )}
                {isBooleanQuestion(props.question) &&
                    isBooleanResponse(props.response) &&
                    props.response.checked === true && (
                        <Typography variant="body2">{props.question.label}</Typography>
                    )}
            </CardContent>
        </Card>
    );
};
