import { Box, Typography } from '@mui/material';
import { TodoItem } from '@spec/Todo';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { queryToArray } from '../../queries';
import { useMeContext } from '../../queries/me';
import { useAssignedTodoItems } from '../../queries/todo';
import { ActionButton, ActionContainer } from '../ActionButtons';
import { SettingsIcon } from '../Icons';
import { NoItems } from '../NoItems';
import { SubTitle } from '../PageTitle';
import { ContextualDialog, DialogComponents } from '../StableDialog';
import { WaitLoading } from '../WaitLoading';
import { DialogMode, useTodoCategoriesContext } from './Context';
import { TodoItemCard } from './TodoItemCard';
import { ConfirmTodoItem } from './dialogs/ConfirmTodoItem';

export const Index: React.FC = () => {
    const maybeItems = useAssignedTodoItems();
    return (
        <Box>
            <WaitLoading waitFor={[maybeItems]}>
                <TodoItems items={queryToArray(maybeItems)} />
            </WaitLoading>
            <ManageLink />
            <ContextualDialog components={dialogComponents} />
        </Box>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    ConfirmTodoItem: ConfirmTodoItem,
};

const TodoItems: React.FC<{ items: TodoItem[] }> = (props) => {
    const { categories } = useTodoCategoriesContext();
    const location = useLocation();
    const maybeCurrentId = Number(location.hash.replace(/^#/, ''));
    return (
        <Box>
            <SubTitle title="あなたのやること" />
            {props.items.length === 0 && (
                <NoItems mt={4}>現在、登録されているやることはありません</NoItems>
            )}
            <Box mt={2}>
                {props.items.map((item) => (
                    <TodoItemCard
                        key={item.id}
                        item={item}
                        categories={categories}
                        expanded={item.id === maybeCurrentId}
                    />
                ))}
            </Box>
        </Box>
    );
};

const ManageLink: React.FC = () => {
    const { grants } = useMeContext();
    return (
        <Box>
            {grants.manageTodo === true && (
                <ActionContainer>
                    <Typography variant="body2" color="primary">
                        あなたは「やること管理」の権限を持っているので管理者画面を開けます。
                    </Typography>
                    <ActionButton startIcon={<SettingsIcon />} to={RoutingPattern.todoAdmin}>
                        管理者画面を開く
                    </ActionButton>
                </ActionContainer>
            )}
        </Box>
    );
};
