import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { SurveyPeriod } from '@spec/Survey';
import dayjs from 'dayjs';
import React from 'react';
import { sortByKey } from '../../../../lib/ArrayUtils';
import { DeleteIconButton, EditIconButton } from '../../../ActionButtons';
import { LinkIcon } from '../../../Icons';
import { NoItems } from '../../../NoItems';
import { useDialogContext, useEditPeriodContext } from './Context';

export const PeriodsTable: React.FC<{ periods: SurveyPeriod[] }> = (props) => {
    if (props.periods.length === 0) {
        return <NoItems my={4}>実施期間はまだありません</NoItems>;
    }
    const periods = sortByKey(props.periods, 'openedAt', 'desc');
    return (
        <Paper variant="outlined">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>名称</TableCell>
                        <TableCell>開始日時</TableCell>
                        <TableCell>終了日時</TableCell>
                        <TableCell>設問</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {periods.map((v) => (
                        <PeriodRow key={v.id} period={v} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

const PeriodRow: React.FC<{ period: SurveyPeriod }> = (props) => {
    const { setDialogMode } = useDialogContext();
    const { period } = useEditPeriodContext();
    const v = props.period;
    return (
        <TableRow>
            <TableCell>{v.name}</TableCell>
            <TableCell>{dayjs(v.openedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
            <TableCell>{dayjs(v.closedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
            <TableCell>{v.questions.map((q) => q.shortTitle).join(', ')}</TableCell>
            <TableCell align="right">
                <EditIconButton
                    onClick={() => {
                        period.setValue(v);
                        setDialogMode('EditPeriod');
                    }}
                />
                <IconButton
                    size="small"
                    onClick={() => {
                        period.setValue(v);
                        setDialogMode('LinkQuestions');
                    }}
                >
                    <LinkIcon fontSize="small" />
                </IconButton>
                <DeleteIconButton
                    onClick={() => {
                        period.setValue(v);
                        setDialogMode('DeletePeriod');
                    }}
                />
            </TableCell>
        </TableRow>
    );
};
