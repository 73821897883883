import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { SurveyPermissions } from '@spec/Grants';
import { Survey } from '@spec/Survey';
import React from 'react';
import { accessibleSurveyGroupIds, getReadableTeams } from '../../domains/Survey';
import { findById } from '../../lib/ArrayUtils';
import { useMeContext } from '../../queries/me';
import { ActionButton, ActionContainer } from '../ActionButtons';
import { useTeamsContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { SettingsIcon, SpeakerNotesIcon } from '../Icons';
import { SubTitle } from '../PageTitle';
import { RouterLink } from '../RouterLink';
import { useSurveysContext } from './Context';
import { ActiveSurveys } from './list';
import { getReportUrl } from './urls';

export const Index: React.FC = () => {
    const { grants } = useMeContext();
    const permissions = grants.survey;
    return (
        <Box>
            <Box mx={2}>
                <ActiveSurveys />
                <ReportList permissions={permissions} />
            </Box>
            {grants.manageSurvey === true && (
                <ActionContainer>
                    <Typography variant="body2" color="primary">
                        あなたは「サーベイ管理」の権限を持っているので管理者画面を開けます。
                    </Typography>
                    <ActionButton startIcon={<SettingsIcon />} to="admin">
                        管理者画面を開く
                    </ActionButton>
                </ActionContainer>
            )}
        </Box>
    );
};

const ReportList: React.FC<{ permissions: SurveyPermissions }> = (props) => {
    const { surveys } = useSurveysContext();
    const groupIds = accessibleSurveyGroupIds(surveys, props.permissions);
    if (groupIds.length === 0) {
        return null;
    }
    return (
        <Box mt={2}>
            <SubTitle title="レポート閲覧" />
            <Box mx={2}>
                <Typography my={2} variant="body2">
                    あなたは以下のサーベイグループのレポートを参照できます。権限の設定に不明点などがあればお近くの人事までご連絡ください。
                </Typography>
                {groupIds.map((groupId) => (
                    <ReportLinkButton
                        key={groupId}
                        groupId={groupId}
                        permissions={props.permissions}
                    />
                ))}
            </Box>
        </Box>
    );
};

const ReportLinkButton: React.FC<{ groupId: number; permissions: SurveyPermissions }> = (props) => {
    const { surveys, surveyGroups } = useSurveysContext();
    const group = findById(props.groupId, surveyGroups);
    const reportUrl = getReportUrl(group.id);
    return (
        <Card>
            <CardContent>
                <RouterLink to={reportUrl}>
                    <Typography variant="h6">{group.name}</Typography>
                </RouterLink>
                <Typography mt={0.5} variant="body2">
                    レポート閲覧可能な範囲
                </Typography>
                <FlexBox mt={0.5} alignItems="flex-start" gap={2}>
                    {surveys
                        .filter((v) => v.surveyGroupId === props.groupId)
                        .map((v) => (
                            <ReadableTeams key={v.id} survey={v} permissions={props.permissions} />
                        ))}
                </FlexBox>
                <ActionContainer>
                    <ActionButton startIcon={<SpeakerNotesIcon />} to={reportUrl}>
                        レポートを見る
                    </ActionButton>
                </ActionContainer>
            </CardContent>
        </Card>
    );
};

const ReadableTeams: React.FC<{ survey: Survey; permissions: SurveyPermissions }> = (props) => {
    const { teams } = useTeamsContext();
    const readableTeams = getReadableTeams(props.survey, teams, props.permissions);
    if (readableTeams.length === 0) {
        return null;
    }
    return (
        <Box sx={{ width: 200 }}>
            <Typography variant="body2">{props.survey.name}</Typography>
            <Divider />
            <Box mt={0.5} ml={1}>
                {readableTeams.map((v) => (
                    <Typography key={v.id} variant="body2">
                        {v.name}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};
