import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTalentsContext } from '../Context';
import { NewcomerList } from '../talent/newcomers/NewcomerList';

export const Newcomers: React.FC = () => {
    const { talents } = useTalentsContext();
    const latest = dayjs(Math.max(...talents.map((v) => v.joinedAt.getTime())));
    if (latest.isValid() === false) {
        throw new Error(`The latest talent was not found`);
    }
    const year = latest.year();
    const month = latest.month() + 1;
    return (
        <Box>
            <NewcomerList year={year} month={month} />
        </Box>
    );
};
