import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { Talent } from '@spec/Talent';
import { getTalentUrl } from '../../../Routes';
import { fullName } from '../../../domains/Talent';
import { LinkButton } from '../../ActionButtons';
import { IconCircle } from '../../IconCircle';
import { HowToRegIcon } from '../../Icons';

export const RenewedTalent = (props: { talent: Talent }) => {
    const talent = props.talent;
    return (
        <Box my={10} textAlign="center">
            <Box mb={4}>
                <IconCircle icon={HowToRegIcon} color={grey[400]} />
            </Box>
            <Box mb={3}>
                <Typography variant="h6">
                    {fullName(talent)}さんの現在の社員番号は
                    {talent.employment.employeeCode}です
                </Typography>
            </Box>
            <LinkButton
                variant="outlined"
                size="large"
                to={getTalentUrl(talent.employment.employeeCode)}
            >
                プロフィールを見る
            </LinkButton>
        </Box>
    );
};
