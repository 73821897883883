import { Box, CssBaseline, ThemeProvider, useTheme } from '@mui/material';
import React from 'react';
import { Footer } from './Footer';

export const NoMenuLayout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const theme = useTheme();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: '100vh',
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {props.children}
                </Box>
                <Footer />
            </Box>
        </ThemeProvider>
    );
};
