import { PersonalEvent } from '@spec/Talent';
import { ReactNode, createContext, useContext, useState } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../../Context';

export type DialogMode = 'Add' | 'Edit' | 'Delete';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const PersonalEventHistoryContext = createContext(
    {} as {
        personalEvent: PersonalEvent | null;
        setPersonalEvent: (value: PersonalEvent | null) => void;
    }
);
const PersonalEventHistoryContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [personalEvent, setPersonalEvent] = useState<PersonalEvent | null>(null);
    return (
        <PersonalEventHistoryContext.Provider value={{ personalEvent, setPersonalEvent }}>
            {props.children}
        </PersonalEventHistoryContext.Provider>
    );
};
export const usePersonalEventHistoryContext = () => useContext(PersonalEventHistoryContext);

export const ContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    return (
        <DialogContextProvider>
            <PersonalEventHistoryContextProvider>
                {props.children}
            </PersonalEventHistoryContextProvider>
        </DialogContextProvider>
    );
};
