import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { queryToArray } from '../../queries';
import { useApiReferences } from '../../queries/apiReference';
import { TerminalIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { WaitLoading } from '../WaitLoading';
import { BackToIndex } from './BackToIndex';
import { ContextProvider } from './Context';

export const Layout: React.FC = () => {
    const maybeReferences = useApiReferences();
    return (
        <Box>
            <PageTitle icon={TerminalIcon} title="APIリファレンス" />
            <Box my={2}>
                <BackToIndex />
            </Box>
            <WaitLoading waitFor={[maybeReferences]}>
                <ContextProvider apis={queryToArray(maybeReferences)}>
                    <Outlet />
                </ContextProvider>
            </WaitLoading>
        </Box>
    );
};
