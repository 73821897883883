import {
    Box,
    Card,
    CardContent,
    Chip,
    ChipTypeMap,
    Divider,
    TableCell,
    TableRow,
    Typography,
    tableCellClasses,
    tableHeadClasses,
    useTheme,
} from '@mui/material';
import { ApiReference } from '@spec/ApiReference';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApplicationError, ParameterError } from '../../Errors';
import { privilegeLabelMap } from '../../domains/Grants';
import { StrictMap } from '../../lib/MapUtils';
import { FlexBox } from '../FlexBox';
import { SortableTable } from '../SortableTable';
import { useApiReferenceContext } from './Context';

type Ref = React.MutableRefObject<HTMLDivElement | null>;
type RefsMap = Map<string, Ref | null>;

export const Category: React.FC = () => {
    const { category } = useParams();
    const [refs, setRefs] = useState<RefsMap>(new Map());
    const { references } = useApiReferenceContext();
    if (category === undefined) {
        throw new ParameterError('unknown category');
    }
    const endpoints = references.get(category);
    if (endpoints === undefined) {
        throw new ParameterError('unknown category');
    }
    return (
        <Box mt={4}>
            <Typography mt={3} mb={1} variant="h5">
                {category} APIs
            </Typography>
            <Index refs={refs} references={endpoints} />
            <Example />
            <Box mt={2}>
                <Divider />
            </Box>
            <Box key={category}>
                {endpoints.map((v) => (
                    <Box key={v.endpoint}>
                        <EndPoint
                            api={v}
                            addRef={(ref: Ref) => {
                                setRefs((prev) => {
                                    const next = new Map(prev);
                                    next.set(v.endpoint, ref);
                                    return next;
                                });
                            }}
                        />
                        <Box mt={2}>
                            <Divider />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const Path: React.FC<{ path: string; requiredPrivileges: string[] }> = (props) => {
    return (
        <FlexBox pt={2} mx={2} gap={2}>
            <Typography
                variant="h6"
                sx={{
                    fontFamily: '"Ubuntu Mono", monospace',
                    fontWeight: 700,
                }}
            >
                {props.path}
            </Typography>
            {props.requiredPrivileges.map((v) => (
                <RequiredPrivilege key={v} privilege={v} />
            ))}
        </FlexBox>
    );
};

const RequiredPrivilege: React.FC<{ privilege: string }> = (props) => {
    const theme = useTheme();
    return (
        <Typography
            variant="caption"
            sx={{
                px: 1,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.light,
                fontWeight: 700,
            }}
        >
            {props.privilege}
        </Typography>
    );
};

const SampleCode: React.FC<{ genre: 'Request' | 'Response'; code: string }> = (props) => {
    const theme = useTheme();
    const backgroundColor =
        props.genre === 'Request' ? theme.palette.secondary.dark : theme.palette.primary.dark;
    return (
        <Box my={1} mx={2}>
            <Typography
                variant="caption"
                sx={{
                    px: 1,
                    display: 'inline-block',
                    fontWeight: 700,
                    color: theme.palette.primary.contrastText,
                    backgroundColor,
                }}
            >
                {props.genre}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    p: 1,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    whiteSpace: 'pre',
                    fontFamily: '"Ubuntu Mono", monospace',
                }}
            >
                {props.code}
            </Typography>
        </Box>
    );
};

const Example: React.FC = () => {
    return (
        <Box>
            <Path path="METHOD endpoint" requiredPrivileges={['必要な権限']} />
            <Typography mt={1} mx={2} variant="body2">
                APIの概要
            </Typography>
            <SampleCode genre="Request" code="Requestのサンプル" />
            <SampleCode genre="Response" code="Responseのサンプル" />
        </Box>
    );
};

const EndPoint: React.FC<{ api: ApiReference; addRef: (ref: Ref) => void }> = ({ api, addRef }) => {
    const ref = useRef(null);
    useEffect(() => {
        addRef(ref);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div ref={ref}>
            <Path
                path={api.endpoint}
                requiredPrivileges={(api.permissions ?? []).map((v) => privilegeLabelMap.get(v))}
            />
            {api.description && <Description description={api.description} />}
            {api.sampleRequest && (
                <SampleCode genre="Request" code={JSON.stringify(api.sampleRequest, null, 4)} />
            )}
            {api.sampleResponse && (
                <SampleCode genre="Response" code={JSON.stringify(api.sampleResponse, null, 4)} />
            )}
        </div>
    );
};

const Description: React.FC<{ description: string | string[] }> = (props) => {
    const description = [props.description].flat();
    return (
        <Box>
            {description.map((v, i) => (
                <Typography key={i} mt={1} mx={2} variant="body2">
                    {v}
                </Typography>
            ))}
        </Box>
    );
};

const methodIndex = new StrictMap([
    ['GET', 0],
    ['POST', 1],
    ['PUT', 2],
    ['DELETE', 3],
]);

interface ApiIndex {
    id: string;
    method: string;
    methodIndex: number;
    path: string;
    requiredPrivileges: string[];
}

const toIndex = (v: ApiReference): ApiIndex => {
    const [method, ...path] = v.endpoint.split(' ');
    return {
        id: v.endpoint,
        method,
        methodIndex: methodIndex.get(method),
        path: path.join(' '),
        requiredPrivileges: v.permissions ?? [],
    };
};

const Index: React.FC<{
    refs: RefsMap;
    references: ApiReference[];
}> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const key = decodeURIComponent(location.hash).replace('#', '');
        if (key) {
            const ref = props.refs.get(key);
            if (ref?.current) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, [props.refs, location.hash]);
    const indices = props.references.map(toIndex);
    return (
        <Card>
            <CardContent sx={{ p: 1 }}>
                <SortableTable
                    headers={[
                        ['methodIndex', 'method'],
                        ['path', 'path'],
                        ['requiredPrivileges', 'required privileges'],
                    ]}
                    defaultSortKey="path"
                    defaultSortDirection="asc"
                    secondarySortKey="method"
                    rows={indices}
                    sx={{
                        [`& .${tableHeadClasses.root} .${tableCellClasses.root}:nth-child(2)`]: {
                            paddingLeft: 0,
                        },
                    }}
                >
                    {(row) => (
                        <TableRow
                            hover
                            onClick={() => {
                                navigate(`${location.pathname}#${encodeURIComponent(row.id)}`);
                            }}
                        >
                            <IndexRow index={row} />
                        </TableRow>
                    )}
                </SortableTable>
            </CardContent>
        </Card>
    );
};

type ChipColor = ChipTypeMap['props']['color'];

const IndexRow: React.FC<{ index: ApiIndex }> = (props) => {
    const { method, path } = props.index;
    const getColor = (method: string): ChipColor => {
        switch (method) {
            case 'GET':
                return 'success';
            case 'POST':
                return 'warning';
            case 'PUT':
                return 'info';
            case 'DELETE':
                return 'error';
            default:
                throw new ApplicationError(`unknown HTTP method: ${method}`);
        }
    };
    const color = getColor(method);
    return (
        <>
            <TableCell sx={{ border: 'none', width: '4rem', py: 1 }}>
                <Chip color={color} label={method} sx={{ width: '4rem' }} />
            </TableCell>
            <TableCell sx={{ border: 'none', pl: 0 }}>{path}</TableCell>
            <TableCell sx={{ border: 'none' }}>
                {props.index.requiredPrivileges.map((v) => (
                    <RequiredPrivilege key={v} privilege={v} />
                ))}
            </TableCell>
        </>
    );
};
