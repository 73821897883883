import { Autocomplete, TextField, Typography } from '@mui/material';
import { sortByKey } from '../../../lib/ArrayUtils';
import { FormText } from '../../../lib/Form';
import { useSlackChannels } from '../../../queries/slack';
import { SearchIcon } from '../../Icons';
import { WaitQuery } from '../../WaitLoading';

export const SlackChannelSelector: React.FC<{ formText: FormText }> = (props) => {
    const current = props.formText.value;
    const maybeChannels = useSlackChannels();
    return (
        <WaitQuery query={maybeChannels}>
            {({ data }) => (
                <Autocomplete
                    fullWidth
                    options={sortByKey(data, 'name')}
                    getOptionLabel={(v) => v.name}
                    noOptionsText={
                        <Typography variant="body2" color="error">
                            候補が見つかりません
                        </Typography>
                    }
                    renderOption={(p, v) => (
                        <li {...p} key={v.id}>
                            {v.name}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="チャンネル名で検索"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            <SearchIcon />
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                },
                            }}
                        />
                    )}
                    value={data.find((v) => v.id === current) ?? null}
                    onChange={(_e, v) => {
                        props.formText.setValue(v?.id ?? '');
                    }}
                />
            )}
        </WaitQuery>
    );
};
