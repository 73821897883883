import { DialogContent, DialogTitle } from '@mui/material';
import { useAddSecondment } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useEditTalentContext } from '../Context';
import { SecondmentFormContent, useSecondmentForm } from './SecondmentForm';

export const AddSecondmentDialog = () => {
    const { closeDialog } = useDialogContext();
    const { employeeCode } = useEditTalentContext();
    const form = useSecondmentForm({
        assignmentType: '',
        companyName: '',
        email: '',
    });
    const mutation = useAddSecondment(employeeCode);
    return (
        <>
            <DialogTitle>出向関連情報の追加</DialogTitle>
            <DialogContent>
                <SecondmentFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で出向関連情報を追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="出向関連情報の追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
