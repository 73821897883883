import { Team } from '@spec/Organization';
import { Talent, TalentId } from '@spec/Talent';
import { TodoAssignment } from '@spec/Todo';
import dayjs, { Dayjs } from 'dayjs';
import { findBaseTeam } from '../../../../domains/Organization';
import { fullName } from '../../../../domains/Talent';
import { findById } from '../../../../lib/ArrayUtils';

export interface Assignee {
    id: TalentId;
    employeeCode: string;
    name: string;
    joinedAt: Dayjs;
    isNewGraduate: boolean;
    employmentStatus: string;
    teamName: string;
    completedAt: Dayjs | null;
}

export const toAssignee = (t: Talent, teams: Team[], assignment?: TodoAssignment): Assignee => {
    const team = findById(t.teamId, teams);
    const baseTeam = findBaseTeam(t, teams);
    return {
        id: t.id,
        employeeCode: t.employment.employeeCode,
        name: fullName(t),
        joinedAt: dayjs(t.joinedAt),
        isNewGraduate: t.isNewGraduate,
        employmentStatus: t.employment.employmentStatus,
        teamName: baseTeam ? `${baseTeam.name} > ${team.name}` : team.name,
        completedAt: assignment?.completedAt ? dayjs(assignment.completedAt) : null,
    };
};
