import { RoutingPattern } from '../../Routes';

export const getArticleUrl = (articleId: number, hitonowaId?: string, comment?: boolean) => {
    const path = hitonowaId === undefined ? 'article' : `@${hitonowaId}`;
    const hash = comment ? '#comment' : '';
    return `${RoutingPattern.notes}/${path}/${articleId}${hash}`;
};
export const getArticleEditUrl = (articleId: number, hitonowaId?: string) =>
    `${getArticleUrl(articleId, hitonowaId)}/edit`;

export const newPostUrl = `${RoutingPattern.notes}/post`;

export const getAuthorUrl = (hitonowaId: string) => `${RoutingPattern.notes}/@${hitonowaId}`;

export const getTagUrl = (tag: string) => `${RoutingPattern.notes}/tag/${tag}`;

/**
 * When the user is editing an article, this function returns the URL of the article page.
 */
export const exitArticleEditUrl = (pathname: string): string | null => {
    const regex = new RegExp(`${RoutingPattern.notes}/([^/]+)/(\\d+)/edit`);
    const result = regex.exec(pathname);
    if (result === null) {
        return null;
    }
    const hitonowaId = result[1].startsWith('@') ? result[1].slice(1) : undefined;
    return getArticleUrl(Number(result[2]), hitonowaId);
};

export const getSearchUrl = (word: string) => `${RoutingPattern.notes}/search?word=${word}`;
