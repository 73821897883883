import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { createContext, useContext, useState, type ReactNode } from 'react';
import { z } from 'zod';
import { colorThemes } from '../../colors';
import { hasStorageKey, useStorageJson } from '../../lib/Storage';
import { useTenantContext } from '../Context';
import { PaletteIcon } from '../Icons';

const STORAGE_KEY = 'theme';

const ThemeContext = createContext({} as { theme: string; setTheme: (v: string) => void });
export const useThemeContext = () => useContext(ThemeContext);
export const ThemeContextProvider = (props: { children: ReactNode }) => {
    const { tenant } = useTenantContext();
    const [theme, setTheme] = useStorageJson(STORAGE_KEY, {}, z.record(z.string()));
    return (
        <ThemeContext.Provider
            value={{ theme: theme[tenant] ?? 'hitonowa', setTheme: (v) => setTheme(tenant, v) }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};

export const ThemeSelector = () => {
    const { theme, setTheme } = useThemeContext();
    const themes = Object.keys(colorThemes);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setTheme(theme);
        setAnchorEl(null);
    };
    const handleMenuItemClick = (selected: string) => {
        setTheme(selected);
        setAnchorEl(null);
    };

    const [openTooltip, setOpenTooltip] = useState(() => !hasStorageKey(STORAGE_KEY));

    return (
        <>
            <Tooltip
                arrow
                title="テーマが選択できるようになりました。"
                open={openTooltip}
                onClose={() => setOpenTooltip(false)}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -8],
                                },
                            },
                        ],
                    },
                }}
            >
                <IconButton onClick={handleClick}>
                    <PaletteIcon sx={(theme) => ({ color: theme.palette.nav.icon })} />
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick('hitonowa')}>hitonowa</MenuItem>
                {themes.map((v) => (
                    <MenuItem key={v} selected={v === theme} onClick={() => handleMenuItemClick(v)}>
                        {v}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
