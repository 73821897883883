import { ApiReference } from '@spec/ApiReference';
import { createContext, ReactNode, useContext } from 'react';
import { sortByKey } from '../../lib/ArrayUtils';

type ReferenceMap = Map<string, ApiReference[]>;

const buildReferenceMap = (apis: ApiReference[]): ReferenceMap => {
    const res: ReferenceMap = new Map();
    for (const api of sortByKey(apis, 'category')) {
        if (api.category !== undefined) {
            res.set(api.category, [...(res.get(api.category) ?? []), api]);
        }
    }
    res.set(
        'etc',
        apis.filter((v) => v.category === undefined).map((v) => ({ ...v, category: 'etc' }))
    );
    return res;
};

const ApiReferenceContext = createContext(
    {} as {
        references: ReferenceMap;
    }
);
export const useApiReferenceContext = () => useContext(ApiReferenceContext);

export const ContextProvider: React.FC<{ apis: ApiReference[]; children: ReactNode }> = (props) => {
    const references = buildReferenceMap(props.apis);
    return (
        <ApiReferenceContext.Provider value={{ references }}>
            {props.children}
        </ApiReferenceContext.Provider>
    );
};
