import { SurveyGroup, SurveyPeriod } from '@spec/Survey';
import React, { createContext, ReactNode, useContext } from 'react';
import { StateValue, useStateValue } from '../../../../lib/Context';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode = 'AddPeriod' | 'EditPeriod' | 'LinkQuestions' | 'DeletePeriod';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const CurrentGroupContext = createContext({} as { surveyGroup: SurveyGroup });
export const useCurrentGroupContext = () => useContext(CurrentGroupContext);

const EditPeriodContext = createContext(
    {} as {
        period: StateValue<SurveyPeriod | null>;
    }
);
const EditPeriodContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const period = useStateValue<SurveyPeriod | null>(null);
    return (
        <EditPeriodContext.Provider value={{ period }}>{props.children}</EditPeriodContext.Provider>
    );
};
export const useEditPeriodContext = () => useContext(EditPeriodContext);

export const ContextProvider: React.FC<{ surveyGroup: SurveyGroup; children: ReactNode }> = (
    props
) => {
    return (
        <DialogContextProvider>
            <CurrentGroupContext.Provider value={{ surveyGroup: props.surveyGroup }}>
                <EditPeriodContextProvider>{props.children}</EditPeriodContextProvider>
            </CurrentGroupContext.Provider>
        </DialogContextProvider>
    );
};
