import { Alert, Box, Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useEmptyForm } from '../../../../lib/Form';
import { useRestoreTeam } from '../../../../queries/organization';
import { SubmitButton } from '../../../SubmitButton';
import { useDialogContext, useSelectedTeamContext } from '../Context';

export const RestoreDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { selectedTeam } = useSelectedTeamContext();
    if (selectedTeam.value === null) {
        throw Error('Team does not selected.');
    }
    const team = selectedTeam.value;
    const form = useEmptyForm();
    const mutation = useRestoreTeam(team.id);
    return (
        <>
            <DialogTitle>「{team.name}」を復帰します</DialogTitle>
            <DialogContent>
                <Box mb={1}>
                    <Typography variant="body2">
                        下部に廃止済みの組織が存在する場合、それらは復帰されません。
                    </Typography>
                </Box>
                {form.failed && <Alert severity="error">更新に失敗しました</Alert>}
                <Box textAlign="center" mt={4} mb={1} mx={8}>
                    <SubmitButton
                        fullWidth
                        variant="contained"
                        inProgress={form.inProgress}
                        onClick={() => {
                            form.send(mutation.mutateAsync().then(closeDialog));
                        }}
                    >
                        組織を復帰する
                    </SubmitButton>
                    <Box mt={4}>
                        <Button variant="outlined" size="medium" onClick={closeDialog}>
                            キャンセル
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </>
    );
};
