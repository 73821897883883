import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useAddEmployment } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useEditTalentContext } from '../Context';
import {
    EmploymentFormContent,
    toEditEmploymentRequest,
    useEmploymentForm,
} from './EmploymentForm';

export const AddEmploymentDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { employeeCode } = useEditTalentContext();
    const form = useEmploymentForm({
        email: '',
        employeeCode: '',
        employmentStatus: '',
        isNewGraduate: false,
        joinedAt: null,
        leavedAt: null,
    });
    const mutation = useAddEmployment(employeeCode);
    return (
        <>
            <DialogTitle>雇用履歴の追加</DialogTitle>
            <DialogContent>
                <EmploymentFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で履歴を追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="履歴の追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditEmploymentRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
