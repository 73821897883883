import type { ReactNode } from 'react';
import { useTenantContext } from './Context';

type Tenant = 'carta' | 'treasure2024';

type TenantContentProps = { [key in Tenant]?: ReactNode };

export const TenantContent = (props: TenantContentProps) => {
    const { tenant } = useTenantContext();
    switch (tenant) {
        case 'carta':
            return props.carta ?? null;
        case 'treasure2024':
            return props.treasure2024 ?? null;
        default:
            return null;
    }
};
