import React from 'react';
import { ActionButton } from '../../../ActionButtons';
import { EditIcon } from '../../../Icons';
import { useDialogContext } from './Context';

export const AddCategoryButton: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    return (
        <ActionButton startIcon={<EditIcon />} onClick={() => setDialogMode('AddCategory')}>
            新しいカテゴリを作る
        </ActionButton>
    );
};
