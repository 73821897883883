import { TodoItemStatus } from '../../../domains/Todo';

export const getStatusLabel = (status: TodoItemStatus) =>
    (
        ({
            draft: { color: 'info', label: '準備中' },
            public: { color: 'success', label: '公開済み' },
            finished: { color: 'error', label: '公開終了' },
        }) as const
    )[status];
