import { Button, Typography } from '@mui/material';
import { FlexBox } from './FlexBox';
import { BeforeIcon, NextIcon } from './Icons';
import { UnitText } from './UnitText';

export function sliceItems<T>(items: T[], page: number, itemsPerPage: number): T[] {
    return items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
}

export interface PagerProps {
    current: number;
    setPage: (page: number) => void;
    amount: number;
    perItems: number;
}

export const Pager = (props: PagerProps) => {
    const lastPage = Math.ceil(props.amount / props.perItems);
    if (props.current < 1 || props.current > lastPage) {
        props.setPage(1);
    }
    const from = (props.current - 1) * props.perItems + 1;
    const to = Math.min(props.amount, props.current * props.perItems);
    return (
        <FlexBox justifyContent="space-between" my={1}>
            <Button
                disabled={props.current <= 1}
                startIcon={<BeforeIcon />}
                onClick={() => props.setPage(props.current - 1)}
            >
                前のページ
            </Button>
            <Typography>
                {props.current}/{lastPage}
                <UnitText text=" ページ" />
                <UnitText text={`（全${props.amount}件中の${from}〜${to}件）`} />
            </Typography>
            <Button
                disabled={props.current === lastPage}
                endIcon={<NextIcon />}
                onClick={() => props.setPage(props.current + 1)}
            >
                次のページ
            </Button>
        </FlexBox>
    );
};
