import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { NotesArticle, NotesComment } from '@spec/Notes';
import dayjs from 'dayjs';
import { getTalentUrl } from '../../../Routes';
import { fullName } from '../../../domains/Talent';
import { findById, sortByKey } from '../../../lib/ArrayUtils';
import { useTalentsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { IconText } from '../../IconText';
import { AccessTimeIcon } from '../../Icons';
import { PopoverTalent } from '../../PopoverTalent';
import { RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { HitonowaId } from '../../talent/HitonowaId';
import { CommentForm } from './CommentForm';

export const Comments: React.FC<{
    article: NotesArticle;
    comments: NotesComment[];
}> = (props) => {
    if (props.comments.length === 0) {
        return (
            <Box>
                <Typography mb={1} color="primary">
                    この記事へのコメントはまだありません。最初のコメントを書いてみませんか？
                </Typography>
                <CommentForm article={props.article} />
            </Box>
        );
    }
    return (
        <Box>
            <Typography color="primary">記事へのコメント</Typography>
            <Stack mt={1} spacing={1}>
                {sortByKey(props.comments, 'commentedAt').map((v) => (
                    <CommentCard key={v.id} comment={v} />
                ))}
            </Stack>
            <Typography mt={2} mb={1} color="primary">
                コメントを書く
            </Typography>
            <CommentForm article={props.article} />
        </Box>
    );
};

const CommentCard: React.FC<{ comment: NotesComment }> = (props) => {
    const { talents } = useTalentsContext();
    const v = props.comment;
    const talent = findById(v.talentId, talents);
    const talentUrl = getTalentUrl(talent.employment.employeeCode);
    return (
        <Card square={false}>
            <CardContent>
                <FlexBox gap={1} flexWrap="nowrap">
                    <PopoverTalent talent={talent}>
                        <TalentAvatar size="medium" talent={talent} />
                    </PopoverTalent>
                    <Box flexGrow={1}>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                            {v.content}
                        </Typography>
                        <FlexBox gap={1}>
                            <PopoverTalent talent={talent}>
                                <FlexBox gap={1}>
                                    <HitonowaId hitonowaId={talent.hitonowaId} enableLink />
                                    <RouterLink to={talentUrl} variant="body2">
                                        {fullName(talent)}
                                    </RouterLink>
                                </FlexBox>
                            </PopoverTalent>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(v.commentedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                        </FlexBox>
                    </Box>
                </FlexBox>
            </CardContent>
        </Card>
    );
};
