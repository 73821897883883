import type { TeamId } from '@spec/Organization';
import { TalentId } from '@spec/Talent';
import {
    ValueFeedbackParticipantsResponse,
    ValueFeedbacksResponse,
    type ValueFeedbackReadPermissionsResponse,
} from '@spec/ValueFeedback';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useCompanyValues = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.companyValues],
        queryFn: () => gateway.fetchCompanyValues(),
    });
};

export const useValueFeedbacks = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.valueFeedbacks],
        queryFn: () =>
            gateway
                .get<ValueFeedbacksResponse>('/value-feedbacks')
                .then((res) => res.valueFeedbacks),
    });
};

export const useValueFeedbackDetail = (valueFeedbackId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.valueFeedbacks, valueFeedbackId],
        queryFn: () => gateway.fetchValueFeedbackDetail(valueFeedbackId),
    });
};

export const invalidateValueFeedbackDetail = (queryClient: QueryClient, valueFeedbackId: number) =>
    queryClient.invalidateQueries({ queryKey: [queryKey.valueFeedbacks, valueFeedbackId] });

export const useAdminTalentFeedbacks = (valueFeedbackId: number, talentId: TalentId) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.talentFeedbacks, valueFeedbackId, talentId],
        queryFn: () => gateway.getTalentFeedbacks(valueFeedbackId, talentId),
    });
};

export const useAdminValueFeedbackStats = (valueFeedbackId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.valueFeedbackStats, valueFeedbackId],
        queryFn: () => gateway.getValueFeedbackStats(valueFeedbackId),
    });
};

export const useAdminValueFeedbackScores = (valueFeedbackId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.valueFeedbackScores, valueFeedbackId],
        queryFn: () => gateway.getValueFeedbackScores(valueFeedbackId),
    });
};

export const useAdminValueFeedbackSelections = (valueFeedbackId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.valueFeedbackSelections, valueFeedbackId],
        queryFn: () => gateway.fetchValueFeedbackSelections(valueFeedbackId),
    });
};

export const invalidateAdminValueFeedbackSelections = (
    queryClient: QueryClient,
    valueFeedbackId: number
) =>
    queryClient.invalidateQueries({
        queryKey: [queryKey.valueFeedbackSelections, valueFeedbackId],
    });

export const useMemberFeedbacks = (valueFeedbackId: number, talentId: TalentId) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: ['memberFeedbacks', valueFeedbackId, talentId],
        queryFn: () => gateway.getMemberFeedbacks(valueFeedbackId, talentId),
    });
};

export const useAdminParticipants = (valueFeedbackId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.valueFeedbacks, 'participants', valueFeedbackId],
        queryFn: () =>
            gateway
                .get<ValueFeedbackParticipantsResponse>(
                    `/value-feedbacks/${valueFeedbackId}/participants`
                )
                .then((res) => res.talentIds),
    });
};

export const useAssignParticipants = (valueFeedbackId: number, talentIds: TalentId[]) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (assigned: boolean) => {
            const url = `/value-feedbacks/${valueFeedbackId}/participants`;
            return assigned ? gateway.delete(url, { talentIds }) : gateway.post(url, { talentIds });
        },
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [queryKey.valueFeedbacks, 'participants', valueFeedbackId],
            }),
    });
};

export const useValueFeedbackReadPermissions = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.valueFeedbacks, 'readPermissions'],
        queryFn: () =>
            gateway
                .get<ValueFeedbackReadPermissionsResponse>('/value-feedbacks/grants/read')
                .then((res) => res.permissions),
    });
};

const useMutateValueFeedbackReadPermission = (method: 'POST' | 'DELETE') => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    const endpoint = '/value-feedbacks/grants/read';
    return useMutation({
        mutationFn: (args: { talentId: TalentId; teamId: TeamId }) => {
            if (method === 'POST') {
                return gateway.post(endpoint, args);
            } else {
                return gateway.delete(endpoint, args);
            }
        },
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [queryKey.valueFeedbacks, 'readPermissions'],
            }),
    });
};

export const useGrantValueFeedbackReadPermission = () =>
    useMutateValueFeedbackReadPermission('POST');

export const useRevokeValueFeedbackReadPermission = () =>
    useMutateValueFeedbackReadPermission('DELETE');
