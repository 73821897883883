import { Box, Stack, Typography, type TypographyProps } from '@mui/material';
import React from 'react';
import { FormCheckBox, FormTextField } from '../../lib/Form';
import { useCurrentTimeContext } from '../Context';
import { DateInput } from '../DateInput';
import { FlexBox } from '../FlexBox';
import { GradeSelector } from '../GradeSelector';
import { TeamSelector } from '../TeamSelector';
import { WorkplaceSelector } from '../WorkplaceSelector';
import { useRegisterFormContext } from './Context';

export const EmploymentForm: React.FC = () => {
    const { form } = useRegisterFormContext();
    const { currentTime } = useCurrentTimeContext();
    return (
        <Stack spacing={2}>
            <FlexBox>
                <Label>メールアドレス</Label>
                <Box flexGrow={1}>
                    <FormTextField formText={form.email} fullWidth />
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>社員番号</Label>
                <Box flexGrow={1}>
                    <FormTextField formText={form.employeeCode} fullWidth />
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>入社日</Label>
                <Box>
                    <FlexBox flexGrow={1}>
                        <DateInput formDate={form.joinedAt} max={currentTime} />
                        <Box ml={2}>
                            <FormCheckBox
                                formBoolean={form.isNewGraduate}
                                name="isNewGraduate"
                                label="新卒入社"
                            />
                        </Box>
                    </FlexBox>
                    <Typography mt={0.5} variant="body2" color="primary">
                        ※未来の日付は設定できません
                    </Typography>
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>雇用形態</Label>
                <Box flexGrow={1}>
                    <FormTextField formText={form.employmentStatus} fullWidth />
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>グレード</Label>
                <GradeSelector formText={form.gradeText} />
            </FlexBox>
            <FlexBox>
                <Label>所属</Label>
                <Box flexGrow={1}>
                    <TeamSelector teamId={form.teamId} required />
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>役職</Label>
                <Box flexGrow={1}>
                    <FormTextField formText={form.position} fullWidth />
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>勤務地</Label>
                <Box flexGrow={1}>
                    <WorkplaceSelector formText={form.workplace} />
                </Box>
            </FlexBox>
        </Stack>
    );
};

const Label = (props: TypographyProps) => <Typography sx={{ width: '7rem' }} {...props} />;
