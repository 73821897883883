import TitleIcon from '@mui/icons-material/AccountTree';
import { Box, Button, Typography } from '@mui/material';
import { Team, TeamId } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RoutingPattern } from '../../../Routes';
import { findChildTeams } from '../../../domains/Organization';
import { isEnrolledTalent } from '../../../domains/Talent';
import { FormCheckBox } from '../../../lib/Form';
import { useTeamIntroductions } from '../../../queries/organization';
import { useTalents } from '../../../queries/talent';
import { LinkButton } from '../../ActionButtons';
import { useTeamsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { HelpTip } from '../../HelpTip';
import { BeforeIcon } from '../../Icons';
import { PageModal } from '../../PageModal';
import { PageTitle } from '../../PageTitle';
import { ContextualDialog, DialogComponents } from '../../StableDialog';
import { UnitText } from '../../UnitText';
import { WaitQuery } from '../../WaitLoading';
import { NotFound } from '../../notFound';
import {
    ContextProvider,
    DialogMode,
    useDialogContext,
    useSelectedTeamContext,
    useShowAdditionalMembersContext,
} from './Context';
import { Introduction } from './Introduction';
import { NodeTeam } from './NodeTeam';
import { Visualizer } from './Visualizer';
import { AbolishDialog } from './dialogs/AbolishDialog';
import { CreateDialog } from './dialogs/CreateDialog';
import { DeleteDialog } from './dialogs/DeleteDialog';
import { EditDialog } from './dialogs/EditDialog';
import { RestoreDialog } from './dialogs/RestoreDialog';

export const TeamDetail: React.FC = () => {
    return (
        <ContextProvider>
            <PageModal>
                <View />
            </PageModal>
        </ContextProvider>
    );
};

const View: React.FC = () => {
    const { teams } = useTeamsContext();
    const maybeTalents = useTalents();
    const teamId = Number(useParams().teamId);
    if (Number.isNaN(teamId)) {
        return <NotFound />;
    }

    return (
        <WaitQuery query={maybeTalents}>
            {({ data }) => (
                <>
                    <RootTeam teamId={teamId} teams={teams} talents={data} />
                    <ContextualDialog components={dialogComponents} />
                </>
            )}
        </WaitQuery>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    CreateTeam: CreateDialog,
    EditTeam: EditDialog,
    AbolishTeam: AbolishDialog,
    RestoreTeam: RestoreDialog,
    DeleteTeam: DeleteDialog,
};

const RootTeam: React.FC<{ teamId: TeamId; teams: Team[]; talents: Talent[] }> = (props) => {
    const maybeTeamIntroductions = useTeamIntroductions();
    const { showAdditionalMembers } = useShowAdditionalMembersContext();
    const team = props.teams.find((v) => v.id === props.teamId);
    if (team === undefined) {
        return <NotFound />;
    }
    const childTeams = findChildTeams(props.teamId, props.teams).filter(
        (v) => v.abolishedAt === null
    );
    const members = pickMembers(childTeams, props.talents, showAdditionalMembers.value);
    return (
        <Box>
            <Box mb={2}>
                <LinkButton to={RoutingPattern.organization} startIcon={<BeforeIcon />}>
                    組織の一覧に戻る
                </LinkButton>
            </Box>
            <PageTitle icon={TitleIcon} title={`${team.name}の仲間たち`} />
            <WaitQuery query={maybeTeamIntroductions}>
                {({ data }) => <Introduction teamId={props.teamId} introductions={data} />}
            </WaitQuery>
            <Box mt={2}>
                <MemberAmounts
                    members={members.length}
                    mainMembers={pickMembers(childTeams, props.talents, false).length}
                    showAdditionalMembers={showAdditionalMembers.value}
                />
            </Box>
            {members.length > 0 ? (
                <Visualizer teams={childTeams} talents={members} />
            ) : (
                <EmptyTeam team={team} />
            )}
            <Box mt={2}>
                <FormCheckBox
                    formBoolean={showAdditionalMembers}
                    name="showAdditionalMembers"
                    label="兼務の人も表示する"
                />
            </Box>
            {childTeams.map((v) => (
                <NodeTeam key={v.id} team={v} teams={props.teams} />
            ))}
        </Box>
    );
};

const MemberAmounts: React.FC<{
    members: number;
    mainMembers: number;
    showAdditionalMembers: boolean;
}> = (props) => {
    if (props.members === 0) {
        return null;
    }
    const additionalMembers = props.members - props.mainMembers;
    return (
        <FlexBox>
            <Typography>
                <UnitText text="総勢" />
                {props.members}
                <UnitText text="名" />
                {additionalMembers > 0 && <UnitText text={`（うち兼務${additionalMembers}名）`} />}
            </Typography>
            {props.showAdditionalMembers === true && (
                <HelpTip title="主務と兼務の両方にいる人は兼務者としてカウントしていません。また、同じ人がいくつも兼務していて複数回数出ている場合も一人としてカウントしています" />
            )}
        </FlexBox>
    );
};

const EmptyTeam: React.FC<{ team: Team }> = (props) => {
    const { setDialogMode } = useDialogContext();
    const { selectedTeam } = useSelectedTeamContext();
    if (props.team.abolishedAt === null) {
        return <Typography color="error">現在この部署に在籍している人はいません</Typography>;
    }
    return (
        <Box>
            <Typography color="error">廃止済みの部署です</Typography>
            <Box mt={1}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        selectedTeam.setValue(props.team);
                        setDialogMode('EditTeam');
                    }}
                >
                    編集する
                </Button>
            </Box>
        </Box>
    );
};

const pickMembers = (
    teams: Team[],
    talents: Talent[],
    showAdditionalMembers: boolean
): Talent[] => {
    const teamIds = teams.map((v) => v.id);
    const members = talents
        .filter((v) => isEnrolledTalent(v))
        .filter((v) => teamIds.includes(v.teamId));
    if (showAdditionalMembers === true) {
        members.push(
            ...talents.filter(
                (v) =>
                    !!v.additionalPosts
                        .map((post) => teamIds.includes(post.teamId))
                        .find((vv) => vv === true)
            )
        );
    }
    return [...new Set(members)];
};
