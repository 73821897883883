import type { Talent } from '@spec/Talent';
import { createContext, useContext } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { RoutingPattern } from '../../../Routes';
import { useTalentsContext } from '../../Context';

const AuthorContext = createContext(
    {} as {
        author: Talent;
    }
);

// works as a layout component
export const AuthorContextProvider: React.FC = () => {
    const { author } = useParams();
    const { talents } = useTalentsContext();
    const navigate = useNavigate();

    if (author === undefined) {
        navigate(RoutingPattern.notes);
        return null;
    }
    const hitonowaId = author.replace(/^@/, '');
    const talent = talents.find((v) => v.hitonowaId === hitonowaId);
    if (talent === undefined) {
        navigate(RoutingPattern.notes);
        return null;
    }

    return (
        <AuthorContext.Provider value={{ author: talent }}>
            <Outlet />
        </AuthorContext.Provider>
    );
};

export const useAuthorContext = () => useContext(AuthorContext);
