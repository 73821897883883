import { Form } from '../../lib/Form';

export type ArticleInput = {
    isPinned: boolean;
    title: string;
    tags: string;
    content: string;
};

export type ArticleForm = Form<ArticleInput>;

export type ArticleCacheSetter = <K extends keyof ArticleInput>(k: K, v: ArticleInput[K]) => void;

export const KIBELA_ARCHIVE_URL =
    'https://drive.google.com/drive/search?q=in:0ALUN9NiM8mmeUk9PVA%20title:.md%20';
