import React from 'react';
import { Box } from '@mui/material';
import { SubTitle } from '../../../PageTitle';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { AddTodoItemDialog } from './dialogs/AddTodoItemDialog';
import { AddTodoItemButton } from './AddTodoItemButton';
import { ContextProvider, DialogMode } from './Context';
import { TodoItemFilterForm } from './TodoItemFilterForm';
import { TodoItemTable } from './TodoItemTable';

export const TodoItemList: React.FC = () => {
    return (
        <ContextProvider>
            <SubTitle title="管理者向けやること一覧" />
            <Box mt={2}>
                <TodoItemFilterForm />
            </Box>
            <Box mt={2}>
                <TodoItemTable />
            </Box>
            <AddTodoItemButton />
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddTodoItem: AddTodoItemDialog,
};
