import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteSurvey } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useSurveyContext } from '../Context';

export const DeleteSurveyDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { survey } = useSurveyContext();
    const form = useEmptyForm();
    const mutation = useDeleteSurvey(survey.id);
    return (
        <>
            <DialogTitle>サーベイを削除する</DialogTitle>
            <DialogContent>
                <DialogRow label="名称">
                    <Typography>{survey.name}</Typography>
                </DialogRow>
                <DialogRow label="詳細">
                    <Typography>{survey.description}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="このサーベイを削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
