import { getAnalytics, logEvent } from 'firebase/analytics';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { firebaseApp } from '../../App';

export const Analytics: React.FC<React.PropsWithChildren<unknown>> = () => {
    const location = useLocation();
    useEffect(() => {
        logEvent(getAnalytics(firebaseApp), 'pageView');
    }, [location.pathname]);
    return null;
};
