import { type DailyBackupResponse } from '@spec/humanCapital';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useBackupDates = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.humanCapital, 'dates'],
        queryFn: () =>
            gateway
                .get<{ dates: string[] }>('/human-capital/dates')
                // convert to Date manually, not automatically by Gateway
                .then((res) => res.dates.map((v) => new Date(v))),
    });
};

export const useDailyBackup = (date: Date) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.humanCapital, 'date', date],
        queryFn: () =>
            gateway.get<DailyBackupResponse>(
                `/human-capital/date/${dayjs(date).format('YYYYMMDD')}`
            ),
    });
};
