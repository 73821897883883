import { useState } from 'react';

export interface StateValue<T> {
    value: T;
    setValue: React.Dispatch<React.SetStateAction<T>>;
}

export const useStateValue = <T>(v: T): StateValue<T> => {
    const [value, setValue] = useState<T>(v);
    return { value, setValue };
};
