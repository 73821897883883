import type {
    NotificationEvent,
    SurveyNotification,
    TodoNotification,
    ValueFeedbackNotification,
} from '@spec/notification';
import { buildAnnouncement as buildSurveyAnnouncement } from './Survey';
import { buildAnnouncement as buildTodoAnnouncement, type Announcement } from './Todo';
import { buildAnnouncement as buildValueFeedbackAnnouncement } from './ValueFeedback';

export const buildAnnouncement = (v: NotificationEvent): Announcement[] => {
    if (isSurveyNotification(v)) {
        return buildSurveyAnnouncement(v);
    }
    if (isValueFeedbackNotification(v)) {
        return buildValueFeedbackAnnouncement(v);
    }
    if (isTodoNotification(v)) {
        return buildTodoAnnouncement(v);
    }
    return [];
};

const isSurveyNotification = (v: NotificationEvent): v is SurveyNotification => v.type === 'survey';

const isValueFeedbackNotification = (v: NotificationEvent): v is ValueFeedbackNotification =>
    v.type === 'valueFeedback';

const isTodoNotification = (v: NotificationEvent): v is TodoNotification => v.type === 'todo';

export const deduplicateNotifications = (
    items: NotificationEvent[],
    priority: 'first' | 'last' = 'first'
): NotificationEvent[] => {
    const uniqueMap = new Map<string, NotificationEvent>();
    for (const item of items) {
        const key = [item.type, item.key].join(':');
        if (priority === 'first' && uniqueMap.has(key)) {
            continue;
        }
        uniqueMap.set(key, item);
    }
    return [...uniqueMap.values()];
};
