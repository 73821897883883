import { Box, Container, Divider, Typography } from '@mui/material';
import type { NotesArticle, NotesArticleResponse } from '@spec/Notes';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { ParameterError } from '../../../Errors';
import { RoutingPattern } from '../../../Routes';
import { isApplicablePath, shouldRedirect, type ApplicablePath } from '../../../domains/Notes';
import { useMeContext } from '../../../queries/me';
import { useNotesArticle } from '../../../queries/notes';
import { ActionButton, ActionContainer, LinkButton } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { EditIcon } from '../../Icons';
import { ArticleMarkdown } from '../../Markdown';
import { PageModal } from '../../PageModal';
import { ErrorContent, Loading } from '../../WaitLoading';
import { ArticleAuthor } from '../ArticleAuthor';
import { ArticleTags } from '../ArticleTags';
import { useAuthor } from '../Hooks';
import { getArticleEditUrl, getArticleUrl } from '../urls';
import { ArticleNav } from './ArticleNav';
import { Comments } from './Comments';
import { Likes } from './Likes';
import { ArticleNotFound } from './NotFound';
import { PinButton } from './PinButton';

export const Article: React.FC = () => {
    const { author } = useParams();
    const articleId = Number(useParams().articleId);
    if (!isApplicablePath(author)) {
        return <Navigate to={RoutingPattern.notes} />;
    }
    return (
        <PageModal>
            <ArticleLoader articleId={articleId} path={author} />
        </PageModal>
    );
};

const ArticleLoader: React.FC<{ articleId: number; path: ApplicablePath }> = (props) => {
    const maybeArticle = useNotesArticle(props.articleId);
    if (maybeArticle.isPending) {
        return (
            <Box my={20}>
                <Loading />
            </Box>
        );
    }
    if (maybeArticle.isError) {
        const error = maybeArticle.error;
        if (error instanceof ParameterError) {
            return <ArticleNotFound />;
        }
        return <ErrorContent />;
    }
    return <ArticleContent response={maybeArticle.data} path={props.path} />;
};

const ArticleContent: React.FC<{ response: NotesArticleResponse; path: ApplicablePath }> = (
    props
) => {
    const v = props.response.article;
    const location = useLocation();
    const author = useAuthor(v);
    if (shouldRedirect(author.hitonowaId, props.path)) {
        return <Navigate to={getArticleUrl(v.id, author.hitonowaId)} replace={true} />;
    }
    if (location.hash === '#comment') {
        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        }, 1000);
    }
    return (
        <Container maxWidth="md">
            <FlexBox flexWrap="nowrap" alignItems="flex-start">
                <Typography variant="h4">{v.title}</Typography>
                <PinButton article={v} />
                <Box ml={1.5}>
                    <MiniUpdateButton article={v} />
                </Box>
            </FlexBox>
            <Box mb={1.5}>
                <ArticleTags tags={v.tags} />
            </Box>
            <ArticleAuthor article={v} />
            <Box mt={2}>
                <ArticleMarkdown source={v.content} />
            </Box>
            <Box mt={2}>
                <Likes article={v} talentIds={props.response.likedTalentIds} />
            </Box>
            <Box mt={2}>
                <Divider />
            </Box>
            <Box mt={2}>
                <ArticleNav article={v} />
            </Box>
            <UpdateButton article={v} />
            <Box mt={4}>
                <Comments article={v} comments={props.response.comments} />
            </Box>
        </Container>
    );
};

const MiniUpdateButton: React.FC<{ article: NotesArticle }> = (props) => {
    const { me } = useMeContext();
    if (props.article.talentId !== me.id) {
        return null;
    }
    return (
        <LinkButton
            variant="outlined"
            startIcon={<EditIcon />}
            to={getArticleEditUrl(props.article.id, me.hitonowaId)}
            sx={{ mt: 0.5, whiteSpace: 'nowrap', minWidth: 'auto' }}
        >
            編集する
        </LinkButton>
    );
};

const UpdateButton: React.FC<{ article: NotesArticle }> = (props) => {
    const { me } = useMeContext();
    if (props.article.talentId !== me.id) {
        return null;
    }
    return (
        <ActionContainer>
            <ActionButton
                startIcon={<EditIcon />}
                to={getArticleEditUrl(props.article.id, me.hitonowaId)}
            >
                この記事を編集する
            </ActionButton>
        </ActionContainer>
    );
};
