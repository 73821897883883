import { Survey, type SurveyQuestion } from '@spec/Survey';
import React, { createContext, ReactNode, useContext } from 'react';
import { StateValue, useStateValue } from '../../../../lib/Context';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode =
    | 'EditSurvey'
    | 'DeleteSurvey'
    | 'AddQuestion'
    | 'EditQuestion'
    | 'DeleteQuestion';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const SurveyContext = createContext({} as { survey: Survey });
export const useSurveyContext = () => useContext(SurveyContext);

const EditQuestionContext = createContext(
    {} as {
        question: StateValue<SurveyQuestion | null>;
    }
);
const EditQuestionContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const question = useStateValue<SurveyQuestion | null>(null);
    return (
        <EditQuestionContext.Provider value={{ question }}>
            {props.children}
        </EditQuestionContext.Provider>
    );
};
export const useEditQuestionContext = () => useContext(EditQuestionContext);

export const ContextProvider: React.FC<{ survey: Survey; children: ReactNode }> = (props) => {
    return (
        <DialogContextProvider>
            <SurveyContext.Provider value={{ survey: props.survey }}>
                <EditQuestionContextProvider>{props.children}</EditQuestionContextProvider>
            </SurveyContext.Provider>
        </DialogContextProvider>
    );
};
