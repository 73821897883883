import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import { fullName, hiraToKana, searchTalent } from '../../domains/Talent';

export const isTalent = (v: unknown): v is Talent =>
    typeof v === 'object' && v !== null && Object.keys(v).includes('lastName');

export const isTeam = (v: unknown): v is Team =>
    typeof v === 'object' && v !== null && Object.keys(v).includes('parentId');

export const searchItems = <T>(text: string, items: T[], limit?: number): Array<T | string> => {
    const filtered = items.filter((item) => {
        if (isTalent(item)) {
            return (
                searchTalent(text, item) ||
                item.employment.employeeCode.toLowerCase().includes(text.toLowerCase())
            );
        }
        if (isTeam(item)) {
            return (
                hiraToKana(item.name).toLowerCase().includes(hiraToKana(text).toLowerCase()) ||
                (item.code?.toLowerCase() ?? '').includes(text.toLowerCase())
            );
        }
        return true;
    });
    const DEFAULT_FILTER_LIMIT = 30;
    const filterLimit = limit ?? DEFAULT_FILTER_LIMIT;
    const sliced: Array<T | string> = filtered.slice(0, filterLimit);
    if (filtered.length > filterLimit) {
        sliced.push(`他 ${filtered.length - filterLimit}件（多すぎて省略されました）`);
    }
    return sliced;
};

export const getOptionLabel = (item: unknown): string => {
    if (isTalent(item)) {
        return fullName(item);
    }
    if (isTeam(item)) {
        return item.name;
    }
    return '';
};
