import { Box } from '@mui/material';
import React from 'react';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { BackToProfile } from './BackToProfile';
import { ContextProvider, DialogMode } from './Context';
import { EditTalentProps } from './Contract';
import { EditPersonality } from './EditPersonality';
import { EditSecondment } from './EditSecondment';
import { EmploymentHistories } from './EmploymentHistories';
import { TransferHistories } from './TransferHistories';
import { AddEmploymentDialog } from './dialogs/AddEmploymentDialog';
import { AddSecondmentDialog } from './dialogs/AddSecondmentDialog';
import { AddTransferDialog } from './dialogs/AddTransferDialog';
import { DeleteEmploymentDialog } from './dialogs/DeleteEmploymentDialog';
import { DeleteSecondmentDialog } from './dialogs/DeleteSecondmentDialog';
import { DeleteTransferDialog } from './dialogs/DeleteTransferDialog';
import { EditEmploymentDialog } from './dialogs/EditEmploymentDialog';
import { EditSecondmentDialog } from './dialogs/EditSecondmentDialog';
import { EditTransferDialog } from './dialogs/EditTransferDialog';

export const EditTalent: React.FC<EditTalentProps> = (props) => {
    return (
        <ContextProvider {...props}>
            <Box>
                <BackToProfile talent={props.talent} />
                <Box mt={2}>
                    <EditPersonality {...props} />
                </Box>
                <Box mt={4}>
                    <EditSecondment {...props} />
                </Box>
                <Box mt={4}>
                    <EmploymentHistories {...props} />
                </Box>
                <Box mt={4}>
                    <TransferHistories {...props} />
                </Box>
            </Box>
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddSecondment: AddSecondmentDialog,
    EditSecondment: EditSecondmentDialog,
    DeleteSecondment: DeleteSecondmentDialog,
    AddTransfer: AddTransferDialog,
    EditTransfer: EditTransferDialog,
    DeleteTransfer: DeleteTransferDialog,
    AddEmployment: AddEmploymentDialog,
    EditEmployment: EditEmploymentDialog,
    DeleteEmployment: DeleteEmploymentDialog,
};
