import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Talent } from '@spec/Talent';
import React, { useState } from 'react';
import { useEmptyForm } from '../../../../lib/Form';
import { useSuspendTalent } from '../../../../queries/talent';
import { ActionButton } from '../../../ActionButtons';
import { DialogActionButtons, StableDialog } from '../../../StableDialog';

interface Props {
    talent: Talent;
}

export const SuspendButton: React.FC<Props> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useEmptyForm();
    const mutation = useSuspendTalent(props.talent.employment.employeeCode);
    return (
        <>
            <StableDialog open={dialogOpen} noClose onClose={() => setDialogOpen(false)}>
                <DialogTitle>アカウントを停止しますか？</DialogTitle>
                <DialogContent>
                    アカウントを停止するとこの従業員本人はログインできなくなり、一般のアカウントからはその存在が見えなくなります。
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    startIcon={<PauseIcon />}
                    submitLabel="アカウントを停止する"
                    cancelLabel="キャンセル"
                    errorMessage="停止に失敗しました"
                    onSubmit={() => mutation.mutateAsync(true)}
                    closeDialog={() => setDialogOpen(false)}
                />
            </StableDialog>
            <ActionButton startIcon={<PauseIcon />} onClick={() => setDialogOpen(true)}>
                アカウントを停止する
            </ActionButton>
        </>
    );
};

export const UnsuspendButton: React.FC<Props> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useEmptyForm();
    const mutation = useSuspendTalent(props.talent.employment.employeeCode);
    return (
        <>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>アカウントの停止を解除しますか？</DialogTitle>
                <DialogContent>
                    アカウントの停止を解除するとログイン可能になり、一般のアカウントからも見えるようになります。
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    startIcon={<PlayArrowIcon />}
                    submitLabel="アカウントの停止を解除する"
                    cancelLabel="キャンセル"
                    errorMessage="停止の解除に失敗しました"
                    onSubmit={() => mutation.mutateAsync(false)}
                    closeDialog={() => setDialogOpen(false)}
                />
            </Dialog>
            <ActionButton startIcon={<PlayArrowIcon />} onClick={() => setDialogOpen(true)}>
                アカウントの停止を解除する
            </ActionButton>
        </>
    );
};
