import React from 'react';
import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';

export const SubmitButton: React.FC<
    Partial<ButtonProps> & {
        inProgress: boolean;
    }
> = (props) => {
    const { inProgress, disabled, ...buttonProps } = props;
    return (
        <Box sx={{ position: 'relative' }}>
            {inProgress && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        top: '50%',
                        left: '50%',
                    }}
                    size={24}
                />
            )}
            <Button
                disabled={disabled || inProgress}
                variant="contained"
                size="medium"
                {...buttonProps}
            />
        </Box>
    );
};
