import { TalentId } from '@spec/Talent';
import { createContext, ReactNode, useContext, useState } from 'react';
import {
    DialogContextProvider,
    TalentsContextProvider,
    useGenericDialogContext,
} from '../../../Context';

export type DialogMode = 'AddPermission' | 'RevokePermission';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const TalentIdContext = createContext(
    {} as { talentId: TalentId | null; setTalentId: (v: TalentId) => void }
);
const TalentIdContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [talentId, setTalentId] = useState<TalentId | null>(null);
    return (
        <TalentIdContext.Provider value={{ talentId, setTalentId }}>
            {props.children}
        </TalentIdContext.Provider>
    );
};
export const useTalentIdContext = () => useContext(TalentIdContext);

export const ContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    return (
        <TalentsContextProvider>
            <DialogContextProvider>
                <TalentIdContextProvider>{props.children}</TalentIdContextProvider>
            </DialogContextProvider>
        </TalentsContextProvider>
    );
};
