import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { formatDate } from '../../../../../lib/DateUtils';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteEmployment } from '../../../../../queries/talent';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { EmploymentContext, useDialogContext } from '../Context';

export const DeleteEmploymentDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const context = useContext(EmploymentContext);
    const v = context.employment.value;
    if (v === null) {
        throw Error(`null employment`);
    }
    const form = useEmptyForm();
    const mutation = useDeleteEmployment(v.id);
    return (
        <>
            <DialogTitle>雇用履歴の削除</DialogTitle>
            <DialogContent>
                <DialogRow label="email">
                    <Typography>{v.email}</Typography>
                </DialogRow>
                <DialogRow label="社員番号">
                    <Typography>{v.employeeCode}</Typography>
                </DialogRow>
                <DialogRow label="雇用形態">
                    <Typography>{v.employmentStatus}</Typography>
                </DialogRow>
                <DialogRow label="新卒フラグ">
                    <Typography>{v.isNewGraduate ? '新卒' : '中途'}</Typography>
                </DialogRow>
                <DialogRow label="入社日">
                    <Typography>{formatDate(v.joinedAt)}</Typography>
                </DialogRow>
                <DialogRow label="退職日">
                    <Typography>{formatDate(v.leavedAt)}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この雇用履歴を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="履歴の削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
