import { SurveyGroup } from '@spec/Survey';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode = 'AddGroup' | 'EditGroup' | 'DeleteGroup' | 'AddSurvey';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const TargetGroupContext = createContext(
    {} as { group: SurveyGroup | null; setGroup: (v: SurveyGroup | null) => void }
);
export const useTargetGroupContext = () => useContext(TargetGroupContext);

export const ContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [group, setGroup] = useState<SurveyGroup | null>(null);
    return (
        <DialogContextProvider>
            <TargetGroupContext.Provider value={{ group, setGroup }}>
                {props.children}
            </TargetGroupContext.Provider>
        </DialogContextProvider>
    );
};
