import type {
    AdminTodoItemsResponse,
    EditTodoItemRequest,
    PostTodoItemResponse,
    TodoAssignRequest,
    TodoAssignmentsResponse,
} from '@spec/Todo';
import {
    useMutation,
    useQuery,
    useQueryClient,
    type MutationFunction,
} from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useTodoItems = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.todoItems],
        queryFn: () => gateway.get<AdminTodoItemsResponse>('/todo/items'),
    });
};

export const useTodoAssignments = (itemId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.todoItems, itemId],
        queryFn: () =>
            gateway
                .get<TodoAssignmentsResponse>(`/todo/items/${itemId}/assign`)
                .then((res) => res.assignments),
    });
};

const useTodoMutation = <Res = void, Req = void>(mutationFn: MutationFunction<Res, Req>) => {
    const queryClient = useQueryClient();
    return useMutation<Res, Error, Req>({
        mutationFn,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey.todoItems] }),
    });
};

export const useAddTodoItem = () => {
    const gateway = useGateway();
    return useTodoMutation<PostTodoItemResponse, EditTodoItemRequest>((args) =>
        gateway.post('/todo/items', args)
    );
};

export const useUpdateTodoItem = (itemId: number) => {
    const gateway = useGateway();
    return useTodoMutation<void, EditTodoItemRequest>((args) =>
        gateway.put(`/todo/items/${itemId}`, args)
    );
};

export const useDeleteTodoItem = (itemId: number) => {
    const gateway = useGateway();
    return useTodoMutation(() => gateway.delete(`/todo/items/${itemId}`));
};

export const usePublishTodoItem = (itemId: number) => {
    const gateway = useGateway();
    return useTodoMutation<void>(() => gateway.post(`/todo/items/${itemId}/publish`));
};

export const useUnpublishTodoItem = (itemId: number) => {
    const gateway = useGateway();
    return useTodoMutation<void>(() => gateway.delete(`/todo/items/${itemId}/publish`));
};

export const useFinishTodoItem = (itemId: number) => {
    const gateway = useGateway();
    return useTodoMutation<void>(() => gateway.post(`/todo/items/${itemId}/finish`));
};

export const usePreviewTodoItem = () => {
    const gateway = useGateway();
    return useMutation<void, Error, EditTodoItemRequest>({
        mutationFn: (args) => gateway.post('/todo/items/preview', args),
        // invalidation is not needed
    });
};

export const useAssignTodoItems = (itemId: number) => {
    const gateway = useGateway();
    return useTodoMutation<void, TodoAssignRequest>((args) =>
        gateway.post(`/todo/items/${itemId}/assign`, args)
    );
};

export const useWithdrawTodoItems = (itemId: number) => {
    const gateway = useGateway();
    return useTodoMutation<void, TodoAssignRequest>((args) =>
        gateway.delete(`/todo/items/${itemId}/assign`, args)
    );
};
