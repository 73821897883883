import { Box } from '@mui/material';
import { Talent } from '@spec/Talent';
import React from 'react';
import { getTalentUrl } from '../../../../Routes';
import { LinkButton } from '../../../ActionButtons';
import { BeforeIcon } from '../../../Icons';

export const BackToProfile: React.FC<{ talent: Talent }> = (props) => {
    return (
        <Box>
            <LinkButton
                to={getTalentUrl(props.talent.employment.employeeCode)}
                startIcon={<BeforeIcon />}
            >
                プロフィールに戻る
            </LinkButton>
        </Box>
    );
};
