import { NextSabbaticalLeave, SabbaticalLeave, Talent, TalentId } from '@spec/Talent';
import dayjs, { Dayjs } from 'dayjs';
import { ApplicationError } from '../Errors';
import { findById, sortByKey } from '../lib/ArrayUtils';

export const findUpcomingLeave = (leaves: SabbaticalLeave[], now: Date): SabbaticalLeave | null => {
    const nowDayjs = dayjs(now);
    return leaves.find((v) => dayjs(v.offeredAt) > nowDayjs) ?? null;
};

export const findAvailableLeave = (leaves: SabbaticalLeave[], now: Date): SabbaticalLeave | null =>
    leaves.find((v) => isAvailable(v, now)) ?? null;

const isAvailable = (leave: SabbaticalLeave, now: Date): boolean => {
    const offeredAt = dayjs(leave.offeredAt);
    const expiredAt = dayjs(leave.expiredAt);
    return dayjs(now).isBetween(offeredAt, expiredAt, null, '[)');
};

export const isExpiredLeave = (leave: SabbaticalLeave, now: Date): boolean =>
    dayjs(leave.expiredAt) <= dayjs(now);

export const getDeadline = (leave: SabbaticalLeave): Dayjs =>
    dayjs(leave.expiredAt).subtract(1, 'day');

export interface LeavesRow {
    talent: Talent;
    leaves: SabbaticalLeave[];
    next: NextSabbaticalLeave | null;
}

export const generateLeavesList = (
    talents: Talent[],
    exists: SabbaticalLeave[],
    upcoming: NextSabbaticalLeave[]
): LeavesRow[] => {
    const talentLeaves: Map<TalentId, SabbaticalLeave[]> = new Map();
    sortByKey(exists, 'offeredAt').forEach((v) =>
        talentLeaves.set(v.talentId, [...(talentLeaves.get(v.talentId) ?? []), v])
    );
    const next: Map<TalentId, NextSabbaticalLeave> = new Map();
    sortByKey(upcoming, 'offeringAt').forEach((v) => next.set(v.talentId, v));
    const upcomingTalentIds = [...next.keys()].filter((v) => !talentLeaves.has(v));
    const res: LeavesRow[] = [];
    for (const [talentId, leaves] of talentLeaves.entries()) {
        const talent = findById(talentId, talents);
        res.push({ talent, leaves, next: next.get(talentId) ?? null });
    }
    for (const talentId of upcomingTalentIds) {
        const v = next.get(talentId);
        if (v === undefined) {
            throw new ApplicationError('The next sabbatical leave does not exists');
        }
        const talent = findById(talentId, talents);
        res.push({ talent, leaves: [], next: v });
    }
    return res;
};
