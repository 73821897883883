import { Box, Stack, Typography, type TypographyProps } from '@mui/material';
import React from 'react';
import { FormTextField } from '../../lib/Form';
import { FlexBox } from '../FlexBox';
import { useRegisterFormContext } from './Context';

export const PersonalityForm: React.FC = () => {
    const { form } = useRegisterFormContext();
    return (
        <Stack spacing={2}>
            <FlexBox>
                <Label>氏名</Label>
                <Box flexGrow={1}>
                    <FlexBox>
                        <SubLabel>姓</SubLabel>
                        <Box flexGrow={1}>
                            <FormTextField formText={form.lastName} fullWidth />
                        </Box>
                        <SubLabel ml={4}>名</SubLabel>
                        <Box flexGrow={1}>
                            <FormTextField formText={form.firstName} fullWidth />
                        </Box>
                    </FlexBox>
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>ヨミガナ</Label>
                <Box flexGrow={1}>
                    <FlexBox>
                        <SubLabel>セイ</SubLabel>
                        <Box flexGrow={1}>
                            <FormTextField formText={form.lastNameKana} fullWidth />
                        </Box>
                        <SubLabel ml={4}>メイ</SubLabel>
                        <Box flexGrow={1}>
                            <FormTextField formText={form.firstNameKana} fullWidth />
                        </Box>
                    </FlexBox>
                </Box>
            </FlexBox>
            <FlexBox>
                <Label>英語名</Label>
                <Box flexGrow={1}>
                    <FormTextField formText={form.romanName} fullWidth />
                </Box>
            </FlexBox>
        </Stack>
    );
};

const Label = (props: TypographyProps) => <Typography sx={{ width: '7rem' }} {...props} />;
const SubLabel = (props: TypographyProps) => <Typography sx={{ width: '2.5rem' }} {...props} />;
