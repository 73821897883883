import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useEmptyForm } from '../../../../../lib/Form';
import { useUnpublishTodoItem } from '../../../../../queries/todoAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext, useTodoItemContext } from '../Context';

export const UnpublishTodoItemDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { item } = useTodoItemContext();
    const form = useEmptyForm();
    const mutation = useUnpublishTodoItem(item.id);
    return (
        <>
            <DialogTitle>「{item.title}」を準備中に戻す</DialogTitle>
            <DialogContent>
                <Typography>公開中のやることを準備中に戻すと、</Typography>
                <Typography mt={1}>
                    ・未完了の対象者がヒトノワを開いた時にお知らせダイアログが表示されます
                </Typography>
                <Typography mt={0.5}>
                    ・未完了の対象者のヒトノワ画面上のやること一覧から消えます
                </Typography>
                <Typography mt={2}>
                    混乱を招く恐れがあるため基本的には戻さないことをおすすめしますが、どうしてもという場合は戻せるようにしています。
                </Typography>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="準備中に戻す"
                cancelLabel="戻さずに閉じる"
                errorMessage="公開状態の変更に失敗しました"
                onSubmit={mutation.mutateAsync}
                closeDialog={closeDialog}
            />
        </>
    );
};
