import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useAddTodoCategory } from '../../../../../queries/todoCategoryAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../Context';
import { TodoItemFormContent, useTodoCategoryForm } from './TodoCategoryForm';

export const AddCategoryDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const form = useTodoCategoryForm({
        label: '',
    });
    const mutation = useAddTodoCategory();
    return (
        <>
            <DialogTitle>カテゴリの追加</DialogTitle>
            <DialogContent>
                <TodoItemFormContent form={form} />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でカテゴリを追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
