import { Typography, useTheme, type BoxProps } from '@mui/material';
import { FlexBox } from './FlexBox';
import { SearchOffIcon } from './Icons';

export const NoItems = (props: BoxProps) => {
    const theme = useTheme();
    const { children, ...rest } = props;
    return (
        <FlexBox my={2} gap={0.5} justifyContent="center" {...rest}>
            <SearchOffIcon sx={{ color: theme.palette.text.disabled }} />
            <Typography sx={{ color: theme.palette.text.disabled }}>{props.children}</Typography>
        </FlexBox>
    );
};
