import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { SurveyMemo, SurveyPeriod } from '@spec/Survey';
import { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { fullName } from '../../../../domains/Talent';
import { findById } from '../../../../lib/ArrayUtils';
import { useSurveyMemos } from '../../../../queries/survey';
import { useTalentsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { Markdown } from '../../../Markdown';
import { TalentAvatar } from '../../../TalentAvatar';
import { WaitQuery } from '../../../WaitLoading';
import { PostMemoForm } from './PostMemoForm';

export const Memos: React.FC<{
    period: SurveyPeriod;
    talent: Talent;
    index: number;
}> = (props) => {
    const maybeMemos = useSurveyMemos(props.period);
    return (
        <WaitQuery query={maybeMemos} size="medium">
            {({ data }) => {
                const memos = data.filter((v) => v.respondentTalentId === props.talent.id);
                if (memos.length === 0 && props.index !== 0) {
                    return null;
                }
                return (
                    <Box mx={1}>
                        <PostMemoForm period={props.period} talent={props.talent} />
                        <Box mt={1}>
                            {memos.map((v) => (
                                <MemoCard key={v.id} memo={v} />
                            ))}
                        </Box>
                    </Box>
                );
            }}
        </WaitQuery>
    );
};

const MemoCard: React.FC<{ memo: SurveyMemo }> = (props) => {
    const { talents } = useTalentsContext();
    const author = findById(props.memo.authorTalentId, talents);
    return (
        <Card>
            <CardContent>
                <FlexBox gap={1}>
                    <TalentAvatar talent={author} size="small" />
                    <Typography variant="body2">{fullName(author)}</Typography>
                    <Typography variant="body2">
                        {dayjs(props.memo.publishedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                </FlexBox>
                <Box my={1}>
                    <Divider />
                </Box>
                <Markdown source={props.memo.content} variant="body2" />
            </CardContent>
        </Card>
    );
};
