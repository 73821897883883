import {
    NotesArticleResponse,
    NotesArticleSummariesResponse,
    NotesAuthorArticleSummariesResponse,
    NotesLikesResponse,
    NotesNotificationsResponse,
    NotesPinnedArticleSummariesResponse,
    NotesTagsResponse,
    PostNotesArticleRequest,
    PostNotesArticleResponse,
    UploadImageResponse,
} from '@spec/Notes';
import {
    QueryClient,
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { ApplicationError } from '../Errors';
import { useTenantContext } from '../components/Context';
import { deleteCache } from '../components/notes/Hooks';
import { Gateway, blobToBase64, useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useNotesArticles = () => {
    const gateway = useGateway();
    return useInfiniteQuery({
        queryKey: [queryKey.notes],
        queryFn: ({ pageParam }) =>
            gateway.get<NotesArticleSummariesResponse>(
                `/notes/articles${pageParam ? `?offset=${pageParam}` : ''}`
            ),
        initialPageParam: 0,
        getNextPageParam: (res) => res.nextId,
    });
};

export const useSearchArticles = (word: string) => {
    const gateway = useGateway();
    return useInfiniteQuery({
        queryKey: [queryKey.notes, 'search', word],
        queryFn: ({ pageParam }) => {
            const params = new URLSearchParams({ word });
            if (pageParam > 0) {
                params.set('offset', `${pageParam}`);
            }
            return gateway.get<NotesArticleSummariesResponse>(
                `/notes/articles/search?${params.toString()}`
            );
        },
        initialPageParam: 0,
        getNextPageParam: (res) => res.nextId,
    });
};

export const useAuthorsNote = (hitonowaId: string) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notes, 'author', hitonowaId],
        queryFn: () =>
            gateway.get<NotesAuthorArticleSummariesResponse>(
                `/notes/articles/author/@${hitonowaId}`
            ),
    });
};

export const useTaggedNote = (tag: string) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notes, 'tag', tag],
        queryFn: () =>
            gateway.get<NotesAuthorArticleSummariesResponse>(`/notes/articles/tag/${tag}`),
    });
};

export const useNotesArticle = (articleId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notes, articleId],
        queryFn: () => gateway.get<NotesArticleResponse>(`/notes/articles/${articleId}`),
    });
};

export const usePinnedArticles = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notes, 'pinned'],
        queryFn: () => gateway.get<NotesPinnedArticleSummariesResponse>(`/notes/articles/pinned`),
    });
};

const invalidate = (queryClient: QueryClient) =>
    queryClient.invalidateQueries({ queryKey: [queryKey.notes] });

export const usePostArticle = () => {
    const queryClient = useQueryClient();
    const gateway = useGateway();
    const { tenant } = useTenantContext();
    return useMutation({
        mutationFn: async (args: PostNotesArticleRequest) => {
            const res = await gateway.post<PostNotesArticleResponse>('/notes/articles', args);
            await invalidate(queryClient);
            deleteCache(tenant);
            return res.articleId;
        },
    });
};

export const useUpdateArticle = (articleId: number) => {
    const queryClient = useQueryClient();
    const gateway = useGateway();
    return useMutation({
        mutationFn: (args: PostNotesArticleRequest) =>
            gateway.put(`/notes/articles/${articleId}`, args),
        onSuccess: () => invalidate(queryClient),
    });
};

export const useNotesLikes = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notes, 'likes'],
        queryFn: () => gateway.get<NotesLikesResponse>('/notes/likes'),
    });
};

export const useToggleLikeArticle = (
    queryClient: QueryClient,
    articleId: number,
    liked: boolean
) => {
    const gateway = useGateway();
    return useMutation({
        mutationFn: () => {
            const url = `/notes/articles/${articleId}/like`;
            return liked ? gateway.delete(url) : gateway.post(url);
        },
        onSuccess: () => invalidate(queryClient),
    });
};

export const useTogglePinArticle = (
    queryClient: QueryClient,
    articleId: number,
    pinned: boolean
) => {
    const gateway = useGateway();
    return useMutation({
        mutationFn: () => {
            const url = `/notes/articles/${articleId}/pin`;
            return pinned ? gateway.delete(url) : gateway.post(url);
        },
        onSuccess: () => invalidate(queryClient),
    });
};

export const uploadImage = async (gateway: Gateway, file: File): Promise<string> => {
    const image = await blobToBase64(file);
    if (typeof image !== 'string') {
        throw new ApplicationError('unknown image type');
    }
    return gateway
        .post<UploadImageResponse>('/notes/image', { image })
        .then((res) => `/${res.path}`);
};

export const useNotesTags = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notes, 'tags'],
        queryFn: () => gateway.get<NotesTagsResponse>('/notes/tags').then((res) => res.tags),
    });
};

export const useNotesNotifications = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.notes, 'notifications'],
        queryFn: () =>
            gateway
                .get<NotesNotificationsResponse>('/notes/notifications')
                .then((res) => res.notifications),
        staleTime: 60 * 1000,
        refetchOnWindowFocus: true,
    });
};

export const usePostComment = (articleId: number) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (content: string) =>
            gateway.post(`/notes/articles/${articleId}/comment`, { content }),
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: [queryKey.notes, articleId] }),
            ]);
        },
    });
};

export const useMarkNotesNotificationAsRead = () => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (notificationDate: Date) =>
            gateway.post(`/notes/notifications`, { readAt: notificationDate }),
        onSuccess: () =>
            queryClient.invalidateQueries({ queryKey: [queryKey.notes, 'notifications'] }),
    });
};
