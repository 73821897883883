import { Env } from './Contract';

export const env: Env = {
    apiHost: 'https://api.chihaya.dev',
    apiEncryptionKey: 's99ngw18b32DrvB44uTBGJChL5ngiqvw',
    sentryOptions: {
        environment: 'staging',
        tracesSampleRate: 1.0,
    },
    firebaseOptions: {
        apiKey: 'AIzaSyCO6zOIG5ymmRTDbubl7mrQPzsyHBrbcgI',
        authDomain: 'chihaya.dev',
        projectId: 'chihaya-dev',
        storageBucket: 'chihaya-dev.appspot.com',
        messagingSenderId: '921296305320',
        appId: '1:921296305320:web:1f53aa3c991d67c3e2969d',
        measurementId: 'G-BGH06FY962',
    },
    enableRegister: true,
    wakeUpRequest: true,
    queryClientStaleSeconds: 60,
};
