import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { unparse } from 'papaparse';
import React, { useEffect, useState } from 'react';
import { reportInfo } from '../../../Errors';
import { LeavesRow, getDeadline } from '../../../domains/SabbaticalLeave';
import { fullName } from '../../../domains/Talent';
import { generateCsv, removeCsvUrl } from '../../../lib/Csv';
import { useMeContext } from '../../../queries/me';

export const CsvDownload: React.FC<{ rows: LeavesRow[] }> = (props) => {
    const { me } = useMeContext();
    const [csvUrl, setCsvUrl] = useState<string | null>(null);
    const format = (v?: Date) => (v ? dayjs(v).format('YYYY-MM-DD') : '');
    useEffect(() => {
        const fields = ['社員番号', '氏名', '前回発生日', '取得期限', '次回発生日'];
        const data = props.rows.map((row) => {
            return [
                row.talent.employment.employeeCode,
                fullName(row.talent),
                format(row.leaves[0]?.offeredAt),
                row.leaves[0] ? getDeadline(row.leaves[0]).format('YYYY-MM-DD') : '',
                format(row.next?.offeringAt),
            ];
        });
        const url = generateCsv(unparse({ fields, data }));
        setCsvUrl(url);
        return () => {
            if (url !== null) {
                removeCsvUrl(url);
            }
        };
    }, [props.rows]);
    return (
        <Box textAlign="center">
            {csvUrl && (
                <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    startIcon={<DownloadIcon />}
                    href={csvUrl}
                    download="sabbatical_leaves.csv"
                    onClick={() => {
                        reportInfo(`${me.hitonowaId} downloads selections CSV`);
                    }}
                >
                    CSVをダウンロードする
                </Button>
            )}
        </Box>
    );
};
