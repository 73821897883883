import { Talent } from '@spec/Talent';
import React from 'react';
import { SuspendButton, UnsuspendButton } from './SuspendButton';

export const SuspendTalent: React.FC<{ talent: Talent }> = (props) => {
    if (props.talent.isSuspended) {
        return <UnsuspendButton talent={props.talent} />;
    }
    return <SuspendButton talent={props.talent} />;
};
