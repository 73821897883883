import type { EditTodoCategoryRequest } from '@spec/Todo';
import { useMutation, useQueryClient, type MutationFunction } from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

const useTodoCategoryMutation = <T = void>(mutationFn: MutationFunction<void, T>) => {
    const queryClient = useQueryClient();
    return useMutation<void, Error, T>({
        mutationFn,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey.todoCategories] }),
    });
};

export const useAddTodoCategory = () => {
    const gateway = useGateway();
    return useTodoCategoryMutation<EditTodoCategoryRequest>((args) =>
        gateway.post('/todo/categories', args)
    );
};

export const useUpdateTodoCategory = (categoryId: number) => {
    const gateway = useGateway();
    return useTodoCategoryMutation<EditTodoCategoryRequest>((args) =>
        gateway.put(`/todo/categories/${categoryId}`, args)
    );
};

export const useDeleteTodoCategory = (categoryId: number) => {
    const gateway = useGateway();
    return useTodoCategoryMutation(() => gateway.delete(`/todo/categories/${categoryId}`));
};
