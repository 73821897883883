import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { getGitHubUrl } from '../../Routes';
import { FlexBox } from '../FlexBox';
import { ExternalLink } from '../RouterLink';

const ICON_SIZE = 14;

export const GitHubId: React.FC<{
    githubId: string | null;
    disableLink?: boolean;
}> = (props) => {
    const theme = useTheme();
    if (props.githubId === null) {
        return null;
    }
    const imageFileSuffix = theme.palette.mode === 'dark' ? '-white' : '';
    return (
        <FlexBox flexWrap="nowrap" gap={0.5}>
            <img
                src={`/images/github-mark${imageFileSuffix}.svg`}
                style={{ width: ICON_SIZE, height: ICON_SIZE }}
            />
            {props.disableLink === true ? (
                <Typography variant="body2">{props.githubId}</Typography>
            ) : (
                <ExternalLink variant="body2" href={getGitHubUrl(props.githubId)}>
                    {props.githubId}
                </ExternalLink>
            )}
        </FlexBox>
    );
};
