import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { Team, TeamId } from '@spec/Organization';
import React from 'react';
import { generateBreadcrumbs } from '../domains/Organization';
import { useTeamsContext } from './Context';
import { FlexBox } from './FlexBox';

// Use this as a default teamId
export const ROOT_TEAM_ID = 3;

export const TeamDrill: React.FC<{
    teamId: TeamId | null;
    setTeamId: (v: TeamId | null) => void;
    filterTeam?: (v: Team) => boolean;
}> = (props) => {
    const { availableTeams } = useTeamsContext();
    const rootTeams = availableTeams.filter((v) => v.parentId === null);
    if (props.teamId === null) {
        if (rootTeams.length === 1) {
            props.setTeamId(rootTeams[0].id);
        }
        return (
            <Selector
                parentId={null}
                teamId={null}
                setTeamId={props.setTeamId}
                filterTeam={props.filterTeam}
            />
        );
    }
    const bread = generateBreadcrumbs(props.teamId, availableTeams);
    const children = availableTeams.filter((v) => v.parentId === props.teamId);
    return (
        <FlexBox>
            {bread.map((v) => {
                return (
                    <Selector
                        key={v.id}
                        parentId={v.parentId}
                        teamId={v.id}
                        setTeamId={props.setTeamId}
                        filterTeam={props.filterTeam}
                    />
                );
            })}
            {children.length > 0 && (
                <Selector
                    parentId={props.teamId}
                    teamId={null}
                    setTeamId={props.setTeamId}
                    filterTeam={props.filterTeam}
                />
            )}
        </FlexBox>
    );
};

const Selector: React.FC<{
    parentId: TeamId | null;
    teamId: TeamId | null;
    setTeamId: (v: TeamId | null) => void;
    filterTeam?: (v: Team) => boolean;
}> = (props) => {
    const f = () => true;
    const { availableTeams } = useTeamsContext();
    const teams = availableTeams
        .filter(props.filterTeam ?? f)
        .filter((v) => v.parentId === props.parentId);
    if (teams.length === 0) {
        return null;
    }
    const rootTeams = availableTeams.filter((v) => v.parentId === null);
    if (rootTeams.length === 1 && props.teamId === rootTeams[0].id) {
        return null;
    }
    const hasChildren = availableTeams.some((v) => v.parentId === props.teamId);
    return (
        <FlexBox>
            <TextField
                select
                value={props.teamId ?? props.parentId ?? '-'}
                onChange={(e) => {
                    const v = Number(e.target.value);
                    props.setTeamId(Number.isNaN(v) ? null : v);
                }}
            >
                <MenuItem value={props.parentId ?? '-'}>指定なし</MenuItem>
                {teams.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                        {v.name}
                    </MenuItem>
                ))}
            </TextField>
            {props.teamId && hasChildren && (
                <Box mx={1}>
                    <Typography>›</Typography>
                </Box>
            )}
        </FlexBox>
    );
};
