import {
    Checkbox,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import type {
    EditSurveyQuestionRequest,
    SurveyBooleanQuestion,
    SurveyLikertQuestion,
    SurveyTextQuestion,
} from '@spec/Survey';
import { isBooleanQuestion, isLikertQuestion, isTextQuestion } from '../../../../../domains/Survey';
import { ApplicationError } from '../../../../../Errors';
import {
    FormTextField,
    useForm,
    useFormText,
    type Form,
    type FormText,
} from '../../../../../lib/Form';
import { maxLength, numberRange, numeric, required } from '../../../../../lib/Form/Validators';
import { FlexBox } from '../../../../FlexBox';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useEditQuestionContext } from '../Context';
import { useUpdateSurveyQuestion } from '../../../../../queries/surveyAdmin';

export const EditQuestionDialog = () => {
    const { question } = useEditQuestionContext();
    if (question.value === null) {
        throw new ApplicationError('question is not stored in the context');
    }
    const q = question.value;
    return (
        <>
            <DialogTitle>「{q.title}」を編集する</DialogTitle>
            {isLikertQuestion(q) === true && <LikertForm question={q} />}
            {isBooleanQuestion(q) === true && <BooleanForm question={q} />}
            {isTextQuestion(q) === true && <TextForm question={q} />}
        </>
    );
};

const Actions = (props: { form: Form<any>; serialize: () => EditSurveyQuestionRequest }) => {
    const { closeDialog } = useDialogContext();
    const { question } = useEditQuestionContext();
    if (question.value === null) {
        throw new ApplicationError('question is not stored in the context');
    }
    const mutation = useUpdateSurveyQuestion(question.value.id);
    return (
        <DialogActionButtons
            form={props.form}
            submitLabel="この質問を更新する"
            cancelLabel="更新せずに閉じる"
            errorMessage="更新に失敗しました"
            onSubmit={() => mutation.mutateAsync(props.serialize())}
            closeDialog={closeDialog}
        />
    );
};

const LikertForm = (props: { question: SurveyLikertQuestion }) => {
    const q = props.question;
    const form = useForm({
        shortTitle: useFormText(q.shortTitle, [required, maxLength(191)]),
        title: useFormText(q.title, [required, maxLength(191)]),
        minLabel: useFormText(q.minLabel, [required, maxLength(191)]),
        maxLabel: useFormText(q.maxLabel, [required, maxLength(191)]),
        minScore: useFormText(`${q.minScore}`, [required, numeric, numberRange(1, 10)]),
        maxScore: useFormText(`${q.maxScore}`, [required, numeric, numberRange(1, 10)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
                <DialogRow label="スコアとラベル">
                    <FlexBox gap={1}>
                        <ScoreRange formText={form.minScore} />
                        <FormTextField formText={form.minLabel} />
                        <Typography flexGrow={1} textAlign="center">
                            〜
                        </Typography>
                        <FormTextField formText={form.maxLabel} />
                        <ScoreRange formText={form.maxScore} />
                    </FlexBox>
                </DialogRow>
            </DialogContent>
            <Actions
                form={form}
                serialize={() => {
                    const args = form.serialize();
                    return {
                        ...args,
                        minScore: Number(args.minScore),
                        maxScore: Number(args.maxScore),
                    };
                }}
            />
        </>
    );
};

const ScoreRange = (props: { formText: FormText }) => {
    const { formText } = props;
    const scoreRange = Array.from({ length: 10 }, (_, i) => i + 1);
    return (
        <TextField
            select
            value={formText.value}
            onChange={(e) => formText.setValue(e.target.value)}
        >
            {scoreRange.map((v) => (
                <MenuItem key={v} value={`${v}`}>
                    {v}
                </MenuItem>
            ))}
        </TextField>
    );
};

const BooleanForm = (props: { question: SurveyBooleanQuestion }) => {
    const q = props.question;
    const form = useForm({
        shortTitle: useFormText(q.shortTitle, [required, maxLength(191)]),
        title: useFormText(q.title, [required, maxLength(191)]),
        label: useFormText(q.label, [required, maxLength(191)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
                <DialogRow label="チェックボックスのラベル">
                    <Checkbox disabled checked />
                    <FormTextField formText={form.label} />
                </DialogRow>
            </DialogContent>
            <Actions form={form} serialize={form.serialize} />
        </>
    );
};

const TextForm = (props: { question: SurveyTextQuestion }) => {
    const q = props.question;
    const form = useForm({
        shortTitle: useFormText(q.shortTitle, [required, maxLength(191)]),
        title: useFormText(q.title, [required, maxLength(191)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
            </DialogContent>
            <Actions form={form} serialize={form.serialize} />
        </>
    );
};
