import {
    Avatar,
    Checkbox,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Typography,
    useTheme,
} from '@mui/material';
import { SurveyQuestion } from '@spec/Survey';
import { useState } from 'react';
import { useEmptyForm } from '../../../../../lib/Form';
import { useLinkSurveyQuestions } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useSurveysContext } from '../../../Context';
import { QuestionTypeIcon } from '../../survey/QuestionList';
import { useCurrentGroupContext, useDialogContext, useEditPeriodContext } from '../Context';

export const LinkQuestionsDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { surveyGroup } = useCurrentGroupContext();
    const { surveys } = useSurveysContext();
    const { period } = useEditPeriodContext();
    const v = period.value;
    if (v === null) {
        throw Error('target period was not set');
    }
    const [checked, setChecked] = useState(new Set(v.questions.map((q) => q.id)));
    const onCheck = (questionId: number) => {
        setChecked((before) => {
            if (before.has(questionId)) {
                before.delete(questionId);
            } else {
                before.add(questionId);
            }
            return new Set(before);
        });
    };
    const form = useEmptyForm();
    const mutation = useLinkSurveyQuestions(v.id);
    return (
        <>
            <DialogTitle>「{v.name}」に設問を紐付ける</DialogTitle>
            <DialogContent>
                <Typography>選択した設問が回答画面に表示されるようになります。</Typography>
                <Typography mt={0.5}>既に回答済みの結果には影響しません。</Typography>
                <List dense>
                    {surveys
                        .filter((v) => v.surveyGroupId === surveyGroup.id)
                        .map((survey) => (
                            <>
                                <ListSubheader key={survey.id} disableGutters>
                                    {survey.name}
                                </ListSubheader>
                                {survey.questions.map((q) => (
                                    <QuestionListItem
                                        key={q.id}
                                        q={q}
                                        selected={checked.has(q.id)}
                                        onClick={onCheck}
                                    />
                                ))}
                            </>
                        ))}
                </List>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="チェックした設問を紐付ける"
                cancelLabel="更新せずに閉じる"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync({ questionIds: [...checked] })}
                closeDialog={closeDialog}
            />
        </>
    );
};

const QuestionListItem: React.FC<{
    q: SurveyQuestion;
    selected: boolean;
    onClick: (questionId: number) => void;
}> = ({ q, selected, onClick }) => {
    const theme = useTheme();
    const iconColor = selected ? theme.palette.primary.main : undefined;
    return (
        <ListItem disablePadding>
            <ListItemButton onClick={() => onClick(q.id)}>
                <ListItemIcon>
                    <Checkbox checked={selected} disableRipple />
                </ListItemIcon>
                <Avatar variant="rounded" sx={{ width: 20, height: 20, bgcolor: iconColor }}>
                    <QuestionTypeIcon q={q} fontSize="small" />
                </Avatar>
                <Typography ml={1} color={selected ? '' : theme.palette.text.disabled}>
                    {q.shortTitle}
                </Typography>
            </ListItemButton>
        </ListItem>
    );
};
