import { Box, Typography, useTheme } from '@mui/material';

export const LengthCounter: React.FC<{ text: string; maxLength: number }> = ({
    text,
    maxLength,
}) => {
    const theme = useTheme();
    const charsLeft = maxLength - text.length;
    const threshold = maxLength > 255 ? maxLength * 0.05 : 10;
    if (charsLeft > threshold) {
        return null;
    }
    const color = charsLeft > 0 ? theme.palette.warning.main : theme.palette.error.main;
    return (
        <Box textAlign="right">
            <Typography textAlign="right" variant="button" sx={{ color }}>
                <span>{text.length}</span>
                <span style={{ margin: '0 2px' }}>/</span>
                <span>{maxLength}</span>
            </Typography>
        </Box>
    );
};
