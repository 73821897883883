import { Typography, useTheme } from '@mui/material';
import { Team } from '@spec/Organization';
import dayjs from 'dayjs';
import React, { type ReactNode } from 'react';
import { getTeamUrl } from '../../../Routes';
import { useCurrentTimeContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { RouterLink } from '../../RouterLink';
import { UnitText } from '../../UnitText';

export const TeamCard: React.FC<{ team: Team; memberAmount: number | null; showCode: boolean }> = (
    props
) => {
    const theme = useTheme();
    const { currentTime } = useCurrentTimeContext();
    const teamName = props.showCode
        ? `${props.team.name}（${props.team.code ?? '-'}）`
        : props.team.name;
    const grey = theme.palette.grey[500];
    if (props.team.establishedAt > currentTime) {
        return (
            <RouterLink to={getTeamUrl(props.team.id)} modal>
                <RootBox>
                    <Typography sx={{ color: grey }}>{teamName}</Typography>
                    <Typography sx={{ color: grey }} variant="body2">
                        {dayjs(props.team.establishedAt).format('（YYYY-MM-DD〜）')}
                    </Typography>
                </RootBox>
            </RouterLink>
        );
    }
    if (props.team.abolishedAt !== null && props.team.abolishedAt < currentTime) {
        return (
            <RouterLink to={getTeamUrl(props.team.id)} modal>
                <RootBox>
                    <Typography sx={{ color: grey, textDecoration: 'line-through' }}>
                        {teamName}
                    </Typography>
                    <Typography sx={{ color: grey }} variant="body2">
                        {dayjs(props.team.abolishedAt).format('（〜YYYY-MM-DD）')}
                    </Typography>
                </RootBox>
            </RouterLink>
        );
    }
    return (
        <RootBox>
            <RouterLink to={getTeamUrl(props.team.id)} modal>
                {teamName}
            </RouterLink>
            <Typography pl={1} pr={2} variant="body2" color="textSecondary">
                {props.memberAmount ?? '-'}
                <UnitText text="名" />
            </Typography>
        </RootBox>
    );
};

function RootBox(props: { children: ReactNode }) {
    return <FlexBox alignItems="baseline" p={0.5} sx={{ height: '30px' }} {...props} />;
}
