import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { NoMenuLayout } from './layout/NoMenuLayout';
import { Loading } from './WaitLoading';

const RENDER_WAIT = 1000;

export const FirstLoading: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, RENDER_WAIT);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return (
        <Fade in={show}>
            <div>
                <NoMenuLayout>
                    <Loading />
                </NoMenuLayout>
            </div>
        </Fade>
    );
};
