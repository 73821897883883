import {
    Alert,
    Box,
    Button,
    DialogContent,
    DialogTitle,
    Theme,
    ThemeProvider,
    Typography,
} from '@mui/material';
import React from 'react';
import { createCustomTheme } from '../../../../Theme';
import { useEmptyForm } from '../../../../lib/Form';
import { useDeleteTeam } from '../../../../queries/organization';
import { SubmitButton } from '../../../SubmitButton';
import { useDialogContext, useSelectedTeamContext } from '../Context';

export const DeleteDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { selectedTeam } = useSelectedTeamContext();
    if (selectedTeam.value === null) {
        throw Error('Team does not selected.');
    }
    const team = selectedTeam.value;
    const form = useEmptyForm();
    const mutation = useDeleteTeam(team.id);
    return (
        <>
            <DialogTitle>「{team.name}」を削除します</DialogTitle>
            <DialogContent>
                <Typography variant="h6" color="error">
                    この操作は取り消せません！
                </Typography>
                <Box mt={1}>
                    <Typography variant="body2">
                        組織を削除すると、元には戻せなくなります。
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body2">
                        部署の統廃合などがあった場合は削除ではなく、編集から「廃止日」を設定してください。
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body2">
                        この機能は間違えて作ってしまった組織を削除するためのものです。
                    </Typography>
                </Box>
                {form.failed && (
                    <Box mt={1}>
                        <Alert severity="error">削除に失敗しました</Alert>
                    </Box>
                )}
                <Box textAlign="center" mt={4} mb={1} mx={8}>
                    <ThemeProvider
                        theme={(theme: Theme) =>
                            createCustomTheme({
                                ...theme,
                                palette: { primary: theme.palette.error },
                            })
                        }
                    >
                        <SubmitButton
                            fullWidth
                            variant="contained"
                            inProgress={form.inProgress}
                            onClick={() => {
                                form.send(mutation.mutateAsync().then(closeDialog));
                            }}
                        >
                            {team.name}を完全に削除する
                        </SubmitButton>
                    </ThemeProvider>
                    <Box mt={4}>
                        <Button variant="outlined" size="medium" onClick={closeDialog}>
                            キャンセル
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </>
    );
};
