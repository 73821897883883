import { Alert, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMeContext } from '../../../queries/me';
import { usePostArticle } from '../../../queries/notes';
import { ActionContainer } from '../../ActionButtons';
import { EditIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { SubmitButton } from '../../SubmitButton';
import { ArticleFormContent, toPostNotesArticleRequest, useArticleForm } from '../ArticleForm';
import { getArticleUrl } from '../urls';

export const Post: React.FC = () => {
    const { me } = useMeContext();
    const [form, setCache] = useArticleForm(null, '', '', '', false);
    const navigate = useNavigate();
    const mutation = usePostArticle();
    return (
        <Box>
            <Box mx={2} mb={3}>
                <SubTitle title="新しいノートを書く" />
            </Box>
            <ArticleFormContent form={form} setCache={setCache} />
            <ActionContainer>
                <SubmitButton
                    fullWidth
                    inProgress={form.inProgress}
                    disabled={form.hasErrorElement}
                    startIcon={<EditIcon />}
                    onClick={() => {
                        form.send(
                            mutation
                                .mutateAsync(toPostNotesArticleRequest(form))
                                .then((articleId) =>
                                    navigate(getArticleUrl(articleId, me.hitonowaId))
                                )
                        );
                    }}
                >
                    投稿する
                </SubmitButton>
            </ActionContainer>
            {form.failed && (
                <Box mt={2}>
                    <Alert severity="error">記事の投稿に失敗しました</Alert>
                </Box>
            )}
        </Box>
    );
};
