import { Talent } from '@spec/Talent';
import {
    CompanyValue,
    ValueFeedback,
    ValueFeedbackFeedback,
    ValueFeedbackSelection,
} from '@spec/ValueFeedback';
import type { ValueFeedbackNotification } from '@spec/notification';
import dayjs from 'dayjs';
import { getValueFeedbackUrl } from '../Routes';
import { ValueFeedbackStatus, scoreLabels } from '../components/valueFeedback/Contract';
import type { Announcement } from './Todo';

export const companyValueAsString = (v: CompanyValue): string => `${v.id}. ${v.label}`;

export const scoreAsString = (score: number | null): string | null => {
    if (score === null) {
        return null;
    }
    if (score === 0) {
        return scoreLabels.get(score);
    }
    return `${score}: ${scoreLabels.get(score)}`;
};

export const getStatus = (v: ValueFeedback, date: Date): ValueFeedbackStatus => {
    const d = dayjs(date);
    if (v.publishedAt !== null) {
        return 'published';
    }
    if (dayjs(v.finishFeedbackAt) < d) {
        return 'confirmFeedback';
    }
    if (dayjs(v.startFeedbackAt) < d) {
        return 'feedback';
    }
    if (dayjs(v.finishSelectionAt) < d) {
        return 'confirmSelection';
    }
    if (dayjs(v.startSelectionAt) < d) {
        return 'selection';
    }
    return 'prepare';
};

export const isActive = (v: ValueFeedback, date: Date): boolean =>
    dayjs(date).isBetween(dayjs(v.startSelectionAt), dayjs(v.finishFeedbackAt), null, '[)');

export const sortValueFeedbacksPreferLatest = (
    _a: ValueFeedback,
    _b: ValueFeedback
): -1 | 0 | 1 => {
    const a = dayjs(_a.startSelectionAt);
    const b = dayjs(_b.startSelectionAt);
    if (a < b) {
        return 1;
    }
    if (a > b) {
        return -1;
    }
    return 0;
};

export const filterValueFeedbackCandidates = (v: Talent): boolean =>
    /^c[abd]/.test(v.employment.employeeCode) && v.employment.employmentStatus !== 'パート';

export const isConfirmed = (v: ValueFeedbackFeedback): boolean => v.confirmedAt instanceof Date;

export const hasIgnoredSelection = (
    selections: ValueFeedbackSelection[],
    feedbacks: ValueFeedbackFeedback[]
): boolean =>
    selections
        .map((selection) => feedbacks.find((v) => v.selection.talentId === selection.talentId))
        .filter((v) => v === undefined).length > 0;

export const buildAnnouncement = (v: ValueFeedbackNotification): Announcement[] => {
    const f = (
        v: ValueFeedbackNotification,
        contents: string[],
        actionLabel: string
    ): Announcement => ({
        id: v.id,
        key: '',
        title: `${v.payload.name} バリューフィードバック`,
        publishedAt: v.occurredAt,
        contents,
        action: {
            label: actionLabel,
            url: getValueFeedbackUrl(v.payload),
        },
    });
    switch (v.action) {
        case 'startSelection':
            return [
                f(
                    v,
                    [
                        'バリューフィードバックが始まりました。',
                        `フィードバック者の選定期間は${dayjs(v.payload.finishSelectionAt).format('M/D(ddd)')}までです。`,
                    ],
                    'フィードバック者を選ぶ'
                ),
            ];
        case 'remindSelection':
            return [
                f(
                    v,
                    [
                        'フィードバック者の選択期間がそろそろ終わります。',
                        `選定期間は${dayjs(v.payload.finishSelectionAt).format('M/D(ddd)')}までです。`,
                    ],
                    'フィードバック者を選ぶ'
                ),
            ];
        case 'startFeedback':
            return [
                f(
                    v,
                    [
                        '自己振り返りとフィードバックが書けるようになりました。',
                        `記入期間は${dayjs(v.payload.finishFeedbackAt).format('M/D(ddd)')}までです。`,
                    ],
                    '自己振り返り・フィードバックを記入する'
                ),
            ];
        case 'remindFeedback':
            return [
                f(
                    v,
                    [
                        '自己振り返りとフィードバックの記入期間がそろそろ終わります。',
                        `記入期間は${dayjs(v.payload.finishFeedbackAt).format('M/D(ddd)')}までです。`,
                    ],
                    '自己振り返り・フィードバックを記入する'
                ),
            ];
        case 'publish':
            return [
                f(
                    v,
                    ['バリューフィードバックが公開されました。'],
                    '自己振り返り・フィードバックを見る'
                ),
            ];
        default:
            return [];
    }
};
