import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Alert } from '@mui/material';
import { env } from '@env';

export const DevelopmentAlert: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [open, setOpen] = useState(true);
    if (env.sentryOptions.environment !== 'development' || open === false) {
        return null;
    }
    return (
        <Box mt={1} mx={1}>
            <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
                You are in development mode.
            </Alert>
        </Box>
    );
};
