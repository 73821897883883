import { Box, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { DependencyList, EffectCallback, useEffect, useRef, useState } from 'react';
import { FormDate } from '../lib/Form';
import { zenToHan } from '../lib/Form/Filters';
import { FlexBox } from './FlexBox';

const format = (v: Date | null): string | null => (v ? dayjs(v).format('YYYY-MM-DD') : null);

const useDidMountEffect = (func: EffectCallback, deps: DependencyList) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export const DateTimeInput: React.FC<{
    formDate: FormDate;
    min?: Date;
    max?: Date;
    autoFocus?: boolean;
}> = (props) => {
    const [year, setYear] = useState<number | null>(props.formDate.value?.getFullYear() ?? null);
    const [month, setMonth] = useState<number | null>(
        props.formDate.value ? props.formDate.value.getMonth() + 1 : null
    );
    const [day, setDay] = useState<number | null>(props.formDate.value?.getDate() ?? null);
    const [time, setTime] = useState(
        props.formDate.value === null ? '' : dayjs(props.formDate.value).format('HH:mm')
    );
    const [date, setDate] = useState<Date | null>(props.formDate.value);

    useDidMountEffect(() => {
        if (year === null || month === null || day === null) {
            setDate(null);
            return;
        }
        const nextString = `${year}-${month}-${day} ${time}`;
        const next = dayjs(nextString);
        if (
            nextString !== next.format('YYYY-M-D HH:mm') ||
            (props.min && props.min.getTime() > next.toDate().getTime()) ||
            (props.max && props.max.getTime() < next.toDate().getTime())
        ) {
            setDate(null);
        } else {
            setDate(next.toDate());
        }
    }, [year, month, day, time, props.min, props.max]);

    useDidMountEffect(() => {
        if (format(date) !== format(props.formDate.value)) {
            props.formDate.setValue(date);
        }
    }, [format(date)]);

    const maybeDate = `${year}-${month}-${day}`;
    const weekday =
        dayjs(maybeDate).format('YYYY-M-D') === maybeDate ? dayjs(maybeDate).format('ddd') : '-';

    return (
        <FlexBox>
            <TextField
                autoFocus={props.autoFocus}
                sx={{ width: '4rem' }}
                error={date === null}
                value={year ?? ''}
                onChange={(e) => setYear(toNullishNumber(e))}
                slotProps={{
                    htmlInput: { maxLength: 4 },
                }}
            ></TextField>
            <Box ml={0.5} mr={1}>
                <Typography>年</Typography>
            </Box>
            <TextField
                sx={{ width: '3rem', '& .MuiInputBase-input': { textAlign: 'right' } }}
                error={date === null}
                value={month ?? ''}
                onChange={(e) => setMonth(toNullishNumber(e))}
                slotProps={{
                    htmlInput: { maxLength: 2 },
                }}
            ></TextField>
            <Box ml={0.5} mr={1}>
                <Typography>月</Typography>
            </Box>
            <TextField
                sx={{ width: '3rem', '& .MuiInputBase-input': { textAlign: 'right' } }}
                error={date === null}
                value={day ?? ''}
                onChange={(e) => setDay(toNullishNumber(e))}
                slotProps={{
                    htmlInput: { maxLength: 2 },
                }}
            ></TextField>
            <Box ml={0.5}>
                <Typography>日</Typography>
            </Box>
            <Box mr={1} sx={{ width: '2rem' }}>
                <Typography>（{weekday}）</Typography>
            </Box>
            <Box>
                <TextField
                    error={date === null}
                    value={time}
                    onChange={(e) => {
                        const value = zenToHan(e.target.value).replace(/[^0-9]/g, '');
                        const m = value.match(/^([0-9]{2})([0-9]+)$/);
                        setTime(m === null ? value : `${m[1]}:${m[2]}`);
                    }}
                    sx={{ width: '5rem' }}
                    slotProps={{
                        htmlInput: {
                            maxLength: 5,
                            style: { textAlign: 'center' },
                        },
                    }}
                />
            </Box>
        </FlexBox>
    );
};

const toNullishNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
): number | null => {
    const x = parseInt(zenToHan(e.target.value), 10);
    return Number.isNaN(x) ? null : x;
};
