import { Typography } from '@mui/material';
import type { Secondment } from '@spec/Talent';
import { FlexBox } from '../../FlexBox';
import { CopyableEmail } from './CopyableEmail';

export function SecondmentRow(props: { secondment: Secondment }) {
    const v = props.secondment;
    return (
        <FlexBox>
            <Typography variant="body2">{v.assignmentType} :</Typography>
            <Typography ml={0.5} mr={2} variant="body2">
                {v.companyName}
            </Typography>
            {v.email !== '' && <CopyableEmail email={v.email} />}
        </FlexBox>
    );
}
