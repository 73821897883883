import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IconCircle } from '../IconCircle';
import { ClearIcon } from '../Icons';

export const NotFound: React.FC<
    React.PropsWithChildren<{ title?: string; messages?: string[] }>
> = (props) => {
    const title = props.title ?? 'ページが見つかりません';
    const messages = props.messages ?? ['指定のURLは存在しないか、閲覧権限がありません。'];
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    useEffect(() => setCurrentUrl(window.location.href), [location]);
    return (
        <Box my={10} textAlign="center">
            <Box mb={4}>
                <IconCircle icon={ClearIcon} color={grey[400]} />
            </Box>
            <Box mb={3}>
                <Typography variant="h6">{title}</Typography>
            </Box>
            {messages.map((v, i) => (
                <Box key={i} mb={2}>
                    <Typography>{v}</Typography>
                </Box>
            ))}
            <Box mb={6}>
                <Typography sx={{ fontWeight: 700 }}>{currentUrl}</Typography>
            </Box>
        </Box>
    );
};
