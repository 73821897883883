import type { Privilege } from '@spec/Grants';
import type { Talent } from '@spec/Talent';
import { createContext, useContext, useState, type ReactNode } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode = 'GrantPrivilege' | 'RevokePrivilege';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const TargetTalentContext = createContext({} as { talent: Talent });
const TargetTalentContextProvider = (props: { talent: Talent; children: ReactNode }) => {
    return (
        <TargetTalentContext.Provider value={{ talent: props.talent }}>
            {props.children}
        </TargetTalentContext.Provider>
    );
};
export const useTargetTalentContext = () => useContext(TargetTalentContext);

const PrivilegeContext = createContext(
    {} as {
        grantedPrivileges: Set<Privilege>;
        privilege: Privilege | null;
        setPrivilege: (v: Privilege | null) => void;
    }
);
const PrivilegeContextProvider = (props: {
    grantedPrivileges: Set<Privilege>;
    children: ReactNode;
}) => {
    const [privilege, setPrivilege] = useState<Privilege | null>(null);
    return (
        <PrivilegeContext.Provider
            value={{
                grantedPrivileges: props.grantedPrivileges,
                privilege,
                setPrivilege,
            }}
        >
            {props.children}
        </PrivilegeContext.Provider>
    );
};
export const usePrivilegeContext = () => useContext(PrivilegeContext);

export const ContextProvider = (props: {
    talent: Talent;
    grantedPrivileges: Set<Privilege>;
    children: ReactNode;
}) => {
    return (
        <DialogContextProvider>
            <TargetTalentContextProvider talent={props.talent}>
                <PrivilegeContextProvider grantedPrivileges={props.grantedPrivileges}>
                    {props.children}
                </PrivilegeContextProvider>
            </TargetTalentContextProvider>
        </DialogContextProvider>
    );
};
