import OfflinePinIcon from '@mui/icons-material/TaskAlt';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Typography,
    TypographyProps,
} from '@mui/material';
import { TodoCategory, TodoItem } from '@spec/Todo';
import dayjs from 'dayjs';
import React from 'react';
import { findById } from '../../lib/ArrayUtils';
import { useCurrentTimeContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { ExpandMoreIcon } from '../Icons';
import { SlackMrkDwn } from '../Markdown';
import { UnitText } from '../UnitText';
import { useDialogContext, useTodoItemContext } from './Context';

export const TodoItemCard: React.FC<{
    item: TodoItem;
    categories: TodoCategory[];
    expanded?: boolean;
}> = (props) => {
    const v = props.item;
    const category = findById(v.todoCategoryId, props.categories);
    const { setItem } = useTodoItemContext();
    const { setDialogMode } = useDialogContext();
    return (
        <Box my={1}>
            <Accordion variant="outlined" disableGutters defaultExpanded={props.expanded ?? false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FlexBox gap={1}>
                        <Chip color="primary" label={category.label} />
                        {v.title}
                        <Deadline deadline={v.deadline} />
                    </FlexBox>
                </AccordionSummary>
                <AccordionDetails
                    sx={(theme) => ({
                        borderTop: `1px solid ${theme.palette.divider}`,
                        backgroundColor: theme.palette.background.default,
                    })}
                >
                    <SlackMrkDwn source={v.description} />
                    <FlexBox mt={3}>
                        <Button
                            startIcon={<OfflinePinIcon />}
                            variant="outlined"
                            size="medium"
                            sx={{ margin: 'auto' }}
                            onClick={() => {
                                setItem(v);
                                setDialogMode('ConfirmTodoItem');
                            }}
                        >
                            やることの一覧から外す
                        </Button>
                    </FlexBox>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

const Deadline: React.FC<{ deadline: Date | null }> = (props) => {
    const { currentTime } = useCurrentTimeContext();
    if (props.deadline === null) {
        return null;
    }
    const deadline = dayjs(props.deadline);
    const remainingDays = deadline.startOf('day').diff(dayjs(currentTime).startOf('day'), 'days');
    const p: TypographyProps =
        remainingDays < 2 ? { color: 'error', fontWeight: 'bold' } : { color: 'primary' };
    return (
        <Typography variant="body2" {...p}>
            {deadline.format('M月D日 (ddd) H:mm')}
            <UnitText text="まで" />
        </Typography>
    );
};
