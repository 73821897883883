import { Typography, useTheme } from '@mui/material';
import { Talent } from '@spec/Talent';
import React from 'react';
import { useSlackChannels } from '../../queries/slack';
import { FlexBox } from '../FlexBox';
import { SlackLink } from '../RouterLink';
import { WaitQuery } from '../WaitLoading';

export const SlackChannel: React.FC<{ talent: Talent }> = (props) => {
    const theme = useTheme();
    const maybeChannels = useSlackChannels();
    const channelId = props.talent.slackChannelId;
    if (channelId === null) {
        return null;
    }
    return (
        <WaitQuery query={maybeChannels}>
            {({ data }) => {
                const channel = data.find((v) => v.id === channelId);
                return channel === undefined ? (
                    <FlexBox flexWrap="nowrap">
                        <img
                            src="/images/Slack_Mark.svg"
                            style={{
                                width: 20,
                                height: 20,
                            }}
                        />
                        <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>
                            チャンネルが見つかりません
                        </Typography>
                    </FlexBox>
                ) : (
                    <FlexBox flexWrap="nowrap">
                        <img
                            src="/images/Slack_Mark.svg"
                            style={{
                                width: 20,
                                height: 20,
                            }}
                        />
                        <SlackLink variant="body2" channel={channel.id}>
                            #{channel.name}
                        </SlackLink>
                    </FlexBox>
                );
            }}
        </WaitQuery>
    );
};
