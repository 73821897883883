import { Box } from '@mui/material';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ApplicationError } from '../../../Errors';
import { getIamUrl } from '../../../Routes';
import { useMeContext } from '../../../queries/me';
import { useRetrieveHitonowaId } from '../../../queries/talent';
import { PersonIcon } from '../../Icons';
import { PageTitle } from '../../PageTitle';
import { WaitQuery } from '../../WaitLoading';
import { NotFound } from '../../notFound';
import { TalentDetailContainer } from '../detail';

export const Iam = () => {
    const { hitonowaId } = useParams();
    if (hitonowaId === undefined) {
        throw new ApplicationError('hitonowaId is not specified');
    }
    const maybeEmployeeCode = useRetrieveHitonowaId(hitonowaId);
    return (
        <Box>
            <PageTitle icon={PersonIcon} title="人を探す" />
            <WaitQuery
                query={maybeEmployeeCode}
                error={
                    <NotFound
                        title={`${hitonowaId}に該当する従業員が見つかりません`}
                        messages={['ヒトノワIDが正しいか確認してください。']}
                    />
                }
            >
                {({ data }) => <TalentDetailContainer employeeCode={data} />}
            </WaitQuery>
        </Box>
    );
};

export const Me: React.FC = () => {
    const { me } = useMeContext();
    return <Navigate to={getIamUrl(me.hitonowaId)} replace />;
};
