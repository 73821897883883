import { DialogContent, DialogTitle } from '@mui/material';
import { AbolishTeamRequest } from '@spec/Organization';
import React from 'react';
import { Form, Strict, useForm, useFormDate } from '../../../../lib/Form';
import { useAbolishTeam } from '../../../../queries/organization';
import { DateInput } from '../../../DateInput';
import { DialogActionButtons, DialogRow } from '../../../StableDialog';
import { useDialogContext, useSelectedTeamContext } from '../Context';

interface Elements {
    abolishedAt: Date | null;
}

const guard = (v: Elements): v is Strict<Elements> => v.abolishedAt !== null;

const toAbolishTeamRequest = (form: Form<Elements>): AbolishTeamRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('Invalid form values');
    }
    return args;
};

export const AbolishDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { selectedTeam } = useSelectedTeamContext();
    if (selectedTeam.value === null) {
        throw Error('Team does not selected.');
    }
    const team = selectedTeam.value;
    const form = useForm<Elements>({
        abolishedAt: useFormDate(team.abolishedAt),
    });
    const mutation = useAbolishTeam(team.id);
    return (
        <>
            <DialogTitle>「{team.name}」を廃止します</DialogTitle>
            <DialogContent>
                <DialogRow label="廃止日">
                    <DateInput autoFocus formDate={form.abolishedAt} />
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="組織を廃止する"
                cancelLabel="キャンセル"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(toAbolishTeamRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
