import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import React from 'react';
import { findTeam } from '../../domains/Organization';
import { fullName, fullNameKana } from '../../domains/Talent';
import { FlexBox } from '../FlexBox';
import { AccountTreeIcon } from '../Icons';
import { TalentAvatar, avatarSize } from '../TalentAvatar';
import { isTalent, isTeam } from './Domains';

export const ItemCard: React.FC<{ item: Talent | Team | string; teams: Team[] }> = (props) => {
    if (isTalent(props.item)) {
        return <TalentCard talent={props.item} teams={props.teams} />;
    }
    if (isTeam(props.item)) {
        return <TeamCard team={props.item} teams={props.teams} />;
    }
    return <Typography variant="body2">{props.item}</Typography>;
};

const TalentCard: React.FC<{ talent: Talent; teams: Team[] }> = (props) => {
    const theme = useTheme();
    const v = props.talent;
    const baseTeam = findTeam(findTeam(v.teamId, props.teams).baseTeamId, props.teams);
    return (
        <FlexBox flexWrap="nowrap">
            <TalentAvatar size="medium" talent={v} />
            <Box ml={1}>
                <FlexBox gap={theme.spacing(1)}>
                    <Typography variant="body2">{v.employment.employeeCode}</Typography>
                    <Typography variant="body2">
                        {fullName(v)}（{fullNameKana(v)}）
                    </Typography>
                </FlexBox>
                <Typography variant="body2">{baseTeam.name}</Typography>
            </Box>
        </FlexBox>
    );
};

const TeamCard: React.FC<{ team: Team; teams: Team[] }> = (props) => {
    const v = props.team;
    const parent = props.teams.find((t) => t.id === v.parentId) ?? null;
    return (
        <FlexBox flexWrap="nowrap" gap={1}>
            <Avatar
                variant="rounded"
                sx={{
                    width: avatarSize.medium,
                    height: avatarSize.medium,
                }}
            >
                <AccountTreeIcon />
            </Avatar>
            <Box>
                <Typography variant="body2">
                    {v.name}（{v.code ?? '-'}）
                </Typography>
                <Typography variant="body2">
                    {parent && (
                        <>
                            {parent.name}（{parent.code ?? '-'}）
                        </>
                    )}
                </Typography>
            </Box>
            {v.abolishedAt !== null && (
                <Typography variant="body2" color="error">
                    廃止済みの組織です
                </Typography>
            )}
        </FlexBox>
    );
};
