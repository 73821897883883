import { type CircleTerm } from '@spec/Circle';
import dayjs from 'dayjs';
import { sortByKey } from '../lib/ArrayUtils';

export const findCurrentTerm = (terms: CircleTerm[], now: Date): CircleTerm | null => {
    const x = dayjs(now);
    const current = terms.find((v) => x.isBetween(v.beginAt, v.endAt, 'day', '[]'));
    return current ?? null;
};

export const findLatestTerm = (terms: CircleTerm[]): CircleTerm | null => {
    const sorted = sortByKey(terms, 'beginAt', 'desc');
    return sorted[0] ?? null;
};
