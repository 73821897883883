import { Alert, Box } from '@mui/material';
import { NotesArticle } from '@spec/Notes';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useMeContext } from '../../../queries/me';
import { useNotesArticle, useUpdateArticle } from '../../../queries/notes';
import { ActionContainer } from '../../ActionButtons';
import { EditIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { SubmitButton } from '../../SubmitButton';
import { WaitQuery } from '../../WaitLoading';
import { ArticleFormContent, toPostNotesArticleRequest, useArticleForm } from '../ArticleForm';
import { useAuthor } from '../Hooks';
import { TAGS_SEPARATOR } from '../TagSelector';
import { getArticleUrl } from '../urls';
import { useAuthorContext } from './AuthorContext';

export const UpdateArticle: React.FC = () => {
    const { me } = useMeContext();
    const { author } = useAuthorContext();
    const articleId = Number(useParams().articleId);
    const maybeArticle = useNotesArticle(articleId);

    return (
        <Box>
            <WaitQuery query={maybeArticle}>
                {({ data }) =>
                    data.article.talentId === me.id ? (
                        <UpdateArticleForm article={data.article} />
                    ) : (
                        <Navigate to={getArticleUrl(data.article.id, author.hitonowaId)} />
                    )
                }
            </WaitQuery>
        </Box>
    );
};

const UpdateArticleForm: React.FC<{ article: NotesArticle }> = (props) => {
    const [form, setCache] = useArticleForm(
        props.article.id,
        props.article.title,
        props.article.tags.join(TAGS_SEPARATOR),
        props.article.content,
        props.article.isPinned
    );
    const author = useAuthor(props.article);
    const navigate = useNavigate();
    const mutation = useUpdateArticle(props.article.id);
    return (
        <Box>
            <Box mx={2} mb={3}>
                <SubTitle title="ノートの編集" />
            </Box>
            <ArticleFormContent form={form} setCache={setCache} existingTags={props.article.tags} />
            <ActionContainer>
                <SubmitButton
                    fullWidth
                    inProgress={form.inProgress}
                    disabled={form.hasErrorElement}
                    startIcon={<EditIcon />}
                    onClick={() => {
                        form.send(
                            mutation
                                .mutateAsync(toPostNotesArticleRequest(form))
                                .then(() =>
                                    navigate(getArticleUrl(props.article.id, author.hitonowaId))
                                )
                        );
                    }}
                >
                    更新する
                </SubmitButton>
            </ActionContainer>
            {form.failed && (
                <Box mt={2}>
                    <Alert severity="error">記事の更新に失敗しました</Alert>
                </Box>
            )}
        </Box>
    );
};
