import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Breadcrumbs, Button, useTheme } from '@mui/material';
import { Team, TeamId, TeamIntroductionsResponse } from '@spec/Organization';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { getTeamUrl } from '../../../Routes';
import { generateBreadcrumbs } from '../../../domains/Organization';
import { useMeContext } from '../../../queries/me';
import { useTeamIntroductions } from '../../../queries/organization';
import { FlexBox } from '../../FlexBox';
import { EditIcon } from '../../Icons';
import { ArticleMarkdown } from '../../Markdown';
import { RouterLink } from '../../RouterLink';
import { WaitQuery } from '../../WaitLoading';
import { useDialogContext, useSelectedTeamContext } from './Context';
import { MemberTable } from './MemberTable';

export const NodeTeam: React.FC<{ team: Team; teams: Team[] }> = (props) => {
    const maybeTeamIntroductions = useTeamIntroductions();
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px',
    });

    return (
        <Box mt={2} ref={ref}>
            <Bread teamId={props.team.id} teams={props.teams} />
            <WaitQuery query={maybeTeamIntroductions}>
                {({ data }) => <Introduction teamId={props.team.id} introductions={data} />}
            </WaitQuery>
            <Box mt={2}>{inView && <MemberTable team={props.team} />}</Box>
        </Box>
    );
};

const Bread: React.FC<{ teamId: TeamId; teams: Team[] }> = (props) => {
    const theme = useTheme();
    const nav = generateBreadcrumbs(props.teamId, props.teams);
    const team = props.teams.find((v) => v.id === props.teamId);
    if (team === undefined) {
        throw new Error(`Bread: team was not found ${props.teamId}`);
    }
    return (
        <FlexBox
            my={1}
            py={0.5}
            px={1}
            sx={{
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Box flexGrow={1}>
                <Breadcrumbs>
                    {nav.map((v) => (
                        <RouterLink key={v.id} to={getTeamUrl(v.id)}>
                            {v.name}
                        </RouterLink>
                    ))}
                </Breadcrumbs>
            </Box>
            <EditMenu teamId={props.teamId} teams={props.teams} />
        </FlexBox>
    );
};

const EditMenu: React.FC<{ teamId: TeamId; teams: Team[] }> = (props) => {
    const theme = useTheme();
    const { setDialogMode } = useDialogContext();
    const { selectedTeam } = useSelectedTeamContext();
    const { grants } = useMeContext();
    const team = props.teams.find((v) => v.id === props.teamId);
    if (team === undefined) {
        throw new Error(`Bread: team was not found ${props.teamId}`);
    }
    if (grants.editTalent === false) {
        return null;
    }
    return (
        <FlexBox flexWrap="nowrap" gap={theme.spacing(1)} sx={{ whiteSpace: 'nowrap' }}>
            <Button
                size="small"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                    selectedTeam.setValue(team);
                    setDialogMode('CreateTeam');
                }}
            >
                下部組織を作る
            </Button>
            <Button
                size="small"
                startIcon={<EditIcon />}
                onClick={() => {
                    selectedTeam.setValue(team);
                    setDialogMode('EditTeam');
                }}
            >
                編集する
            </Button>
        </FlexBox>
    );
};

const Introduction: React.FC<{
    teamId: TeamId;
    introductions: TeamIntroductionsResponse;
}> = (props) => {
    const introduction = props.introductions.introductions.find((v) => v.teamId === props.teamId);
    if (introduction === undefined) {
        return null;
    }
    return (
        <Box mx={2}>
            <ArticleMarkdown source={introduction.summary} fontSize={12} />
        </Box>
    );
};
