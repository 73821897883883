import React from 'react';
import { Checkbox, FormControlLabel, TextField, TextFieldProps, Typography } from '@mui/material';
import { FormBoolean, FormText } from './Contract';
import { applyFilters } from './Filters';

export const FormTextField: React.FC<
    React.PropsWithChildren<
        {
            formText: FormText;
        } & TextFieldProps
    >
> = (props) => {
    const { formText, ...rest } = props;
    return (
        <TextField
            {...rest}
            value={formText.value}
            error={formText.error !== null}
            onChange={(e) =>
                formText.setValue(formText.filters.onChange.reduce((v, f) => f(v), e.target.value))
            }
            onCompositionEnd={(e) =>
                formText.setValue(
                    formText.filters.onCompositionEnd.reduce(
                        (v, f) => f(v),
                        // generic CompositionEvent does not have target.value
                        (e as any).target.value
                    )
                )
            }
            onBlur={() =>
                // do not modify dirty flag by filters
                formText.setValue(applyFilters(formText), formText.dirty)
            }
        />
    );
};

export const FormCheckBox: React.FC<{ formBoolean: FormBoolean; label: string; name: string }> = (
    props
) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.formBoolean.value}
                    onChange={(e) => {
                        props.formBoolean.setValue(e.target.checked);
                    }}
                    name={props.name}
                    size="small"
                />
            }
            label={<Typography variant="body2">{props.label}</Typography>}
        />
    );
};
