import type { TeamId } from '@spec/Organization';
import type {
    EditSurveyGroupRequest,
    EditSurveyPeriodRequest,
    EditSurveyQuestionRequest,
    EditSurveyRequest,
    LinkSurveyQuestionsRequest,
} from '@spec/Survey';
import type { TalentId } from '@spec/Talent';
import { useMutation, useQueryClient, type MutationFunction } from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { invalidateMe, useMeContext } from './me';
import { queryKey } from './queryKey';

const useGrantMutation = (mutationFn: MutationFunction<void, TalentId>) => {
    const queryClient = useQueryClient();
    const { me } = useMeContext();
    return useMutation({
        mutationFn,
        onSuccess: (_, talentId) => {
            const queries = [queryClient.invalidateQueries({ queryKey: [queryKey.surveys] })];
            if (talentId === me.id) {
                queries.push(invalidateMe(queryClient));
            }
            return Promise.all(queries);
        },
    });
};

export const useGrantGroupOperate = (surveyGroupId: number) => {
    const gateway = useGateway();
    return useGrantMutation((talentId) =>
        gateway.post(`/surveys/grants/operate`, { surveyGroupId, talentId })
    );
};

export const useRevokeGroupOperate = (surveyGroupId: number) => {
    const gateway = useGateway();
    return useGrantMutation((talentId) =>
        gateway.delete(`/surveys/grants/operate`, { surveyGroupId, talentId })
    );
};

export const useGrantSurveyRead = (surveyId: number, teamId: TeamId) => {
    const gateway = useGateway();
    return useGrantMutation((talentId) =>
        gateway.post(`/surveys/grants/read`, { surveyId, teamId, talentId })
    );
};

export const useRevokeSurveyRead = (surveyId: number, teamId: TeamId) => {
    const gateway = useGateway();
    return useGrantMutation((talentId) =>
        gateway.delete(`/surveys/grants/read`, { surveyId, teamId, talentId })
    );
};

const useSurveyMutation = <Req = void>(mutationFn: MutationFunction<void, Req>) => {
    const queryClient = useQueryClient();
    return useMutation<void, Error, Req>({
        mutationFn,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey.surveys] }),
    });
};

export const useAddSurveyGroup = () => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyGroupRequest>((args) =>
        gateway.post('/surveys/groups', args)
    );
};

export const useUpdateSurveyGroup = (groupId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyGroupRequest>((args) =>
        gateway.put(`/surveys/groups/${groupId}`, args)
    );
};

export const useDeleteSurveyGroup = (groupId: number) => {
    const gateway = useGateway();
    return useSurveyMutation(() => gateway.delete(`/surveys/groups/${groupId}`));
};

export const useAddSurvey = (groupId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyRequest>((args) =>
        gateway.post(`/surveys/groups/${groupId}/survey`, args)
    );
};

export const useUpdateSurvey = (surveyId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyRequest>((args) =>
        gateway.put(`/surveys/${surveyId}`, args)
    );
};

export const useDeleteSurvey = (surveyId: number) => {
    const gateway = useGateway();
    return useSurveyMutation(() => gateway.delete(`/surveys/${surveyId}`));
};

export const useAddSurveyPeriod = (groupId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyPeriodRequest>((args) =>
        gateway.post(`/surveys/${groupId}/periods`, args)
    );
};

export const useUpdateSurveyPeriod = (periodId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyPeriodRequest>((args) =>
        gateway.put(`/surveys/periods/${periodId}`, args)
    );
};

export const useDeleteSurveyPeriod = (periodId: number) => {
    const gateway = useGateway();
    return useSurveyMutation(() => gateway.delete(`/surveys/periods/${periodId}`));
};

export const useLinkSurveyQuestions = (periodId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<LinkSurveyQuestionsRequest>((args) =>
        gateway.post(`/surveys/periods/${periodId}/questions`, args)
    );
};

export const useAddSurveyQuestion = (surveyId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyQuestionRequest>((args) =>
        gateway.post(`/surveys/${surveyId}/questions`, args)
    );
};

export const useUpdateSurveyQuestion = (questionId: number) => {
    const gateway = useGateway();
    return useSurveyMutation<EditSurveyQuestionRequest>((args) =>
        gateway.put(`/surveys/questions/${questionId}`, args)
    );
};

export const useDeleteSurveyQuestion = (questionId: number) => {
    const gateway = useGateway();
    return useSurveyMutation(() => gateway.delete(`/surveys/questions/${questionId}`));
};
