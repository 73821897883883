import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { ApplicationError } from '../../../../../Errors';
import { fullName } from '../../../../../domains/Talent';
import { useEmptyForm } from '../../../../../lib/Form';
import { useWithdrawTodoItems } from '../../../../../queries/todoAdmin';
import { useTalentsContext } from '../../../../Context';
import { FlexBox } from '../../../../FlexBox';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../itemList/Context';
import { useEmployeeCodesContext, useTodoItemContext } from '../Context';

export const RemoveTargetsDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { item } = useTodoItemContext();
    const { employeeCodes, resetEmployeeCodes } = useEmployeeCodesContext();
    const form = useEmptyForm();
    const mutation = useWithdrawTodoItems(item.id);
    return (
        <>
            <DialogTitle>「{item.title}」から対象者を除外する</DialogTitle>
            <DialogContent>
                {item.publishedAt !== null && (
                    <Typography mb={1}>
                        除外された対象者がヒトノワを開いた時にお知らせダイアログが表示されます
                    </Typography>
                )}
                <Typography mb={1}>以下の{employeeCodes.size}名を対象から除外します。</Typography>
                {[...employeeCodes].map((v) => (
                    <TargetTalent key={v} employeeCode={v} />
                ))}
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="対象から外す"
                cancelLabel="何もせずに閉じる"
                errorMessage="対象の更新に失敗しました"
                onSubmit={() =>
                    mutation
                        .mutateAsync({ employeeCodes: [...employeeCodes] })
                        .then(resetEmployeeCodes)
                }
                closeDialog={closeDialog}
            />
        </>
    );
};

const TargetTalent: React.FC<{ employeeCode: string }> = (props) => {
    const { talents } = useTalentsContext();
    const t = talents.find((v) => v.employment.employeeCode === props.employeeCode);
    if (t === undefined) {
        throw new ApplicationError(`Talent ${props.employeeCode} was not found`);
    }
    return (
        <FlexBox mt={0.5} gap={1}>
            <Typography variant="body2">{t.employment.employeeCode}</Typography>
            <Typography variant="body2">{fullName(t)}</Typography>
        </FlexBox>
    );
};
