import React, { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export const ScrollToTop: React.FC<React.PropsWithChildren<unknown>> = () => {
    const action = useNavigationType();
    const { pathname, state } = useLocation();
    useEffect(() => {
        // https://github.com/voyagegroup/carta_chihaya/issues/2717#issuecomment-1635516840
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (action !== 'POP' && !state) {
            window.scrollTo(0, 0);
        }
    }, [action, pathname, state]);
    return null;
};
