import { StrictMap } from '../../lib/MapUtils';

export type ValueFeedbackStatus =
    | 'prepare'
    | 'selection'
    | 'confirmSelection'
    | 'feedback'
    | 'confirmFeedback'
    | 'published';

export const scoreLabels = new StrictMap([
    [4, '非常に体現できていた'],
    [3, '体現できていた'],
    [2, 'やや体現できていた'],
    [1, '体現できていなかった'],
    [0, '分からない'],
]);

export const commentLabels = {
    applause: '総評／良かった点',
    expectation: '総評／期待やアドバイス、改善点など',
};

export const MAX_RESERVED_SELECTIONS = 10;
