import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Container,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import {
    Survey,
    SurveyApplyingItem,
    SurveyBooleanQuestion,
    SurveyGroup,
    SurveyLikertQuestion,
    SurveyPeriod,
    SurveyQuestion,
    SurveyTextQuestion,
} from '@spec/Survey';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationError } from '../../../Errors';
import { FormTheme, useMobile } from '../../../Theme';
import {
    findActivePeriod,
    isBooleanQuestion,
    isLikertQuestion,
    isTextQuestion,
} from '../../../domains/Survey';
import { findById } from '../../../lib/ArrayUtils';
import { useEmptyForm } from '../../../lib/Form';
import { useApplySurvey, useIsAppliedSurveyGroup } from '../../../queries/survey';
import { ActionContainer } from '../../ActionButtons';
import { useCurrentTimeContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { Markdown } from '../../Markdown';
import { NoItems } from '../../NoItems';
import { SubTitle } from '../../PageTitle';
import { DialogActionButtons, StableDialog } from '../../StableDialog';
import { TenantContent } from '../../TenantContent';
import { WaitLoading } from '../../WaitLoading';
import { useSurveysContext } from '../Context';
import { ContextProvider, ResponseMap, ResponseValue, useApplyFormContext } from './Context';

export const ApplySurvey: React.FC = () => {
    const { groupId } = useParams();
    const { surveyGroups } = useSurveysContext();
    const surveyGroupId = Number(groupId);
    const currentGroup = findById(surveyGroupId, surveyGroups);
    const isApplied = useIsAppliedSurveyGroup(surveyGroupId);
    const { currentTime } = useCurrentTimeContext();
    const period = findActivePeriod(currentGroup, dayjs(currentTime));
    if (period === null) {
        return <NoItems mt={8}>現在「{currentGroup.name}」は実施されていません</NoItems>;
    }
    return (
        <Box>
            <ContextProvider>
                <Typography mb={1} textAlign="center" variant="h6">
                    {currentGroup.name} {period.name}
                </Typography>
                <Container maxWidth="md">
                    <Card>
                        <CardContent>
                            <Markdown source={currentGroup.description} variant="body2" />
                        </CardContent>
                    </Card>
                </Container>
                <WaitLoading size="medium" waitFor={[isApplied]}>
                    {isApplied.data === true && (
                        <Box m={4}>
                            <Typography textAlign="center" color="primary">
                                回答済みのサーベイです
                            </Typography>
                        </Box>
                    )}
                    {isApplied.data === false && <ApplyForm group={currentGroup} period={period} />}
                </WaitLoading>
            </ContextProvider>
        </Box>
    );
};

const toItems = (x: ResponseMap): SurveyApplyingItem[] => {
    const items: SurveyApplyingItem[] = [];
    const toItem = (questionId: number, value: ResponseValue): SurveyApplyingItem => {
        switch (typeof value) {
            case 'number':
                return { questionId, score: value };
            case 'boolean':
                return { questionId, checked: value };
            case 'string':
                return { questionId, message: value };
            default:
                throw new ApplicationError(
                    `unexpected response for question ${questionId}: ${value}`
                );
        }
    };
    for (const [questionId, response] of x.entries()) {
        items.push(toItem(questionId, response));
    }
    return items;
};

const ApplyForm: React.FC<{ group: SurveyGroup; period: SurveyPeriod }> = (props) => {
    const form = useEmptyForm();
    const { responses } = useApplyFormContext();
    const { surveys } = useSurveysContext();
    const [openDialog, setOpenDialog] = useState(false);
    const closeDialog = useCallback(() => {
        setOpenDialog(false);
    }, []);
    const mutation = useApplySurvey(props.group.id);
    return (
        <FormTheme>
            {surveys
                .filter((v) => v.surveyGroupId === props.group.id)
                .map((v) => (
                    <SurveyApplyForm key={v.id} period={props.period} survey={v} />
                ))}
            <ActionContainer>
                <Button
                    variant="contained"
                    size="medium"
                    fullWidth
                    disabled={responses.size === 0 || form.succeeded === true}
                    onClick={() => setOpenDialog(true)}
                >
                    サーベイに回答する
                </Button>
            </ActionContainer>
            {form.succeeded === true && (
                <Box mt={2}>
                    <Container maxWidth="md">
                        <Alert severity="success">サーベイに回答しました</Alert>
                    </Container>
                </Box>
            )}
            <StableDialog open={openDialog} noClose onClose={closeDialog} maxWidth="sm" fullWidth>
                <DialogTitle>{props.group.name}に回答する</DialogTitle>
                <DialogContent>
                    <Typography>回答結果は上書きできません。今の内容で確定しますか？</Typography>
                    <TenantContent
                        carta={
                            <Alert severity="warning" sx={{ mt: 2 }}>
                                「CARTAパルスサーベイ」の設問は事業部の上長も閲覧可能です
                            </Alert>
                        }
                    />
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    submitLabel="今の内容で回答を確定する"
                    cancelLabel="入力画面に戻る"
                    errorMessage="回答に失敗しました"
                    onSubmit={() => mutation.mutateAsync({ responses: toItems(responses) })}
                    closeDialog={closeDialog}
                />
            </StableDialog>
        </FormTheme>
    );
};

const SurveyApplyForm: React.FC<{ period: SurveyPeriod; survey: Survey }> = (props) => {
    const surveyQuestionIds = props.survey.questions.map((v) => v.id);
    const questions = props.period.questions.filter((v) => surveyQuestionIds.includes(v.id));
    return (
        <Box mt={2}>
            <Container maxWidth="md">
                <SubTitle title={props.survey.name} />
                <Markdown source={props.survey.description} variant="body2" />
                {questions.map((q) => (
                    <QuestionForm key={q.id} question={q} />
                ))}
            </Container>
        </Box>
    );
};

const QuestionForm: React.FC<{ question: SurveyQuestion }> = (props) => {
    const q = props.question;
    return (
        <Box mt={2}>
            <Card>
                <CardContent>
                    <Typography>{q.title}</Typography>
                    {isLikertQuestion(q) && <LikertQuestionForm question={q} />}
                    {isBooleanQuestion(q) && <BooleanQuestionForm question={q} />}
                    {isTextQuestion(q) && <TextQuestionForm question={q} />}
                </CardContent>
            </Card>
        </Box>
    );
};

const LikertQuestionForm: React.FC<{ question: SurveyLikertQuestion }> = (props) => {
    const q = props.question;
    const scores = [];
    for (let i = q.minScore; i <= q.maxScore; i++) {
        scores.push(i);
    }
    const [score, setScore] = useState<number | null>(null);
    const { setResponses } = useApplyFormContext();
    return useMobile() ? (
        <Box mx={2} my={1}>
            <FlexBox>
                <Typography flexGrow={1} variant="body2">
                    {q.minLabel}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                    {q.maxLabel}
                </Typography>
            </FlexBox>
            <RadioGroup
                row
                sx={{
                    marginTop: 1,
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap',
                }}
                value={score}
                onChange={(e) => {
                    const v = Number(e.target.value);
                    setScore(v);
                    setResponses(q.id, v);
                }}
            >
                {scores.map((score) => (
                    <FormControlLabel
                        key={score}
                        label={<Typography variant="caption">{score}</Typography>}
                        value={score}
                        control={
                            <Radio
                                size="small"
                                disableFocusRipple
                                disableRipple
                                sx={{ padding: 0 }}
                            />
                        }
                        labelPlacement="top"
                    />
                ))}
            </RadioGroup>
        </Box>
    ) : (
        <Box my={1}>
            <FlexBox justifyContent="center" alignItems="flex-end" flexWrap="nowrap" gap={2}>
                <Typography variant="body2" flexBasis={0} flexGrow={1} textAlign="right">
                    {q.minLabel}
                </Typography>
                <RadioGroup
                    row
                    sx={{
                        width: '300px',
                        justifyContent: 'space-between',
                        flexWrap: 'nowrap',
                    }}
                    value={score}
                    onChange={(e) => {
                        const v = Number(e.target.value);
                        setScore(v);
                        setResponses(q.id, v);
                    }}
                >
                    {scores.map((score) => (
                        <FormControlLabel
                            key={score}
                            label={<Typography variant="caption">{score}</Typography>}
                            value={score}
                            control={
                                <Radio
                                    size="small"
                                    disableFocusRipple
                                    disableRipple
                                    sx={{ padding: 0 }}
                                />
                            }
                            labelPlacement="top"
                        />
                    ))}
                </RadioGroup>
                <Typography variant="body2" flexBasis={0} flexGrow={1}>
                    {q.maxLabel}
                </Typography>
            </FlexBox>
        </Box>
    );
};

const BooleanQuestionForm: React.FC<{ question: SurveyBooleanQuestion }> = (props) => {
    const { responses, setResponses } = useApplyFormContext();
    return (
        <Box ml={2}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!responses.get(props.question.id)}
                        onChange={(e) => {
                            setResponses(props.question.id, e.target.checked);
                        }}
                        name="checked"
                        size="small"
                    />
                }
                label={<Typography variant="body2">{props.question.label}</Typography>}
            />
        </Box>
    );
};

const TextQuestionForm: React.FC<{ question: SurveyTextQuestion }> = (props) => {
    const { responses, setResponses } = useApplyFormContext();
    return (
        <Box>
            <TextField
                fullWidth
                multiline
                minRows={4}
                value={responses.get(props.question.id ?? '')}
                onChange={(e) => setResponses(props.question.id, e.target.value)}
            />
        </Box>
    );
};
