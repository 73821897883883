import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HeightIcon from '@mui/icons-material/Height';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Alert, Avatar, Box, Button, ButtonGroup, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Talent } from '@spec/Talent';
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { ApplicationError, reportError } from '../../Errors';
import { getProfileImageUrl } from '../../domains/Talent';
import { toNullable, useAsyncState } from '../../lib/AsyncResource';
import { ClearIcon } from '../Icons';
import { SubmitButton } from '../SubmitButton';
import { AvatarSize, avatarSize } from '../TalentAvatar';

const Preview: React.FC<{ size: AvatarSize; src: string | undefined }> = (props) => {
    return (
        <Avatar
            sx={{
                width: avatarSize[props.size],
                height: avatarSize[props.size],
                borderWidth: 1,
                borderStyle: 'dotted',
                borderColor: grey[400],
            }}
            variant="rounded"
            src={props.src}
        />
    );
};

const IMAGE_TYPE = 'image/jpeg';
const JPEG_QUALITY = 0.7;

export const UploadProfileImage: React.FC<
    React.PropsWithChildren<{
        talent: Talent;
        onUpload: (blob: Blob) => Promise<void>;
    }>
> = (props) => {
    const theme = useTheme();
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [croppedUrl, setCroppedUrl] = useState<string | undefined>(undefined);
    const [blob, setBlob] = useState<Blob | null>(null);
    const [uploaded, setUploaded] = useAsyncState<true>({
        inProgress: false,
        value: null,
    });
    const inputEl = useRef<HTMLInputElement | null>(null);
    const cropperRef = useRef<HTMLImageElement>(null);
    const getCropper = (): Cropper | undefined => (cropperRef?.current as any)?.cropper;
    useEffect(() => {
        getProfileImageUrl(props.talent)
            .then((url) => {
                if (url !== null) {
                    setImageUrl(url);
                    setCroppedUrl(url);
                }
            })
            .catch((e) => {
                reportError(e);
                setImageUrl(undefined);
                setCroppedUrl(undefined);
            });
    }, [props.talent]);
    return (
        <Box>
            <Box my={1} display="flex">
                <Box>
                    <Box>
                        <Typography variant="body2">プレビュー大</Typography>
                        <Preview size="large" src={croppedUrl} />
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body2">中</Typography>
                        <Preview size="medium" src={croppedUrl} />
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body2">小</Typography>
                        <Preview size="small" src={croppedUrl} />
                    </Box>
                </Box>
                <Box ml={2} flexGrow={1}>
                    <Box>
                        <input
                            ref={inputEl}
                            color="primary"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                setUploaded({ inProgress: false, value: null });
                                if (e.target.files === null) {
                                    return;
                                }
                                const f = e.target.files.item(0);
                                if (f === null) {
                                    return;
                                }
                                const reader = new FileReader();
                                reader.onload = () => {
                                    setImageUrl(reader.result as string);
                                };
                                reader.readAsDataURL(f);
                            }}
                            id="icon-button-file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="icon-button-file">
                            <Button
                                fullWidth
                                variant="outlined"
                                component="span"
                                color="primary"
                                size="medium"
                                startIcon={<PermMediaIcon />}
                            >
                                ファイル選択
                            </Button>
                        </label>
                    </Box>
                    <Box>
                        <Cropper
                            src={imageUrl}
                            style={{
                                height: 300,
                                margin: theme.spacing(1, 0, 0.5),
                            }}
                            ref={cropperRef}
                            aspectRatio={1}
                            dragMode="move"
                            autoCropArea={1}
                            crop={() => {
                                const cropper = getCropper();
                                if (cropper) {
                                    const canvas = cropper.getCroppedCanvas({
                                        maxWidth: 800,
                                        maxHeight: 800,
                                    });
                                    setCroppedUrl(canvas.toDataURL(IMAGE_TYPE, JPEG_QUALITY));
                                    canvas.toBlob(setBlob, IMAGE_TYPE, JPEG_QUALITY);
                                }
                            }}
                        />
                        <Box display="flex" justifyContent="space-between">
                            <Box flexGrow={1} flexBasis={200}></Box>
                            <Box textAlign="center" pt={0.5}>
                                <ButtonGroup>
                                    <Button
                                        title="回転"
                                        size="small"
                                        onClick={() => getCropper()?.rotate(90)}
                                    >
                                        <RotateRightIcon />
                                    </Button>
                                    <Button
                                        title="左右判定"
                                        size="small"
                                        onClick={() => {
                                            const cropper = getCropper();
                                            if (cropper) {
                                                cropper.scaleX(cropper.getImageData().scaleX * -1);
                                            }
                                        }}
                                    >
                                        <HeightIcon style={{ transform: 'rotate(90deg)' }} />
                                    </Button>
                                    <Button
                                        title="上下反転"
                                        size="small"
                                        onClick={() => {
                                            const cropper = getCropper();
                                            if (cropper) {
                                                cropper.scaleY(cropper.getImageData().scaleY * -1);
                                            }
                                        }}
                                    >
                                        <HeightIcon />
                                    </Button>
                                    <Button
                                        title="リセット"
                                        size="small"
                                        onClick={() => getCropper()?.reset()}
                                    >
                                        <ClearIcon />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            <Box flexGrow={1} flexBasis={200} textAlign="right">
                                <Typography variant="caption" color="primary">
                                    ※マウスホイールで画像の拡大縮小が可能です
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <SubmitButton
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                startIcon={<CloudUploadIcon />}
                                inProgress={uploaded.inProgress}
                                disabled={blob === null}
                                onClick={() => {
                                    if (blob) {
                                        setUploaded({ inProgress: true, value: null });
                                        props
                                            .onUpload(blob)
                                            .then(() => {
                                                setUploaded({ inProgress: false, value: true });
                                                setBlob(null);
                                            })
                                            .catch((e) => {
                                                reportError(
                                                    new ApplicationError(
                                                        `Failed to upload profile image: ${e}`
                                                    )
                                                );
                                                setUploaded({ inProgress: false, value: e });
                                            });
                                    }
                                }}
                            >
                                アップロード
                            </SubmitButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {toNullable(uploaded) && (
                <Alert severity="success">プロフィール画像を更新しました</Alert>
            )}
            {uploaded.value instanceof Error && (
                <Alert severity="error">画像のアップロードに失敗しました</Alert>
            )}
        </Box>
    );
};
