import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { LeaveOfAbsence, Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { getTalentUrl } from '../../../Routes';
import { fullName, isLeftTalent, isSuspendedTalent } from '../../../domains/Talent';
import { findById } from '../../../lib/ArrayUtils';
import { FormCheckBox, useFormBoolean } from '../../../lib/Form';
import { queryToArray } from '../../../queries';
import { useLeaveOfAbsences, useTalents } from '../../../queries/talent';
import { FlexBox } from '../../FlexBox';
import { DoDisturbOnIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { RouterLink } from '../../RouterLink';
import { WaitLoading, WaitQuery } from '../../WaitLoading';
import { TalentSearch } from '../../search/TalentSearch';

export const LeaveOfAbsences: React.FC = () => {
    const maybeAbsences = useLeaveOfAbsences();
    return (
        <Box>
            <Box my={2}>
                <SubTitle icon={DoDisturbOnIcon} title="休職情報" />
            </Box>
            <WaitLoading waitFor={[maybeAbsences]}>
                <Content absences={queryToArray(maybeAbsences)} />
            </WaitLoading>
        </Box>
    );
};

const Content: React.FC<{ absences: LeaveOfAbsence[] }> = (props) => {
    const [talent, setTalent] = useState<Talent | null>(null);
    const onlyActive = useFormBoolean(false);
    const absences = props.absences
        .filter((v) => (talent === null ? true : v.talentId === talent.id))
        .filter((v) => (onlyActive.value ? v.returnedAt === null : true));
    const absentTalentIds = new Set(absences.map((v) => v.talentId));
    return (
        <Box>
            <FlexBox>
                <Box flexGrow={1} mr={2}>
                    <TalentSearch
                        value={talent}
                        onChange={setTalent}
                        filterTalent={(t) => absentTalentIds.has(t.id)}
                    />
                </Box>
                <FormCheckBox formBoolean={onlyActive} name="onlyActive" label="継続中の休職のみ" />
            </FlexBox>
            <Box mt={2}>
                <AbsencesTable absences={absences} />
            </Box>
        </Box>
    );
};

const AbsencesTable: React.FC<{ absences: LeaveOfAbsence[] }> = (props) => {
    const maybeTalents = useTalents();
    if (props.absences.length === 0) {
        return (
            <Box mt={8} textAlign="center">
                <Typography color="error">休職情報はありません</Typography>
            </Box>
        );
    }
    return (
        <WaitQuery query={maybeTalents}>
            {({ data }) => (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>社員番号</TableCell>
                            <TableCell>氏名</TableCell>
                            <TableCell>休職日</TableCell>
                            <TableCell>復職日</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.absences.map((v) => (
                            <AbsenceRow
                                key={v.id}
                                absence={v}
                                talent={findById(v.talentId, data)}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
        </WaitQuery>
    );
};

const AbsenceRow: React.FC<{ absence: LeaveOfAbsence; talent: Talent }> = (props) => {
    const talent = props.talent;
    return (
        <TableRow>
            <TableCell>
                <RouterLink variant="body2" to={getTalentUrl(talent.employment.employeeCode)}>
                    {talent.employment.employeeCode}
                </RouterLink>
            </TableCell>
            <TableCell>
                <FlexBox>
                    <RouterLink variant="body2" to={getTalentUrl(talent.employment.employeeCode)}>
                        {fullName(talent)}
                    </RouterLink>
                    {isLeftTalent(talent) && <TalentChip label="退" />}
                    {isSuspendedTalent(talent) && <TalentChip label="停" />}
                </FlexBox>
            </TableCell>
            <TableCell>{format(props.absence.leavedAt)}</TableCell>
            <TableCell>{format(props.absence.returnedAt)}</TableCell>
        </TableRow>
    );
};

const format = (v: Date | null): string => {
    if (v === null) {
        return '-';
    }
    return dayjs(v).format('YYYY-MM-DD');
};

const TalentChip: React.FC<{ label: string }> = (props) => (
    <Box mx={0.25} px={0.25} sx={{ backgroundColor: '#ccc' }}>
        <Typography variant="caption">{props.label}</Typography>
    </Box>
);
