import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { queryToArray } from '../../../queries';
import { useTodoItems } from '../../../queries/todoAdmin';
import { useTodoCategories } from '../../../queries/todoCategory';
import { TalentsContextProvider } from '../../Context';
import { RequirePrivilege } from '../../RequirePrivilege';
import { WaitLoading } from '../../WaitLoading';
import { ContextProvider } from './Context';

export const Layout: React.FC = () => {
    const maybeTodoCategories = useTodoCategories();
    const maybeTodoItems = useTodoItems();
    return (
        <Box>
            <RequirePrivilege
                fn={(grants) => grants.manageTodo}
                alertMessage="この画面を見るには「やること管理」の権限が必要です"
            >
                <WaitLoading waitFor={[maybeTodoCategories, maybeTodoItems]}>
                    <TalentsContextProvider>
                        <ContextProvider
                            categories={queryToArray(maybeTodoCategories)}
                            items={maybeTodoItems.data?.items ?? []}
                            stats={maybeTodoItems.data?.stats ?? []}
                        >
                            <Outlet />
                        </ContextProvider>
                    </TalentsContextProvider>
                </WaitLoading>
            </RequirePrivilege>
        </Box>
    );
};
