import { SurveyGroup } from '@spec/Survey';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
    FilterCondition,
    initializeFilterCondition,
    parsePage,
    serializeFilterCondition,
} from './Filter';

const FilterFormContext = createContext(
    {} as {
        condition: FilterCondition;
        updateCondition: (v: Partial<FilterCondition>) => void;
        page: number;
        setPage: (v: number) => void;
    }
);
const FilterFormContextProvider: React.FC<{
    surveyGroup: SurveyGroup;
    currentTime: Date;
    children: ReactNode;
}> = (props) => {
    const [condition, setCondition] = useState<FilterCondition>(() =>
        initializeFilterCondition(window.location.search, props.surveyGroup, props.currentTime)
    );
    const updateCondition = (v: Partial<FilterCondition>) => {
        setCondition((before) => {
            return { ...before, ...v };
        });
    };
    const [page, setPage] = useState(() => parsePage(window.location.search));
    useEffect(() => {
        window.history.replaceState(
            {},
            '',
            `?${serializeFilterCondition(condition, page).toString()}`
        );
    }, [condition, page]);
    return (
        <FilterFormContext.Provider value={{ condition, updateCondition, page, setPage }}>
            {props.children}
        </FilterFormContext.Provider>
    );
};
export const useFilterFormContext = () => useContext(FilterFormContext);

export const ContextProvider: React.FC<{
    surveyGroup: SurveyGroup;
    currentTime: Date;
    children: ReactNode;
}> = (props) => {
    return (
        <FilterFormContextProvider surveyGroup={props.surveyGroup} currentTime={props.currentTime}>
            {props.children}
        </FilterFormContextProvider>
    );
};
