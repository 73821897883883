import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { RoutingPattern, getRelativePath } from '../../../Routes';
import { useTalent } from '../../../queries/talent';
import { useTeamsContext } from '../../Context';
import { PageModal } from '../../PageModal';
import { WaitQuery } from '../../WaitLoading';
import { NotFound } from '../../notFound';
import { Profile } from './Profile';
import { RenewedTalent } from './RenewedTalent';
import { EditTalent } from './edit';
import { UploadDefaultImage } from './uploadDefaultImage';

export const TalentDetail: React.FC = () => {
    const { employeeCode } = useParams();
    if (employeeCode === undefined) {
        return <NotFound />;
    }
    return (
        <PageModal>
            <TalentDetailContainer employeeCode={employeeCode} />
        </PageModal>
    );
};

export const TalentDetailContainer: React.FC<{ employeeCode: string }> = (props) => {
    const { teams } = useTeamsContext();
    const maybeTalent = useTalent(props.employeeCode);
    const p = getRelativePath(RoutingPattern.talentDetail);
    return (
        <WaitQuery query={maybeTalent}>
            {({ data }) => {
                const { talent, grants } = data;
                if (talent === null || grants === null) {
                    return (
                        <NotFound
                            title="対象の人材が見つかりません"
                            messages={['社員番号の指定を確認してください。']}
                        />
                    );
                }
                if (talent.employment.employeeCode !== props.employeeCode) {
                    return <RenewedTalent talent={talent} />;
                }
                return (
                    <Routes>
                        <Route
                            path={p(RoutingPattern.editPersonality)}
                            element={<EditTalent talent={talent} />}
                        />
                        <Route
                            path={p(RoutingPattern.setDefaultImage)}
                            element={<UploadDefaultImage talent={talent} />}
                        />
                        <Route path="*" element={<Profile talent={talent} teams={teams} />} />
                    </Routes>
                );
            }}
        </WaitQuery>
    );
};
