import { Box, Divider, Stack, Typography } from '@mui/material';
import { HelpAccordion } from '../HelpAccordion';

interface Column {
    title: string;
    description: string[];
}

const columns: Column[] = [
    { title: '平均勤続年数', description: ['正社員だけを対象に算出。'] },
    { title: '平均年齢', description: ['正社員だけを対象に算出。'] },
    {
        title: '従業員数',
        description: ['社員区分がca（正社員、契約社員、嘱託、パート）とcb（役員）の合計。'],
    },
    { title: '社員離職率', description: [] },
    { title: '女性比率', description: [] },
    { title: '女性管理職比率', description: [] },
    { title: '高度女性人材比率', description: [] },
];

export const ColumnsHelp = () => {
    return (
        <HelpAccordion title="人的資本データの項目について">
            <Stack spacing={2}>
                {columns.map((column) => (
                    <Box key={column.title}>
                        <Typography color="primary">{column.title}</Typography>
                        <Divider />
                        <Stack mt={1} spacing={2}>
                            {column.description.map((v, i) => (
                                <Typography key={i} variant="body2">
                                    {v}
                                </Typography>
                            ))}
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </HelpAccordion>
    );
};
