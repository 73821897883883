import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIconProps,
    Tooltip,
    useTheme,
} from '@mui/material';
import { RouterLink } from '../../RouterLink';
import { useNavContext } from './Context';

type NavItemLinkProps = {
    icon: React.FC<SvgIconProps>;
    label: string;
    href: string;
    paths: string[];
};

type NavItemButtonProps = {
    icon: React.FC<SvgIconProps>;
    label: string;
    onClick: () => void;
};

type NavItemProps = NavItemLinkProps | NavItemButtonProps;

const isNavItemLinkProps = (props: NavItemProps): props is NavItemLinkProps => {
    return 'href' in props || 'paths' in props;
};

export const NavItem = (props: NavItemProps) => {
    const theme = useTheme();
    const { collapse, firstPath } = useNavContext();
    const itemProps = isNavItemLinkProps(props)
        ? {
              component: RouterLink,
              to: props.href,
              selected: props.paths.includes(firstPath),
          }
        : { onClick: props.onClick };
    return (
        <Tooltip
            title={props.label}
            followCursor
            placement="right-end"
            disableHoverListener={!collapse}
        >
            <ListItemButton
                {...itemProps}
                sx={{
                    '&.Mui-selected': { backgroundColor: theme.palette.nav.selected },
                }}
            >
                <ListItemIcon>
                    <props.icon sx={{ color: theme.palette.nav.icon }} />
                </ListItemIcon>
                <ListItemText primary={props.label} />
            </ListItemButton>
        </Tooltip>
    );
};
