import { isPrivilege, type Grant, type Privilege } from '@spec/Grants';

export const NOT_SELECTED = 'all';

export interface FilterCondition {
    privilege: Privilege | null;
}

const filterKeys: Record<keyof FilterCondition | 'page', string> = {
    privilege: 'privilege',
    page: 'p',
};

export const initializeFilterCondition = (query: string): FilterCondition => {
    const searchParams = new URLSearchParams(query);
    return {
        privilege: (() => {
            const privilege = searchParams.get(filterKeys.privilege);
            return isPrivilege(privilege) ? privilege : null;
        })(),
    };
};

export const parsePage = (query: string): number => {
    const searchParams = new URLSearchParams(query);
    return Number(searchParams.get(filterKeys.page) ?? 1);
};

export const serializeFilterCondition = (
    condition: FilterCondition,
    page: number
): URLSearchParams => {
    const searchParams = new URLSearchParams();
    if (condition.privilege !== null) {
        searchParams.set(filterKeys.privilege, `${condition.privilege}`);
    }
    if (page > 1) {
        searchParams.set(filterKeys.page, `${page}`);
    }
    return searchParams;
};

export const conditionAsStrings = (
    condition: FilterCondition
): Record<keyof FilterCondition, string> => {
    return {
        privilege: condition.privilege ?? NOT_SELECTED,
    };
};

export const filterGrants = (grants: Grant[], condition: FilterCondition): Grant[] => {
    return grants.filter((v) => {
        if (condition.privilege === null) {
            return true;
        }
        return v.privilege === condition.privilege;
    });
};

export const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
): Partial<FilterCondition> => {
    const { name, value } = e.target;
    switch (name) {
        case 'privilege': {
            const v = isPrivilege(value) ? value : null;
            return { privilege: v };
        }
        default: {
            throw Error(`Unexpected parameter: ${name}`);
        }
    }
};
