import { CircularProgress, Typography } from '@mui/material';
import { Talent, TalentId } from '@spec/Talent';
import React from 'react';
import { findCurrentTerm } from '../../../domains/Circle';
import { queryToArray } from '../../../queries';
import { useCircleMembers, useCircleTerms, useCircles } from '../../../queries/circle';

export const CurrentCircle: React.FC<{ talent: Talent }> = (props) => {
    const maybeCircleTerms = useCircleTerms();
    if (maybeCircleTerms.isPending) {
        return <Loading />;
    }
    const circleTerms = queryToArray(maybeCircleTerms);
    const currentTerm = findCurrentTerm(circleTerms, new Date());
    if (currentTerm === null) {
        return null;
    }
    return <CircleName termId={currentTerm.id} talentId={props.talent.id} />;
};

const CircleName: React.FC<{ termId: number; talentId: TalentId }> = (props) => {
    const maybeCircles = useCircles();
    const maybeCircleMembers = useCircleMembers(props.termId);
    if (maybeCircles.isPending || maybeCircleMembers.isPending) {
        return <Loading />;
    }
    const x = queryToArray(maybeCircleMembers).find((v) => v.talentId === props.talentId);
    if (x === undefined) {
        return null;
    }
    const circle = queryToArray(maybeCircles).find((v) => v.id === x.circleId);
    if (circle === undefined) {
        return null;
    }
    return <Typography variant="body2">所属サークル : {circle.name}</Typography>;
};

const Loading: React.FC = () => <CircularProgress size={12} />;
