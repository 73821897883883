import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { ReturnRequest, Talent } from '@spec/Talent';
import React, { useState } from 'react';
import { Form, Strict, useForm, useFormDate } from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { useReturnTalent } from '../../../../queries/talent';
import { ActionButton } from '../../../ActionButtons';
import { DateInput } from '../../../DateInput';
import { DoDisturbOnIcon } from '../../../Icons';
import { DialogActionButtons, DialogRow, StableDialog } from '../../../StableDialog';

interface Elements {
    returnedAt: Date | null;
}

const guard = (v: Elements): v is Strict<Elements> => v.returnedAt !== null;

const toReturnRequest = (form: Form<Elements>): ReturnRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('Invalid form values');
    }
    return args;
};

export const ReturnTalent: React.FC<{ talent: Talent }> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useForm<Elements>({
        returnedAt: useFormDate(null, [required]),
    });
    const mutation = useReturnTalent(props.talent.employment.employeeCode);
    return (
        <>
            <StableDialog noClose open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>従業員の復職</DialogTitle>
                <DialogContent>
                    <Typography>復職日を指定してください。</Typography>
                    <DialogRow label="">
                        <DateInput autoFocus formDate={form.returnedAt} />
                    </DialogRow>
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    startIcon={<DoDisturbOnIcon />}
                    submitLabel="復職設定する"
                    cancelLabel="キャンセル"
                    errorMessage="復職処理に失敗しました"
                    onSubmit={async () => {
                        await mutation.mutateAsync(toReturnRequest(form));
                    }}
                    closeDialog={() => setDialogOpen(false)}
                />
            </StableDialog>
            <ActionButton startIcon={<DoDisturbOnIcon />} onClick={() => setDialogOpen(true)}>
                この従業員を復職させる
            </ActionButton>
        </>
    );
};
