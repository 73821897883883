import type { EditWorkplaceRequest } from '@spec/Workplace';
import {
    useMutation,
    useQuery,
    useQueryClient,
    type MutationFunction,
} from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useWorkplaces = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.workplaces],
        queryFn: () => gateway.fetchWorkplaces(),
    });
};

const useWorkplaceMutation = <T = void>(mutationFn: MutationFunction<void, T>) => {
    const queryClient = useQueryClient();
    return useMutation<void, Error, T>({
        mutationFn,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKey.workplaces] }),
    });
};

export const useAddWorkplace = () => {
    const gateway = useGateway();
    return useWorkplaceMutation<EditWorkplaceRequest>((args) => gateway.post('/workplaces', args));
};

export const useUpdateWorkplace = (workplaceId: number) => {
    const gateway = useGateway();
    return useWorkplaceMutation<EditWorkplaceRequest>((args) =>
        gateway.put(`/workplaces/${workplaceId}`, args)
    );
};

export const useDeleteWorkplace = (workplaceId: number) => {
    const gateway = useGateway();
    return useWorkplaceMutation(() => gateway.delete(`/workplaces/${workplaceId}`));
};
