import { Box } from '@mui/material';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSurveys } from '../../queries/survey';
import { PollIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { BackToIndex } from './BackToIndex';
import { SurveysContextProvider } from './Context';

export const Layout: React.FC = () => {
    const maybeSurveys = useSurveys();
    const location = useLocation();
    return (
        <SurveysContextProvider maybeSurveys={maybeSurveys}>
            <PageTitle icon={PollIcon} title="サーベイ" />
            {location.pathname !== '/survey' && (
                <Box my={2}>
                    <BackToIndex />
                </Box>
            )}
            <Outlet />
        </SurveysContextProvider>
    );
};
