import { env } from '@env';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { AuthErrorCode } from '@spec/Errors';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import isBetween from 'dayjs/plugin/isBetween';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { getAuth, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import React, { ReactNode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import { firebaseApp } from './App';
import { ApplicationError, ErrorWithCode, reportError } from './Errors';
import { ColorTheme, getTheme } from './Theme';
import {
    AvatarUpdateContextProvider,
    ColorModeContextProvider,
    FirebaseUserContextProvider,
    TenantContextProvider,
    useColorModeContext,
} from './components/Context';
import { ErrorBoundary } from './components/ErrorBoundary';
import { FirstLoading } from './components/FirstLoading';
import { ErrorContent } from './components/WaitLoading';
import { Layout } from './components/layout';
import { ThemeContextProvider } from './components/layout/ThemeSelector';
import { SignIn } from './components/signIn';
import { NotRegistered, RequirePermission } from './components/signInError';
import { SignUp } from './components/signUp';
import { MeContextProvider } from './queries/me';
import { queryKey } from './queries/queryKey';
import { Gateway, GatewayContextProvider, queryClient } from './stores/Gateway';

dayjs.locale('ja');
dayjs.extend(isBetween);

Sentry.init({
    ...env.sentryOptions,
    dsn: 'https://071cc8e7204c48f9bfa4ebcb361de8f4@o549740.ingest.sentry.io/5675912',
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
});

logEvent(getAnalytics(firebaseApp), 'openApp');

const el = document.getElementById('content');
if (el === null) {
    reportError(Error('Mount point was not found.'));
    throw Error('Mount point was not found.');
}
const root = createRoot(el);

// default value to align with the local API
const DEFAULT_REVISION = 'GitUiRevision';

const uiRevision = process.env.VITE_UI_REVISION ?? DEFAULT_REVISION;
const gateway = new Gateway(uiRevision);

const getTenant = () => {
    const path = window.location.pathname.split('/')[1];
    if (path === '' || path === 'me') {
        return null;
    }
    return path;
};

const tenant = getTenant();
if (tenant !== null) {
    gateway.setTenant(tenant);
}

const App: React.FC<{ view: ReactNode }> = (props) => (
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={({ error }) => <ErrorBoundary error={error} />}>
            <GatewayContextProvider gateway={gateway}>
                <QueryClientProvider client={queryClient}>
                    <ColorModeContextProvider>
                        <AvatarUpdateContextProvider>
                            <View view={props.view} />
                        </AvatarUpdateContextProvider>
                    </ColorModeContextProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </GatewayContextProvider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>
);

const View: React.FC<{ view: ReactNode }> = (props) => {
    const { colorMode } = useColorModeContext();
    return (
        <ThemeProvider theme={getTheme(colorMode)}>
            <CssBaseline />
            {props.view}
        </ThemeProvider>
    );
};

root.render(<App view={<FirstLoading />} />);

onAuthStateChanged(getAuth(firebaseApp), (user) => {
    if (user === null) {
        root.render(<App view={<SignIn />} />);
    } else {
        if (user.email !== null) {
            Sentry.setUser({ email: user.email });
        }
        getView()
            .then((view) =>
                root.render(
                    <FirebaseUserContextProvider firebaseUser={user}>
                        <App view={view} />
                    </FirebaseUserContextProvider>
                )
            )
            .catch((e) => {
                throw e;
            });
    }
});

const wrapError = (v: unknown): Error => (v instanceof Error ? v : new ApplicationError(`${v}`));

const getView = async (): Promise<ReactNode> => {
    try {
        const result = await getRedirectResult(getAuth(firebaseApp));
        if (result?.operationType === 'signIn') {
            await gateway.signIn();
        }
        const [me, talents, teams] = await Promise.all([
            gateway.me(),
            gateway.fetchAllTalents(),
            gateway.fetchTeams(),
        ]);

        const path = window.location.pathname.split('/')[1];
        if (path === '') {
            window.location.href = `/${me.defaultTenant}`;
            return null;
        }
        if (path === 'me') {
            window.location.href = `/${me.defaultTenant}/me`;
            return null;
        }

        queryClient.setQueryData([queryKey.talents], talents);
        queryClient.setQueryData([queryKey.teams], teams);
        setUserId(getAnalytics(firebaseApp), `${me.talent.id}`);
        return (
            <MeContextProvider response={me}>
                <TenantContextProvider tenant={path}>
                    <ThemeContextProvider>
                        <ColorTheme>
                            <Layout basePath={path} />
                        </ColorTheme>
                    </ThemeContextProvider>
                </TenantContextProvider>
            </MeContextProvider>
        );
    } catch (e) {
        const error = wrapError(e);
        reportError(error);
        const errorCode = error instanceof ErrorWithCode ? `${error.code}` : '';
        if (errorCode.startsWith('auth/')) {
            switch (errorCode as AuthErrorCode) {
                case 'auth/not-registered-yet':
                    return env.enableRegister ? <SignUp /> : <NotRegistered />;
                case 'auth/token-has-expired':
                    return <SignIn />;
                default:
                    return <RequirePermission />;
            }
        }
        return <ErrorContent />;
    }
};
