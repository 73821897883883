import {
    Box,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    LeaveOfAbsence,
    NextSabbaticalLeave,
    SabbaticalLeave,
    SabbaticalLeaveBaseDate,
    Talent,
} from '@spec/Talent';
import dayjs from 'dayjs';
import React from 'react';
import { getTalentUrl } from '../../../Routes';
import {
    LeavesRow,
    findAvailableLeave,
    generateLeavesList,
    getDeadline,
    isExpiredLeave,
} from '../../../domains/SabbaticalLeave';
import { fullName, isLeftTalent } from '../../../domains/Talent';
import { queryToArray } from '../../../queries';
import {
    useLeaveOfAbsences,
    useNextSabbaticalLeaves,
    useSabbaticalLeaves,
    useTalents,
} from '../../../queries/talent';
import { useCurrentTimeContext } from '../../Context';
import { AccessibilityNewIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { RouterLink } from '../../RouterLink';
import { WaitLoading } from '../../WaitLoading';
import { CsvDownload } from './CsvDownload';

export const SabbaticalLeaves: React.FC = () => {
    const maybeTalents = useTalents();
    const maybeLeaves = useSabbaticalLeaves();
    const maybeNextLeaves = useNextSabbaticalLeaves();
    const maybeAbsences = useLeaveOfAbsences();
    return (
        <Box>
            <Box my={2}>
                <SubTitle icon={AccessibilityNewIcon} title="ボーナス休暇の一覧" />
            </Box>
            <WaitLoading waitFor={[maybeTalents, maybeLeaves, maybeNextLeaves, maybeAbsences]}>
                <Leaves
                    talents={queryToArray(maybeTalents)}
                    leaves={maybeLeaves.data?.leaves ?? []}
                    baseDates={maybeLeaves.data?.baseDates ?? []}
                    nextLeaves={queryToArray(maybeNextLeaves)}
                    absences={queryToArray(maybeAbsences)}
                />
            </WaitLoading>
        </Box>
    );
};

const Leaves: React.FC<{
    talents: Talent[];
    leaves: SabbaticalLeave[];
    baseDates: SabbaticalLeaveBaseDate[];
    nextLeaves: NextSabbaticalLeave[];
    absences: LeaveOfAbsence[];
}> = (props) => {
    const absentTalentIds = new Set(
        props.absences.filter((v) => v.returnedAt === null).map((v) => v.talentId)
    );
    const leavesRows = generateLeavesList(props.talents, props.leaves, props.nextLeaves);
    const baseDates = new Map(props.baseDates.map((v) => [v.talentId, v.basedOn]));
    return (
        <>
            <Box mb={2}>
                <CsvDownload rows={leavesRows} />
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>社員番号</TableCell>
                        <TableCell>氏名</TableCell>
                        <TableCell>個別基準日</TableCell>
                        <TableCell>発生期間</TableCell>
                        <TableCell>次回発生日</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {leavesRows.map((row) => (
                        <LeavesTableRow
                            key={row.talent.id}
                            row={row}
                            isAbsent={absentTalentIds.has(row.talent.id)}
                            baseDate={baseDates.get(row.talent.id) ?? null}
                        />
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

const LeavesTableRow: React.FC<{
    row: LeavesRow;
    isAbsent: boolean;
    baseDate: Date | null;
}> = (props) => {
    const { currentTime } = useCurrentTimeContext();
    const talent = props.row.talent;
    const sx: SxProps = findAvailableLeave(props.row.leaves, currentTime)
        ? { backgroundColor: '#edf7ed' }
        : {};
    const format = (v?: Date) => (v ? dayjs(v).format('YYYY-MM-DD') : '');
    return (
        <TableRow sx={sx}>
            <TableCell>
                <RouterLink variant="body2" to={getTalentUrl(talent.employment.employeeCode)}>
                    {talent.employment.employeeCode}
                </RouterLink>
            </TableCell>
            <TableCell>
                <RouterLink variant="body2" to={getTalentUrl(talent.employment.employeeCode)}>
                    {fullName(talent)}
                </RouterLink>
            </TableCell>
            <TableCell>{props.baseDate && dayjs(props.baseDate).format('YYYY-MM-DD')}</TableCell>
            <TableCell>
                {props.row.leaves.map((leave) => (
                    <Typography
                        key={leave.id}
                        variant="body2"
                        color={
                            isExpiredLeave(leave, currentTime) ? 'text.disabled' : 'text.primary'
                        }
                    >
                        {format(leave.offeredAt)}
                        {' 〜 '}
                        {getDeadline(leave).format('YYYY-MM-DD')}
                    </Typography>
                ))}
            </TableCell>
            <TableCell>{format(props.row.next?.offeringAt)}</TableCell>
            <TableCell>
                {props.isAbsent && '休職中'}
                {isLeftTalent(talent) && '退職済'}
            </TableCell>
        </TableRow>
    );
};
