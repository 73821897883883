import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { RoutingPattern } from '../../../Routes';
import {
    initializeFilterCondition,
    initializeViewCondition,
    ListFilterCondition,
    ListViewCondition,
    parsePage,
    serializeListCondition,
} from './filter';

const FilterFormContext = createContext(
    {} as {
        filterCondition: ListFilterCondition;
        updateFilterCondition: (v: Partial<ListFilterCondition>) => void;
        viewCondition: ListViewCondition;
        updateViewCondition: (v: Partial<ListViewCondition>) => void;
        page: number;
        setPage: (v: number) => void;
    }
);
const FilterFormContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search;
    const action = useNavigationType();

    const [filterCondition, setFilterCondition] = useState<ListFilterCondition>(() => {
        const prevPath = location.state?.prevPath ?? '';
        const match = prevPath.match(/\/talents\?(.+)$/);
        const params = search || (match !== null ? match[1] : '');
        return initializeFilterCondition(params);
    });
    const updateFilterCondition = (v: Partial<ListFilterCondition>) => {
        setFilterCondition((before) => {
            return { ...before, ...v };
        });
    };

    const [viewCondition, setViewCondition] = useState<ListViewCondition>(() =>
        initializeViewCondition(search)
    );
    const updateViewCondition = (v: Partial<ListViewCondition>) => {
        setViewCondition((before) => {
            return { ...before, ...v };
        });
    };

    const [page, setPage] = useState(() => parsePage(search));

    useEffect(() => {
        const params = serializeListCondition(filterCondition, viewCondition, page).toString();
        const nextSearch = params ? `?${params}` : '';
        if (location.pathname === RoutingPattern.talents && search !== nextSearch) {
            navigate(nextSearch, { replace: true });
        }
    }, [filterCondition, viewCondition, page, navigate, search, location.pathname, action]);

    return (
        <FilterFormContext.Provider
            value={{
                filterCondition,
                updateFilterCondition,
                viewCondition,
                updateViewCondition,
                page,
                setPage,
            }}
        >
            {props.children}
        </FilterFormContext.Provider>
    );
};
export const useFilterFormContext = () => useContext(FilterFormContext);

export const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return <FilterFormContextProvider>{props.children}</FilterFormContextProvider>;
};
