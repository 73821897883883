import { DialogContent, DialogTitle, ListItem } from '@mui/material';
import { ApplicationError } from '../../../../../Errors';
import { privilegeLabelMap } from '../../../../../domains/Grants';
import { fullName } from '../../../../../domains/Talent';
import { useEmptyForm } from '../../../../../lib/Form';
import { useRevokePrivilege } from '../../../../../queries/grant';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext } from '../../edit/Context';
import { usePrivilegeContext, useTargetTalentContext } from '../Context';

export const RevokeDialog = () => {
    const { closeDialog } = useDialogContext();
    const { talent } = useTargetTalentContext();
    const { privilege } = usePrivilegeContext();
    if (privilege === null) {
        throw new ApplicationError('Privilege is not selected');
    }
    const form = useEmptyForm();
    const mutation = useRevokePrivilege(talent.id);
    return (
        <>
            <DialogTitle>権限の削除</DialogTitle>
            <DialogContent>
                <DialogRow label={`${fullName(talent)}さんの以下の権限を削除します`}>
                    <ListItem>{privilegeLabelMap.get(privilege)}</ListItem>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="権限を削除する"
                cancelLabel="キャンセル"
                errorMessage="権限の削除に失敗しました"
                onSubmit={() => mutation.mutateAsync(privilege)}
                closeDialog={closeDialog}
            />
        </>
    );
};
