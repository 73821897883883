import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useEmptyForm } from '../../../../lib/Form';
import { useDeleteWorkplace } from '../../../../queries/workplace';
import { DialogActionButtons, DialogRow } from '../../../StableDialog';
import { WorkplaceContext, useDialogContext } from '../Context';

export const DeleteWorkplaceDialog: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { closeDialog } = useDialogContext();
    const context = useContext(WorkplaceContext);
    const v = context.workplace.value;
    if (v === null) {
        throw Error(`null workplace`);
    }
    const form = useEmptyForm();
    const mutation = useDeleteWorkplace(v.id);
    return (
        <>
            <DialogTitle>勤務地の削除</DialogTitle>
            <DialogContent>
                <DialogRow label="名称">
                    <Typography>{v.name}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この勤務地を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="削除に失敗しました"
                onSubmit={mutation.mutateAsync}
                closeDialog={closeDialog}
            />
        </>
    );
};
