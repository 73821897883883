import { Box, Button, Container, Paper, ThemeProvider, Typography } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { RoutingPattern } from '../Routes';
import { getTheme } from '../Theme';
import { IconCircle } from './IconCircle';
import { ClearIcon } from './Icons';

export const ErrorBoundary: React.FC<{ error: unknown }> = (props) => {
    const error =
        props.error instanceof Error ? props.error : new Error(`Unknown error: ${props.error}`);
    return (
        <ThemeProvider theme={getTheme('light')}>
            <Container maxWidth="md">
                <Box my={10} textAlign="center">
                    <IconCircle icon={ClearIcon} color={deepOrange[800]} />
                    <Box my={4}>
                        <Typography variant="h6">予期せぬエラーが発生しました</Typography>
                    </Box>
                    <Paper variant="outlined">
                        <Box py={2}>
                            <Typography variant="body2">{error.toString()}</Typography>
                        </Box>
                    </Paper>
                    <Box mt={4}>
                        <Typography>このエラーは開発チームに通知済みです。</Typography>
                    </Box>
                    <Box mt={4}>
                        <Button size="large" variant="outlined" href={RoutingPattern.index}>
                            トップページへ
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};
