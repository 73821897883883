import {
    Box,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography,
    type SvgIconProps,
} from '@mui/material';
import type { NotificationEvent, NotificationType } from '@spec/notification';
import dayjs from 'dayjs';
import { useState } from 'react';
import { buildAnnouncement } from '../../domains/notification';
import { useNotifications } from '../../queries/notification';
import { ActionButton, ActionContainer } from '../ActionButtons';
import { useCurrentTimeContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { AutorenewIcon, FactCheckIcon, PollIcon } from '../Icons';
import { NoItems } from '../NoItems';
import { SubTitle } from '../PageTitle';
import { WaitQuery } from '../WaitLoading';

const DISPLAY_AMOUNT = 5;

export function AnnouncementList() {
    const maybeNotifications = useNotifications();
    return (
        <Box>
            <SubTitle title="過去のお知らせ" />
            <WaitQuery query={maybeNotifications}>
                {({ data }) =>
                    data.length === 0 ? (
                        <NoItems>最近のお知らせはありません</NoItems>
                    ) : (
                        <Content notifications={data} />
                    )
                }
            </WaitQuery>
        </Box>
    );
}

function getInitialAmount(notifications: NotificationEvent[], currentTime: Date) {
    const threshold = dayjs(currentTime).subtract(7, 'days').valueOf();
    const recent = notifications.filter((v) => v.occurredAt.getTime() > threshold);
    return Math.min(recent.length, DISPLAY_AMOUNT);
}

function Content(props: { notifications: NotificationEvent[] }) {
    const { currentTime } = useCurrentTimeContext();
    const [limit, setLimit] = useState(() => getInitialAmount(props.notifications, currentTime));
    const hasMore = props.notifications.length > limit;
    return (
        <Box>
            <Stack mt={2} spacing={1}>
                {props.notifications.slice(0, limit).map((v) => (
                    <Items key={v.id} notification={v} />
                ))}
            </Stack>
            {limit === 0 && <NoItems>最近のお知らせはありません</NoItems>}
            {hasMore && (
                <ActionContainer>
                    <ActionButton onClick={() => setLimit((prev) => prev + DISPLAY_AMOUNT)}>
                        以前のお知らせを表示
                    </ActionButton>
                </ActionContainer>
            )}
        </Box>
    );
}

function Items(props: { notification: NotificationEvent }) {
    return (
        <>
            {buildAnnouncement(props.notification).map((v) => (
                <Card key={v.id} square={false}>
                    <CardContent>
                        <Category type={props.notification.type} />
                        <FlexBox mt={1} gap={1}>
                            {v.title}
                            <Typography variant="body2">
                                {dayjs(v.publishedAt).format('YYYY-MM-DD HH:mm:ss')}
                            </Typography>
                        </FlexBox>
                        <Box mt={0.5}>
                            <Divider />
                        </Box>
                        <Stack mt={1} mx={0.5} spacing={1}>
                            {v.contents.map((v, i) => (
                                <Typography key={i}>{v}</Typography>
                            ))}
                        </Stack>
                        {v.action !== null && (
                            <ActionContainer mt={2}>
                                <ActionButton to={v.action.url}>{v.action.label}</ActionButton>
                            </ActionContainer>
                        )}
                    </CardContent>
                </Card>
            ))}
        </>
    );
}

function Category(props: { type: NotificationType }) {
    const titles: Record<NotificationType, { icon: React.FC<SvgIconProps>; title: string }> = {
        survey: { icon: PollIcon, title: 'サーベイ' },
        valueFeedback: { icon: AutorenewIcon, title: 'バリューフィードバック' },
        todo: { icon: FactCheckIcon, title: 'やることリスト' },
    };
    const v = titles[props.type];
    return (
        <FlexBox gap={0.5}>
            <v.icon fontSize="small" color="primary" />
            <Typography variant="body2">{v.title}</Typography>
        </FlexBox>
    );
}
