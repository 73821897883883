import { Box, Button, Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import { TeamId } from '@spec/Organization';
import { SurveyReadPermissions } from '@spec/Survey';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isAvailableTeam } from '../../../../domains/Organization';
import { fullName } from '../../../../domains/Talent';
import { findById } from '../../../../lib/ArrayUtils';
import { FormCheckBox, useForm, useFormBoolean } from '../../../../lib/Form';
import { useSurveyReadPermissions } from '../../../../queries/survey';
import { useTalentsContext, useTeamsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { PersonAddDisabledIcon, PersonAddIcon } from '../../../Icons';
import { SubTitle } from '../../../PageTitle';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { TalentAvatar } from '../../../TalentAvatar';
import { ROOT_TEAM_ID, TeamDrill } from '../../../TeamDrill';
import { WaitLoading } from '../../../WaitLoading';
import { useSurveysContext } from '../../Context';
import {
    ContextProvider,
    DialogMode,
    useDialogContext,
    useTalentIdContext,
    useTeamIdContext,
} from './Context';
import { AddPermissionDialog } from './dialogs/AddPermissionDialog';
import { RevokePermissionDialog } from './dialogs/RevokePermissionDialog';

export const Permissions: React.FC = () => {
    const { surveyId } = useParams();
    const { surveys } = useSurveysContext();
    const survey = findById(Number(surveyId), surveys);
    const permissions = useSurveyReadPermissions(survey.id);
    return (
        <Box>
            <SubTitle title={`${survey.name}の閲覧権限`} />
            <ContextProvider>
                <WaitLoading waitFor={[permissions]}>
                    <Box mt={2}>
                        <Tree permissions={permissions.data ?? {}} />
                    </Box>
                    <ContextualDialog components={dialogComponents} />
                </WaitLoading>
            </ContextProvider>
        </Box>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddPermission: AddPermissionDialog,
    RevokePermission: RevokePermissionDialog,
};

const Tree: React.FC<{ permissions: SurveyReadPermissions }> = (props) => {
    const [teamId, setTeamId] = useState<TeamId | null>(ROOT_TEAM_ID);
    const form = useForm({
        openAll: useFormBoolean(true),
    });
    return (
        <Box>
            <TeamDrill teamId={teamId} setTeamId={setTeamId} />
            <FormCheckBox
                formBoolean={form.openAll}
                label="誰も権限を持っていない組織も開く"
                name="open"
            />
            <Box mt={2}>
                <TeamCard
                    teamId={teamId ?? ROOT_TEAM_ID}
                    permissions={props.permissions}
                    open={form.openAll.value}
                />
            </Box>
        </Box>
    );
};

const TeamCard: React.FC<{
    teamId: TeamId;
    permissions: SurveyReadPermissions;
    open: boolean;
}> = (props) => {
    const { setTeamId } = useTeamIdContext();
    const { setDialogMode } = useDialogContext();
    const { teams } = useTeamsContext();
    const team = findById(props.teamId, teams);
    const children = teams.filter(isAvailableTeam).filter((v) => v.parentId === props.teamId);
    const { setTalentId } = useTalentIdContext();
    const { talents } = useTalentsContext();
    const readerIds = props.permissions[team.id] ?? [];
    const readers = readerIds.map((v) => findById(v, talents));
    return (
        <Box mt={0.5} pl={1.5}>
            <Typography>{team.name}</Typography>
            {(props.open || readers.length > 0) && (
                <Card>
                    <CardContent>
                        <FlexBox>
                            <FlexBox flexGrow={1} gap={2}>
                                {readers.map((v) => (
                                    <FlexBox key={v.id} flexWrap="nowrap" gap={0.5}>
                                        <TalentAvatar size="small" talent={v} />
                                        <Typography variant="body2">{fullName(v)}</Typography>
                                        <Tooltip title="閲覧権限を削除する">
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setTeamId(team.id);
                                                    setTalentId(v.id);
                                                    setDialogMode('RevokePermission');
                                                }}
                                            >
                                                <PersonAddDisabledIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </FlexBox>
                                ))}
                                {readers.length === 0 && (
                                    <Typography variant="body2" color="primary">
                                        閲覧者は設定されていません
                                    </Typography>
                                )}
                            </FlexBox>
                            <Box>
                                <Button
                                    variant="outlined"
                                    startIcon={<PersonAddIcon />}
                                    onClick={() => {
                                        setTeamId(team.id);
                                        setDialogMode('AddPermission');
                                    }}
                                >
                                    追加する
                                </Button>
                            </Box>
                        </FlexBox>
                    </CardContent>
                </Card>
            )}
            <Box
                ml={2}
                sx={(theme) => ({
                    marginLeft: '22px',
                    borderLeft: `1px dotted ${theme.palette.text.disabled}`,
                })}
            >
                {children.map((v) => (
                    <TeamCard
                        key={v.id}
                        teamId={v.id}
                        permissions={props.permissions}
                        open={props.open}
                    />
                ))}
            </Box>
        </Box>
    );
};
