import React from 'react';
import { Box, Typography } from '@mui/material';
import { queryToArray } from '../../../queries';
import { useWorkplaces } from '../../../queries/workplace';
import { ContextualDialog, DialogComponents } from '../../StableDialog';
import { WaitLoading } from '../../WaitLoading';
import { BackButton } from './BackButton';
import { ContextProvider, DialogMode } from './Context';
import { WorkplaceTable } from './WorkplaceTable';
import { AddWorkplaceDialog } from './dialogs/AddWorkplaceDialog';
import { DeleteWorkplaceDialog } from './dialogs/DeleteWorkplaceDialog';
import { EditWorkplaceDialog } from './dialogs/EditWorkplaceDialog';

export const Workplaces: React.FC = () => {
    const maybeWorkplaces = useWorkplaces();
    return (
        <ContextProvider>
            <BackButton />
            <Box mt={2} mx={2}>
                <Typography variant="subtitle1" color="primary">
                    勤務地の編集
                </Typography>
                <WaitLoading waitFor={[maybeWorkplaces]}>
                    <WorkplaceTable workplaces={queryToArray(maybeWorkplaces)} />
                </WaitLoading>
                <ContextualDialog components={dialogComponents} />
            </Box>
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    Add: AddWorkplaceDialog,
    Edit: EditWorkplaceDialog,
    Delete: DeleteWorkplaceDialog,
};
