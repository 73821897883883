import {
    Alert,
    Box,
    CircularProgress,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { LogRow } from '@spec/Logs';
import { TalentId } from '@spec/Talent';
import dayjs from 'dayjs';
import { useState } from 'react';
import { fullName } from '../../domains/Talent';
import { findById } from '../../lib/ArrayUtils';
import { queryToArray } from '../../queries';
import { useMeContext } from '../../queries/me';
import { useOperationLogs } from '../../queries/operationLogs';
import { useTalents } from '../../queries/talent';
import { useCurrentTimeContext } from '../Context';
import { HistoryIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { Pager, sliceItems } from '../Pager';
import { RequirePrivilege } from '../RequirePrivilege';
import { ExternalLink } from '../RouterLink';
import { WaitLoading, WaitQuery } from '../WaitLoading';
import { LogType, ReadWrite } from './Contract';
import { FilterForm } from './FilterForm';

export const Logs = () => {
    const { grants } = useMeContext();
    return (
        <Box>
            <PageTitle icon={HistoryIcon} title="操作履歴" />
            <RequirePrivilege fn={() => grants.readLogs}>
                <LogContents />
            </RequirePrivilege>
        </Box>
    );
};

const LogContents = () => {
    const { currentTime } = useCurrentTimeContext();
    const [year, setYear] = useState(currentTime.getFullYear());
    const [month, setMonth] = useState(currentTime.getMonth() + 1);
    const [logType, setLogType] = useState<LogType>('ALL');
    const [readWrite, setReadWrite] = useState<ReadWrite>('BOTH');
    const [path, setPath] = useState('');
    const logs = useOperationLogs(year, month);
    const filteredLogs = queryToArray(logs)
        .filter((v) => v.path.startsWith(path))
        .filter((v) => {
            switch (logType) {
                case 'ALL':
                    return true;
                case 'SUCCEEDED':
                    return v.sentryEventId === null;
                case 'ERROR':
                    return v.sentryEventId !== null;
                default:
                    return false;
            }
        })
        .filter((v) => {
            switch (readWrite) {
                case 'BOTH':
                    return true;
                case 'READ':
                    return v.method === 'GET';
                case 'WRITE':
                    return v.method !== 'GET';
                default:
                    return false;
            }
        });
    return (
        <Box>
            <FilterForm
                year={year}
                setYear={setYear}
                month={month}
                setMonth={setMonth}
                logType={logType}
                setLogType={setLogType}
                readWrite={readWrite}
                setReadWrite={setReadWrite}
                path={path}
                setPath={setPath}
            />
            <WaitLoading waitFor={[logs]}>
                <LogsTable logs={filteredLogs} />
            </WaitLoading>
        </Box>
    );
};

const LogsTable = (props: { logs: LogRow[] }) => {
    const ITEMS_PER_PAGE = 100;
    const [page, setPage] = useState(1);
    const logs = sliceItems(props.logs, page, ITEMS_PER_PAGE);
    if (props.logs.length === 0) {
        return (
            <Box mt={4} ml={4}>
                <Typography color="error">対象月の操作記録はありません</Typography>
            </Box>
        );
    }
    return (
        <Box mt={2}>
            <Alert severity="warning">
                この画面で現在閲覧可能な操作ログは、対象月の最新1万件までです。
            </Alert>
            <Pager
                current={page}
                setPage={setPage}
                amount={props.logs.length}
                perItems={ITEMS_PER_PAGE}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>requestTime</TableCell>
                        <TableCell>talent</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>IP</TableCell>
                        <TableCell>messages</TableCell>
                        <TableCell>error</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map((log, i) => (
                        <LogTableRow key={i} log={log} />
                    ))}
                </TableBody>
            </Table>
            <Pager
                current={page}
                setPage={setPage}
                amount={props.logs.length}
                perItems={ITEMS_PER_PAGE}
            />
        </Box>
    );
};

const LogTableRow = (props: { log: LogRow }) => {
    const log = props.log;
    const sx: SxProps = log.sentryEventId
        ? { [`& .${tableCellClasses.root}`]: { backgroundColor: deepOrange[100], color: '#000' } }
        : {};
    return (
        <TableRow sx={sx}>
            <TableCell>{dayjs(log.requestTime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
            <TableCell>{log.talentId && <Operator talentId={log.talentId} />}</TableCell>
            <TableCell>
                {log.method} {log.path}
            </TableCell>
            <TableCell>{log.remoteAddress}</TableCell>
            <TableCell>
                {log.messages.map((v, i) => (
                    <Typography key={i} variant="body2">
                        {v}
                    </Typography>
                ))}
            </TableCell>
            <TableCell>
                {log.sentryEventId && (
                    <ExternalLink
                        href={`https://sentry.io/carta-chihaya/serverless/events/${log.sentryEventId}`}
                    >
                        <img
                            src="/images/sentry-wordmark-dark-58x17.svg"
                            style={{ verticalAlign: 'middle' }}
                        />
                    </ExternalLink>
                )}
            </TableCell>
        </TableRow>
    );
};

const Operator = (props: { talentId: TalentId }) => {
    const maybeTalents = useTalents();
    return (
        <WaitQuery query={maybeTalents} loading={<CircularProgress color="secondary" size={12} />}>
            {({ data }) => <>{fullName(findById(props.talentId, data))}</>}
        </WaitQuery>
    );
};
