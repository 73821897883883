import { Link, LinkProps, useTheme } from '@mui/material';
import React from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { ApplicationError } from '../Errors';
import { useGateway } from '../stores/Gateway';
import { useTenantContext } from './Context';
import { FilterNoneIcon } from './Icons';

export const RouterLink: React.FC<
    Omit<LinkProps, 'component'> & {
        to: string;
        modal?: boolean;
    }
> = (props) => {
    const { modal, variant, ...rest } = props;
    const theme = useTheme();
    const location = useLocation();
    const gateway = useGateway();
    return (
        <Link
            sx={{ color: theme.palette.link }}
            component={ReactRouterLink}
            variant={variant ?? 'inherit'}
            reloadDocument={gateway.shouldUpdateUi()}
            state={
                modal
                    ? { modal: true, prevPath: [location.pathname, location.search].join('') }
                    : undefined
            }
            {...rest}
        />
    );
};

export const ExternalLink: React.FC<LinkProps & { icon?: boolean }> = (props) => {
    const { variant, icon, children, ...rest } = props;
    const theme = useTheme();
    return (
        <Link
            sx={{ color: theme.palette.link }}
            variant={variant ?? 'inherit'}
            target="_blank"
            rel="noreferrer noopener"
            {...rest}
        >
            {children}
            {icon === true && <FilterNoneIcon sx={{ marginLeft: '2px', fontSize: '12px' }} />}
        </Link>
    );
};

export const SlackLink = (props: LinkProps & { icon?: boolean; channel: string }) => {
    const { tenant } = useTenantContext();
    const href = getSlackChannelUrl(tenant, props.channel);
    return <ExternalLink {...props} href={href} />;
};

const getSlackChannelUrl = (tenant: string, channelId: string) => {
    switch (tenant) {
        case 'carta':
            return `https://cartaholdings.slack.com/archives/${channelId}`;
        case 'treasure2024':
            return `https://treasure2024hq.slack.com/archives/${channelId}`;
        default:
            throw new ApplicationError('Invalid tenant');
    }
};
