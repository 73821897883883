import {
    Box,
    Button,
    ButtonProps,
    CardActionArea,
    Container,
    IconButton,
    IconButtonProps,
    Link as MuiLink,
    type CardActionAreaProps,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import { FormTheme } from '../Theme';
import { DeleteIcon, EditIcon } from './Icons';
import { RouterLink } from './RouterLink';

export const EditIconButton: React.FC<IconButtonProps> = (props) => {
    const { size, ...rest } = props;
    const s = size ?? 'small';
    return (
        <IconButton size={s} {...rest}>
            <EditIcon fontSize={s} />
        </IconButton>
    );
};

export const DeleteIconButton: React.FC<IconButtonProps> = (props) => {
    const { size, ...rest } = props;
    const s = size ?? 'small';
    return (
        <IconButton size={s} {...rest}>
            <DeleteIcon fontSize={s} />
        </IconButton>
    );
};

export type LinkButtonProps = ButtonProps & LinkProps;

/**
 * Button that links to a route
 */
export const LinkButton: React.FC<LinkButtonProps> = (props) => {
    return <Button component={RouterLink} {...props} />;
};

/**
 * Button that links to an external site
 */
export const ExternalLinkButton: React.FC<ButtonProps> = (props) => (
    <Button component={MuiLink} target="_blank" rel="noreferrer noopener" {...props} />
);

export const ActionButton: React.FC<ButtonProps | LinkButtonProps> = (props) => {
    if (isLinkButtonProps(props)) {
        return <LinkButton size="medium" fullWidth variant="outlined" {...props} />;
    }
    return <Button size="medium" fullWidth variant="outlined" {...props} />;
};

const isLinkButtonProps = (v: ButtonProps | LinkButtonProps): v is LinkButtonProps =>
    Object.hasOwn(v, 'to');

export const ActionContainer: React.FC<{ mt?: number; children: ReactNode }> = (props) => {
    const mt = props.mt ?? 4;
    return (
        <FormTheme>
            <Container maxWidth="sm">
                <Box
                    mx={8}
                    mt={mt}
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    {props.children}
                </Box>
            </Container>
        </FormTheme>
    );
};

export const CardLink = (props: CardActionAreaProps & LinkProps) => (
    <CardActionArea component={RouterLink} underline="none" {...props} />
);
