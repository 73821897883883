import React, { createContext, ReactNode, useContext } from 'react';
import { Box, BoxProps, Paper, SxProps, Typography } from '@mui/material';
import { FormTheme } from '../Theme';
import { FlexBox } from './FlexBox';

const FilterFormContext = createContext({} as { dense: boolean });
const useFilterFormContext = () => useContext(FilterFormContext);

export const FilterForm: React.FC<{ dense?: boolean; children: ReactNode }> = (props) => {
    const dense = props.dense ?? false;
    return (
        <FilterFormContext.Provider value={{ dense }}>
            <FormTheme>
                <Paper>
                    <Box px={dense ? 2 : 3} py={dense ? 0.5 : 1.5}>
                        {props.children}
                    </Box>
                </Paper>
            </FormTheme>
        </FilterFormContext.Provider>
    );
};

export const FilterRow: React.FC<{ children: ReactNode }> = (props) => {
    const { dense } = useFilterFormContext();
    return (
        <FlexBox py={dense ? 0.5 : 1} gap={2}>
            {props.children}
        </FlexBox>
    );
};

export const FilterCondition: React.FC<
    BoxProps & { label?: ReactNode; labelWidth?: number; children: ReactNode }
> = (props) => {
    const { label, labelWidth, children, ...rest } = props;
    const sx: SxProps = { whiteSpace: 'nowrap' };
    if (labelWidth !== undefined) {
        sx.width = `${labelWidth}rem`;
    }
    return (
        <FlexBox flexWrap="nowrap" gap={1} {...rest}>
            {label && (
                <Typography variant="body2" sx={sx}>
                    {label}
                </Typography>
            )}
            {children}
        </FlexBox>
    );
};
