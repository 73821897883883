import { Alert, Box } from '@mui/material';
import { useGrants } from '../../queries/grant';
import { SecurityIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { WaitQuery } from '../WaitLoading';
import { ContextProvider } from './Context';
import { GrantsList } from './GrantsList';
import { Reference } from './Reference';

export const Grants = () => {
    const maybeGrants = useGrants();
    return (
        <ContextProvider>
            <PageTitle icon={SecurityIcon} title="権限管理" />
            <Box my={2}>
                <Alert severity="info">
                    本システムでは誰がどんな権限を持っているかは公開情報となります。
                </Alert>
            </Box>
            <Box my={2}>
                <Reference />
            </Box>
            <WaitQuery query={maybeGrants}>{({ data }) => <GrantsList grants={data} />}</WaitQuery>
        </ContextProvider>
    );
};
