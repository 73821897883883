import { Filter, FormText } from './Contract';

export const applyFilters = (formText: FormText): string =>
    [
        ...formText.filters.onChange,
        ...formText.filters.onCompositionEnd,
        ...formText.filters.onBlur,
    ].reduce((v, f) => f(v), formText.value);

export const trim: Filter = (v) => v.trim();

const KANA_CHAR_OFFSET = 0x60;

export const hiraToKana = (v: string): string =>
    v.replace(/[\u3041-\u3096]/g, (match) =>
        String.fromCharCode(match.charCodeAt(0) + KANA_CHAR_OFFSET)
    );

export const toLowerCase: Filter = (v) => v.toLowerCase();

export const numericOnly = (v: string): string => v.replace(/[^0-9]/g, '').replace(/^0+/, '');

const ZEN_ASCII_OFFSET = 0xfee0;
export const zenToHan = (v: string): string =>
    v
        .replace(/[−‐]/g, '-')
        .replace(/[０-９Ａ-Ｚａ-ｚ，．]/g, (match) =>
            String.fromCharCode(match.charCodeAt(0) - ZEN_ASCII_OFFSET)
        )
        .replace(/\u3000/g, ' ');

export const extractGitHubId = (url: string): string => {
    return url.replace('https://github.com/', '');
};
