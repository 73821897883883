import { Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import React from 'react';
import { getTalentEditPersonalityUrl } from '../../../Routes';
import { useMeContext } from '../../../queries/me';
import { ActionButton, ActionContainer } from '../../ActionButtons';
import { EditIcon } from '../../Icons';
import { SuspendTalent } from './suspend';
import { TransferTalent } from './transfer';
import { WithdrawTalent } from './withdraw';

export const EditMenu: React.FC<{ talent: Talent }> = (props) => {
    const { grants } = useMeContext();
    if (grants.editTalent === false) {
        return null;
    }
    return (
        <ActionContainer>
            <Typography variant="body2" color="primary">
                あなたは人の情報を編集する権限を持っています
            </Typography>
            <ActionButton
                startIcon={<EditIcon />}
                to={getTalentEditPersonalityUrl(props.talent.employment.employeeCode)}
            >
                プロフィールを編集する
            </ActionButton>
            <TransferTalent talent={props.talent} />
            <WithdrawTalent talent={props.talent} />
            <SuspendTalent talent={props.talent} />
        </ActionContainer>
    );
};
