import { Box, Button, Divider } from '@mui/material';
import { useDialogContext, useRegisterFormContext } from './Context';
import { EmploymentForm } from './EmploymentForm';
import { PersonalityForm } from './PersonalityForm';

export const RegisterFormContent: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    const { form } = useRegisterFormContext();
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Box mx={4} mt={4}>
                <EmploymentForm />
                <Box my={2}>
                    <Divider />
                </Box>
                <PersonalityForm />
                <Box mt={5} mb={2} mx={20}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="medium"
                        disabled={!form.canSubmit}
                        onClick={() => {
                            setDialogMode('Confirm');
                        }}
                    >
                        登録する
                    </Button>
                </Box>
            </Box>
        </form>
    );
};
