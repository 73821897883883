import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteSecondment } from '../../../../../queries/talent';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useEditTalentContext, useSecondmentContext } from '../Context';

export const DeleteSecondmentDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { employeeCode } = useEditTalentContext();
    const { secondment } = useSecondmentContext();
    if (secondment === null) {
        throw Error(`null Secondment`);
    }
    const form = useEmptyForm();
    const mutation = useDeleteSecondment(employeeCode);
    return (
        <>
            <DialogTitle>出向関連情報の削除</DialogTitle>
            <DialogContent>
                <DialogRow label="駐在区分">
                    <Typography>{secondment.assignmentType}</Typography>
                </DialogRow>
                <DialogRow label="企業名">
                    <Typography>{secondment.companyName}</Typography>
                </DialogRow>
                <DialogRow label="メールアドレス">
                    <Typography>{secondment.email}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この出向関連情報を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="出向関連情報の削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
