import React from 'react';
import { Box } from '@mui/material';
import { Form, FormTextField, useForm, useFormText } from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { DialogRow } from '../../../../StableDialog';

type TodoCategoryForm = Form<EditTodoCategoryRequest>;

export const useTodoCategoryForm = (v: EditTodoCategoryRequest) =>
    useForm<typeof v>({
        label: useFormText(v.label, [required]),
    });

interface EditTodoCategoryRequest {
    label: string;
}

export const TodoItemFormContent: React.FC<{ form: TodoCategoryForm }> = ({ form }) => {
    return (
        <Box>
            <DialogRow label="カテゴリ名">
                <FormTextField autoFocus fullWidth formText={form.label} />
            </DialogRow>
        </Box>
    );
};
