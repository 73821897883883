import { Team } from '@spec/Organization';
import { createContext, ReactNode, useContext } from 'react';
import { StateValue, useStateValue } from '../../../lib/Context';
import { FormBoolean, useFormBoolean } from '../../../lib/Form';
import {
    DialogContextProvider,
    TalentsContextProvider,
    useGenericDialogContext,
} from '../../Context';

export type DialogMode = 'CreateTeam' | 'EditTeam' | 'AbolishTeam' | 'RestoreTeam' | 'DeleteTeam';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const SelectedTeamContext = createContext(
    {} as {
        selectedTeam: StateValue<Team | null>;
    }
);
const SelectedTeamContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const selectedTeam = useStateValue<Team | null>(null);
    return (
        <SelectedTeamContext.Provider value={{ selectedTeam }}>
            {props.children}
        </SelectedTeamContext.Provider>
    );
};
export const useSelectedTeamContext = () => useContext(SelectedTeamContext);

const ShowAdditionalMembersContext = createContext(
    {} as {
        showAdditionalMembers: FormBoolean;
    }
);
const ShowAdditionalMembersContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const showAdditionalMembers = useFormBoolean(true);
    return (
        <ShowAdditionalMembersContext.Provider value={{ showAdditionalMembers }}>
            {props.children}
        </ShowAdditionalMembersContext.Provider>
    );
};
export const useShowAdditionalMembersContext = () => useContext(ShowAdditionalMembersContext);

export const ContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    return (
        <TalentsContextProvider>
            <DialogContextProvider>
                <SelectedTeamContextProvider>
                    <ShowAdditionalMembersContextProvider>
                        {props.children}
                    </ShowAdditionalMembersContextProvider>
                </SelectedTeamContextProvider>
            </DialogContextProvider>
        </TalentsContextProvider>
    );
};
