import type { TodoCategoriesResponse } from '@spec/Todo';
import { useQuery } from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useTodoCategories = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.todoCategories],
        queryFn: () =>
            gateway.get<TodoCategoriesResponse>('/todo/categories').then((res) => res.categories),
    });
};
