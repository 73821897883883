import { LeaveOfAbsence, Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import React from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { queryToArray } from '../../../queries';
import { useLeaveOfAbsences } from '../../../queries/talent';
import { FlexBox } from '../../FlexBox';
import { PrivilegeTip } from '../../HelpTip';
import { WaitLoading } from '../../WaitLoading';

export const LeaveOfAbsences: React.FC<{ talent: Talent }> = (props) => {
    const maybeAbsences = useLeaveOfAbsences();
    const absences = queryToArray(maybeAbsences).filter((v) => v.talentId === props.talent.id);
    return (
        <Box mt={4}>
            <FlexBox>
                <Typography>休職情報</Typography>
                <PrivilegeTip privilege="SHOW_FULL_PERSONALITY" />
            </FlexBox>
            <WaitLoading size="medium" waitFor={[maybeAbsences]}>
                <Box mt={1}>
                    <AbsencesTable absences={absences} />
                </Box>
            </WaitLoading>
        </Box>
    );
};

const AbsencesTable: React.FC<{ absences: LeaveOfAbsence[] }> = (props) => {
    if (props.absences.length === 0) {
        return <Typography variant="body2">休職の記録はありません</Typography>;
    }
    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>休職日</TableCell>
                        <TableCell>復職日</TableCell>
                        <TableCell>休職日数</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.absences.map((v) => (
                        <TableRow key={v.id}>
                            <TableCell>{format(v.leavedAt)}</TableCell>
                            <TableCell>{format(v.returnedAt)}</TableCell>
                            <TableCell>
                                {v.returnedAt === null
                                    ? '-'
                                    : dayjs(v.returnedAt).diff(v.leavedAt, 'days')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

const format = (v: Date | null): string => {
    if (v === null) {
        return '-';
    }
    return dayjs(v).format('YYYY-MM-DD');
};
