import React, { type ReactNode } from 'react';
import { ApplicationError, reportError } from '../../Errors';
import { DialogContextProvider, useFirebaseUserContext } from '../Context';
import { ContextualDialog, DialogComponents } from '../StableDialog';
import { NoMenuLayout } from '../layout/NoMenuLayout';
import { ConfirmDialog } from './ConfirmDialog';
import { DialogMode } from './Context';
import { SignUpFormContent, SignUpFormContextProvider } from './SignUpForm';

export const SignUp: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { firebaseUser } = useFirebaseUserContext();
    if (firebaseUser.email === null) {
        reportError(new ApplicationError('Unexpected null firebase user.'));
        return null;
    }
    return (
        <NoMenuLayout>
            <ContextProvider>
                <SignUpFormContent email={firebaseUser.email} />
                <ContextualDialog components={dialogComponents} />
            </ContextProvider>
        </NoMenuLayout>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    Confirm: ConfirmDialog,
};

const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <SignUpFormContextProvider>{props.children}</SignUpFormContextProvider>
        </DialogContextProvider>
    );
};
