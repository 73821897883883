import { DialogTitle } from '@mui/material';
import React from 'react';
import { useEditPersonalEvent } from '../../../../queries/me';
import { DialogActionButtons } from '../../../StableDialog';
import { useDialogContext, usePersonalEventHistoryContext } from '../Context';
import {
    PersonalEventForm,
    toEditPersonalEventRequest,
    usePersonalEventHistoryForm,
} from '../PersonalEventForm';

export const EditPersonalEventDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { personalEvent } = usePersonalEventHistoryContext();
    if (personalEvent === null) {
        throw Error('null personalEvent');
    }
    const form = usePersonalEventHistoryForm({
        label: personalEvent.label,
        startYear: `${personalEvent.startYear}`,
        endYear: personalEvent.endYear === null ? '' : `${personalEvent.endYear}`,
    });
    const mutation = useEditPersonalEvent(personalEvent.id);
    return (
        <>
            <DialogTitle>経歴の編集</DialogTitle>
            <PersonalEventForm form={form} />
            <DialogActionButtons
                form={form}
                submitLabel="この内容で経歴を更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="経歴の更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(toEditPersonalEventRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};
