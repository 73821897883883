import { Box, TableCell, TableRow } from '@mui/material';
import type { Grant } from '@spec/Grants';
import type { Team } from '@spec/Organization';
import type { Talent, TalentId } from '@spec/Talent';
import dayjs, { type Dayjs } from 'dayjs';
import { privilegeLabelMap } from '../../domains/Grants';
import { fullName } from '../../domains/Talent';
import { findById } from '../../lib/ArrayUtils';
import { useTalentsContext, useTeamsContext } from '../Context';
import { PopoverTalent } from '../PopoverTalent';
import { SortableTable } from '../SortableTable';
import { useFilterFormContext } from './Context';

interface Row {
    id: string;
    talentId: TalentId;
    employeeCode: string;
    talentName: string;
    baseTeamName: string;
    privilege: string;
    grantedAt: Dayjs;
}

const headers: Array<[keyof Row, string]> = [
    ['privilege', '権限'],
    ['employeeCode', '社員番号'],
    ['talentName', '氏名'],
    ['baseTeamName', '所属'],
    ['grantedAt', '付与日時'],
];

const grantToRow = (grant: Grant, talents: Talent[], teams: Team[]): Row => {
    const talent = findById(grant.talentId, talents);
    const team = findById(talent.teamId, teams);
    const baseTeam = findById(team.baseTeamId, teams);
    const grantedAt = dayjs(grant.grantedAt);
    return {
        id: `${grant.talentId}-${grant.privilege}-${grantedAt.format('YYYYMMDDHHmmss')}`,
        talentId: grant.talentId,
        employeeCode: talent.employment.employeeCode,
        talentName: fullName(talent),
        baseTeamName: baseTeam.name,
        privilege: privilegeLabelMap.get(grant.privilege),
        grantedAt,
    };
};

export const GrantsTable = (props: { grants: Grant[] }) => {
    const { talents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const { page, setPage } = useFilterFormContext();
    return (
        <Box>
            <SortableTable
                stickyHeader
                headers={headers}
                defaultSortKey="grantedAt"
                defaultSortDirection="desc"
                secondarySortKey="privilege"
                rows={props.grants.map((v) => grantToRow(v, talents, teams))}
                pagerProps={{
                    current: page,
                    setPage,
                    amount: props.grants.length,
                    perItems: 50,
                }}
            >
                {(row) => (
                    <TableRow hover>
                        <TableCell>{row.privilege}</TableCell>
                        <TableCell>{row.employeeCode}</TableCell>
                        <TableCell>
                            <PopoverTalent talent={findById(row.talentId, talents)}>
                                {row.talentName}
                            </PopoverTalent>
                        </TableCell>
                        <TableCell>{row.baseTeamName}</TableCell>
                        <TableCell>{row.grantedAt.format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    </TableRow>
                )}
            </SortableTable>
        </Box>
    );
};
