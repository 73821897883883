import { Typography } from '@mui/material';
import { SurveyLikertResponse, SurveyQuestion } from '@spec/Survey';
import { isLikertQuestion } from '../../../../domains/Survey';
import { FlexBox } from '../../../FlexBox';

export const ScoreList: React.FC<{
    questions: SurveyQuestion[];
    responses: SurveyLikertResponse[];
}> = (props) => {
    const questions = props.questions.filter(isLikertQuestion);
    return (
        <FlexBox gap={2}>
            {questions.map((q) => {
                const res = props.responses.find((v) => v.questionId === q.id);
                return (
                    <FlexBox key={q.id} gap={0.5}>
                        <Typography variant="body2">{q.shortTitle}</Typography>
                        <Typography>{res?.score ?? '-'}</Typography>
                    </FlexBox>
                );
            })}
        </FlexBox>
    );
};
